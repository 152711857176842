/**
 * catalogs of countries
 * @constant
 */
export const COUNTRIES = [
  { value: 'MX', label: 'México' },
  { value: 'CO', label: 'Colombia' },
  { value: 'AR', label: 'Argentina' },
  { value: 'ES', label: 'España' },
  { value: 'PT', label: 'Portugal' },
  { value: 'IT', label: 'Italia' },
  { value: 'BR', label: 'Brasil' }
]

/**
 * catalogs of countries
 * @constant
 */
export const AREA_OPTIONS = [
  { id: 'engineering', label: 'Ingeniería' },
  { id: 'check', label: 'Check' },
  { id: 'marketing', label: 'Marketing' },
  { id: 'quality', label: 'QA' },
  { id: 'credit', label: 'Crédito' },
  { id: 'management', label: 'Dirección General' },
  { id: 'finances', label: 'Finanzas' },
  { id: 'ncc', label: 'NCC' },
  { id: 'negotiation', label: 'Negociación' },
  { id: 'operations', label: 'Operaciones' },
  { id: 'financial_planning', label: 'Planeación Financiera' },
  { id: 'customer_service', label: 'Servicio al Cliente' },
  { id: 'human_resources', label: 'Talento' },
  { id: 'sales', label: 'Ventas' },
  { id: 'la_tasa', label: 'La Tasa' },
  { id: 'enconta', label: 'Enconta' },
  { id: 'colombia', label: 'Colombia' },
  { id: 'argentina', label: 'Argentina' },
  { id: 'strategic_planning', label: 'Planeación Estratégica' },
  { id: 'accountants', label: 'Contadores' },
  { id: 'hydra', label: 'Hydra' }
]

/**
 * @constant
 */
export const LOCALES = {
  MXN: 'es-MX',
  COP: 'es-CO',
  ARS: 'es-AR',
  USD: 'es-US',
  EUR: 'en-478'
}

/**
 * @constant
 */
export const TAXES_TYPES = [
  { id: '01', label: 'IVA', labelComplete: 'Impuesto de Valor Agregado' },
  { id: '02', label: 'IC', labelComplete: 'Impuesto al Consumo' },
  {
    id: '03',
    label: 'ICA',
    labelComplete: 'Impuesto de Industria, Comercio y Aviso'
  },
  { id: '04', label: 'INC', labelComplete: 'Impuesto Nacional al Consumo' },
  { id: '05', label: 'ReteIVA', labelComplete: 'Retención sobre el IVA' },
  { id: '06', label: 'ReteFuente', labelComplete: 'Retención sobre Renta' },
  { id: '07', label: 'ReteICA', labelComplete: 'Retención sobree el ICA' },
  {
    id: '20',
    label: 'FtoHorticultura',
    labelComplete: 'Cuota de fomento Horticultura'
  },
  { id: '21', label: 'Timbre', labelComplete: 'Impuesto de Timbre' },
  {
    id: '22',
    label: 'Bolsas',
    labelComplete: 'Impuesto al consumo de Bolsa Plástica'
  },
  {
    id: '23',
    label: 'INCarbono',
    labelComplete: 'Impuesto Nacional al Carbono'
  },
  {
    id: '24',
    label: 'INCombustibles',
    labelComplete: 'Impuesto Nacional a los Combustibles'
  },
  {
    id: '25',
    label: 'Sobretasa Combustibles',
    labelComplete: 'Sobretasa a los Combustibles'
  },
  {
    id: '26',
    label: 'Sordicom',
    labelComplete: 'Contribución minoristas(Combustibles)'
  },
  { id: 'ZY', label: 'No causa', labelComplete: 'No responsable de tributo' },
  {
    id: 'ZZ',
    label: 'Nonmbre de la figura tributaria',
    labelComplete: 'Otros tributos, tasas, contribuciones y similares'
  }
]

/**
 * @constant
 */
export const REQUIRED = [{ id: 'true', label: 'Si' }, { id: 'false', label: 'No' }]

/**
 * @constant
 */
export const INPUT_TYPES = [
  { id: 'text', label: 'Texto' },
  { id: 'date', label: 'Fecha' },
  { id: 'select', label: 'Opción múltiple' },
  { id: 'url', label: 'URL' },
  { id: 'money', label: 'Dinero' },
  { id: 'file', label: 'Archivo' }
]

/**
 * @constant
 */
export const CHARGES_STATUS = [
  { id: 'PAYMENT PENDING', label: 'Pago pendiente' },
  { id: 'PAID', label: 'Pagado' },
  { id: 'CANCELLED', label: 'Cancelado' },
  { id: 'UNPAYABLE', label: 'Incobrable' },
  { id: 'CONDONED', label: 'Condonado' },
  { id: 'RETURNED', label: 'Devolución' }
]

/**
 * @constant
 */
export const EXPENSES_STATUS = [
  { id: 'EN ESPERA', label: 'EN ESPERA' },
  { id: 'PAGADO', label: 'PAGADO' },
  { id: 'COMPLETADO', label: 'COMPLETADO' },
  { id: 'CANCELADO', label: 'CANCELADO' }
]
/**
 * Definir con back los posibles tipos
 * @constant
 */
export const EXPENSES_TYPES = [
  { id: 'BURÓ DE CREDITO', label: 'Buró de crédito' },
  { id: 'COMISIONES BANCARIAS', label: 'Comisiones bancarias' }
]

/**
 * @constant
 */
export const CURRENCIES = [
  { id: 'MXN', label: 'Pesos Mexicanos' },
  { id: 'COP', label: 'Pesos Colombianos' },
  { id: 'ARS', label: 'Pesos Argentinos' },
  { id: 'USD', label: 'Dólares' },
  { id: 'EUR', label: 'Euros' }
]

/**
 * @constant
 */
export const PRODUCT_LINES = [
  { id: 'rtd', label: 'Resuelve' },
  { id: 'check', label: 'Check' },
  { id: 'la_tasa', label: 'La Tasa' },
  { id: 'credito', label: 'Crédito' },
  { id: 'bravo', label: 'Bravo' }
]

export const MOVEMENTS_CSV_STATUS = {
  with_errors: 'Con Errores',
  completed: 'Completado',
  processing: 'Procesando'
}

/**
 * @constant
 */
export const INVOICE_ONE_STATUS = [
  { id: 'DRAFT', label: 'BORRADOR' },
  { id: 'SENT', label: 'ENVIADO' },
  { id: 'ERROR', label: 'ERROR' }
]
/**
 * @constant
 */
export const INVOICE_ONE_TYPES = [
  { id: 'FACTURA', label: 'FACTURA' },
  { id: 'NOTA CREDITO', label: 'NOTA CREDITO' }
]

/**
 * @constant
 */
export const SAVING_VEHICLE = [{ id: 'pisa', label: 'Pisa' }]

/**
 * @constant
 */
export const CLARIFICATION_STATUS = {
  new: 'Nuevo',
  in_progress: 'En proceso',
  canceled: 'Cancelada',
  approved: 'Aprobada'
}

/**
 * @constant
 */
export const STEPS_REQUEST_FAILED = {
  rtd: [
    {
      id: 1,
      code: 'start',
      label: 'Inicio del proceso de aprobación'
    },
    {
      id: 2,
      code: 'bank_account_creation',
      label: 'Creación de la cuenta en Bank'
    },
    {
      id: 3,
      code: 'document_data_mapping',
      label: 'Validación de la información de la solicitud'
    },
    {
      id: 4,
      code: 'document_creation',
      label: 'Creación de documentos'
    },
    {
      id: 5,
      code: 'upload_gcs',
      label: 'Carga de documentos a GCS'
    },
    {
      id: 6,
      code: 'upload_aws_autentic',
      label: 'Carga de documentos al AWS de Autentic'
    },
    {
      id: 7,
      code: 'send_document',
      label: 'Envío de documentos'
    },
    {
      id: 8,
      code: 'package_creation',
      label: 'Creación del paquete'
    },
    {
      id: 9,
      code: 'cr_status_update',
      label: 'Actualización del estatus de la solicitud vía PubSub'
    },
    {
      id: 10,
      code: 'package_assigned_contract',
      label: 'Asignación de paquete al contrato'
    }
  ],
  bravo: [
    {
      id: 1,
      code: 'start',
      label: 'Inicio del proceso de aprobación'
    },
    {
      id: 2,
      code: 'bank_account_creation',
      label: 'Creación de la cuenta en Bank'
    },
    {
      id: 3,
      code: 'document_data_mapping',
      label: 'Validación de la información de la solicitud'
    },
    {
      id: 4,
      code: 'document_creation',
      label: 'Creación de documentos'
    },
    {
      id: 5,
      code: 'upload_gcs',
      label: 'Carga de documentos a GCS'
    },
    {
      id: 6,
      code: 'upload_aws_autentic',
      label: 'Carga de documentos al AWS de Autentic'
    },
    {
      id: 7,
      code: 'send_document',
      label: 'Envío de documentos'
    },
    {
      id: 8,
      code: 'package_creation',
      label: 'Creación del paquete'
    },
    {
      id: 9,
      code: 'cr_status_update',
      label: 'Actualización del estatus de la solicitud vía PubSub'
    },
    {
      id: 10,
      code: 'package_assigned_contract',
      label: 'Asignación de paquete al contrato'
    }
  ]
}

/**
 * @constant
 */
export const CONTRACT_REQUEST_STATUS = {
  review_pending: 'Revisión pendiente',
  in_review: 'En revisión',
  canceled: 'Cancelado',
  rejected: 'Rechazado',
  approved: 'Aprobado',
  contract_created: 'Contrato creado',
  canceled_after_approve: 'Cancelado despues de ser aprobado'
}

/**
 * @constant
 */
export const DOCUMENT_TITLES = {
  DOCUMENT_PASSPORT: 'Pasaporte',
  DOCUMENT_IDENTITY_FRONT: 'Documento de identidad frente',
  DOCUMENT_IDENTITY_BACK: 'Documento de identidad reverso',
  DOCUMENT_ADDRESS_FRONT: 'Comprobante de domicilio frente',
  DOCUMENT_ADDRESS_BACK: 'Comprobante de domicilio reverso',
  DOCUMENT_CREDIT_REPORT: 'Reporte de crédito',
  DOCUMENT_SETTLEMENT_PLAN: 'Plan de liquidación',
  DEBT_ATTACHMENT: 'Comprobante de deuda',
  DOCUMENT_OTHER: 'Otros documentos',
  ADDRESS_COMPROBANT: 'Comprobante de dirección',
  DOCUMENT_PAYROLL: 'Documento de nomina'
}

/**
 * @constant
 */
export const DOC_TYPES = {
  'text/xml': 'document',
  'image/gif': 'image',
  'image/jpeg': 'image',
  'text/plain': 'document',
  'image/png': 'image',
  'image/x-ms-bmp': 'image',
  'image/svg+xml': 'image',
  'image/webp': 'image',
  'application/msword': 'document',
  'application/pdf': 'pdf',
  'application/vnd.ms-excel': 'document',
  'application/vnd.ms-powerpoint': 'document',
  'application/vnd.wap.wmlc': 'audio',
  'application/zip': 'compress',
  'audio/mpeg': 'audio',
  'audio/mp3': 'audio',
  'audio/ogg': 'audio',
  'video/3gpp': 'video',
  'video/mpeg': 'video',
  'audio/wav': 'audio',
  'video/quicktime': 'video',
  'video/x-flv  ': 'video',
  'video/x-ms-wmv': 'video',
  'video/x-msvideo ': 'video',
  'video/mp4': 'video'
}

/**
 * @constant
 */
export const PREVIEW_TYPES = {
  gif: 'image',
  jpeg: 'image',
  jpg: 'image',
  png: 'image',
  bmp: 'image',
  webp: 'image',
  pdf: 'pdf',
  mpeg: 'audio',
  mp3: 'audio',
  ogg: 'audio',
  wav: 'audio',
  default: 'default'
}

/**
 * @constant
 */
export const CLIENT_STATUS = {
  client: 'Activo',
  opportunity: 'Inactivo',
  drop: 'Baja'
}

/**
 * @constant
 */
export const FILES_ICONS = {
  'text/xml': 'mdi-file-xml',
  'image/gif': 'mdi-file-image',
  'image/jpeg': 'mdi-file-image',
  'text/plain': 'mdi-file',
  'image/png': 'mdi-file-image',
  'image/x-ms-bmp': 'mdi-file-image',
  'image/svg+xml': 'mdi-file-image',
  'image/webp': 'mdi-file-image',
  'application/msword': 'mdi-file-document',
  'application/pdf': 'mdi-file-pdf',
  'application/vnd.ms-excel': 'mdi-file-excel',
  'application/vnd.ms-powerpoint': 'mdi-file-powerpoint',
  'application/vnd.wap.wmlc': 'mdi-file-music',
  'application/zip': 'mdi-file-hidden',
  'audio/mpeg': 'mdi-file-music',
  'audio/ogg': 'mdi-file-music',
  'video/3gpp': 'mdi-file-video',
  'video/mpeg': 'mdi-file-video',
  'audio/wav': 'mdi-file-music',
  'video/quicktime': 'mdi-file-video',
  'video/x-flv  ': 'mdi-file-video',
  'video/x-ms-wmv': 'mdi-file-video',
  'video/x-msvideo ': 'mdi-file-video',
  'video/mp4': 'video'
}

export const MIME_TYPES = [
  'text/xml',
  'image/*',
  'application/pdf',
  'text/csv',
  'application/x-rar-compressed',
  'application/zip',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow'
]

/**
 * @constant
 * @see https://api.github.com/emojis
 */
export const FLAGS = {
  mx:
    'https://github.githubassets.com/images/icons/emoji/unicode/1f1f2-1f1fd.png?v8',
  co:
    'https://github.githubassets.com/images/icons/emoji/unicode/1f1e8-1f1f4.png?v8',
  ar:
    'https://github.githubassets.com/images/icons/emoji/unicode/1f1e6-1f1f7.png?v8',
  es:
    'https://github.githubassets.com/images/icons/emoji/unicode/1f1ea-1f1f8.png?v8',
  it:
    'https://github.githubassets.com/images/icons/emoji/unicode/1f1ee-1f1f9.png?v8',
  pt:
    'https://github.githubassets.com/images/icons/emoji/unicode/1f1f5-1f1f9.png?v8'
}
