// Libraries
import React from 'react'
import { withFormik, Form, Field, ErrorMessage } from 'formik'
import styled from 'styled-components'
import Notification from 'cogo-toast'

// Comoponents
import { FieldWrapper } from 'css/utils'
import SelectField from 'shared/selectField'
import FieldAmount from 'shared/fieldAmount'
import Button from 'shared/button'
import Required from 'shared/required'
import { absolutify } from 'utils'
import API from 'api/core'

const CHARGES_STATUS = [
  { id: 'RETURNED', label: 'DEVOLUCIÓN' },
  { id: 'CONDONED', label: 'CONDONACIÓN' }
]

/**
 * @param {String} value
 * @param {FormikValues} values
 * @param {TypeIncomeDetails} details
 */
function validateAmount (value, values, details) {
  const returned =
    absolutify(details.receivable_amount) - absolutify(details.remaining_amount)
  const [_, amountValue] = values.amount.split(' ') //eslint-disable-line
  const amountFloat = absolutify(amountValue)
  let error
  if (!value) {
    error = 'Campo requerido'
  } else if (values.incomeType === 'RETURNED' && amountFloat > returned) {
    error = `Monto exedido. No debe ser superior ${returned}`
  } else if (
    values.incomeType === 'CONDONED' &&
    amountFloat > absolutify(details.receivable_amount)
  ) {
    error = `Monto exedido. No debe ser superior ${details.receivable_amount}`
  }
  return error
}

const WrapperButtons = styled.div`
  border-top: 1px solid #dbdbdb;
  background-color: whitesmoke;
  padding: 1.25rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  margin-bottom: -1.25rem;
  margin-top: 1.25rem;
`

/**
 * @typedef {import('formik').FormikProps<FormikValues>} FormikProps
 * @typedef {import('api/core').TypeIncomeDetails} TypeIncomeDetails
 * @typedef {{
 *  incomeType: string,
 * amount: string,
 * floatAmount: number | null
 * }} FormikValues
 * @typedef {{
 * floatValue: number,
 * value: string,
 * formattedValue: string,
 * }} formatValue
 * @typedef {{
 * details: TypeIncomeDetails,
 * toggleModal: any
 * }} PropsCreateCharges
 * @param {PropsCreateCharges & FormikProps} props
 */
const CreateCharges = ({
  handleChange,
  setFieldValue,
  values,
  isSubmitting,
  details,
  toggleModal
}) => {
  /**
   * @param {formatValue} formatValues
   */
  function changeAmount (formatValues) {
    const { formattedValue, floatValue } = formatValues
    setFieldValue('amount', formattedValue)
    setFieldValue('floatAmount', floatValue)
  }

  return (
    <div>
      <Form onCutCapture={handleChange}>
        <FieldWrapper>
          <Field name='incomeType'>
            {({ field }) => (
              <SelectField
                {...field}
                id='incomeType'
                name='incomeType'
                label='Tipo de ingreso'
                placeholder='Seleciona una opción'
                options={CHARGES_STATUS}
                required
              />
            )}
          </Field>
          <ErrorMessage
            name='incomeType'
            render={message => (
              <Required message={message} className='required-modal' />
            )}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Field
            name='amount'
            validate={value => validateAmount(value, values, details)}
          >
            {({ field }) => (
              <FieldAmount
                {...field}
                name='amount'
                label='Monto a cobrar'
                placeholder='Monto del cargo'
                currency={details.currency}
                onValueChange={formatValues => changeAmount(formatValues)}
                required
              />
            )}
          </Field>
          <ErrorMessage
            name='amount'
            render={message => (
              <Required message={message} className='required-modal' />
            )}
          />
        </FieldWrapper>
        <WrapperButtons>
          <Button
            type='submit'
            icon='send'
            buttonClass='primary right'
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Enviar
          </Button>
          <Button icon='cancel' buttonClass='secondary' onClick={toggleModal}>
            Cancelar
          </Button>
        </WrapperButtons>
      </Form>
    </div>
  )
}
export default withFormik({
  mapPropsToValues: () => ({
    incomeType: '',
    amount: '',
    floatAmount: null
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const data = {
      status: values.incomeType,
      amount:
        values.incomeType === 'RETURNED'
          ? `-${values.floatAmount}`
          : values.floatAmount,
      income_id: props.details.id
    }
    setSubmitting(true)
    try {
      await API.Accounting.CreateCharges(data)
      Notification.success('Cargo creado con éxito')
      props.getIncomeDetails()
      props.toggleModal()
    } catch (error) {
      console.error(error)
      Notification.error('Ocurrio un error, intenta de nuevo')
    }
    setSubmitting(false)
  }
})(CreateCharges)
