// Libraries
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import classNames from 'classnames'
import update from 'immutability-helper'
import validator from 'validator'
import Notification from 'cogo-toast'

// Componentes
import API from 'api/payments'
import Title from 'shared/title'
import Button from 'shared/button'

const title = 'Crear Pago'
const breadcrumbLocation = [
  { label: 'Gateway de pagos', route: '/payments' },
  { label: title, route: '' }
]

class NewPayment extends Component {
  constructor (props) {
    super(props)
    this.data = {
      form: {
        amount: '0',
        carrier: '',
        clabe: '',
        currency: '',
        description: '',
        email: '',
        last_name: '',
        mobile_number: '',
        name: '',
        payment_method: '',
        reference: '',
        requester: '',
        webhook_url: ''
      },
      selects: {
        carriers: [{ value: 'Santander', label: 'Santander' }],
        currencies: [
          { value: 'MXN', label: 'MXN' },
          { value: 'COP', label: 'COP' },
          { value: 'ARS', label: 'ARS' }
        ],
        payment_methods: [{ value: 'clabe', label: 'Cuenta clabe' }],
        requesters: [
          { value: 'RTD AR', label: 'RTD AR' },
          { value: 'RTD CO', label: 'RTD CO' },
          { value: 'RTD MX', label: 'RTD MX' }
        ]
      },
      options: {
        carriers: ['Santander'],
        currencies: ['MXN', 'COP', 'ARS'],
        payment_methods: ['clabe'],
        requesters: ['RTD AR', 'RTD CO', 'RTD MX']
      },
      isValid: {
        amount: true,
        carrier: true,
        clabe: true,
        currency: true,
        description: true,
        last_name: true,
        name: true,
        payment_method: true,
        reference: true,
        requester: true,
        webhook_url: true
      },
      requestInProgress: false
    }
    this.state = Object.assign({}, this.data)
  }

  // --------------------------------------------------------------------------
  // Previene duplicados en el envío del formulario.
  // Valida los inputs.
  // Envía la petición al servidor solo si la validación fue exitosa.
  // --------------------------------------------------------------------------

  sendData (e) {
    e.preventDefault()

    const isValidInputs =
      this.isValidReference() &&
      this.isValidCarrier() &&
      this.isValidPaymentMethod() &&
      this.isValidAmount() &&
      this.isValidCurrency() &&
      this.isValidRequester() &&
      this.isValidDescription() &&
      this.isValidCLABE() &&
      this.isValidWebHook()

    if (isValidInputs && !this.state.requestInProgress) {
      const payment = {
        reference: this.state.form.reference,
        carrier: this.state.form.carrier,
        payment_method: this.state.form.payment_method,
        amount: this.state.form.amount,
        currency: this.state.form.currency,
        requester: this.state.form.requester,
        description: this.state.form.description,
        customer_metadata: {
          name: this.state.form.name,
          last_name: this.state.form.last_name,
          email: this.state.form.email,
          mobile_number: this.state.form.mobile_number
        },
        transaction_metadata: {
          clabe: this.state.form.clabe,
          webhook_url: this.state.form.webhook_url
        }
      }

      this.setState({ requestInProgress: true }, function () {
        API.Payments.Create({ transaction: payment })
          .then(response => {
            this.setState({ requestInProgress: false })
            if (response.status === 'error') {
              Notification.error('Error al intentar general el pago.')
            } else {
              this.props.history.push('/payments')
            }
          })
          .catch(error => {
            console.error(error)
          })
      })
    }
  }

  // --------------------------------------------------------------------------
  // Valida que el tipo de dato de cada input sea correcto.
  // --------------------------------------------------------------------------

  isValidReference () {
    const isValid = !validator.isEmpty(this.state.form.reference)

    this.setState({
      isValid: update(this.state.isValid, { reference: { $set: isValid } })
    })
    return isValid
  }

  isValidCarrier () {
    const isValid = this.state.options.carriers.includes(
      this.state.form.carrier
    )

    this.setState({
      isValid: update(this.state.isValid, { carrier: { $set: isValid } })
    })
    return isValid
  }

  isValidPaymentMethod () {
    const isValid = this.state.options.payment_methods.includes(
      this.state.form.payment_method
    )

    this.setState({
      isValid: update(this.state.isValid, { payment_method: { $set: isValid } })
    })
    return isValid
  }

  isValidAmount () {
    const isValid = validator.isDecimal(this.state.form.amount)

    this.setState({
      isValid: update(this.state.isValid, { amount: { $set: isValid } })
    })
    return isValid
  }

  isValidCurrency () {
    const isValid = this.state.options.currencies.includes(
      this.state.form.currency
    )

    this.setState({
      isValid: update(this.state.isValid, { currency: { $set: isValid } })
    })
    return isValid
  }

  isValidRequester () {
    const isValid = this.state.options.requesters.includes(
      this.state.form.requester
    )

    this.setState({
      isValid: update(this.state.isValid, { requester: { $set: isValid } })
    })
    return isValid
  }

  isValidDescription () {
    const isValid = !validator.isEmpty(this.state.form.description)
    this.setState({
      isValid: update(this.state.isValid, { description: { $set: isValid } })
    })
    return isValid
  }

  isValidCLABE () {
    const isValid = !validator.isEmpty(this.state.form.clabe)
    this.setState({
      isValid: update(this.state.isValid, { clabe: { $set: isValid } })
    })
    return isValid
  }

  isValidName () {
    const isValid = !validator.isEmpty(this.state.form.name)
    this.setState({
      isValid: update(this.state.isValid, { name: { $set: isValid } })
    })
    return isValid
  }

  isValidLastName () {
    const isValid = !validator.isEmpty(this.state.form.last_name)
    this.setState({
      isValid: update(this.state.isValid, { last_name: { $set: isValid } })
    })
    return isValid
  }

  isValidWebHook () {
    const isValid = validator.isURL(this.state.form.webhook_url)
    this.setState({
      isValid: update(this.state.isValid, { webhook_url: { $set: isValid } })
    })
    return isValid
  }

  // --------------------------------------------------------------------------
  // Onchange value inputs.
  // Establece el valor de this.state.form.input
  // --------------------------------------------------------------------------

  onChangeForm (name, e) {
    if (typeof e.target === 'undefined') {
      this.setState({
        form: update(this.state.form, { [name]: { $set: e.value } })
      })
    } else {
      this.setState({
        form: update(this.state.form, { [name]: { $set: e.target.value } })
      })
    }
  }

  renderForm () {
    return (
      <form className=''>
        <div className='columns is-multiline'>
          <div className='column is-4'>
            <div className='field'>
              <label>Referencia *</label>
              <input
                className={classNames('input', {
                  'is-danger': !this.state.isValid.reference
                })}
                onBlur={this.isValidReference.bind(this)}
                onChange={this.onChangeForm.bind(this, 'reference')}
                type='text'
                value={this.state.form.reference}
              />
            </div>
          </div>
          <div className='column is-4'>
            <div className='field'>
              <label>Carrier *</label>
              <Select
                onBlur={this.isValidCarrier.bind(this)}
                onChange={this.onChangeForm.bind(this, 'carrier')}
                options={this.state.selects.carriers}
                placeholder='Selecciona un carrier'
                value={this.state.form.carrier}
              />
            </div>
          </div>
          <div className='column is-4'>
            <div className='field'>
              <label>Método de pago *</label>
              <Select
                onBlur={this.isValidPaymentMethod.bind(this)}
                onChange={this.onChangeForm.bind(this, 'payment_method')}
                options={this.state.selects.payment_methods}
                placeholder='Selecciona un método de pago'
                value={this.state.form.payment_method}
              />
            </div>
          </div>
          <div className='column is-4'>
            <div className='field'>
              <label>Monto *</label>
              <input
                className={classNames('input', {
                  'is-danger': !this.state.isValid.amount
                })}
                onBlur={this.isValidAmount.bind(this)}
                onChange={this.onChangeForm.bind(this, 'amount')}
                type='number'
                value={this.state.form.amount}
              />
            </div>
          </div>
          <div className='column is-4'>
            <div className='field'>
              <label>Moneda *</label>
              <Select
                onBlur={this.isValidCurrency.bind(this)}
                onChange={this.onChangeForm.bind(this, 'currency')}
                options={this.state.selects.currencies}
                placeholder='Selecciona un tipo de moneda'
                value={this.state.form.currency}
              />
            </div>
          </div>
          <div className='column is-4'>
            <div className='field'>
              <label>Solicitante *</label>
              <Select
                onBlur={this.isValidRequester.bind(this)}
                onChange={this.onChangeForm.bind(this, 'requester')}
                options={this.state.selects.requesters}
                placeholder='Selecciona un producto'
                value={this.state.form.requester}
              />
            </div>
          </div>
          <div className='column is-4'>
            <div className='field'>
              <label>Descripción *</label>
              <div className='control'>
                <textarea
                  className={classNames('input', {
                    'is-danger': !this.state.isValid.description
                  })}
                  onBlur={this.isValidDescription.bind(this)}
                  onChange={this.onChangeForm.bind(this, 'description')}
                  value={this.state.form.description}
                />
              </div>
            </div>
          </div>
          <div className='column is-4'>
            <div className='field'>
              <label>CLABE *</label>
              <input
                className={classNames('input', {
                  'is-danger': !this.state.isValid.clabe
                })}
                onBlur={this.isValidCLABE.bind(this)}
                onChange={this.onChangeForm.bind(this, 'clabe')}
                type='text'
                value={this.state.form.clabe}
              />
            </div>
          </div>
          <div className='column is-4'>
            <div className='field'>
              <label>Webhook *</label>
              <input
                className={classNames('input', {
                  'is-danger': !this.state.isValid.webhook_url
                })}
                onBlur={this.isValidWebHook.bind(this)}
                onChange={this.onChangeForm.bind(this, 'webhook_url')}
                type='url'
                value={this.state.form.webhook_url}
              />
            </div>
          </div>
        </div>

        <h2 className='title is-4'>Datos personales</h2>
        <div className='columns is-multiline'>
          <div className='column is-4'>
            <label>Nombre *</label>
            <input
              className={classNames('input', {
                'is-danger': !this.state.isValid.name
              })}
              onBlur={this.isValidName.bind(this)}
              onChange={this.onChangeForm.bind(this, 'name')}
              type='text'
              value={this.state.form.name}
            />
          </div>
          <div className='column is-4'>
            <label>Apellidos *</label>
            <input
              className={classNames('input', {
                'is-danger': !this.state.isValid.last_name
              })}
              onBlur={this.isValidLastName.bind(this)}
              onChange={this.onChangeForm.bind(this, 'last_name')}
              type='text'
              value={this.state.form.last_name}
            />
          </div>
          <div className='column is-4'>
            <label>Correo</label>
            <input
              className='input'
              onChange={this.onChangeForm.bind(this, 'email')}
              type='text'
              value={this.state.form.email}
            />
          </div>
          <div className='column is-4'>
            <label>Télefono</label>
            <input
              className='input'
              onChange={this.onChangeForm.bind(this, 'mobile_number')}
              type='text'
              value={this.state.form.mobile_number}
            />
          </div>
        </div>
        <div>
          <Button
            className='primary right'
            onClick={this.sendData.bind(this)}
            icon='content-save'
          >
            Guardar
          </Button>
          <Link to='/payments'>
            <Button className='secondary' icon='cancel'>
              Cancelar
            </Button>
          </Link>
        </div>
      </form>
    )
  }

  render () {
    return (
      <div>
        <Title title={title} breadcrumbLocation={breadcrumbLocation} />
        <hr />
        <h1 className='title is-3'>Datos del pago</h1>
        {this.renderForm()}
      </div>
    )
  }
}

export default NewPayment
