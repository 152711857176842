import React from 'react'

/**
 * @typedef {Object} PropsLodaer
 * @property {string} [size]
 * @property {string} [className]
 * @param {PropsLodaer} props
 */
const Loader = ({ size = '36', className }) => (
  <div
    className={`has-text-primary has-text-centered espacio-medium ${className}`}
  >
    <br />
    <i className={`mdi mdi-loading mdi-spin mdi-${size}px`} />
  </div>
)

export default Loader
