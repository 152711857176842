// Libraries
import React, { PureComponent } from 'react'
import ImageZoom from 'react-medium-image-zoom'
import styled from 'styled-components'

// Components
import { gviewGrey } from 'css/colors'
import { FlexWrapper } from 'css/utils'
import Button from 'shared/button'

const Content = styled.div`
  background: ${gviewGrey};
`
const ButtonsWrapper = styled(FlexWrapper)`
  padding: 1rem 1.2rem;
  justify-content: center;
`

const MIN_ZOOM_STEP = -4

/**
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @typedef {{
 * rotation: number,
 * zoomStep: number,
 * }} State
 *
 * @typedef {{
 * title: string,
 * url: string,
 * src: string,
 * }} PropsZoomableImage
 *
 * @extends {PureComponent<PropsZoomableImage & RouteComponentProps, State>}
 */
class ZoomableImage extends PureComponent {
  /**
   * @param {State}
   */
  state = {
    rotation: 0,
    zoomStep: 0
  }

  /**
   * @param {RouteComponentProps} prevProps
   */
  componentDidUpdate = prevProps => {
    const { src } = this.props
    // Reset state zoom and rotation
    if (src !== prevProps.src) {
      this.reset()
    }
  }

  rotateLeft = () => {
    this.setState(({ rotation }) => ({ rotation: rotation - 90 }))
  }

  rotateRight = () => {
    this.setState(({ rotation }) => ({ rotation: rotation + 90 }))
  }

  zoomIn = () => {
    this.setState(({ zoomStep }) => ({ zoomStep: zoomStep + 1 }))
  }

  zoomOut = () => {
    this.setState(({ zoomStep }) => ({ zoomStep: zoomStep - 1 }))
  }

  resetZoom = () => {
    this.setState({ zoomStep: 0 })
  }

  reset = () => {
    this.setState({ rotation: 0, zoomStep: 0 })
  }

  render () {
    const { title, url } = this.props
    const { rotation, zoomStep } = this.state
    // Calculate the with of the img if has zoom or not
    const currentWidth = 100 + zoomStep * 20
    return (
      <Content>
        <div className='document-container'>
          <ImageZoom
            image={{
              src: url,
              alt: title,
              style: {
                transform: `rotate(${rotation}deg)`,
                width: `${currentWidth}%`,
                display: 'block',
                flex: 'none',
                height: 'auto',
                margin: 'auto',
                maxWidth: 'none'
              }
            }}
            zoomImage={{
              src: url,
              alt: title
            }}
            defaultStyles={{
              zoomContainer: {
                zIndex: 9999
              }
            }}
          />
        </div>
        <ButtonsWrapper>
          <div className='control'>
            <Button
              buttonClass='primary right'
              onClick={this.rotateLeft}
              icon='rotate-left'
              tooltip='Izquierda'
            />
          </div>
          <div className='control'>
            <Button
              icon='rotate-right'
              buttonClass='primary right'
              onClick={this.rotateRight}
              tooltip='Derecha'
            />
          </div>
          <div className='control'>
            <Button
              icon='magnify-plus'
              buttonClass='primary right'
              onClick={this.zoomIn}
              tooltip='Acercar'
            />
          </div>
          <div className='control'>
            <Button
              icon='magnify-minus'
              buttonClass='primary right'
              disabled={zoomStep === MIN_ZOOM_STEP}
              onClick={this.zoomOut}
              tooltip='Alejar'
            />
          </div>
          <div className='control'>
            <Button
              icon='eraser'
              buttonClass='primary'
              disabled={zoomStep === 0}
              onClick={this.resetZoom}
              tooltip={zoomStep !== 0 && 'Restaurar'}
            />
          </div>
        </ButtonsWrapper>
      </Content>
    )
  }
}
export default ZoomableImage
