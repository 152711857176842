// Libraries
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

// Components
import PreviewFile from 'shared/previewFile'
import { FlexWrapper } from 'css/utils'
import { darkBlack, gviewGrey, colorPrimary } from 'css/colors'

const Content = styled.section`
  width: 70%;
  margin: 0 auto;
  margin-top: 1rem;
`

const WrapperFile = styled(FlexWrapper)`
  align-self: flex-start;
  flex-direction: column;
  border: 1px solid ${gviewGrey};
  & p {
    color: ${colorPrimary};
    padding: 0.4em;
  }
`
const UnOrderList = styled.ul`
  width: 100%;
  cursor: pointer;
`
const ItemList = styled.li`
  padding: 0.4em;
  border-top: 1px solid ${gviewGrey};
  color: ${darkBlack};
  ${props =>
    props.active &&
    css`
      background: ${gviewGrey};
    `}
  &:hover {
    background: ${props => (props.active ? '' : '#f5f5f5')};
  }
`
/**
 * @typedef {import('api/core').Document} Document
 * @typedef {{documents: Array<Document>}} PropsDocuments
 * @param {PropsDocuments} props
 */
const ClienDocuments = ({ documents }) => {
  const [selectedDocument, setSelectedDocument] = useState({})
  return (
    <Content>
      <FlexWrapper>
        <WrapperFile>
          <p>DOCUMENTOS DEL CLIENTE</p>
          {documents && (
            <UnOrderList>
              {documents &&
                documents.map(file => {
                  return (
                    <ItemList
                      active={selectedDocument.uid === file.uid}
                      key={file.uid}
                      onClick={() => setSelectedDocument(file)}
                    >
                      {file.document_type}
                    </ItemList>
                  )
                })}
            </UnOrderList>
          )}
        </WrapperFile>
        <PreviewFile
          title={selectedDocument.description}
          url={selectedDocument.url}
          mimetype={selectedDocument.content_type}
        />
      </FlexWrapper>
    </Content>
  )
}
export default ClienDocuments
