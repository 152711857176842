// Libraries
import React, { Component } from 'react'
import className from 'classnames'

// components
import { getCountryLabel, formatDate } from 'utils'
import FlowDetails from '../flowDetails'
import LinkButton from 'shared/linkButton'

/**
 * @typedef {import('api/core').TypeFlowPayment} TypeFlowPayment
 *
 * @typedef {Object} Props
 * @property {number} totalEntries
 * @property {function} [setFile]
 * @property {function} updateStatus
 * @property {Array<TypeFlowPayment>} flowPayments
 *
 * @typedef {Object} State
 * @property {TypeFlowPayment | null } selectFlowPayment
 * @property {boolean} modalOpen
 * @property {object} fileName
 *
 * @extends {React.Component<Props, State>}
 */

class FlowPayment extends Component {
  /**
   * @type {State}
   */
  state = {
    selectFlowPayment: null,
    modalOpen: false,
    fileName: ''
  }

  /**
   * @param {TypeFlowPayment} flowPayment
   */

  changeSelectedItem = flowPayment => {
    this.setState(state => ({
      selectFlowPayment:
        state.selectFlowPayment && state.selectFlowPayment.id === flowPayment.id
          ? null
          : flowPayment
    }))
  }

  toggleModal = () => {
    this.setState(state => ({ modalOpen: !state.modalOpen }))
  }

  onCloseDetails = () => {
    this.setState({ selectFlowPayment: null })
  }

  /**
   * @param {Object} file
   */
  setFile = file => {
    this.setState({ fileName: file.name })
  }

  render () {
    const { flowPayments, totalEntries, updateStatus } = this.props
    const { selectFlowPayment, fileName, modalOpen } = this.state
    return (
      <div>
        <div className='flex-between'>
          <p className='register-results no-padding'>
            {`Hay ${totalEntries} registros`}
          </p>
          <div className='flex-between'>
            <LinkButton
              url='/accounting/flow-payment/report'
              icon='cloud-download'
              buttonClass='create right'
              testId='generate-report'
            >
              Reporte
            </LinkButton>
            <LinkButton
              buttonClass='create left'
              icon='plus-circle-outline'
              testId='newFlow'
              url='/accounting/flow-payment/new'
            >
              Nuevo egreso
            </LinkButton>
          </div>
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th>Proveedor</th>
              <th>Tipo de flujo</th>
              <th>País</th>
              <th>Fecha de la factura</th>
              <th>Estado</th>
              <th>Folio</th>
            </tr>
          </thead>
          <tbody>
            {flowPayments &&
              flowPayments.map(flowPayment => {
                return (
                  <tr
                    key={flowPayment.id}
                    onClick={() => this.changeSelectedItem(flowPayment)}
                    className={className({
                      'flowDetails--select':
                        selectFlowPayment &&
                        flowPayment.id === selectFlowPayment.id
                    })}
                  >
                    <td className='link'>
                      {flowPayment.payment_receptor.name}
                    </td>
                    <td>{flowPayment.flow_type.description}</td>
                    <td>
                      {getCountryLabel(flowPayment.payment_receptor.country)}
                    </td>
                    <td>{formatDate(flowPayment.invoice_date)}</td>
                    <td className='subtitle'>{flowPayment.status}</td>
                    <td className='subtitle'>{flowPayment.folio}</td>
                  </tr>
                )
              })}
          </tbody>
        </table>
        {selectFlowPayment && (
          <FlowDetails
            key={selectFlowPayment.id}
            updateStatus={updateStatus}
            id={selectFlowPayment.id}
            flowPayment={selectFlowPayment}
            isOpen={!!selectFlowPayment}
            modalOpen={modalOpen}
            toggleModal={this.toggleModal}
            setFile={this.setFile}
            onCloseDetails={this.onCloseDetails}
            fileName={fileName}
          />
        )}
      </div>
    )
  }
}
export default FlowPayment
