// Libraries
import React, { useEffect } from 'react'
import { Field, Form, withFormik, ErrorMessage } from 'formik'
import { withRouter } from 'react-router-dom'
import * as Yup from 'yup'
import styled from 'styled-components'

// Components
import { Title, FlexWrapper, FieldWrapper, FormWrapper } from 'css/utils'
import SelectField from 'shared/selectField'
import Button from 'shared/button'
import Required from 'shared/required'
import LinkButton from 'shared/linkButton'
import CountryButtons from 'shared/countryButtons'
import { getOptions } from 'utils'
import { REQUIRED, INPUT_TYPES } from 'shared/catalogs'

const MARGIN = '1.3rem'

const FlexEndWrapper = styled(FlexWrapper)`
  justify-content: flex-end;
`
const validationSchema = Yup.object().shape({
  type: Yup.string()
    .trim()
    .required('Campo requerido'),
  label: Yup.string()
    .trim()
    .required('Campo requerido'),
  required: Yup.string().required('Campo requerido'),
  nameForm: Yup.string()
    .trim()
    .required('Campo requerido'),
  area: Yup.string()
    .trim()
    .required('Campo requerido'),
  options: Yup.string()
    .trim()
    .when('type', {
      is: type => type === 'select',
      then: Yup.string().required('Campo requerido'),
      otherwise: Yup.string().notRequired()
    }),
  hideInForm: Yup.string().required('Campo requerido'),
  editable: Yup.string().required('Campo requerido')
})

/**
 * @typedef {import('api/core').ClarificationArea} ClarificationArea
 * @typedef {import('./index').Fields} Fields
 * @typedef {import('formik').FormikProps<ValuesFormik>} FormikProps
 * @typedef {{
 * type: string;
 * label: string;
 * required: boolean | string;
 * nameForm: string;
 * options: string;
 * area: string;
 * hideInForm: boolean | string;
 * editable: boolean | string;
 * }} ValuesFormik
 *
 * @typedef {{
 * fields: Array<Fields>;
 * toggleModal: function;
 * handleResetForm: function;
 * onChangeCountry: function;
 * country: string;
 * areas: Array<ClarificationArea>,
 * requestInProgress: boolean
 * }} PropsNewClarificationType
 *
 * @param {PropsNewClarificationType & FormikProps} props
 */

const NewClarificationType = ({
  fields,
  values,
  toggleModal,
  handleResetForm,
  resetForm,
  onChangeCountry,
  country,
  areas,
  requestInProgress,
  handleSubmit,
  setValues,
  setFieldValue
}) => {
  const updateValue =
    values.type === 'select' ||
    values.type === 'file' ||
    values.required === 'true'

  const updateRequired = values.hideInForm === 'true'

  useEffect(() => {
    setValues({
      ...values,
      hideInForm: 'false',
      editable: 'false'
    })
  }, [updateValue, country])

  useEffect(() => {
    if (updateRequired) {
      setFieldValue('required', 'false')
    }
  }, [updateRequired])

  return (
    <div>
      <LinkButton
        url='/clarification/types'
        icon='arrow-left-thick'
        buttonClass='link bottom'
      >
        Tipos de aclaraciones
      </LinkButton>
      <FormWrapper>
        <FlexWrapper bottom={MARGIN}>
          <Title>Crear formulario para aclaraciones</Title>
          {fields.length > 0 && (
            <Button
              buttonClass='primary bottom'
              onClick={toggleModal}
              testId='view-field'
              icon='eye'
            >
              Ver campos creados
            </Button>
          )}
        </FlexWrapper>
        <FieldWrapper right={MARGIN}>
          <CountryButtons onChangeCounty={onChangeCountry} country={country} />
        </FieldWrapper>
        {country && (
          <Form onSubmitCapture={handleSubmit} data-testid='form'>
            <FlexWrapper>
              <FieldWrapper right={MARGIN}>
                <Field name='area'>
                  {({ field }) => (
                    <SelectField
                      {...field}
                      id='area'
                      name='area'
                      label='Selecciona area'
                      placeholder='Seleciona una opción'
                      options={getOptions(areas)}
                      loading={requestInProgress}
                      required
                    />
                  )}
                </Field>
                <ErrorMessage
                  name='area'
                  render={message => <Required message={message} />}
                />
              </FieldWrapper>
              <FieldWrapper>
                <label htmlFor='nameForm'>Nombre para el formulario *</label>
                <Field
                  id='nameForm'
                  name='nameForm'
                  placeholder='Mi formulario de aclaración'
                  style={{ fontSize: '1em' }}
                />
                <ErrorMessage
                  name='nameForm'
                  render={message => <Required message={message} />}
                />
              </FieldWrapper>
            </FlexWrapper>
            <FieldWrapper>
              <Field name='type'>
                {({ field }) => (
                  <SelectField
                    {...field}
                    id='type'
                    name='type'
                    label='Tipo de campo que desea agregar'
                    placeholder='Seleciona una opción'
                    options={INPUT_TYPES}
                    required
                  />
                )}
              </Field>
              <ErrorMessage
                name='type'
                render={message => <Required message={message} />}
              />
            </FieldWrapper>
            {values.type === 'select' && (
              <FieldWrapper>
                <label htmlFor='options'>
                  Debes agregar las opciones separadas por coma *
                </label>
                <Field
                  id='options'
                  name='options'
                  placeholder='uno, dos, tres, cuatro'
                  style={{ fontSize: '1em' }}
                />
                <ErrorMessage
                  name='options'
                  render={message => <Required message={message} />}
                />
              </FieldWrapper>
            )}
            <FieldWrapper>
              <label htmlFor='label'>
                Debes agregar un encabezado para el campo *
              </label>
              <Field
                id='label'
                name='label'
                placeholder='Nombre del cliente'
                style={{ fontSize: '1em' }}
              />
              <ErrorMessage
                name='label'
                render={message => <Required message={message} />}
              />
            </FieldWrapper>
            <FlexWrapper>
              <FieldWrapper right={MARGIN}>
                <Field name='required'>
                  {({ field }) => (
                    <SelectField
                      {...field}
                      id='required'
                      name='required'
                      label='¿Este será un campo obligatorio?'
                      placeholder='Seleciona una opción'
                      options={REQUIRED}
                      required
                    />
                  )}
                </Field>
                <ErrorMessage
                  name='required'
                  render={message => <Required message={message} />}
                />
              </FieldWrapper>
              <FieldWrapper right={MARGIN}>
                <Field name='hideInForm'>
                  {({ field }) => (
                    <SelectField
                      {...field}
                      id='hideInForm'
                      name='hideInForm'
                      label='¿Ocultar en el formulario?'
                      placeholder='Seleciona una opción'
                      options={REQUIRED}
                      disabled={values.type === 'select'}
                      required
                    />
                  )}
                </Field>
                <ErrorMessage
                  name='hideInForm'
                  render={message => <Required message={message} />}
                />
              </FieldWrapper>
              <FieldWrapper>
                <Field name='editable'>
                  {({ field }) => (
                    <SelectField
                      {...field}
                      id='editable'
                      name='editable'
                      label='¿Editar al revisar aclaración?'
                      placeholder='Seleciona una opción'
                      options={REQUIRED}
                      disabled={values.type === 'select'}
                      required
                    />
                  )}
                </Field>
                <ErrorMessage
                  name='editable'
                  render={message => <Required message={message} />}
                />
              </FieldWrapper>
            </FlexWrapper>
            <FlexEndWrapper>
              <Button
                type='submit'
                buttonClass='primary'
                icon='plus-circle-outline'
                testId='add-field'
              >
                {fields.length > 0 ? 'Agregar otro campo' : 'Agregar campo'}
              </Button>
              <Button
                buttonClass='clear'
                icon='broom'
                onClick={() => handleResetForm(resetForm)}
              >
                Limpiar formulario
              </Button>
            </FlexEndWrapper>
          </Form>
        )}
      </FormWrapper>
    </div>
  )
}

export default withRouter(
  withFormik({
    mapPropsToValues: () => ({
      type: '',
      label: '',
      required: '',
      nameForm: '',
      options: '',
      area: '',
      hideInForm: '',
      editable: ''
    }),
    validationSchema,
    handleSubmit: (values, { setValues, props }) => {
      props.addField(values, setValues)
    }
  })(NewClarificationType)
)
