// Libraries
import React, { Component } from 'react'
import Notification from 'cogo-toast'
import styled, { css } from 'styled-components'

// Components
import { Title, FlexWrapper } from 'css/utils'
import { colorPrimary, mediumGrey, darkBlack } from 'css/colors'
import Card from 'shared/card'
import Loader from 'shared/loader'
import { formatDate } from 'utils'
import { MOVEMENTS_CSV_STATUS } from 'shared/catalogs'
import API from 'api/bank'

const Content = styled.section`
  margin: 0 auto;
  width: 70%;
`
const WrapperDetails = styled(FlexWrapper)`
  justify-content: space-around;
`
const Uid = styled.span`
  font-size: 15px;
  margin-left: 1rem;
  color: ${colorPrimary};
`
const SubHeader = styled.p`
  font-size: 12px;
  color: ${mediumGrey};
`
const Header = styled.h2`
  font-size: 20px;
  color: ${darkBlack};
  margin-bottom: 1.3rem;
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      border-color: whitesmoke;
      & a {
        color: ${mediumGrey};
      }
    `}
`

/**
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @typedef {import('api/bank').MovementDetail} MovementDetail
 * @typedef {Object} State
 * @property {MovementDetail} details
 * @property {boolean} requestInProgress
 *
 * @extends {React.Component<RouteComponentProps, State>}
 */

export default class Details extends Component {
  /**
   * @type {State}
   */
  state = {
    details: {},
    requestInProgress: false
  }

  componentDidMount = () => {
    this.getDetails()
  }

  getDetails = () => {
    const {
      match: { params }
    } = this.props
    this.setState({ requestInProgress: true })
    API.Movements.MovementDetails(params.uid)
      .then(response => {
        this.setState({ details: response })
      })
      .catch(error => {
        console.error(error)
        Notification.error('Ha ocurrido un error cargamdo los detalles')
      })
      .finally(() => {
        this.setState({ requestInProgress: false })
      })
  }

  render () {
    const { details, requestInProgress } = this.state
    if (requestInProgress) return <Loader />
    return (
      <Content>
        <Title>
          Movimiento<Uid>{details.uid}</Uid>
        </Title>
        <Card title={details.name}>
          <WrapperDetails>
            <div>
              <SubHeader>Nombre</SubHeader>
              <Header>{details.name}</Header>
              <SubHeader>Estado</SubHeader>
              <Header>{MOVEMENTS_CSV_STATUS[details.status]}</Header>
              <SubHeader>Creado</SubHeader>
              <Header>{formatDate(details.insertedAt)}</Header>
              <SubHeader>Total de Movimientos</SubHeader>
              <Header>{details.totalExistingMovements}</Header>
            </div>
            <div className='align-self-start'>
              <SubHeader>Movimientos exitosos</SubHeader>
              <Header>{details.totalSuccessMovements}</Header>
              <SubHeader>Movimientos fallidos</SubHeader>
              <Header>{details.totalFailedMovements}</Header>
              <SubHeader>Movimientos existentes</SubHeader>
              <Header>{details.totalExistingMovements}</Header>
              <SubHeader>Documento</SubHeader>
              <Header disabled={details.url === 'wait_until_upload'}>
                <a href={details.url}>Descargar</a>
              </Header>
            </div>
          </WrapperDetails>
        </Card>
      </Content>
    )
  }
}
