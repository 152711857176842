// Libraries
import React, { Component } from 'react'
import Select from 'react-select'
import classNames from 'classnames'
import update from 'immutability-helper'
import validator from 'validator'
import { Link } from 'react-router-dom'
import Notification from 'cogo-toast'

// Components
import API from 'api/bank'
import Title from 'shared/title'
import Button from 'shared/button'

const title = 'Crear cuenta'
const breadcrumbLocation = [
  { label: title, route: '/accounts' },
  { label: 'nueva', route: '' }
]

export default class extends Component {
  constructor (props) {
    super(props)
    this.state = {
      form: {
        source: '',
        country: '',
        reference: '',
        currency: ''
      },
      selects: {
        sources: [
          { value: 'PISA', label: 'PISA' },
          { value: 'ALLIANZ', label: 'ALLIANZ' }
        ],
        countries: [
          { value: 'ar', label: 'Argentina' },
          { value: 'co', label: 'Colombia' },
          { value: 'mx', label: 'México' }
        ]
      },
      options: {
        sources: ['PISA', 'ALLIANZ'],
        countries: ['mx', 'ar', 'co']
      },
      isValid: {
        country: true,
        reference: true,
        source: true
      },
      requestInProgress: false
    }
  }

  getCurrencyByCountry (country) {
    if (country === 'ar') return 'ARS'
    else if (country === 'co') return 'COP'
    // default value
    return 'MXN'
  }

  // --------------------------------------------------------------------------
  // Previene duplicados en el envío del formulario.
  // Valida los inputs.
  // Envía la petición al servidor solo si la validación fue exitosa.
  // --------------------------------------------------------------------------

  sendData (e) {
    e.preventDefault()
    const { form, requestInProgress } = this.state
    const { history } = this.props
    const isValidInputs =
      this.isValidSource() && this.isValidCountry() && this.isValidReference()

    // Do something only if the form is valid
    if (isValidInputs && !requestInProgress) {
      const { country, source, reference } = form
      const currency = this.getCurrencyByCountry(country)
      const account = { currency, metadata: { country, source, reference } }

      // Make the request
      this.setState({ requestInProgress: true }, () => {
        API.Accounts.Create({ account }).then(response => {
          this.setState({ requestInProgress: false })
          if (response.status === 'error') {
            Notification.error('Error al intentar crear la cuenta')
          } else {
            history.push('/bank-accounts')
          }
        })
      })
    }
  }

  // --------------------------------------------------------------------------
  // Valida que el tipo de dato de cada input sea correcto.
  // --------------------------------------------------------------------------

  isValidSource () {
    const isValid = this.state.options.sources.includes(this.state.form.source)
    this.setState({
      isValid: update(this.state.isValid, { source: { $set: isValid } })
    })
    return isValid
  }

  isValidCountry () {
    const isValid = this.state.options.countries.includes(
      this.state.form.country
    )
    this.setState({
      isValid: update(this.state.isValid, { country: { $set: isValid } })
    })
    return isValid
  }

  isValidReference () {
    const isValid = !validator.isEmpty(this.state.form.reference)
    this.setState({
      isValid: update(this.state.isValid, { reference: { $set: isValid } })
    })
    return isValid
  }

  // --------------------------------------------------------------------------
  // Onchange value inputs.
  // Establece el valor de this.state.form.input
  // --------------------------------------------------------------------------

  onChangeForm (name, e) {
    if (typeof e.target === 'undefined') {
      this.setState({
        form: update(this.state.form, { [name]: { $set: e.value } })
      })
    } else {
      this.setState({
        form: update(this.state.form, { [name]: { $set: e.target.value } })
      })
    }
  }

  renderForm () {
    return (
      <form className=''>
        <div className='columns is-multiline'>
          <div className='column is-4'>
            <div className='field'>
              <label>Instrumento de ahorro</label>
              <Select
                onBlur={this.isValidSource.bind(this)}
                onChange={this.onChangeForm.bind(this, 'source')}
                options={this.state.selects.sources}
                placeholder='Selecciona un instrumento'
                value={this.state.form.source}
              />
            </div>
          </div>
          <div className='column is-4'>
            <div className='field'>
              <label>País</label>
              <Select
                onBlur={this.isValidCountry.bind(this)}
                onChange={this.onChangeForm.bind(this, 'country')}
                options={this.state.selects.countries}
                placeholder='Selecciona un pais'
                value={this.state.form.country}
              />
            </div>
          </div>
          <div className='column is-4'>
            <div className='field'>
              <label>Referencia</label>
              <input
                className={classNames('input', {
                  'is-danger': !this.state.isValid.reference
                })}
                onBlur={this.isValidReference.bind(this)}
                onChange={this.onChangeForm.bind(this, 'reference')}
                type='text'
                value={this.state.form.reference}
              />
            </div>
          </div>
        </div>
        <div>
          <Button
            buttonClass='primary'
            icon='check'
            onClick={this.sendData.bind(this)}
          >
            Confirmar
          </Button>
          <Link to='/bank-accounts'>
            <Button buttonClass='secondary' icon='cancel'>
              Cancelar
            </Button>
          </Link>
        </div>
      </form>
    )
  }

  // --------------------------------------------------------------------------
  // Render Principal.
  // --------------------------------------------------------------------------

  render () {
    return (
      <div>
        <Title title={title} breadcrumbLocation={breadcrumbLocation} />
        {this.renderForm()}
      </div>
    )
  }
}
