// Libraries
import React, { Fragment } from 'react'
import styled from 'styled-components'

// Components
import { SubHeader, Header, SubTitle, InfoWrapper, Content } from './styles'
import { FlexWrapper } from 'css/utils'
import { getCountryLabel } from 'utils'

const ContentWrapper = styled(Content)`
  margin-right: 2rem;
  align-self: flex-start;
  padding-right: 2rem;
`
/**
 * @typedef {import('api/core').TypeInvoiceDetails} TypeInvoiceDetails
 * @typedef {{
 * invoice: TypeInvoiceDetails
 * }} PropsRecipient
 * @param {PropsRecipient} props
 */
const Recipient = ({ invoice }) => {
  return (
    <ContentWrapper>
      <SubTitle>Receptor</SubTitle>
      <InfoWrapper>
        <Header>
          {invoice.recipient_name} {invoice.recipient_second_name || ''}{' '}
          {invoice.recipient_last_name}
        </Header>
        <SubHeader>Correo</SubHeader>
        <Header>{invoice.recipient_email}</Header>
        <SubHeader>Dirección</SubHeader>
        <Header>{invoice.recipient_address}</Header>
        <SubHeader>Nombre comercial</SubHeader>
        <Header>{invoice.recipient_commercial_name || 'Sin nombre'}</Header>
        <FlexWrapper>
          <div>
            <SubHeader>NIT</SubHeader>
            <Header>{invoice.recipient_nit}</Header>
          </div>
          <div>
            <SubHeader>País</SubHeader>
            <Header>
              {invoice.recipient_country &&
                getCountryLabel(invoice.recipient_country.toUpperCase())}
            </Header>
          </div>
          <div>
            <SubHeader>Departamento</SubHeader>
            <Header>{invoice.recipient_department}</Header>
          </div>
        </FlexWrapper>
        <FlexWrapper>
          <div>
            <SubHeader>Ciudad</SubHeader>
            <Header>{invoice.recipient_city}</Header>
          </div>
          <div>
            <SubHeader>Código postal</SubHeader>
            <Header>{invoice.recipient_postal_code || 'N/A'}</Header>
          </div>
          <div>
            <SubHeader>Código ciudad</SubHeader>
            <Header>{invoice.recipient_city_code || 'N/A'}</Header>
          </div>
        </FlexWrapper>
      </InfoWrapper>
      <InfoWrapper>
        <FlexWrapper>
          <div>
            <SubHeader>Régimen</SubHeader>
            <Header>{invoice.recipient_regime}</Header>
          </div>
          <div>
            <SubHeader>T. Receptor</SubHeader>
            <Header>{invoice.recipient_type}</Header>
          </div>
        </FlexWrapper>
        {invoice.version === 2 && (
          <Fragment>
            <FlexWrapper>
              <div>
                <SubHeader>T. Documento</SubHeader>
                <Header>{invoice.recipient_document_type}</Header>
              </div>
              <div>
                <SubHeader>Tel. Celular</SubHeader>
                <Header>{invoice.recipient_mobile_phone_number}</Header>
              </div>
              <div>
                <SubHeader>Tel. Casa</SubHeader>
                <Header>{invoice.recipient_phone_number}</Header>
              </div>
            </FlexWrapper>
            <FlexWrapper>
              <div>
                <SubHeader>Obligaciones</SubHeader>
                <Header>{invoice.recipient_fiscal_duties}</Header>
              </div>
              <div>
                <SubHeader>Tributo</SubHeader>
                <Header>{invoice.recipient_tribute}</Header>
              </div>
              <div>
                <SubHeader>C. Departamento</SubHeader>
                <Header>{invoice.recipient_department_code}</Header>
              </div>
            </FlexWrapper>
          </Fragment>
        )}
      </InfoWrapper>
    </ContentWrapper>
  )
}
export default Recipient
