// Libraries
import React from 'react'
import Downshift from 'downshift'
import matchSorter from 'match-sorter'
import classNames from 'classnames'

// Components
import IconButton from 'shared/iconButton'
import Loader from 'shared/loader'
import { personalData } from 'utils'

/**
 * @typedef {Object} PropsSearchSuggestions
 * @property {Array} data
 * @property {boolean} requestInProgress
 * @property {function} onChangeValue
 * @property {any} [onChange]
 * @property {string} [title]
 * @property {string} [label]
 * @property {string} [placeholder]
 * @property {string} [positionButtonClass]
 * @property {string} [positionLoadingClass]
 *
 * @param {PropsSearchSuggestions} props
 */
const SearchSuggestions = ({
  data,
  requestInProgress,
  onChangeValue,
  title,
  label,
  onChange,
  positionButtonClass,
  positionLoadingClass,
  placeholder = 'Buscar por nombre o correo'
}) => {
  const suggestions = data ? personalData(data) : []
  return (
    <div className='flex-start'>
      <h1 className='title'>{title}</h1>
      <Downshift
        onChange={onChange}
        itemToString={item => (item ? item.name : '')}
      >
        {({
          getInputProps,
          getMenuProps,
          getItemProps,
          getLabelProps,
          highlightedIndex,
          isOpen,
          getToggleButtonProps,
          clearSelection,
          inputValue
        }) => (
          <div>
            <div className='relative'>
              <label
                {...getLabelProps({
                  htmlFor: 'suggestion',
                  className: 'label'
                })}
              >
                {label}
              </label>
              <input
                className='input'
                {...getInputProps({
                  placeholder: placeholder,
                  /**
                   * @param {React.ChangeEvent<HTMLSelectElement>} event
                   */
                  onChange: event => {
                    const { value } = event.currentTarget
                    if (value === '') {
                      clearSelection()
                    }

                    onChangeValue(value)
                  }
                })}
              />
              {requestInProgress ? (
                <Loader className={`searching ${positionLoadingClass}`} />
              ) : (
                <IconButton
                  {...getToggleButtonProps()}
                  icon='close-circle'
                  onClick={clearSelection}
                  className={`search ${positionButtonClass}`}
                  tooltip='limpiar'
                  type='button'
                />
              )}
            </div>
            <ul className={isOpen ? '' : 'hidden'} {...getMenuProps()}>
              {isOpen &&
                matchSorter(suggestions, inputValue || '', {
                  keys: ['name', 'email']
                }).map((item, index) => (
                  <li
                    {...getItemProps({
                      item,
                      className: `${classNames('suggestion', {
                        'background-select': index === highlightedIndex
                      })}`,
                      key: index
                    })}
                  >
                    <div className='is-bold'>{item.name}</div>
                    <div>{item.email}</div>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </Downshift>
    </div>
  )
}
export default SearchSuggestions
