// Libraries
import React, { Fragment } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import Notification from 'cogo-toast'

// Components
import API from 'api/core'
import { getCountryLabel, diffFromNowHumanize, getLabel } from 'utils'
import {
  white,
  darkBlack,
  greenTraffic,
  yellowTraffic,
  redTraffic,
  lightgreyHover
} from 'css/colors'
import { Table, TableHeader, TableBody, TableData } from 'css/utils'
import { CLARIFICATION_STATUS, AREA_OPTIONS } from 'shared/catalogs'
import Loading from 'shared/loader'

const WapperColors = styled.div`
  & > span {
    color: ${white};
    font-size: 14px;
    margin-right: 1rem;
    padding: 0.2rem 1rem;
    border-radius: 3px;
    font-weight: 600;
    &:nth-child(1) {
      background: ${greenTraffic};
    }
    &:nth-child(2) {
      background: ${yellowTraffic};
    }
    &:nth-child(3) {
      background: ${redTraffic};
    }
    &:nth-child(4) {
      background: ${darkBlack};
      margin-right: 0;
    }
  }
`
const Input = styled.input`
  width: 70px;
  border: 1px solid ${lightgreyHover};
  padding: 0.2rem 0.5rem;
  font-size: 12px;
  border-radius: 3px;
  margin: 0 0.5em;
  -moz-appearance: textfield;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
const Label = styled.label`
  font-weight: 400;
  font-size: 18px;
`

const WrapperFilterId = styled.div`
  margin: 0.5em 0 1em 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & > div {
    position: absolute;
    left: 210px;
  }
`

/**
 * @typedef {import('api/core').ListClarificationType} ListClarificationType
 * @typedef {{
 * clarifcations: Array<ListClarificationType>
 * totalEntries: number,
 * handleDetails: function
 * }} PopsClarificationTable
 *
 * @param {PopsClarificationTable} props
 */
const ClarificationTable = ({ clarifcations, totalEntries, handleDetails }) => {
  const [loading, setLoading] = React.useState(false)

  /**
   * @param {string} id
   */
  async function getClarificationDetails (id) {
    setLoading(true)
    try {
      const { data, errors } = await API.Clarifications.GetClarificationDetails(
        id
      )
      if (errors && !data) {
        Notification.info('Número de aclaración inválido')
      } else {
        handleDetails(id)
      }
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error al cargar los detalles')
    } finally {
      setLoading(false)
    }
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  function handleChangeValue (event) {
    const { value } = event.currentTarget
    if (value.trim()) {
      getClarificationDetails(value)
    }
  }

  return (
    <div>
      <WrapperFilterId>
        <Label>Ir a aclaración</Label>
        <Input
          name='clarificationId'
          id='clarificationId'
          placeholder='12345'
          type='number'
          onKeyDown={event => event.key === 'Enter' && handleChangeValue(event)}
        />
        {loading && <Loading size='24' />}
      </WrapperFilterId>
      <Fragment>
        <div className='flex-between'>
          <p className='register-results no-padding'>
            {totalEntries === 0
              ? 'No hay aclaraciones creadas'
              : `Hay ${totalEntries} aclaraciones`}
          </p>
          <WapperColors>
            <span>0 a 16 horas</span>
            <span>16 a 20 horas</span>
            <span>Mas de 20 horas</span>
            <span>Cerradas</span>
          </WapperColors>
        </div>
      </Fragment>
      <Table>
        <TableHeader>
          <tr>
            <th>Área</th>
            <th>Tipo</th>
            <th>Colaborador</th>
            <th>Creado</th>
            <th>País</th>
            <th>Estado</th>
            <th>Folio</th>
          </tr>
        </TableHeader>
        <TableBody noLine>
          {clarifcations &&
            clarifcations.map(
              ({
                id,
                area,
                creator_area: creatorArea,
                type,
                status,
                date,
                creator,
                country
              }) => {
                const currentDate = moment.utc()
                const utcDate = moment.utc(date)
                const hoursFromNow = currentDate.diff(utcDate, 'hours')

                return (
                  <tr key={id} onClick={() => handleDetails(id)}>
                    <TableData hours={hoursFromNow} status={status}>
                      {area}
                    </TableData>
                    <td>{type}</td>
                    <td>
                      <p>{creator}</p>
                      <p className='capitalize'>
                        {getLabel(AREA_OPTIONS, creatorArea)}
                      </p>
                    </td>
                    <td>{diffFromNowHumanize(date)}</td>
                    <td>{getCountryLabel(country)}</td>
                    <td>
                      {
                        // @ts-ignore
                        CLARIFICATION_STATUS[status]
                      }
                    </td>
                    <td>{id}</td>
                  </tr>
                )
              }
            )}
        </TableBody>
      </Table>
    </div>
  )
}
export default ClarificationTable
