// Libraries
import React, { Component, Fragment } from 'react'
import className from 'classnames'

// Components
import SelectField from 'shared/selectField'
import Button from 'shared/button'
import { getOptions } from 'utils'
import { COUNTRIES } from 'shared/catalogs'

/**
 * @constant
 */
const productLines = [
  { value: 'rtd', label: 'RTD' },
  { value: 'check', label: 'Check' }
]

/**
 * @typedef {Object} PropsContractFilters
 * @property {any} onChangeValue
 * @property {any} [onFilter]
 * @property {Function} [onCleanFilters]
 * @property {string} testId
 * @property {function} handleCountrySelect
 * @property {{query: string, product_line: string, country: Set<string>}} filters
 *
 * @typedef {PropsContractFilters} Props
 * @extends {Component<Props, {}>}
 */

class ContractFilters extends Component {
  render () {
    const {
      onChangeValue,
      filters,
      onFilter,
      onCleanFilters,
      testId,
      handleCountrySelect
    } = this.props
    const countries = [...filters.country]
    return (
      <div className='flex-between bottom'>
        <input
          className='input full-width text-right'
          type='text'
          name='query'
          placeholder='Buscar por nombre o correo electrónico'
          onKeyDown={e =>
            e.key === 'Enter' && filters.query.trim() ? onFilter() : null
          }
          onChange={onChangeValue}
          value={filters.query}
        />
        <div className='full-width text-right'>
          <SelectField
            name='product_line'
            placeholder='Linea de Negocio'
            value={filters.product_line}
            options={getOptions(productLines)}
            onChange={onChangeValue}
          />
        </div>
        {!handleCountrySelect && (
          <div className='full-width text-right'>
            <SelectField
              name='country'
              placeholder='País'
              value={filters.country}
              options={getOptions(COUNTRIES)}
              onChange={onChangeValue}
            />
          </div>
        )}
        {!!handleCountrySelect && (
          <Fragment>
            <div>
              <Button
                buttonClass={className('filter-country text-right', {
                  'filter-selected': countries.includes('mx')
                })}
                name='mx'
                icon='flag-variant'
                onClick={handleCountrySelect}
              >
                Mex
              </Button>
            </div>
            <div>
              <Button
                buttonClass={className('filter-country text-right', {
                  'filter-selected': countries.includes('co')
                })}
                name='co'
                icon='flag-variant'
                onClick={handleCountrySelect}
              >
                Col
              </Button>
            </div>
            <div>
              <Button
                buttonClass={className('filter-country text-right', {
                  'filter-selected': countries.includes('es')
                })}
                name='es'
                icon='flag-variant'
                onClick={handleCountrySelect}
              >
                Esp
              </Button>
            </div>
            <div>
              <Button
                buttonClass={className('filter-country text-right', {
                  'filter-selected': countries.includes('it')
                })}
                name='it'
                icon='flag-variant'
                onClick={handleCountrySelect}
              >
                Ita
              </Button>
            </div>
            <div>
              <Button
                buttonClass={className('filter-country text-right', {
                  'filter-selected': countries.includes('pt')
                })}
                name='pt'
                icon='flag-variant'
                onClick={handleCountrySelect}
              >
                Por
              </Button>
            </div>
            <div>
              <Button
                buttonClass={className('filter-country text-right', {
                  'filter-selected': countries.includes('br')
                })}
                name='br'
                icon='flag-variant'
                onClick={handleCountrySelect}
              >
                Bra
              </Button>
            </div>
          </Fragment>
        )}
        <div className='flex-between'>
          <Button
            className='text-right'
            buttonClass='primary bold'
            icon='filter'
            onClick={onFilter}
            testId={`search-${testId}`}
          >
            Filtrar
          </Button>
          <Button
            buttonClass='clear bold'
            icon='eraser'
            onClick={onCleanFilters}
            testId={`eraser-${testId}`}
          >
            Limpiar
          </Button>
        </div>
      </div>
    )
  }
}
export default ContractFilters
