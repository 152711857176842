import React from 'react'

/**
 * @typedef {Object} RequiredProps
 * @property {string} message
 * @property {string} [className]
 * @param {RequiredProps} props
 */

const Required = ({ message, className }) => (
  <span className={`massage ${className}`}>{message} *</span>
)
export default Required
