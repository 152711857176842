// Libraries
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

// Components
import { Title } from 'css/utils'
import { buttonSecondaryHover } from 'css/colors'
import { DocumentTitle } from 'shared/title'
import Logo from 'shared/logo'
import Button from 'shared/button'

const Subtitle = styled.h2`
  color: ${buttonSecondaryHover};
  font-size: 14px;
  margin-bottom: 2rem;
  padding: 1rem;
  text-align: center;
  border-radius: 3px;
  border: 1px solid ${buttonSecondaryHover};
`
const LoginContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  padding: 2rem 1.6rem;
  background-color: rgba(255, 255, 255, 0.95);
  -o-box-shadow: 0 0 27px rgba(27, 27, 27, 0.27);
  box-shadow: 0 0 27px rgba(27, 27, 27, 0.27);
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
`
/**
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @param {RouteComponentProps} props
 */
const ErrorPage = ({ location }) => {
  return (
    <div id='page-login'>
      <DocumentTitle title={location.state ? location.state.error : 'Error'} />
      <LoginContent>
        <Link to='/search-clients'>
          <Logo className='dark' />
        </Link>
        <hr />
        <div>
          <Title>¡Ups! :(</Title>
          <Subtitle>
            {location.state ? location.state.error : 'Error desconocido'}
          </Subtitle>
        </div>
        <Link to='/login'>
          <Button buttonClass='button is-link is-fullwidth'>
            Ir al inicio
          </Button>
        </Link>
      </LoginContent>
    </div>
  )
}
export default ErrorPage
