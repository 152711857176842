import styled from 'styled-components'
import { white, colorPrimary, mediumGrey } from 'css/colors'
import { FlexWrapper } from 'css/utils'

export const WrapperUpload = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  & > p {
    padding-right: 12px;
    font-size: 14px;
  }
`
export const Wrapper = styled.div`
  min-height: 120px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #efeff4;
  border-radius: 3px;
  width: ${props => props.size || '48%'};
  margin-bottom: ${props => props.mb || '0'};
`
export const WrapperButtons = styled.div`
  display: flex;
  width: 100%;
  min-height: 120px;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-around; */
  & > .fileName {
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    font-size: 14px;
    width: 100%;
  }
`

export const Content = styled(FlexWrapper)`
  margin-top: 1.5rem;
`

export const ContentFile = styled.div`
  position: relative;
  padding: 0.4rem 0.75rem;
  background-color: ${colorPrimary};
  border-radius: 3px;
  margin-right: 1.5rem;
`

export const Input = styled.input`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0;
  padding: 9px 0;
  cursor: pointer;
`

export const Text = styled.p`
  text-align: center;
  color: white;
  margin-left: 1.2rem;
  margin-bottom: 0 !important;
`

export const Icon = styled.i`
  position: absolute;
  top: 5px;
  left: 8px;
  color: ${white};
  font-size: 20px;
`

export const File = styled.div`
  & h3 {
    color: ${colorPrimary};
    margin-bottom: 0.7rem;
  }
  & p {
    border: 1px solid ${mediumGrey};
    border-radius: 3px;
    padding: 0.3rem 0.75rem;
  }
`
