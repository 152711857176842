// Libarries
import React from 'react'
import styled from 'styled-components'

// Components
import Card from 'shared/card'
import { mediumGrey, darkBlack } from 'css/colors'
import { formatDate } from 'utils'

const Content = styled.div`
  width: 70%;
  margin: 0 auto;
`
const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  width: 100%;
  & div:nth-child(1) {
    margin-right: 1em;
  }
`
const PersonalData = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;
`
const Subtitle = styled.p`
  font-size: 12px;
  color: ${mediumGrey};
`
const Title = styled.h2`
  font-size: 20px;
  color: ${darkBlack};
  margin-bottom: 1.3rem;
`

/**
 * @typedef {import('api/core').PersonalData} PersonalData
 * @typedef {{personalData: PersonalData}} PropsPersonalData
 * @param {PropsPersonalData} props
 */
const PersonalInformation = ({ personalData }) => {
  return (
    <Content>
      <div>
        <Card title='Información personal'>
          {personalData && (
            <PersonalData>
              <div>
                <Subtitle>Nombre</Subtitle>
                <Title>
                  {`${personalData.name} ${personalData.first_surname}`}
                </Title>
                <Subtitle>Correo</Subtitle>
                <Title>{personalData.email}</Title>
                <Subtitle>Nacionalidad</Subtitle>
                <Title>{personalData.nationality}</Title>
                <Subtitle>Lugar de nacimiento</Subtitle>
                <Title>{personalData.birthplace}</Title>
              </div>
              <div>
                <Subtitle>Fecha de nacimiento</Subtitle>
                <Title>{formatDate(personalData.birth_date)}</Title>
                <Subtitle>Número de identificación</Subtitle>
                <Title>
                  {personalData.nit && personalData.nit.document_type}{' '}
                  {personalData.nit && personalData.nit.identification_number}
                </Title>
                <Subtitle>Fecha de expedición </Subtitle>
                <Title>
                  {personalData.nit &&
                    formatDate(personalData.nit.identification_exp_date)}
                </Title>
                <Subtitle>Lugar de expedición </Subtitle>
                <Title>
                  {personalData.nit &&
                    personalData.nit.identification_exp_place}
                </Title>
              </div>
            </PersonalData>
          )}
        </Card>
        <Wrapper>
          <Card title='Información laboral' width='50%'>
            {personalData && (
              <section>
                <Subtitle>Actividad económica</Subtitle>
                <Title>{personalData.economic_activity}</Title>
                <Subtitle>Ocupación</Subtitle>
                <Title>{personalData.ocupation}</Title>
                <Subtitle>Ingreso bruto</Subtitle>
                <Title>{personalData.gross_income}</Title>
                <Subtitle>Ingreso neto</Subtitle>
                <Title>{personalData.net_income}</Title>
              </section>
            )}
          </Card>
          <Card title='Información de contacto' width='50%'>
            {personalData && (
              <section>
                <Subtitle>Dirección</Subtitle>
                <Title>{personalData.addresses}dadda</Title>
                <Subtitle>Correo electrónico</Subtitle>
                <Title>{personalData.email}</Title>
                <Subtitle>Teléfono</Subtitle>
                <Title>{personalData.mobile_phone}</Title>
              </section>
            )}
          </Card>
        </Wrapper>
      </div>
    </Content>
  )
}
export default PersonalInformation
