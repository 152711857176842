// Libraries
import React, { Fragment } from 'react'
import moment from 'moment'

// Components
import Field from '../../../components/field'

/**
 * @typedef {import('api/core').TypeContractClient} TypeContractClient
 * @typedef {{
 * client: TypeContractClient,
 * invalid: object,
 * country: string,
 * productLine: string,
 * handleChangeValue: function,
 * clearInvalid: function,
 * }} PropsIdentity
 * @param {PropsIdentity} props
 */
const Identity = ({
  client,
  country,
  handleChangeValue,
  invalid,
  clearInvalid,
  productLine
}) => {
  return (
    <Fragment>
      <Field
        name='client.names'
        subHeader='Nombre'
        invalid={invalid}
        header={client.names.value}
        handleChangeValue={handleChangeValue}
        modified={client.names.modified}
        clearInvalid={clearInvalid}
      />
      <Field
        name='client.first_surname'
        subHeader='Apellido paterno'
        invalid={invalid}
        header={client.first_surname.value}
        handleChangeValue={handleChangeValue}
        modified={client.first_surname.modified}
        clearInvalid={clearInvalid}
      />
      <Field
        name='client.second_surname'
        subHeader='Apellido materno'
        invalid={invalid}
        header={client.second_surname.value}
        handleChangeValue={handleChangeValue}
        modified={client.second_surname.modified}
        clearInvalid={clearInvalid}
      />
      <Field
        name='client.identification_number'
        subHeader='Identificación'
        invalid={invalid}
        header={
          client.identification_number && client.identification_number.value
        }
        handleChangeValue={handleChangeValue}
        clearInvalid={clearInvalid}
      />
      {(country === 'mx' || productLine === 'credito') && (
        <Field
          name='client.rfc'
          subHeader='RFC'
          invalid={invalid}
          header={client.rfc.value}
          handleChangeValue={handleChangeValue}
          modified={client.rfc.modified}
          clearInvalid={clearInvalid}
        />
      )}
      {country === 'co' && (
        <Fragment>
          <Field
            name='client.identification_exp_date'
            subHeader='Fecha de expedición de cedula'
            invalid={invalid}
            header={
              client.identification_exp_date &&
              moment(client.identification_exp_date.value).format('DD-MMM-YYYY')
            }
            handleChangeValue={handleChangeValue}
            modified={
              client.identification_exp_date &&
              client.identification_exp_date.modified
            }
            clearInvalid={clearInvalid}
          />
          <Field
            name='client.identification_exp_place'
            subHeader='Lugar de expedición de cedula'
            invalid={invalid}
            header={
              client.identification_exp_place &&
              client.identification_exp_place.value
            }
            handleChangeValue={handleChangeValue}
            modified={
              client.identification_exp_place &&
              client.identification_exp_place.modified
            }
            clearInvalid={clearInvalid}
          />
        </Fragment>
      )}
      <Field
        name='client.birth_date'
        subHeader='Fecha de nacimiento'
        invalid={invalid}
        header={moment(client.birth_date.value).format('DD-MMM-YYYY')}
        handleChangeValue={handleChangeValue}
        modified={client.birth_date.modified}
        clearInvalid={clearInvalid}
      />
      <Field
        name='client.nationality'
        subHeader='Nacionalidad'
        invalid={invalid}
        header={client.nationality.value}
        handleChangeValue={handleChangeValue}
        modified={client.nationality.modified}
        clearInvalid={clearInvalid}
      />
      <Field
        name='client.birth_country'
        invalid={invalid}
        subHeader='País de nacimiento'
        header={client.birth_country.value}
        handleChangeValue={handleChangeValue}
        modified={client.birth_country.modified}
        clearInvalid={clearInvalid}
      />
      {country !== 'br' && (
        <Field
          name='client.birth_state'
          subHeader='Estado de nacimiento'
          invalid={invalid}
          header={client.birth_state.value}
          handleChangeValue={handleChangeValue}
          modified={client.birth_state.modified}
          clearInvalid={clearInvalid}
        />
      )}
      <Field
        name='client.email'
        subHeader='Correo'
        invalid={invalid}
        header={client.email.value}
        handleChangeValue={handleChangeValue}
        modified={client.email.modified}
        clearInvalid={clearInvalid}
      />
      <Field
        name='client.phone'
        subHeader='Telefono'
        invalid={invalid}
        header={client.phone.value}
        handleChangeValue={handleChangeValue}
        modified={client.phone.modified}
        clearInvalid={clearInvalid}
      />
      <Field
        name='client.gender'
        subHeader='Genero'
        invalid={invalid}
        header={client.gender.value}
        handleChangeValue={handleChangeValue}
        modified={client.gender.modified}
        clearInvalid={clearInvalid}
      />
    </Fragment>
  )
}
export default Identity
