// Libraries
import React from 'react'

/**
 * @typedef {{
 * className: string
 * onClick?: () => void
 * }} Logo
 * @param {Logo} props
 */
export default function Logo ({ className, onClick }) {
  return (
    <div
      id='resuelve-logo'
      onClick={onClick}
      className={className}
      role='button'
      tabIndex={0}
    >
      <img src='/img/logoResuelve.png' alt='logo-resuelve' />
    </div>
  )
}
