// Libraries
import React from 'react'
import styled, { css } from 'styled-components'

// Components
import { FlexWrapper } from 'css/utils'
import { white, darkBlack, appleGrey, buttonPrimary } from 'css/colors'
import RenapoIne from 'shared/uploadRenapoIne'

const Content = styled.div`
  background: ${white};
`
const NotesWrapper = styled(FlexWrapper)`
  flex-wrap: wrap;
`
const Notes = styled(FlexWrapper)`
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  width: 48%;
  & > label {
    color: ${darkBlack};
    margin-bottom: 0.5rem;
  }
  & > textarea {
    width: 100%;
    max-height: 500px;
    min-height: 120px;
    border-radius: 3px;
    padding: 0.5rem;
    margin-bottom: 0;
  }
  & > textarea:disabled {
    background: ${appleGrey};
  }
  ${props =>
    props.comment &&
    css`
      & > textarea {
        border: 1px solid ${buttonPrimary};
      }
    `}
`
/**
 * @typedef {import('api/core').TypeContractPending} TypeContractPending
 * @typedef {{
 * data: TypeContractPending,
 * comment: string,
 * productLine: string
 * handleChangeComment: function,
 * country: string,
 * currentTab: number,
 * }} PropsNoteList
 *
 * @param {PropsNoteList} props
 */
const NoteList = ({
  data: {
    lead_uid: leadUid,
    lead_files: leadFiles,
    previous_invalid: previousInvalid,
    previous_notes: previousNotes,
    ard
  },
  comment,
  productLine,
  country,
  currentTab,
  handleChangeComment
}) => {
  return (
    <Content>
      <NotesWrapper>
        {productLine !== 'credito' && (
          <Notes>
            <label htmlFor={ard.ard_notes}>Notas del ARD</label>
            <textarea
              value={ard.ard_notes}
              aria-label='Notas del ARD'
              disabled
            />
          </Notes>
        )}
        {previousNotes.length > 0 && currentTab !== 2 && (
          <Notes>
            <label htmlFor={previousNotes}>Última nota de rechazo</label>
            <textarea
              value={previousNotes[0].note}
              aria-label='Última nota de rechazo'
              disabled
            />
          </Notes>
        )}
        {previousInvalid && previousInvalid.length !== 0 && (
          <Notes>
            <label htmlFor={previousInvalid}>Campos inválidos previos</label>
            <textarea
              value={JSON.stringify(previousInvalid)}
              aria-label='Campos inválidos previos'
              className='textarea m-b-20'
              disabled
            />
          </Notes>
        )}
        <Notes comment>
          <label htmlFor={comment}>Escribe un comentario</label>
          <textarea
            aria-label='Escribir comentario'
            placeholder='Escribe un comentario'
            value={comment}
            onChange={handleChangeComment}
          />
        </Notes>
        {country === 'mx' && (
          <RenapoIne leadUid={leadUid} leadFiles={leadFiles} />
        )}
      </NotesWrapper>
    </Content>
  )
}
export default NoteList
