// Libraries
import React from 'react'
import Notification from 'cogo-toast'
import styled from 'styled-components'
import { withFormik, Form, Field } from 'formik'

// Components
import { FlexWrapper } from 'css/utils'
import SelectField from 'shared/selectField'
import Button from 'shared/button'
import { getOptions } from 'utils'
import { COUNTRIES, SAVING_VEHICLE } from 'shared/catalogs'

import API from 'api/core'

const FieldWrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  &:nth-child(1) {
    margin-right: 2rem;
  }
`
/**
 * @typedef {import('formik').FormikProps<FormikValues>} FormikProps
 * @typedef {{
 * toggleModal: Function,
 * }} Props
 * @typedef {{
 * country: string,
 * savingVehicle: string,
 * }} FormikValues
 * @param {FormikProps} props
 */
const GenarateFormat = ({ handleSubmit, values, isSubmitting }) => {
  return (
    <div>
      <Form onSubmitCapture={handleSubmit} data-testid='form'>
        <FlexWrapper>
          <FieldWrapper>
            <Field name='country'>
              {({ field }) => (
                <SelectField
                  {...field}
                  name='country'
                  label='País'
                  placeholder='País'
                  options={getOptions(COUNTRIES)}
                  disabled={isSubmitting}
                  required
                />
              )}
            </Field>
          </FieldWrapper>
        </FlexWrapper>
        <FlexWrapper>
          <FieldWrapper>
            <Field name='savingVehicle'>
              {({ field }) => (
                <SelectField
                  {...field}
                  name='savingVehicle'
                  label='Vehículo de ahorro'
                  placeholder='Vehículo de ahorro'
                  options={SAVING_VEHICLE}
                  disabled={!values.country || isSubmitting}
                  required
                />
              )}
            </Field>
          </FieldWrapper>
        </FlexWrapper>
        <FlexWrapper>
          <Button
            buttonClass='primary'
            icon='file'
            type='submit'
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Generar archivo
          </Button>
        </FlexWrapper>
      </Form>
    </div>
  )
}
export default withFormik({
  mapPropsToValues: () => ({
    country: '',
    savingVehicle: ''
  }),
  handleSubmit: async (values, { setValues, setSubmitting, props }) => {
    const params = {
      country: values.country,
      saving_vehicle: values.savingVehicle
    }
    setSubmitting(true)
    try {
      const { status, errors } = await API.Accounting.GenerateLayoutFile(params)
      setValues({
        country: '',
        savingVehicle: ''
      })
      if (status === 'error') {
        Notification.warn(errors.detail)
      } else {
        Notification.success(
          'Generando archivo.... Cuando este listo lo podrás descargar'
        )
        props.getLayout()
      }
    } catch (error) {
      console.log(error)
      Notification.error('No se pudo generar el archivo')
    } finally {
      props.toggleModal()
    }
    setSubmitting(false)
  }
})(GenarateFormat)
