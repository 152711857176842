// Libraries
import React, { Fragment } from 'react'
import styled from 'styled-components'

// Components
import { SubHeader, Header, SubTitle, InfoWrapper } from './styles'
import { FlexWrapper } from 'css/utils'
import Button from 'shared/button'
import { downloadXml } from 'utils'

const ContentWrapper = styled.div`
  margin-right: 2rem;
  align-self: flex-start;
  width: 100%;
`
const WrapperButton = styled(FlexWrapper)`
  justify-content: flex-end;
  & > button {
    padding-left: 1rem;
  }
`
/**
 * @typedef {import('api/core').TypeInvoiceDetails} TypeInvoiceDetails
 * @typedef {{
 * invoice: TypeInvoiceDetails
 * }} PropsRecipient
 * @param {PropsRecipient} props
 */
const Recipient = ({ invoice }) => {
  return (
    <ContentWrapper>
      <SubTitle>Dian</SubTitle>
      <InfoWrapper>
        <FlexWrapper>
          <div>
            <SubHeader>Respuesta</SubHeader>
            <Header>{invoice.dian_message || 'Sin repuesta aún'}</Header>
          </div>
          {invoice.dian_xml && (
            <WrapperButton>
              <Button
                buttonClass='primary'
                icon='download'
                tooltip='Descargar xml'
                onClick={() => downloadXml(invoice.dian_xml, 'dian')}
              />
            </WrapperButton>
          )}
        </FlexWrapper>
        <SubHeader>Descripción</SubHeader>
        <Header>{invoice.dian_description || 'Descripción'}</Header>
        <SubHeader>Estado</SubHeader>
        <Header>{invoice.dian_status_code || 'Estado'}</Header>
        <SubHeader>Documento válido</SubHeader>
        <Header>{invoice.dian_valid ? 'Si' : 'No'}</Header>
      </InfoWrapper>
      <Header>Errores</Header>
      {invoice.dian_errors &&
        invoice.dian_errors.map((item, index) => {
          return (
            <Fragment key={index}>
              <SubHeader>Error</SubHeader>
              <Header>{item}</Header>
            </Fragment>
          )
        })}
    </ContentWrapper>
  )
}
export default Recipient
