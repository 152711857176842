// Libraries
import React, { Fragment } from 'react'
import moment from 'moment'

// Components
import Field from '../../../components/field'

/**
 * @typedef {import('api/core').TypeContractClientBeneficiary} ClientBeneficiary}
 * @typedef {{
 * beneficiary: ClientBeneficiary,
 * handleChangeValue: function,
 * clearInvalid: function,
 * invalid: object,
 * }} PropsBeneficiary
 *
 * @param {PropsBeneficiary} props
 */
const Beneficiary = ({
  beneficiary,
  handleChangeValue,
  invalid,
  clearInvalid
}) => {
  return (
    <Fragment>
      <Field
        name='beneficiary.relationship'
        subHeader='Relación'
        invalid={invalid}
        header={beneficiary.relationship.value}
        handleChangeValue={handleChangeValue}
        modified={beneficiary.relationship.modified}
        clearInvalid={clearInvalid}
      />
      <Field
        name='beneficiary.name'
        subHeader='Nombre'
        invalid={invalid}
        header={beneficiary.name.value}
        handleChangeValue={handleChangeValue}
        modified={beneficiary.name.modified}
        clearInvalid={clearInvalid}
      />
      <Field
        name='beneficiary.gender'
        subHeader='Género'
        invalid={invalid}
        header={beneficiary.gender.value}
        handleChangeValue={handleChangeValue}
        modified={beneficiary.gender.modified}
        clearInvalid={clearInvalid}
      />
      <Field
        name='beneficiary.birth_date'
        subHeader='Fecha de nacimiento'
        invalid={invalid}
        header={moment(beneficiary.birth_date.value).format('DD-MMM-YYYY')}
        handleChangeValue={handleChangeValue}
        modified={beneficiary.birth_date.modified}
        clearInvalid={clearInvalid}
      />
    </Fragment>
  )
}
export default Beneficiary
