// Libraries
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Notification from 'cogo-toast'

// Components
import ContractFieldsMapper from '../../app/mappers/contract-validation-mapper'
import ZoomableImageForm from '../forms/image-zoom'
import FieldsContainer from './fieldsContainer'
import PdfViewer from '../forms/pdfViewer'
import AudioPlayer from '../forms/audioPlayer'
import Button from 'shared/button'
import Modal from 'shared/modal'
import Required from 'shared/required'
import API from 'api/core'

/**
 * @typedef {Object} viewersMap
 */

const viewersMap = {
  image: ZoomableImageForm,
  pdf: PdfViewer,
  audio: AudioPlayer
}

/**
 * @typedef {Object} State
 * @property {null} contract
 * @property {array} fields
 * @property {string} notes
 * @property {boolean} approveStatus
 * @property {boolean} referenceRequired
 * @property {string} reference
 * @property {boolean} requestInProgress
 * @property {boolean} openModal
 * @property {string} pisaReference
 * @property {{name: string, type: string, value: string}} selectedDocument
 *
 * @typedef {import('react-router-dom').RouteComponentProps} Props
 * @extends {Component<Props, State>}
 */

class Body extends Component {
  /**
   * @type {State}
   */
  state = {
    contract: null,
    fields: [],
    notes: '',
    approveStatus: true,
    referenceRequired: false,
    reference: '',
    requestInProgress: false,
    openModal: false,
    pisaReference: '',
    selectedDocument: {
      name: '',
      type: '',
      value: ''
    }
  }

  /**
   *
   * @param {Props} nextProps
   * @param {State} prevState
   */
  static getDerivedStateFromProps (nextProps, prevState) {
    const { contract } = nextProps
    if (!contract) return null

    const fields = ContractFieldsMapper.map(contract)

    let referenceRequired =
      (contract.country === 'es' || contract.country === 'ar') &&
      contract.product_line === 'rtd'

    return contract !== prevState.contract
      ? {
        contract,
        notes: contract.notes,
        fields,
        referenceRequired
      }
      : null
  }

  /**
   * @param {string} selectedField
   */

  onChangeValidation = selectedField => {
    const { fields } = this.state

    // Get the selected field form fields object
    const currentField = Object.assign({}, fields[selectedField])
    // Change the properties
    currentField.is_valid = !currentField.is_valid
    currentField.description = ''

    // Assign the new state object
    const newFields = Object.assign({}, fields, {
      [selectedField]: currentField
    })
    this.setState({ fields: newFields }, () => this.validateApproveStatus())
  }

  validateApproveStatus () {
    const { fields, notes } = this.state
    const approveStatus = notes
      ? false
      : Object.keys(fields)
      //  Convert to array
        .map(field => fields[field])
      // look for non valid fields
        .every(field => field.is_valid)
    this.setState({ approveStatus })
  }

  /**
   * @param {{ value: string }} text
   * @param {string} selectedField
   */
  onChangeDescription = (text, selectedField) => {
    const { fields } = this.state
    // Get the selected field form fields object
    const currentField = Object.assign({}, fields[selectedField])
    // Change the properties
    currentField.description = text.value || text
    // Assign the new state object
    const newFields = Object.assign({}, fields, {
      [selectedField]: currentField
    })
    this.setState({ fields: newFields })
  }

  /**
   * @param {object} selectedDocument
   */
  onChangeDocument = selectedDocument => {
    this.setState({ selectedDocument })
  }

  /**
   * @param {React.ChangeEvent<HTMLTextAreaElement>} event
   */
  onChangeNotes = event => {
    const { value } = event.currentTarget
    this.setState({ notes: value }, () => this.validateApproveStatus())
  }

  /**
   * @typedef {React.ChangeEvent<HTMLInputElement>} Input
   * @param {string} field
   * @returns {function(Input): void}
   */
  onChangeReference = field => event => {
    const { value } = event.currentTarget
    this.setState({ [field]: value }, () => this.validateApproveStatus())
  }

  toggleModal = () => {
    this.setState(state => ({ openModal: !state.openModal }))
  }

  /**
   * @param {String} contractUid
   */
  generateReference = async contractUid => {
    this.setState({ requestInProgress: true })
    try {
      const {
        data,
        status,
        errors
      } = await API.ContractRequests.GenerateReference(contractUid)
      if (status === 'error') {
        Notification.error(errors.detail)
      }
      this.setState({ pisaReference: data.reference }, this.toggleModal)
    } catch (error) {
      console.error(error)
    } finally {
      this.setState({ requestInProgress: false })
    }
  }

  rejectContract = () => {
    const { fields, contract, notes } = this.state
    const { history } = this.props
    const invalid = Object.keys(fields)
      // Convert fields to a list
      .map(field => fields[field])
      // Remove the invalid ones
      .filter(field => !field.is_valid)
      // Return only name value and description
      .map(({ name, value, description }) => ({ name, value, description }))
    const uid = contract.contract_uid

    const requestNotes = notes || ''
    this.setState({ requestInProgress: true })
    API.ContractRequests.Reject(uid, {
      notes: requestNotes,
      invalid
    })
      .then(() => {
        Notification.success('Contrato rechazado')
        history.push('/contract-requests/validate')
      })
      .catch(() => Notification.error('Ocurrió un error, intenta nuevamente'))
      .finally(() => this.setState({ requestInProgress: false }))
  }

  approveContract = () => {
    const { reference, referenceRequired, contract, pisaReference } = this.state
    const { history } = this.props

    if (referenceRequired && !reference) {
      Notification.warn('Debes capturar la referencia a usar')
      return
    }
    const hasReference = referenceRequired ? reference : null
    const data = pisaReference || hasReference
    this.setState({ requestInProgress: true })
    API.ContractRequests.Approve(contract.contract_uid, { reference: data })
      .then(response => {
        if (response.status === 'error') {
          const errorMessage = response.errors.detail
          if (errorMessage.indexOf('INVALID_PROVIDER_REFERENCE') !== -1) {
            Notification.warn(
              'Por favor ingresa manualmente la referencia a usar'
            )
            this.setState({
              referenceRequired: true,
              requestInProgress: false
            })
          } else {
            Notification.error(errorMessage)
            this.setState({
              requestInProgress: false,
              notes: errorMessage,
              approveStatus: false,
              openModal: false
            })
          }
        } else {
          history.push('/contract-requests/validate')
        }
      })
      .catch(error => {
        console.error(error)
        Notification.error('Ocurrió un error, intenta nuevamente')
        this.setState({ requestInProgress: false })
      })
  }

  /**
   * @param {String} country
   * @param {String} contractUid
   */
  generateContract = (country, contractUid) => {
    if (country === 'mx') {
      this.generateReference(contractUid)
    } else {
      this.approveContract()
    }
  }

  render () {
    const {
      fields,
      contract,
      selectedDocument,
      notes,
      approveStatus,
      referenceRequired,
      reference,
      requestInProgress,
      pisaReference,
      openModal
    } = this.state

    if (!contract) {
      return null
    }
    // Convert object to array
    const fieldsList = Object.keys(fields).map(field => fields[field])
    let ViewerToRender = null
    if (selectedDocument != null) {
      ViewerToRender = viewersMap[selectedDocument.type]
    }
    return (
      <div className='columns'>
        <div className='column is-half'>
          {contract.reference && (
            <label>Referencia: {contract.reference}</label>
          )}
          {contract.ard.ard_email && (
            <label>Solicitada por: {contract.ard.ard_email}</label>
          )}
          <FieldsContainer
            onChangeValidation={this.onChangeValidation}
            onChangeDescription={this.onChangeDescription}
            onChangeDocument={this.onChangeDocument}
            fields={fieldsList}
          />
          <div id='comment-box'>
            <div className='field'>
              <label className='label'>Notas del ARD:</label>
              <div className='control'>
                <textarea
                  value={contract.ard.ard_notes}
                  className='textarea m-b-20'
                  disabled='disabled'
                />
              </div>
            </div>
          </div>
          {contract.previous_notes !== '' && (
            <div id='comment-box'>
              <div className='field'>
                <label className='label'>Notas de rechazo previo:</label>
                <div className='control'>
                  <textarea
                    value={contract.previous_notes}
                    className='textarea m-b-20'
                    disabled='disabled'
                  />
                </div>
              </div>
            </div>
          )}
          {contract.previous_invalid.length !== 0 && (
            <div id='comment-box'>
              <div className='field'>
                <label className='label'>Campos inválidos previos:</label>
                <div className='control'>
                  <textarea
                    value={JSON.stringify(contract.previous_invalid)}
                    className='textarea m-b-20'
                    disabled='disabled'
                  />
                </div>
              </div>
            </div>
          )}
          <div id='comment-box'>
            <div className='field'>
              <label className='label'>Comentarios:</label>
              <div className='control'>
                <textarea
                  value={notes}
                  onChange={this.onChangeNotes}
                  className='textarea m-b-20'
                  placeholder='Comentarios'
                />
              </div>
            </div>
          </div>
          {referenceRequired && (
            <div className='field'>
              <label className='label'>Referencia:</label>
              <div className='control'>
                <input
                  value={reference}
                  onChange={this.onChangeReference('reference')}
                  className='input'
                  placeholder='Referencia'
                />
              </div>
            </div>
          )}
          <div className='field is-grouped is-grouped-right top'>
            {approveStatus ? (
              <Button
                buttonClass='primary'
                onClick={() =>
                  this.generateContract(contract.country, contract.contract_uid)
                }
                loading={requestInProgress}
                disabled={requestInProgress}
                icon='check'
                testId='Approve'
                type='submit'
              >
                Aprobar
              </Button>
            ) : (
              <Button
                buttonClass='secondary'
                onClick={this.rejectContract}
                loading={requestInProgress}
                icon='cancel'
                testId='Cancel'
              >
                Rechazar
              </Button>
            )}
          </div>
          <Modal
            title='Referencia Generada'
            isActive={openModal}
            toggleModal={this.toggleModal}
            onSubmit={this.approveContract}
            requestInProgress={requestInProgress}
            disabled={
              pisaReference.length < 11 ||
              isNaN(pisaReference) ||
              requestInProgress
            }
          >
            <div className='newFlow--field'>
              <label htmlFor='generateReference' className='text-right'>
                Referencia:
              </label>
              <input
                required
                maxLength={11}
                value={pisaReference.trim()}
                onChange={this.onChangeReference('pisaReference')}
              />
              {pisaReference.length < 11 && (
                <Required message='Ingresa 11 números' />
              )}
            </div>
          </Modal>
        </div>
        {selectedDocument && (
          <div className='file-previewer column is-half'>
            {ViewerToRender && (
              <ViewerToRender
                src={selectedDocument.value}
                title={selectedDocument.name}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(Body)
