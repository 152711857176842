// Libraries
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Notification from 'cogo-toast'

// Components
import formatMoney from '../../format/money'
import API from 'api/bank'

class AccountsTable extends Component {
  constructor (props) {
    super(props)
    this.changeStatus = this.changeStatus.bind(this)
  }

  // ------------------------------------------------------------------------------
  // Realiza una petición a la api del banco para activar o desactivar una cuenta.
  // ------------------------------------------------------------------------------

  changeStatus (e, uid, item) {
    e.preventDefault()
    e.stopPropagation()

    API.Accounts.ChangeStatus({ uid: uid }).then(response => {
      if (response.errors != null) {
        Notification.error('Error al intentar cambiar el estatus de la cuenta.')
      } else {
        this.props.changeStatus(response.status, item)
      }
    })
  }

  render () {
    const { accounts, history } = this.props
    // --------------------------------------------------------------------------
    // Render Principal.
    // --------------------------------------------------------------------------

    return (
      <table className='table'>
        <thead>
          <tr>
            <th>No. cuenta</th>
            <th>Balance</th>
            <th>Proveedor</th>
            <th>Referencia Proveedor</th>
            <th>País</th>
            <th>Estatus</th>
            <th>habilitar/inhabilitar</th>
          </tr>
        </thead>
        {accounts.map(({ uid, balance, active, metadata }, i) => {
          const { source, reference, country } = metadata
          return (
            <tbody
              key={uid}
              onClick={() => {
                history.push(`/bank-accounts/view/${uid}`)
              }}
            >
              <tr className='is-red'>
                <td>{uid}</td>
                <td>{balance ? formatMoney.toMoney(balance) : ''}</td>
                <td>{source}</td>
                <td>{reference}</td>
                <td>{country}</td>
                <td>{active ? 'active' : 'inactive'}</td>
                <td>
                  <button
                    className='button is-warning'
                    onClick={e => this.changeStatus(e, uid, i)}
                  >
                    <i
                      className='mdi mdi-cached is-rounded'
                      aria-hidden='true'
                    />
                  </button>
                </td>
              </tr>
            </tbody>
          )
        })}
      </table>
    )
  }
}
export default withRouter(AccountsTable)
