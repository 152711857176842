import React from 'react'

import Required from 'shared/required'

const SUPPORTED_COUNTRIES = ['es', 'pt', 'br']

const countryRegex = {
  es: /U(ES)?\d{7}/,
  pt: /U(PT)?\d{7}/,
  it: /U(IT)?\d{7}/,
  br: /BR\d{7}/
}

/**
 * @param {{reference: string, country: string }} props
 */
export const ShowError = ({ reference, country }) => {
  const valid = validReference(reference, country)
  if (!valid) {
    return <Required message='Referencia no valida' />
  }
  return null
}

/**
 * @param {string} reference
 * @param {string} country
 */
export const validReference = (reference, country) => {
  if (SUPPORTED_COUNTRIES.includes(country)) {
    return countryRegex[country].test(reference)
  }
  return true
}
