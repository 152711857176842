// Libraries
import React, { Component } from 'react'
import Notification from 'cogo-toast'

// Components
import { Title } from 'css/utils'
import SelectField from 'shared/selectField'
import Button from 'shared/button'
import LinkButton from 'shared/linkButton'
import Paginator from 'shared/paginator'
import Modal from 'shared/modal'
import Loader from 'shared/loader'
import GenerateFormat from './genarateFormat'
import LayoutTable from './layoutTable'
import { COUNTRIES, SAVING_VEHICLE } from 'shared/catalogs'
import { getOptions, stringifyQuery, parseQuery } from 'utils'
import API from 'api/core'

/**
 * @typedef {import('api/core').Layout} layout
 * @typedef {import('api/core').Pagination} Pagination
 * @typedef {Object} State
 * @property {Array<layout>} layouts
 * @property {boolean} requestInProgress
 * @property {{country: string, saving_vehicle: string }} filters
 * @property {Pagination} pagination
 * @property {boolean} openModal
 * @property {Array<number>} loadingLayout
 * @property {string} file
 * @property {number | null} layoutId
 *
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @extends {React.Component<RouteComponentProps, State>}
 */

class Layout extends Component {
  /**
   * @type {State}
   */
  state = {
    layouts: [],
    loadingLayout: [],
    file: '',
    layoutId: null,
    filters: {
      country: '',
      saving_vehicle: ''
    },
    pagination: {
      total_pages: 1,
      total_entries: 1,
      page_number: 1,
      page_size: 1
    },
    requestInProgress: false,
    openModal: false
  }

  /**
   * @param {object} prevProps
   */
  componentDidUpdate = prevProps => {
    if (this.props.location.search !== prevProps.location.search) {
      this.getLayout()
    }
  }

  componentDidMount = () => {
    this.getLayout()
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  onChangeValue = event => {
    const { value, name } = event.currentTarget
    this.setState(state => ({
      filters: { ...state.filters, [name]: value }
    }))
  }

  onCleanFilters = () => {
    const { history, location } = this.props
    this.setState(
      {
        filters: {
          saving_vehicle: '',
          country: ''
        }
      },
      () => {
        history.push(location.pathname)
      }
    )
  }

  onFilter = () => {
    const { filters } = this.state
    const {
      history,
      location: { pathname }
    } = this.props
    history.push(pathname + stringifyQuery(filters))
  }

  toggleModal = () => {
    this.setState(state => ({ openModal: !state.openModal }))
  }

  getLayout = async () => {
    const { filters } = this.state
    const { location } = this.props
    const page = parseQuery(location.search).page || 1
    const params = {
      page,
      saving_vehicle: filters.saving_vehicle || 'PISA',
      country: filters.country || 'MX'
    }
    this.setState({ requestInProgress: true })
    try {
      const { data, pagination } = await API.Accounting.GetLayout(params)
      this.setState({ layouts: data, pagination })
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error cargando los formatos')
    } finally {
      this.setState({ requestInProgress: false })
    }
  }

  /**
   * @param {number} id
   */
  getLayoutFile = async id => {
    this.setState(state => ({
      loadingLayout: [...state.loadingLayout, id]
    }))
    try {
      const { layout } = await API.Accounting.GetLayoutFile(id)
      this.setState({
        file: layout,
        layoutId: id
      })
    } catch (error) {
      console.log(error)
      Notification.error('Ha ocurrido un error al descarga el archivo')
    } finally {
      this.setState(state => ({
        loadingLayout: state.loadingLayout.filter(layoutId => id !== layoutId)
      }))
    }
  }

  render () {
    const {
      filters,
      layouts,
      pagination,
      openModal,
      loadingLayout,
      file,
      layoutId,
      requestInProgress
    } = this.state
    return (
      <div>
        <LinkButton
          url='/accounting/income'
          icon='arrow-left-thick'
          buttonClass='link bottom'
        >
          Ingresos
        </LinkButton>
        <div className='flex-between bottom'>
          <Title>Formatos generados</Title>
          <Button
            buttonClass='create'
            icon='plus-circle-outline'
            onClick={this.toggleModal}
          >
            Generar formato
          </Button>
        </div>
        <div className='flex-end bottom'>
          <div className='text-right'>
            <SelectField
              name='saving_vehicle'
              placeholder='Vehículo de ahorro'
              options={SAVING_VEHICLE}
              value={filters.saving_vehicle}
              onChange={this.onChangeValue}
            />
          </div>
          <div className='text-right'>
            <SelectField
              name='country'
              placeholder='País'
              options={getOptions(COUNTRIES)}
              value={filters.country}
              onChange={this.onChangeValue}
            />
          </div>
          <Button
            buttonClass='primary'
            icon='filter'
            onClick={this.onFilter}
            testId='filter-layout'
          >
            Filtrar
          </Button>
          <Button
            buttonClass='clear'
            icon='eraser'
            onClick={this.onCleanFilters}
          >
            Limpiar
          </Button>
        </div>
        {requestInProgress ? (
          <Loader />
        ) : (
          <LayoutTable
            layouts={layouts}
            pagination={pagination}
            loadingLayout={loadingLayout}
            getLayoutFile={this.getLayoutFile}
            file={file}
            layoutId={layoutId}
          />
        )}
        {pagination && <Paginator totalPages={pagination.total_pages} />}
        <Modal
          title='Generar formato'
          isActive={openModal}
          toggleModal={this.toggleModal}
          noFooter
        >
          <GenerateFormat
            toggleModal={this.toggleModal}
            getLayout={this.getLayout}
          />
        </Modal>
      </div>
    )
  }
}
export default Layout
