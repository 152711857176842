import React from 'react'
import { withFormik, Form, Field, ErrorMessage } from 'formik'
import styled from 'styled-components'
import Notification from 'cogo-toast'
import get from 'lodash/get'
import * as Yup from 'yup'

import { FieldWrapper } from 'css/utils'
import { getOptions } from 'utils'
import { COUNTRIES } from 'shared/catalogs'
import SelectField from 'shared/selectField'
import Button from 'shared/button'
import Required from 'shared/required'

import API from 'api/core'

const WrapperButtons = styled.div`
  border-top: 1px solid #dbdbdb;
  background-color: whitesmoke;
  padding: 1.25rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  margin-bottom: -1.25rem;
  margin-top: 1.25rem;
`

/**
 * @constant
 */
const validationSchema = Yup.object().shape({
  country: Yup.string().required('Campo requerido'),
  bankName: Yup.string().required('Campo requerido')
})

/**
 * @typedef {import('formik').FormikProps<FormikValues>} FormikProps
 * @typedef {{
 * name: string,
 * banName: string,
 * }} FormikValues
 * @typedef {{
 * toggleModal: any,
 * bankSeleted: object
 * }} PropsAddBank
 * @param {PropsAddBank & FormikProps} props
 */

function AddBank ({ isSubmitting, toggleModal }) {
  return (
    <Form data-testid='form'>
      <FieldWrapper>
        <Field name='country'>
          {({ field }) => (
            <SelectField
              {...field}
              name='country'
              id='country'
              placeholder='Selecciona un opción'
              label='Selecciona país'
              options={getOptions(COUNTRIES)}
            />
          )}
        </Field>
        <ErrorMessage
          name='country'
          render={message => (
            <Required message={message} className='required-modal' />
          )}
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor='bankName'>Nombre del banco</label>
        <Field id='bankName' name='bankName' />
        <ErrorMessage
          name='bankName'
          render={message => (
            <Required message={message} className='required-modal' />
          )}
        />
      </FieldWrapper>
      <WrapperButtons>
        <Button
          type='submit'
          icon='send'
          buttonClass='primary right'
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          Enviar
        </Button>
        <Button icon='cancel' buttonClass='secondary' onClick={toggleModal}>
          Cancelar
        </Button>
      </WrapperButtons>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: ({ bankSeleted = {} }) => ({
    bankName: bankSeleted.name || '',
    country: bankSeleted.country || ''
  }),
  validationSchema,
  handleSubmit: async (values, { setSubmitting, props }) => {
    const params = props.bankSeleted.id
      ? {
        country: values.country,
        name: values.bankName,
        id: props.bankSeleted.id
      }
      : {
        country: values.country,
        name: values.bankName
      }
    const endpoint = props.bankSeleted.id
      ? API.Accounting.UpdateBank(params)
      : API.Accounting.CreateBank(params)
    const message = props.bankSeleted.id
      ? 'Banco actualizado con éxito'
      : 'Banco creado con éxito'
    setSubmitting(true)
    try {
      await endpoint
      Notification.success(message)
      props.getBanks()
    } catch (error) {
      const hasError = get(
        error,
        'response.data.errors.detail',
        'Ha ocurrido un error'
      )
      Notification.error(hasError)
    } finally {
      setSubmitting(false)
      props.toggleModal()
    }
  }
})(AddBank)
