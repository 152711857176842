// Libraries
import React from 'react'
import styled from 'styled-components'

// Components
import { white, appleGrey, colorSecondary } from 'css/colors'
import { getLabel } from 'utils'
import { REQUIRED, INPUT_TYPES } from 'shared/catalogs'

const TableWraper = styled.table`
  padding: 0.5rem;
  width: 100%;
  border: 1px solid ${appleGrey};
`
const TableHead = styled.thead`
  background: ${colorSecondary};
  & > tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > th {
      padding: 0.4rem 0.5rem;
      width: 100%;
      text-align: left;
      font-size: 12px;
      color: ${white};
    }
  }
`
const TableBody = styled.tbody`
  & > tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0.4rem 0.5rem;
    & > td {
      width: 100%;
      text-align: left;
    }
  }
`

/**
 * @typedef {{
 * fields: Array<Field>
 * }} PropsFields
 *
 * @param {PropsFields} props
 */
const Fields = ({ fields }) => {
  return (
    <div>
      <TableWraper>
        <TableHead>
          <tr>
            <th>Encabezado</th>
            <th>Tipo de campo</th>
            <th>Requerido</th>
            <th>Opciones</th>
          </tr>
        </TableHead>
        <TableBody>
          {fields &&
            fields.map(({ id, type, label, values, required }) => {
              const labels = values && values.map(value => value.label)
              return (
                <tr key={id}>
                  <td>{label}</td>
                  <td>{getLabel(INPUT_TYPES, type)}</td>
                  <td>{getLabel(REQUIRED, `${required}`)}</td>
                  <td>{labels.join(', ') || 'No necesita'}</td>
                </tr>
              )
            })}
        </TableBody>
      </TableWraper>
    </div>
  )
}
export default Fields
