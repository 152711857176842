// Libraries
import React, { Component } from 'react'
import Notification from 'cogo-toast'
import debounce from 'lodash/debounce'
import styled from 'styled-components'

// Components
import { Title } from 'css/utils'
import SearchSuggestions from 'shared/searchSuggestions'
import API from 'api/core'

const Content = styled.div`
  width: 70%;
  margin: 0 auto;
`

/**
 * @typedef {import('api/core').Client} Client
 * @typedef {import('utils').PersonalData} PersonalData
 * @typedef {Object} State
 * @property {Array<Client>} clients
 * @property {boolean} requestInProgress
 * @property {Partial<PersonalData> | null } itemSelected
 *
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @extends {React.Component<RouteComponentProps, State>}
 */

class SearchClients extends Component {
  /**
   * @type {State}
   */
  state = {
    clients: [],
    requestInProgress: false,
    itemSelected: null
  }

  /**
   * @param {PersonalData} selection
   */
  onChangeSelect = selection => {
    this.setState(
      {
        itemSelected: selection
      },
      this.getViewClient
    )
  }

  /**
   * @param {string} value
   */
  onChangeValue = debounce(value => {
    this.searchClients(value)
  }, 200)

  getViewClient = () => {
    const { history } = this.props
    const { itemSelected } = this.state
    if (!itemSelected) return
    history.push(`/client/view/${itemSelected.id}`)
  }

  /**
   * @param {string} value
   */
  searchClients = async value => {
    this.setState({ requestInProgress: true })
    try {
      const { data } = await API.Clients.SearchClients(value.trim())
      this.setState({ clients: data })
    } catch (error) {
      console.error(error)
      Notification.error(
        'Ha ocurrido un error al obtener información de los clientes'
      )
    } finally {
      this.setState({ requestInProgress: false })
    }
  }

  render () {
    const { clients, requestInProgress } = this.state
    return (
      <Content>
        <Title>Buscar Clientes</Title>
        <SearchSuggestions
          data={clients}
          requestInProgress={requestInProgress}
          onChange={this.onChangeSelect}
          onChangeValue={this.onChangeValue}
          positionButtonClass='position-button'
          positionLoadingClass='position-loading'
        />
        <hr />
      </Content>
    )
  }
}
export default SearchClients
