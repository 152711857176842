import React from 'react'
import { Route, Switch } from 'react-router-dom'
import 'moment/locale/es'
import store from 'store'
import LogRocket from 'logrocket'

import '../css/main.scss'
import 'nprogress/nprogress.css'
import 'react-datepicker/dist/react-datepicker.css'

import { decode } from 'utils'
import { UserProvider } from '../contexts/user'

// Private pages, do require an active session
import Sidebar from 'shared/sidebar'
import Topbar from 'shared/topbar'
import Clients from './views/clients'
import ClientDetails from './views/clients/clientDetails'
import Contracts from './views/contracts/contractsPending'
import ContractDetails from './views/contracts/contractsPending/details'
import Payments from './views/payments'
import NewPayment from './views/payments/new'
import InvoiceOne from './views/payments/invoices/invoiceOne'
import InvoiceDetails from './views/payments/invoices/invoiceOne/details'
import BankMovements from './views/bankAccounts/movements'
import BankMovementsDetails from './views/bankAccounts/movements/details'
import Account from './views/bankAccounts/view'
import Accounts from './views/bankAccounts'
import NewAccount from './views/bankAccounts/new'
import PendingContractRequests from './views/contract-requests/list-pending'
import ReviewedContractRequests from './views/contract-requests/list-reviewed'
import PendingContractRequest from './views/contract-requests/show-pending'
import ReviewedContractRequest from './views/contract-requests/show-reviewed'
import Accounting from './views/accounting/expenses'
import Catalogues from './views/accounting/expenses/catalogues'
import NewFlowPayment from './views/accounting/expenses/newFlowPayment'
import NewPaymentReceptor from './views/accounting/expenses/catalogues/newPaymentReceptor'
import Incomes from './views/accounting/incomes'
import ReportExpenses from './views/accounting/expenses/generateReport'
import IncomeDetail from './views/accounting/incomes/incomeDetails'
import NewIncome from './views/accounting/incomes/newIncome'
import Layout from './views/accounting/incomes/layout'
import GenerateReport from './views/accounting/incomes/generateReport'
import Clarification from './views/clarification'
import ClarificationDetails from './views/clarification/clarificationDetails'
import ClarificationTypes from './views/clarification/clarificationTypes'
import NewClarificationTypes from './views/clarification/clarificationTypes/newClarificationTypes'
import ContractsFailed from './views/contracts/contractsFailed'

/**
 * @typedef {import('react-router-dom').RouteComponentProps} Props
 * @param {Props} location
 */
const App = ({ location }) => {
  const parsedToken = decode(store.get('CORE_AUTH_TOKEN'))
  if (parsedToken && parsedToken.meta) {
    LogRocket.identify(parsedToken.meta.email)
  }

  return (
    <UserProvider>
      <Topbar />
      <Sidebar pathname={location.pathname} />
      <div id='app-content'>
        <Switch>
          {/* Routes clarification */}
          <Route exact path={'/clarification'} component={Clarification} />
          <Route
            exact
            path={'/clarification/types'}
            component={ClarificationTypes}
          />
          <Route
            exact
            path={'/clarification/types/new'}
            component={NewClarificationTypes}
          />
          <Route
            exact
            path={'/clarification/:id'}
            component={ClarificationDetails}
          />

          {/* Routes of clients */}
          <Route exact path='/search-clients' component={Clients} />
          <Route path='/client/view/:uid' component={ClientDetails} />

          {/* Routes of contracts pending */}
          <Route
            exact
            path='/contract-requests/validate'
            component={PendingContractRequests}
          />
          <Route
            exact
            path='/contract-requests/validate/view/:uid'
            component={PendingContractRequest}
          />
          <Route
            exact
            path='/contract-requests/reviewed'
            component={ReviewedContractRequests}
          />
          <Route
            path='/contract-requests/reviewed/view/:uid'
            component={ReviewedContractRequest}
          />
          <Route path='/contract-requests/admin' component={ContractsFailed} />

          {/* Routes of accounting */}
          <Route exact path='/accounting/flow-payment' component={Accounting} />
          <Route
            path='/accounting/flow-payment/new'
            component={NewFlowPayment}
          />
          <Route
            exact
            path='/accounting/flow-payment/catalogues'
            component={Catalogues}
          />
          <Route
            exact
            path='/accounting/flow-payment/catalogues/new-receptor'
            component={NewPaymentReceptor}
          />
          <Route
            exact
            path='/accounting/flow-payment/catalogues/:tab'
            component={Catalogues}
          />
          <Route
            exact
            path='/accounting/flow-payment/report'
            component={ReportExpenses}
          />

          <Route exact path='/accounting/income' component={Incomes} />
          <Route exact path='/accounting/income/new' component={NewIncome} />
          <Route exact path='/accounting/income/layout' component={Layout} />
          <Route
            exact
            path='/accounting/income/report'
            component={GenerateReport}
          />
          <Route exact path='/accounting/income/:id' component={IncomeDetail} />

          {/* Routes of contracts */}
          <Route exact path='/contracts/pending' component={Contracts} />
          <Route
            path='/contracts/pending/view/:uid'
            component={ContractDetails}
          />

          {/* Routes of payments */}
          <Route exact path='/payments' component={Payments} />
          <Route path='/payments/new' component={NewPayment} />

          {/* Routes of accounts */}
          <Route exact path='/bank-accounts' component={Accounts} />
          <Route path='/bank-accounts/new' component={NewAccount} />
          <Route path='/bank-accounts/view/:uuid' component={Account} />
          <Route path='/bank-accounts/movements' component={BankMovements} />
          <Route
            path='/bank-accounts/movement/:uid'
            component={BankMovementsDetails}
          />
          <Route path='/bank-accounts/:page' component={Accounts} />

          {/* Routes invoices */}
          <Route exact path='/invoices/invoice-one' component={InvoiceOne} />
          <Route path='/invoices/invoice-one/:id' component={InvoiceDetails} />
        </Switch>
      </div>
    </UserProvider>
  )
}

export default App
