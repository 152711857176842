// Libraries
import React, { Component } from 'react'
import { Field, Form, withFormik, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Notification from 'cogo-toast'

// Components
import SelectField from 'shared/selectField'
import Required from 'shared/required'
import Button from 'shared/button'
import { getOptions } from 'utils'
import LinkButton from 'shared/linkButton'
import { COUNTRIES } from 'shared/catalogs'
import API from 'api/core'

/**
 * @constant
 */

const validationSchema = Yup.object().shape({
  receptor: Yup.string().required('Campo requerido'),
  beneficiary: Yup.string().required('Campo requerido'),
  accountNumber: Yup.number()
    .typeError('Agrega solo números')
    .required('Campo requerido'),
  clabe: Yup.number()
    .typeError('Agrega solo números')
    .required('Campo requerido')
})

/**
 * @typedef {import('../../newFlowPayment/form').Values} Values
 * @typedef {import('formik').FormikProps<Values>} FormikProps
 * @typedef {import('../../newFlowPayment').Option} Option
 * @typedef {import('api/core').ReceptorType} ReceptorType
 *
 * @typedef {Object} State
 * @property {boolean} requestingBank
 * @property {boolean} requestInProgress
 * @property {Array<Option>} bankOptions
 * @property {Array<Option>} receptorTypeOptions
 * @property {Array<ReceptorType>} receptorTypes
 *
 * @typedef {Object} FormNewPaymentReceptor
 *
 * @typedef {import('react-router-dom').RouteComponentProps} Props
 */
/**
 * @extends {Component<FormNewPaymentReceptor & FormikProps, State>}
 */

class NewPaymentReceptor extends Component {
  /**
   * @type {State}
   */

  state = {
    requestingBank: false,
    requestInProgress: false,
    bankOptions: [],
    receptorTypeOptions: [],
    receptorTypes: []
  }

  componentDidMount = () => {
    const { state } = this.props.location
    if (state && state.isUpdate) {
      this.handleUpdate()
    }
  }

  /**
   * @param {FormNewPaymentReceptor} prevProps
   * @param {State} _
   */
  componentDidUpdate (prevProps, _) {
    const { values } = this.props
    if (values.country !== prevProps.values.country) {
      this.getBanks()
      this.getReceptorTypes()
    }
  }

  /**
   * Get bank accounts of api
   * @returns {Promise<void>}
   */
  getBanks = async () => {
    const {
      values: { country }
    } = this.props
    this.setState({ requestingBank: true })
    try {
      const { data } = await API.Accounting.GetBanks({
        country: country.toUpperCase()
      })
      this.setState({ bankOptions: data })
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrrido un error cargando los bancos')
    } finally {
      this.setState({ requestingBank: false })
    }
  }

  /**
   * Get bank accounts of api
   * @returns {Promise<void>}
   */
  getReceptorTypes = async () => {
    const {
      values: { country },
      setValues,
      values,
      location: { state }
    } = this.props
    this.setState({ requestInProgress: true })
    try {
      const { data } = await API.Accounting.GetReceptorTypes({
        country: country.toUpperCase()
      })
      this.setState({
        receptorTypeOptions: data.map(({ id, type }) => ({
          id: id,
          label: type
        })),
        receptorTypes: data
      })
      if (state && state.isUpdate) return
      setValues({ ...values, receptorTypeId: '', iva: '' })
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error cargando la información ')
    } finally {
      this.setState({ requestInProgress: false })
    }
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} e
   */
  handleChangeSelect = e => {
    const { value, name } = e.target
    const { setFieldValue } = this.props
    setFieldValue(name, value)
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} e
   */
  selectReceptorType = e => {
    const { setValues, values } = this.props
    const { value } = e.target
    const { receptorTypes } = this.state
    const receptorType = receptorTypes.find(({ id }) => id === Number(value))
    if (!receptorType) return
    const hasVat = receptorType.vat === 0 ? 'Sin IVA' : receptorType.vat
    setValues({
      ...values,
      receptorTypeId: value,
      iva: hasVat
    })
  }

  handleUpdate = () => {
    const { location, setValues } = this.props
    const {
      state: { paymentReceptor }
    } = location
    setValues({
      id: paymentReceptor.id,
      receptor: paymentReceptor.name,
      beneficiary: paymentReceptor.beneficiary,
      clabe: paymentReceptor.clabe,
      accountNumber: paymentReceptor.account_number,
      bankId: paymentReceptor.bank.id,
      receptorTypeId: paymentReceptor.receptor_type.id,
      country: paymentReceptor.country
    })
  }

  render () {
    const {
      values,
      isSubmitting,
      location: { state }
    } = this.props
    const {
      requestingBank,
      receptorTypeOptions,
      bankOptions,
      requestInProgress
    } = this.state
    const countries = getOptions(COUNTRIES)
    return (
      <div className='newFlow'>
        <h1 className='title'>
          {state && state.isUpdate ? 'Actaulizar receptor' : 'Agregar receptor'}
        </h1>
        <Form data-testid='form'>
          <div className='newFlow--field vertical-bottom'>
            <div className='text-right full-width'>
              <SelectField
                label='País'
                placeholder='Selecciona país '
                options={countries}
                name='country'
                value={values.country}
                onChange={this.handleChangeSelect}
                required
              />
            </div>
            <p className='newFlow--field input-full-width no-margin'>
              <label htmlFor='receptor'>Nombre *</label>
              <Field
                id='receptor'
                name='receptor'
                type='text'
                placeholder='Nombre del receptor'
              />
              <ErrorMessage name='receptor'>
                {message => <Required message={message} />}
              </ErrorMessage>
            </p>
          </div>
          <div className='newFlow--field vertical-bottom'>
            <div className='full-width text-right'>
              <SelectField
                label='Tipo de receptor'
                placeholder='Selecciona tipo'
                options={receptorTypeOptions}
                name='receptorTypeId'
                value={values.receptorTypeId}
                onChange={this.selectReceptorType}
                loading={requestInProgress}
                required
              />
            </div>
            <p className='newFlow--field input-full-width  no-margin'>
              <label htmlFor='iva'>IVA</label>
              <Field
                id='iva'
                name='iva'
                type='text'
                placeholder='Sin IVA'
                disabled
              />
            </p>
          </div>
          <div>
            <h2 className='subtitle'>Información bancaria</h2>
            <div className='newFlow--field vertical-bottom'>
              <p className='newFlow--field input-full-width no-margin'>
                <label htmlFor='beneficiary'>Beneficiario *</label>
                <Field
                  id='beneficiary'
                  type='text'
                  name='beneficiary'
                  placeholder='Nombre del beneficiario'
                />
                <ErrorMessage name='beneficiary'>
                  {message => <Required message={message} />}
                </ErrorMessage>
              </p>
              <div className='full-width text-left '>
                <SelectField
                  label='Banco'
                  placeholder='Selecciona banco'
                  options={getOptions(bankOptions)}
                  name='bankId'
                  value={values.bankId}
                  onChange={this.handleChangeSelect}
                  loading={requestingBank}
                  required
                />
              </div>
            </div>
            <div className='newFlow--field vertical-bottom'>
              <p className='newFlow--field input-full-width'>
                <label htmlFor='accountNumber'>Número de cuenta *</label>
                <Field
                  id='accountNumber'
                  name='accountNumber'
                  type='text'
                  placeholder='Número de cuenta'
                />
                <ErrorMessage name='accountNumber'>
                  {message => <Required message={message} />}
                </ErrorMessage>
              </p>
              <p className='newFlow--field input-full-width text-left '>
                <label htmlFor='clabe'>Clabe *</label>
                <Field
                  id='clabe'
                  name='clabe'
                  type='text'
                  placeholder='Clabe'
                />
                <ErrorMessage name='clabe'>
                  {message => <Required message={message} />}
                </ErrorMessage>
              </p>
            </div>
          </div>
          <div className='flex-end'>
            <Button
              type='submit'
              buttonClass='primary'
              icon='check'
              loading={isSubmitting}
              disabled={isSubmitting}
              testId='createReceptor'
            >
              {state && state.isUpdate
                ? 'Actualizar receptor'
                : 'Crear receptor'}
            </Button>
            <LinkButton
              url='/accounting/flow-payment/catalogues'
              buttonClass='secondary'
              icon='cancel'
            >
              Cancelar
            </LinkButton>
          </div>
        </Form>
      </div>
    )
  }
}

export default withFormik({
  mapPropsToValues: () => {
    return {
      receptor: '',
      beneficiary: '',
      bankId: '',
      clabe: '',
      receptorTypeId: '',
      accountNumber: '',
      country: '',
      iva: '',
      id: null
    }
  },
  validationSchema,
  handleSubmit: async (values, { setSubmitting, props }) => {
    const {
      history: { push },
      location: { state }
    } = props
    const data = {
      id: state && state.isUpdate ? values.id : null,
      receptor_type_id:
        state && state.isUpdate
          ? state.paymentReceptor.receptor_type.id
          : values.receptorTypeId,
      name: values.receptor,
      beneficiary: values.receptor,
      clabe: values.clabe,
      account_number: values.accountNumber,
      bank_id: values.bankId,
      country: values.country.toUpperCase()
    }
    setSubmitting(true)
    const apiCall =
      state && state.isUpdate
        ? API.Accounting.UpdatePaymentReceptors(data)
        : API.Accounting.CreatePaymentReceptors(data)
    const success = state && state.isUpdate ? 'atualizado' : 'creado'
    const errorMesage = state && state.isUpdate ? 'actualizar' : 'crear'
    try {
      await apiCall
      Notification.success(`Receptor ${success} correctamente`)
      push('/accounting/flow-payment/catalogues')
    } catch (error) {
      console.error(error)
      Notification.error(`Ocurrió un error al ${errorMesage} el receptor`)
    }
    setSubmitting(false)
  },
  displayName: 'NewPaymentReceptor'
})(NewPaymentReceptor)
