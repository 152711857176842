/* global ResuelveLogin */
// Librerías
import React from 'react'
import Notification from 'cogo-toast'
import { withRouter } from 'react-router-dom'
import store from 'store'

// Componentes
import Logo from 'shared/logo'
import API from 'api/core'

/**
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 *
 * @extends {React.Component<RouteComponentProps, & {}>}
 */
class Login extends React.Component {
  componentDidMount () {
    // @ts-ignore
    ResuelveLogin(
      {
        domain: process.env.RESUELVE_LOGIN,
        area: 'nowhere',
        element: '#login',
        expiration: 8.64e7
      },
      this.handleLoginResponse
    )
  }

  /**
   * @param {object} error
   * @param {string} token
   */
  handleLoginResponse = async (error, token) => {
    const { history } = this.props
    if (error) {
      Notification.error(error)
      return
    }
    if (token) {
      try {
        const response = await API.User.Login({ token })
        if (response.status === 'success' && token) {
          store.set('CORE_AUTH_TOKEN', token)
          store.set('ROLE', response.data.role)
          history.push('/search-clients')
        } else {
          history.push({
            pathname: '/error',
            state: {
              error: response.errors.detail
            }
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  render () {
    return (
      <section className='hero is-fullheight is-white is-bold unselectable'>
        <div className='hero-body'>
          <div className='container has-text-centered'>
            <div className='login-logo'>
              <Logo className='white' />
            </div>
            <a className='google-button' href='#' id='login'>
              Iniciar sesión
            </a>
          </div>
        </div>
      </section>
    )
  }
}
export default withRouter(Login)
