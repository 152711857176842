// Libraries
import React from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

// Components
import IconButton from 'shared/iconButton'
import { formatCurrency, parseQuery } from 'utils'
import { Table, TableHeader, TableBody, TableDataCell } from 'css/utils'
import {
  blueResuelveLogo,
  redTraffic,
  greenTraffic,
  mediumGrey
} from 'css/colors'

const Status = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > p {
    font-size: 18px;
    font-weight: 600;
    margin-right: 2em;
  }
`
const Version = styled.div`
  & > button i {
    color: ${mediumGrey};
  }
`
const WapperColors = styled.div`
  & > span {
    font-size: 14px;
    margin-right: 1rem;
    padding: 0.2rem 0.5rem;
    border-radius: 3px;
    font-weight: 600;
    &:nth-child(1) {
      border-left: 5px solid ${greenTraffic};
    }
    &:nth-child(2) {
      border-left: 5px solid #84acca;
    }
    &:nth-child(3) {
      border-left: 5px solid ${redTraffic};
      margin-right: 0;
    }
  }
`

/**
 * @param {string} status
 */
const colorStatus = status => {
  switch (status) {
    case 'SENT':
      return greenTraffic
    case 'DRAFT':
      return '#84acca'
    case 'ERROR':
      return redTraffic
    default:
      return blueResuelveLogo
  }
}

/**
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @typedef {import('api/core').TypeInvoiceOne} TypeInvoiceOne
 * @typedef {{
 * invoicesOne: TypeInvoiceOne[];
 * totalEntries: number;
 * }} PropsListInvoince
 *
 * @param {PropsListInvoince & RouteComponentProps} props
 */
const ListInvoince = ({ invoicesOne, totalEntries, history, location }) => {
  const hasParams = isEmpty(parseQuery(location.search))
  return (
    <div>
      <div className='flex-between bottom'>
        <p className='register-results no-padding'>
          {totalEntries === 0
            ? 'No hay facturas creadas'
            : `Hay ${totalEntries} facturas`}
        </p>
        <Status>
          <p>Estados:</p>
          <WapperColors>
            <span>Enviada</span>
            <span>Borrador</span>
            <span>Error</span>
          </WapperColors>
        </Status>
      </div>
      <Table>
        <TableHeader>
          <tr>
            <th>Destinatario</th>
            <th>Tipo</th>
            <th>Facturado</th>
            <th>Vencimiento</th>
            <th>Total</th>
            <th>Resolución</th>
            <th>Folio</th>
            <th>
              <Version>
                <IconButton iconOnly icon='source-fork' tooltip='Versión' />
              </Version>
            </th>
          </tr>
        </TableHeader>
        <TableBody noLine>
          {invoicesOne.length > 0 &&
            invoicesOne.map(
              ({
                id,
                prefix,
                date,
                folio,
                status,
                type,
                total,
                currency,
                resolution,
                version,
                due_date: duDate,
                recipient_last_name: recipientLastName,
                recipient_name: recipientName
              }) => {
                return (
                  <tr
                    key={id}
                    onClick={() =>
                      history.push({
                        pathname: `/invoices/invoice-one/${id}`,
                        state: { params: hasParams }
                      })
                    }
                  >
                    <TableDataCell className='link' color={colorStatus(status)}>
                      {`${recipientName} ${recipientLastName}`}
                    </TableDataCell>
                    <td>{type}</td>
                    <td>{moment(date).format('DD/MM/YYYY')}</td>
                    <td>{moment(duDate).format('DD/MM/YYYY')}</td>
                    <td>{formatCurrency(Number(total), currency)}</td>
                    <td>{resolution}</td>
                    <td>{`${prefix} ${folio}`}</td>
                    <td>{version}</td>
                  </tr>
                )
              }
            )}
        </TableBody>
      </Table>
    </div>
  )
}
export default withRouter(ListInvoince)
