import React, { useEffect, useState, Fragment } from 'react'
import Notification from 'cogo-toast'

import AddCompany from './addCompany'
import { getCountryLabel } from 'utils'
import Button from 'shared/button'
import IconButton from 'shared/iconButton'
import FiltersAccounting from 'shared/filtersAccounting'
import Loader from 'shared/loader'
import Modal from 'shared/modal'
import API from 'api/core'

/**
 * @param {{
 * routeTab: any
 * }} props
 */
export default function Compaines ({ routeTab }) {
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [value, setValue] = useState('')
  const [companySelected, setCompanySelected] = useState({})
  const [companies, setCompanies] = useState([
    {
      name: '',
      id: null,
      country: ''
    }
  ])

  useEffect(() => {
    routeTab()
    getCompanies()
  }, [value])

  async function getCompanies () {
    const params = {
      country: value || 'MX'
    }
    setLoading(true)
    try {
      const { data } = await API.Accounting.GetCompanies(params)
      setCompanies(data)
    } catch (error) {
      Notification.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  function onChangeField (event) {
    const { value } = event.currentTarget
    setValue(value)
  }

  function onCleanFilters () {
    if (!value) return
    setValue('')
  }

  function onFilter () {
    if (!value) return
    getCompanies()
  }

  /**
   * @param {{
   * name: string,
   * id: string | null,
   * country: string
   * }} company
   */
  function hadleUpateCompany (company) {
    setCompanySelected(company)
    setOpenModal(!openModal)
  }

  function handleToggle () {
    setOpenModal(!openModal)
    setCompanySelected({})
  }

  return (
    <Fragment>
      <FiltersAccounting
        className='filter-accounting'
        filters={{ country: value }}
        onFilter={() => onFilter()}
        onChangeField={e => onChangeField(e)}
        onCleanFilters={() => onCleanFilters()}
      />
      <div className='flex-between'>
        <p className='register-results'>Hay {companies.length} resultados</p>
        <Button
          buttonClass='create'
          icon='plus-circle-outline'
          testId='new-compaines'
          onClick={() => setOpenModal(!openModal)}
        >
          Agregar compañia
        </Button>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <table className='table'>
          <thead>
            <tr>
              <th>Nombre de la compañia</th>
              <th>País</th>
              <th>Id de la compañia</th>
            </tr>
          </thead>
          <tbody>
            {companies.map(company => (
              <tr key={company.id}>
                <td className='link'>{company.name}</td>
                <td>{getCountryLabel(company.country)}</td>
                <td>{company.id}</td>
                <td>
                  <IconButton
                    icon='pencil'
                    testId='edit-company'
                    tooltip='Editar compañía'
                    onClick={() => hadleUpateCompany(company)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Modal
        title={companySelected.id ? 'Actualizar compañía' : 'Agregar compañía'}
        toggleModal={() => handleToggle()}
        isActive={openModal}
        noFooter
      >
        <AddCompany
          toggleModal={() => handleToggle()}
          getCompanies={() => getCompanies()}
          companySelected={companySelected}
        />
      </Modal>
    </Fragment>
  )
}
