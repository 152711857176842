// Libraries
import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

// Components
import Input from '../forms/input'
import Image from '../forms/image'
import Debt from '../forms/debt'
import Pdf from '../forms/pdf'
import Audio from '../forms/audio'

const componentsMap = {
  image: Image,
  pdf: Pdf,
  audio: Audio,
  input: Input,
  debt: Debt
}

const hide = {
  display: 'none'
}

const SELECT_OPTIONS = [{ value: 'invalid_field', label: 'Campo invalido' }]

const Field = ({
  title,
  isValid,
  type,
  name,
  input,
  value,
  entity,
  description,
  onChangeValidation,
  onChangeDescription,
  onChangeDocument,
  isEditable,
  notes
}) => {
  const ComponentToRender = componentsMap[type]
  return (
    <div className='columns'>
      <div className='column columns'>
        <div className='column is-1'>
          <input
            className='is-horizontal'
            type='checkbox'
            onChange={e => {
              onChangeValidation(name)
            }}
            checked={!isValid}
            disabled={!isEditable}
          />
        </div>

        <div className='column'>
          {ComponentToRender && (
            <ComponentToRender
              title={title}
              entity={entity}
              notes={notes}
              onClick={e => onChangeDocument({ type, name, value })}
              src={value}
              value={value}
              disabled
            />
          )}

          <div className='columns' style={isValid ? hide : {}}>
            <div className='column'>
              {isEditable ? (
                <Select.Creatable
                  value={description}
                  options={SELECT_OPTIONS}
                  onChange={text => {
                    onChangeDescription(text, name)
                  }}
                  name='description'
                />
              ) : (
                <Input disabled={!isEditable} value={description} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Field.propTypes = {
  onChangeValidation: PropTypes.func,
  onChangeDescription: PropTypes.func,
  onChangeDocument: PropTypes.func,
  isEditable: PropTypes.bool
}

Field.defaultProps = {
  onChangeValidation: () => {},
  onChangeDescription: () => {},
  onChangeDocument: () => {},
  isEditable: true
}

export default Field
