// Libraries
import React, { Component } from 'react'
import formatMoney from '../format/money'
import API from 'api/payments'
import Notification from 'cogo-toast'

class PaymentsTable extends Component {
  cancelPayment (uid, id) {
    API.Payments.Cancel({ uid: uid }).then(response => {
      if (response.status === 'error') {
        Notification.error('Error al intentar cancelar el pago.')
      } else {
        this.props.updateState(id)
      }
    })
  }

  render () {
    const { transactions } = this.props
    if (!transactions) return null
    const body = transactions.map((transaction, i) => {
      const fullName = `${transaction.customer_metadata.name} ${
        transaction.customer_metadata.last_name
      }`
      const insertedAt = transaction.inserted_at.substring(0, 10)
      return (
        <tbody key={transaction.uid}>
          <tr>
            <td>{insertedAt}</td>
            <td>{transaction.carrier}</td>
            <td>{transaction.status}</td>
            <td>{fullName}</td>
            <td>{transaction.customer_metadata.email}</td>
            <td>{formatMoney.toMoney(transaction.amount)}</td>
            <td>
              {transaction.transaction_metadata != null &&
                transaction.transaction_metadata.clabe}
            </td>
            <td>
              <button
                className='button is-danger'
                onClick={() => this.cancelPayment(transaction.uid, i)}
              >
                <i className='mdi mdi-cancel' aria-hidden='true' />
              </button>
            </td>
          </tr>
        </tbody>
      )
    })

    return (
      <table className='table'>
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Carrier</th>
            <th>Status</th>
            <th>Nombre</th>
            <th>Email</th>
            <th>Monto</th>
            <th>Método de pago</th>
            <th>Cancelar</th>
          </tr>
        </thead>
        {body}
      </table>
    )
  }
}

export default PaymentsTable
