// Libraries
// Libraries
import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { withCurrentUser } from 'contexts/user'
import store from 'store'

// Components
import Logo from './../logo'

/**
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @typedef {import('contexts/user').User} User
 * @typedef {{user: User}} PropsTopbar
 *
 * @extends {Component<PropsTopbar & RouteComponentProps & {}>}
 */
class Topbar extends Component {
  logout = () => {
    const { history } = this.props
    store.clearAll()
    history.push('/login')
  }

  render () {
    const { user } = this.props
    return (
      <div id='app-topbar'>
        <div className='topbar-section'>
          <div className='item'>
            <Link to='/search-clients'>
              <Logo className='light' />
            </Link>
          </div>
        </div>
        <div className='topbar-section'>
          <div className='item'>
            <button>
              <img className='avatar' src={user.avatar} />
              {user.name}
            </button>
            <div className='submenu rigth'>
              <button onMouseDown={this.logout}>
                <i className='mdi mdi-logout' /> Salir
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const TopbarwithCurrentUser = withCurrentUser(Topbar)

export default withRouter(TopbarwithCurrentUser)
