// Libraries
/* eslint-disable */
import React, {PureComponent} from 'react'
import ImageZoom from 'react-medium-image-zoom'

// Components
import {DOCUMENT_TITLES} from './constants'

const defaultState = {
  rotation: 0,
  zoomStep: 0
}

const MIN_ZOOM_STEP = -4

class ZoomableImageForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = Object.assign({}, defaultState)
    this.rotateLeft = this.rotateLeft.bind(this)
    this.rotateRight = this.rotateRight.bind(this)
    this.zoomIn = this.zoomIn.bind(this)
    this.zoomOut = this.zoomOut.bind(this)
    this.resetZoom = this.resetZoom.bind(this)
  }

  rotateLeft() {
    this.setState(({rotation}) => ({rotation: rotation - 90}))
  }

  rotateRight() {
    this.setState(({rotation}) => ({rotation: rotation + 90}))
  }

  zoomIn() {
    this.setState(({zoomStep}) => ({zoomStep: zoomStep + 1}))
  }

  zoomOut() {
    this.setState(({zoomStep}) => ({zoomStep: zoomStep - 1}))
  }

  resetZoom() {
    this.setState({zoomStep: 0})
  }

  componentDidUpdate(prevProps, prevState) {
    const {src} = this.props
    // Reset state zoom and rotation
    if (src !== prevProps.src) {
      this.reset()
    }
  }

  reset() {
    this.setState(defaultState)
  }

  render() {
    const {title, src} = this.props
    const {rotation, zoomStep} = this.state
    const [name, _] = title.split('-')
    // Calculate the with of the img if has zoom or not
    const currentWidth = 100 + zoomStep * 20
    return (
      <React.Fragment>
        <div className='document-container'>
          <ImageZoom
            image={{
              src,
              alt: title,
              style: {
                transform: `rotate(${rotation}deg)`,
                width: `${currentWidth}%`
              }
            }}
            zoomImage={{
              src,
              alt: title
            }}
            defaultStyles={{
              zoomContainer: {
                zIndex: 9999
              }
            }}
          />
        </div>
        <span className='label'>{DOCUMENT_TITLES[name] || 'Otros'}</span>
        <div
          style={{marginTop: 10}}
          className='field is-grouped is-grouped-centered'
        >
          <p className='control'>
            <a
              className='button is-primary'
              onClick={this.rotateLeft}
              title='Rotar a la izquierda '
            >
              <i className='mdi mdi-rotate-left' />
            </a>
          </p>
          <p className='control'>
            <a
              className='button is-primary'
              onClick={this.rotateRight}
              title='Rotar a la derecha'
            >
              <i className='mdi mdi-rotate-right' />
            </a>
          </p>
          <p className='control'>
            <a
              className='button is-primary'
              onClick={this.zoomIn}
              title='Acercar'
            >
              <i className='mdi mdi-magnify-plus' />
            </a>
          </p>
          <p className='control'>
            <a
              className='button is-primary'
              disabled={zoomStep === MIN_ZOOM_STEP}
              onClick={this.zoomOut}
              title='Alejar'
            >
              <i className='mdi mdi-magnify-minus' />
            </a>
          </p>
          <p className='control'>
            <a
              disabled={zoomStep === 0}
              className='button is-primary'
              onClick={this.resetZoom}
              title='Restaurar zoom'
            >
              <i className='mdi mdi-eraser' />
            </a>
          </p>
        </div>
      </React.Fragment>
    )
  }
}
export default ZoomableImageForm
