import React from 'react'
import { Link } from 'react-router-dom'

/**
 * @typedef {Object} PropsLinkButton
 * @property {string} url
 * @property {string} [icon]
 * @property {string} buttonClass
 * @property {string} [testId]
 * @property {React.ReactNode} children
 * @param {PropsLinkButton} props
 */

const LinkButton = ({ url, icon, buttonClass, children, testId }) => {
  return (
    <div>
      <Link to={url}>
        <div
          className={`coreButton ${buttonClass}`}
          data-testid={`button-${testId}`}
        >
          <i className={`mdi mdi-${icon}`} style={{ marginRight: '0.3rem' }} />
          {children}
        </div>
      </Link>
    </div>
  )
}
export default LinkButton
