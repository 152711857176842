// Libraries
import React, { Fragment } from 'react'
import { withFormik, Field, Form } from 'formik'
import styled from 'styled-components'
import Notification from 'cogo-toast'

// Components
import { mediumGrey } from 'css/colors'
import { FlexWrapper, FieldWrapper, Title } from 'css/utils'
import DatePicker from 'shared/datePicker'
import SelectField from 'shared/selectField'
import Button from 'shared/button'
import Required from 'shared/required'
import LinkButton from 'shared/linkButton'
import {
  getOptions,
  downloadCSV,
  getFileName,
  hasFilters,
  formatDateToSend
} from 'utils'
import { COUNTRIES } from 'shared/catalogs'
import API from 'api/core'

const Content = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 3rem;
`
const SubTitle = styled.h2`
  font-size: 18px;
  color: ${mediumGrey};
  font-weight: 600;
  padding: 0 0 0.5em 0;
`
const FlexEnd = styled(FlexWrapper)`
  justify-content: flex-end;
  padding: 0.3rem 0;
`

const ContentField = styled(FieldWrapper)`
  &:nth-child(1) {
    margin-right: 1rem;
  }
`

/**
 * @typedef {{
 * invoiceStartDate: string | Date,
 * invoiceEndDate: string | Date,
 * paymentStartDate:string | Date,
 * paymentEndDate: string | Date,
 * country: string,
 * report: string,
 * }} FormikValues
 * @typedef {import('formik').FormikProps<FormikValues>} FormikProps
 * @param {FormikProps} props
 */
const GenerateReport = ({
  handleSubmit,
  setFieldValue,
  isSubmitting,
  values,
  errors,
  touched,
  resetForm
}) => {
  /**
   * @param {Date} date
   * @param {string} field
   */
  function handleChange (date, field) {
    setFieldValue(field, date)
  }

  function canDownloadCsv () {
    const name = values.report && getFileName(values.report)
    downloadCSV(values.report && values.report, name)
  }

  return (
    <Fragment>
      <LinkButton
        url='/accounting/flow-payment'
        icon='arrow-left-thick'
        buttonClass='link bottom'
      >
        Egresos
      </LinkButton>
      <Content>
        <Title>Generar reporte de egresos</Title>
        <Form onSubmitCapture={handleSubmit}>
          <div>
            <SubTitle>Facturación</SubTitle>
            <FlexWrapper>
              <ContentField>
                <Field name='invoiceStartDate'>
                  {({ field }) => (
                    <DatePicker
                      {...field}
                      name='invoiceStartDate'
                      width='100%'
                      label='Fecha de inicio'
                      placeholderText='DD/MM/YYYY'
                      selected={values.invoiceStartDate}
                      onChange={date => handleChange(date, 'invoiceStartDate')}
                      dateFormat='dd MMMM yyyy'
                      maxDate={new Date()}
                    />
                  )}
                </Field>
                {errors.invoiceStartDate && touched.invoiceStartDate && (
                  <Required message={errors.invoiceStartDate} />
                )}
              </ContentField>
              <ContentField>
                <Field name='invoiceEndDate'>
                  {({ field }) => (
                    <DatePicker
                      {...field}
                      name='invoiceEndDate'
                      width='100%'
                      label='Fecha final'
                      placeholderText={
                        !values.invoiceStartDate
                          ? 'Elige fecha de inicio'
                          : 'DD/MM/YYYY'
                      }
                      selected={values.invoiceEndDate}
                      onChange={date => handleChange(date, 'invoiceEndDate')}
                      dateFormat='dd MMMM yyyy'
                      maxDate={new Date()}
                      minDate={values.invoiceStartDate}
                      disabled={!values.invoiceStartDate}
                    />
                  )}
                </Field>
                {errors.invoiceEndDate && touched.invoiceEndDate && (
                  <Required message={errors.invoiceEndDate} />
                )}
              </ContentField>
            </FlexWrapper>
          </div>
          <div>
            <SubTitle>Pago</SubTitle>
            <FlexWrapper>
              <ContentField>
                <Field name='paymentStartDate'>
                  {({ field }) => (
                    <DatePicker
                      {...field}
                      name='paymentStartDate'
                      width='100%'
                      label='Fecha de inicio'
                      placeholderText='DD/MM/YYYY'
                      selected={values.paymentStartDate}
                      onChange={date => handleChange(date, 'paymentStartDate')}
                      dateFormat='dd MMMM yyyy'
                      maxDate={new Date()}
                    />
                  )}
                </Field>
                {errors.paymentStartDate && touched.paymentStartDate && (
                  <Required message={errors.paymentStartDate} />
                )}
              </ContentField>
              <ContentField>
                <Field name='paymentEndDate'>
                  {({ field }) => (
                    <DatePicker
                      {...field}
                      name='paymentEndDate'
                      width='100%'
                      label='Fecha final'
                      placeholderText={
                        !values.paymentStartDate
                          ? 'Elige fecha de inicio'
                          : 'DD/MM/YYYY'
                      }
                      selected={values.paymentEndDate}
                      onChange={date => handleChange(date, 'paymentEndDate')}
                      dateFormat='dd MMMM yyyy'
                      maxDate={new Date()}
                      minDate={values.paymentStartDate}
                      disabled={!values.paymentStartDate}
                    />
                  )}
                </Field>
                {errors.paymentEndDate && touched.paymentEndDate && (
                  <Required message={errors.paymentEndDate} />
                )}
              </ContentField>
            </FlexWrapper>
          </div>
          <div>
            <FlexWrapper>
              <FieldWrapper right='1rem'>
                <Field name='country'>
                  {({ field }) => (
                    <SelectField
                      {...field}
                      id='country'
                      name='country'
                      label='País'
                      placeholder='Seleciona una opción'
                      options={[
                        { id: 'ALL', label: 'Todos' },
                        ...getOptions(COUNTRIES)
                      ]}
                    />
                  )}
                </Field>
              </FieldWrapper>
            </FlexWrapper>
          </div>
          <FlexEnd>
            <Button
              type='submit'
              buttonClass='primary'
              icon='file-document'
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Generar reporte
            </Button>
            <Button
              buttonClass='primary'
              icon='eraser'
              onClick={() => resetForm()}
            >
              Limpiar
            </Button>
            {values.report && (
              <Button
                buttonClass='create'
                icon='cloud-download'
                tooltip='Descargar'
                onClick={() => canDownloadCsv()}
              >
                {getFileName(values.report && values.report)}
              </Button>
            )}
          </FlexEnd>
        </Form>
      </Content>
    </Fragment>
  )
}
export default withFormik({
  mapPropsToValues: () => ({
    invoiceStartDate: '',
    invoiceEndDate: '',
    paymentStartDate: '',
    paymentEndDate: '',
    country: 'ALL' || '',
    incomeType: 'TODOS' || '',
    report: null
  }),
  handleSubmit: async (values, { setSubmitting, setFieldValue }) => {
    const filters = {
      country: values.country === 'ALL' ? '' : values.country,
      income_type: values.incomeType === 'TODOS' ? '' : values.incomeType,
      billing_start_date: values.invoiceStartDate
        ? formatDateToSend(values.invoiceStartDate)
        : '',
      billing_finish_date: values.invoiceEndDate
        ? formatDateToSend(values.invoiceEndDate)
        : '',
      payment_start_date: values.paymentStartDate
        ? formatDateToSend(values.paymentStartDate)
        : '',
      payment_finish_date: values.paymentEndDate
        ? formatDateToSend(values.paymentEndDate)
        : ''
    }
    const params = { ...hasFilters(filters) }

    setSubmitting(true)
    try {
      const { url, errors } = await API.Accounting.ReportExpensesCsv(params)
      if (errors) {
        Notification.error(errors.detail)
      } else {
        Notification.success('Reporte generado correctamente')
        setFieldValue('report', url)
      }
    } catch (error) {
      console.log(error)
      Notification.error('Ha ocurrido un error al generar el reporte')
    }
    setSubmitting(false)
  }
})(GenerateReport)
