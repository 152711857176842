// Libraries
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

// Breadcrumb shows the hierarchy of the current location in the app
class Breadcrumb extends Component {
  // Render every item on the items prop
  renderItems () {
    let items = null
    if (this.props.items.length > 0) {
      items = this.props.items.map((item, index) => {
        const isLast = index === this.props.items.length - 1
        const arrow = !isLast ? (
          <i
            className='mdi mdi-chevron-right breadcrumb--item-icon'
            aria-hidden='true'
          />
        ) : null
        return (
          <div className='breadcrumb--item' key={index}>
            <span className='breadcrumb--item-label'>
              {!isLast && (
                <Link to={item.route}>
                  {item.label}
                  {arrow}
                </Link>
              )}
            </span>
          </div>
        )
      })
    }
    return items
  }

  // Renders breadcrumbs on the DOM
  render () {
    return <div className='breadcrumb'>{this.renderItems()}</div>
  }
}

export default Breadcrumb
