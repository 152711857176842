import { validateContractRequest } from '../views/contracts/components/utils'

const mapper = {
  map: contractRequest => {
    if (!contractRequest) return []
    const contract = validateContractRequest(contractRequest)

    const filesDebts = contract.files.map((file, index) => {
      const [name, extension] = file.name.split('.')
      const fileName = extension ? name : file.name
      return {
        name: `${fileName}-${index}`,
        title: `${fileName}`,
        value: file.url,
        entity: file.entity,
        notes: file.description
      }
    })

    const clientDocuments = contract.client.documents_identity.map(
      (file, index) => {
        return {
          name: `${file.name}-${index}`,
          title: `${file.name}`,
          value: file.url,
          entity: file.entity,
          notes: file.description
        }
      }
    )

    const filesToValidate = [...filesDebts, ...clientDocuments]

    let debtsToValidate = []

    if (contract.debts !== undefined) {
      debtsToValidate = contract.debts.map((debt, index) => ({
        name: `debt.credit_number ${index + 1}`,
        title: `Deuda ${index + 1}`,
        value: [
          `Número de crédito: ${debt.credit_number.value}`,
          `Nombre de entidad financiera: ${debt.financial_entity_name.value}`,
          `Fecha de último pago: ${debt.last_paid_date.value}`,
          `Fecha de última actualización:  ${debt.last_update_date.value}`,
          `Monto capturado: ${debt.entered_amount.value}`,
          `Monto actualizado: ${debt.updated_amount.value}`,
          `Notas: ${debt.note.value || ''}`
        ]
      }))
    }

    const infoToValidate = [
      {
        name: 'country',
        title: 'País',
        value: contract.country
      },
      {
        name: 'email',
        title: 'Email',
        value: contract.client.email.value
      },
      {
        name: 'phone',
        title: 'Teléfono',
        value: contract.client.phone.value
      },
      {
        name: 'names',
        title: 'Nombre',
        value: contract.client.names.value
      },
      {
        name: 'first_surname',
        title: 'Apellido Paterno',
        value: contract.client.first_surname.value
      },
      {
        name: 'second_surname',
        title: 'Apellido Materno',
        value: contract.client.second_surname.value
      },
      {
        name: 'birth_date',
        title: 'Fecha de nacimiento',
        value: contract.client.birth_date.value
      },
      {
        name: 'nationality',
        title: 'Nacionalidad',
        value: contract.client.nationality.value
      },
      {
        name: 'birth_country',
        title: 'País de nacimiento',
        value: contract.client.birth_country.value
      },
      {
        name: 'birth_state',
        title: 'Estado de nacimiento',
        value: contract.client.birth_state.value
      },
      {
        name: 'gender',
        title: 'Género',
        value: contract.client.gender.value
      },
      {
        name: 'occupation',
        title: 'Ocupación',
        value: contract.client.occupation?.value || 'N/A'
      },
      {
        name: 'identity',
        title: 'Identificaión',
        value: contract.client.identification_number.value
      },
      {
        name: 'RFC',
        title: 'RFC',
        value:
          contract && contract.client && contract.client.rfc
            ? contract.client.rfc.value
            : 'N/A'
      },
      {
        name: 'street',
        title: 'Calle',
        value: contract.client.address.street.value
      },
      {
        name: 'street_number',
        title: 'Número exterior',
        value: contract.client.address.street_number.value
      },
      {
        name: 'interior_number',
        title: 'Número interior',
        value: contract.client.address.interior_number.value
      },
      {
        name: 'neighborhood',
        title: 'Colonia',
        value: contract.client.address.neighborhood.value
      },
      {
        name: 'municipality',
        title: 'Municipio',
        value: contract.client.address.municipality.value
      },
      {
        name: 'state',
        title: 'Estado',
        value: contract.client.address.state.value
      },
      {
        name: 'postal_code',
        title: 'Código postal',
        value: contract.client.address.postal_code.value
      },
      {
        name: 'monthly_payment',
        title: 'Pago mensual',
        value: contract.monthly_payment?.value || 'N/A'
      },
      {
        name: 'beneficiary_name',
        title: 'Nombre del beneficiario',
        value: () => {
          if (
            contract.country !== 'co' ||
            contract.product_line !== 'credito'
          ) {
            return contract.client.beneficiary.name.value
          }
        }
      },
      {
        name: 'beneficiary_relationship',
        title: 'Parentezco con el titular',
        value: () => {
          if (
            contract.country !== 'co' ||
            contract.product_line !== 'credito'
          ) {
            return contract.client.beneficiary.relationship.value
          }
        }
      },
      {
        name: 'beneficiary_birth_date',
        title: 'Fecha de nacimiento del beneficiario',
        value: () => {
          if (
            contract.country !== 'co' ||
            contract.product_line !== 'credito'
          ) {
            return contract.client.beneficiary.birth_date.value
          }
        }
      },
      {
        name: 'beneficiary_gender',
        title: 'Género del beneficiario',
        value: () => {
          if (
            contract.country !== 'co' ||
            contract.product_line !== 'credito'
          ) {
            return contract.client.beneficiary.gender.value
          }
        }
      }
    ]

    const invalid = contract.invalid || []

    const client = infoToValidate.map(info => mapper.map_field(invalid, info))
    const debts = debtsToValidate.map(debt =>
      mapper.map_field(invalid, debt, 'debt')
    )
    const files = filesToValidate.map(info => {
      // Por defecto consideramos que son imágenes
      let type = 'image'

      if (info.value.indexOf('.pdf') !== -1) {
        type = 'pdf'
      } else if (
        info.value.indexOf('.wav') !== -1 ||
        info.value.indexOf('.mp3') !== -1 ||
        info.value.indexOf('.amr') !== -1
      ) {
        type = 'audio'
      }

      return mapper.map_field(invalid, info, type)
    })
    const finalAray = [...files, ...client, ...debts]
    return finalAray.reduce(
      (previous, field) => Object.assign(previous, { [field.name]: field }),
      {}
    )
  },

  map_field: (invalid, info, type = 'input') => {
    const field = invalid.find(field => info.title === field.title) || {}

    const isValid = typeof field.description === 'undefined'
    const description = field.description === undefined ? '' : field.description

    return Object.assign(info, {
      type: type,
      is_valid: isValid,
      description: description
    })
  }
}

export default mapper
