import React from 'react'

const Check = () => (
  <svg id='Capa_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1094 355'>
    <title>Logo Check</title>
    <path
      fill='#fff'
      d='M505.4-316.3h-9.7c-2.9 0-5.2-3.1-5.2-6.8v-29.4c0-3.8 2.3-6.8 5.2-6.8h9.7c2.9 0 5.2 3.1 5.2 6.8v29.4c0 3.8-2.4 6.8-5.2 6.8zM542.9-316.3h-9.7c-2.9 0-5.2-3.1-5.2-6.8V-378c0-3.8 2.3-6.8 5.2-6.8h9.7c2.9 0 5.2 3.1 5.2 6.8v54.9c0 3.8-2.3 6.8-5.2 6.8zM580.4-316.3h-9.7c-2.9 0-5.2-3.1-5.2-6.8v-84.1c0-3.8 2.3-6.8 5.2-6.8h9.7c2.9 0 5.2 3.1 5.2 6.8v84.1c0 3.8-2.3 6.8-5.2 6.8zM617.9-316.3h-9.7c-2.9 0-5.2-3.1-5.2-6.8v-115.7c0-3.8 2.3-6.8 5.2-6.8h9.7c2.9 0 5.2 3.1 5.2 6.8v115.7c0 3.8-2.3 6.8-5.2 6.8z'
    />
    <g>
      <path
        fill='#fff'
        d='M674-486.6H448.2c-16.5 0-30 13.5-30 30v153c0 16.5 13.5 30 30 30h137.7c.7 0 1.4.3 1.9.8l39.7 40c1.2 1.2 2.7 1.8 4.3 1.8.8 0 1.6-.2 2.3-.5 2.3-.9 3.8-3.2 3.8-5.7V-271c0-1.5 1.2-2.7 2.7-2.7H674c16.5 0 30-13.5 30-30v-153c0-16.5-13.5-29.9-30-29.9zm-243.6 29.9c0-9.8 8-17.8 17.8-17.8H674c9.8 0 17.8 8 17.8 17.8v153c0 9.8-8 17.8-17.8 17.8h-33.4c-8.2 0-14.9 6.7-14.9 14.9v19l-29.3-29.5c-2.8-2.8-6.6-4.4-10.6-4.4H448.2c-9.8 0-17.8-8-17.8-17.8v-153z'
      />
      <path
        fill='#fff'
        d='M569-259.8c-1.2-1.2-2.7-1.8-4.3-1.8l-94.6-.4c-3.9 0-7.7 1.6-10.5 4.4l-18 25.1.1-18.3c0-4-1.5-7.7-4.3-10.5s-6.5-4.4-10.5-4.4l-10.7-.1c-8.9 0-10.7-6.6-10.6-17.8l.5-153c0-1.6-.6-3.2-1.8-4.3-1.2-1.2-2.7-1.8-4.3-1.8-3.4 0-6.1 2.7-6.1 6.1l-.5 153c0 8.8 1.7 15.9 5.1 21.1 3.9 6 9.9 9 17.7 9l10.7.1a2.732 2.732 0 0 1 2.7 2.7l-.1 33.8c0 2.5 1.5 4.7 3.8 5.7.8.3 1.5.5 2.4.5 1.6 0 3.2-.6 4.3-1.8l28.5-36.4c.5-.5 1.2-.7 1.8-.7l94.6.4c3.4 0 6.1-2.7 6.1-6.1-.2-1.8-.9-3.3-2-4.5z'
      />
    </g>
    <g>
      <path
        fill='#003a63'
        d='M471.3 118.8c-18.9 0-33.8 6.3-44.7 18.9-10.9 12.6-16.4 29.8-16.4 51.7 0 22.5 5.3 39.9 15.8 52.1 10.5 12.3 25.6 18.4 45.1 18.4 12 0 25.7-2.2 41-6.5v17.5c-11.9 4.5-26.6 6.7-44.1 6.7-25.3 0-44.8-7.7-58.6-23-13.8-15.4-20.6-37.2-20.6-65.5 0-17.7 3.3-33.2 9.9-46.5 6.6-13.3 16.2-23.6 28.7-30.8 12.5-7.2 27.2-10.8 44.1-10.8 18 0 33.8 3.3 47.3 9.9l-8.5 17.2c-12.9-6.2-26-9.3-39-9.3zM638.8 277.6v-84.4c0-10.6-2.4-18.6-7.3-23.8-4.8-5.2-12.4-7.9-22.7-7.9-13.7 0-23.8 3.7-30.1 11.2-6.3 7.5-9.5 19.7-9.5 36.7v68.2h-19.8V92.4h19.8v56.1c0 6.7-.3 12.3-1 16.8h1.2c3.9-6.3 9.4-11.2 16.6-14.8 7.2-3.6 15.4-5.4 24.6-5.4 16 0 27.9 3.8 35.9 11.4 8 7.6 12 19.6 12 36.1v85.1h-19.7zM748.9 277.6c-19 0-34.1-5.8-45.1-17.4-11-11.6-16.5-27.7-16.5-48.3 0-20.8 5.1-37.3 15.3-49.5 10.2-12.2 24-18.3 41.2-18.3 16.1 0 28.9 5.3 38.3 15.9 9.4 10.6 14.1 24.6 14.1 42v12.3h-88.7c.4 15.1 4.2 26.6 11.5 34.4 7.2 7.8 17.5 11.8 30.6 11.8 13.9 0 27.6-2.9 41.1-8.7v17.4c-6.9 3-13.4 5.1-19.6 6.4-6.1 1.4-13.5 2-22.2 2zm-5.3-117.2c-10.3 0-18.6 3.4-24.7 10.1-6.2 6.7-9.8 16.1-10.9 28h67.4c0-12.3-2.7-21.7-8.2-28.3-5.6-6.5-13.4-9.8-23.6-9.8zM883.7 277.6c-18.7 0-33.1-5.7-43.3-17.2-10.2-11.5-15.3-27.7-15.3-48.7 0-21.5 5.2-38.2 15.6-50 10.4-11.8 25.2-17.6 44.4-17.6 6.2 0 12.4.7 18.6 2 6.2 1.3 11 2.9 14.6 4.7l-6 16.6c-4.3-1.7-9-3.2-14.1-4.3-5.1-1.1-9.6-1.7-13.5-1.7-26.2 0-39.3 16.7-39.3 50.1 0 15.8 3.2 28 9.6 36.4s15.9 12.7 28.4 12.7c10.7 0 21.7-2.3 33-6.9V271c-8.7 4.4-19.6 6.6-32.7 6.6zM960.1 210.8c3.4-4.8 8.6-11.2 15.6-19.1l42.1-44.6h23.5l-52.9 55.6 56.6 74.9h-23.9L975 216l-14.9 12.9v48.8h-19.5V92.4h19.5v98.2c0 4.4-.3 11.1-1 20.2h1zM306.5 128.1c8.7 16.3 13.7 35 13.7 54.8 0 64.4-52.4 116.7-116.7 116.7S86.7 247.3 86.7 182.9 139.1 66.2 203.4 66.2c31.6 0 60.2 12.6 81.3 33.1l15.8-13.5c-24.9-24.9-59.2-40.2-97.1-40.2C127.6 45.4 66 107.1 66 182.9s61.7 137.4 137.5 137.4S341 258.6 341 182.9c0-26.4-7.5-51.2-20.5-72.1l-14 17.3z'
      />
      <linearGradient
        id='SVGID_1_'
        gradientUnits='userSpaceOnUse'
        x1='146.235'
        y1='150.039'
        x2='354.023'
        y2='150.039'
      >
        <stop offset='.16' stopColor='#97d6c9' />
        <stop offset='.996' stopColor='#a5e680' />
      </linearGradient>
      <path
        fill='url(#SVGID_1_)'
        d='M194 190.5l-47.8-28.9 58.3 81.8L354 56.7z'
      />
    </g>
  </svg>
)

export default Check
