// @ts-nocheck
// Libraries
import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Notification from 'cogo-toast'

// Components
import { withCurrentUser } from 'contexts/user'
import { Title, FlexWrapper } from 'css/utils'
import { appleGrey, buttonPrimary, mediumGrey, darkBlack } from 'css/colors'
import Button from 'shared/button'

import API from 'api/core'

const SIZE = '1.3rem'

const FlexStartWrapper = styled(FlexWrapper)`
  justify-content: flex-start;
`
const Content = styled.div`
  margin-top: 2rem;
`
const CommentWrapper = styled.div`
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  border-left: 3px solid ${buttonPrimary};
  border-bottom: 1px solid ${appleGrey};
  margin-bottom: 2rem;
`
const ContentComment = styled.div`
  & > time {
    border-bottom: 1px solid ${buttonPrimary};
    font-size: 13px;
    color: ${mediumGrey};
  }
  & p:first-child {
    font-weight: bold;
  }
  & p:last-child {
    margin-top: 0.7rem;
    color: ${darkBlack};
    white-space: pre-wrap;
  }
`
const ImageWrapper = styled.figure`
  margin-right: 1rem;
  align-self: flex-start;
  & > img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`
const WriteComment = styled.form`
  margin-bottom: 4rem;
  & > label {
    font-size: 13px;
    color: ${mediumGrey};
  }
  & > textarea {
    display: block;
    line-height: 1.25;
    font-size: 18px;
    max-height: 600px;
    max-width: 100%;
    min-height: 120px;
    min-width: 100%;
    padding: 10px;
    resize: vertical;
    border: 1px solid ${buttonPrimary};
    border-radius: 3px;
    margin-bottom: 1rem;
  }
`

/**
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @typedef {import('contexts/user').User} User
 * @typedef {import('api/core').TypeComment} TypeComment
 * @typedef {{
 * comments: TypeComment[];
 * user: User;
 * comment: string;
 * }} State
 *
 * @typedef {{
 * comments: TypeComment[];
 * user: User;
 * }} props
 *
 * @extends {Component<props & RouteComponentProps, State>}
 *
 */
class Comments extends Component {
  /**
   * @param {props} props
   */
  constructor (props) {
    super(props)
    /**
     * @type {State}
     */
    this.state = {
      comments: props.comments,
      user: props.user,
      comment: ''
    }
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  handleChangeValue = event => {
    const { value } = event.currentTarget
    this.setState({ comment: value })
  }

  sendComment = async () => {
    const { comment, user } = this.state
    const { params } = this.props
    const data = {
      text: comment.trim(),
      date: moment().format('YYYY-MM-DD hh:mm:ss'),
      email: user.email,
      name: user.name,
      avatar: user.avatar
    }
    try {
      await API.Clarifications.CreateClarifitionComments(params.id, data)
      this.setState(state => ({
        ...state,
        comments: [...state.comments, data],
        comment: ''
      }))
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error enviando el comentario')
    }
  }

  render () {
    const { comment, comments } = this.state
    return (
      <Content>
        <Title size={SIZE}>Comentarios</Title>
        {comments &&
          comments.length > 0 &&
          comments.map(({ date, name, text, avatar }) => {
            return (
              <CommentWrapper key={date}>
                <FlexStartWrapper>
                  <ImageWrapper>
                    <img
                      src={avatar || '/img/resuelve.png'}
                      alt={`${name} avatar`}
                    />
                  </ImageWrapper>
                  <ContentComment>
                    <p>{name}</p>
                    <time dateTime={date}>
                      {moment(date).format('MMMM DD, YYYY, hh:mm:a')}
                    </time>
                    <p>{text}</p>
                  </ContentComment>
                </FlexStartWrapper>
              </CommentWrapper>
            )
          })}
        <hr />
        <WriteComment>
          <Title size={SIZE}>Deja tu comentario</Title>
          <label htmlFor='comment'>Comentario</label>
          <textarea
            aria-label='Escribir comentario'
            id='comment'
            placeholder='Escribe un comentario'
            maxLength='500'
            value={comment}
            onChange={this.handleChangeValue}
          />
          <Button
            buttonClass='primary'
            icon='send'
            testId='send-comment'
            disabled={!comment}
            onClick={this.sendComment}
          >
            Publicar comentario
          </Button>
        </WriteComment>
      </Content>
    )
  }
}
export default withCurrentUser(Comments)
