import styled, { css } from 'styled-components'
import { colorSecondary, white, colorPrimary } from 'css/colors'
import { MARGIN_BOTTOM } from 'css/utils'

export const Content = styled.section`
  padding-bottom: 5rem;
`

export const HeaderWrapper = styled.div`
  background: ${colorSecondary};
  margin-left: -1.59rem;
  margin-right: -1.59rem;
  margin-top: -1.33rem;
  & > div:first-child {
    padding: 1rem 0;
  }
`
export const Header = styled.div`
  width: 100%;
  text-align: center;
  color: ${white};
  & > h1 {
    margin-bottom: 0;
  }
`
export const Status = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  font-size: 14px;
  & > div {
    color: ${white};
    margin-right: 1.5rem;
    border-left: 8px;
    border-style: solid;
    border-color: green;
    & > p {
      margin-left: 0.5rem;
    }
  }
  & > div:nth-child(1) {
    ${props =>
    props.totalRequests &&
      css`
        border-left: 8px solid #ffae42 !important;
      `}
  }
  & p:nth-child(1) {
    font-size: 12px;
  }
`
export const TabsWrapper = styled.div`
  & div ul {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    width: 80%;
    & > li {
      color: ${white};
      width: 50%;
      border-bottom: none;
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
      margin-top: ${MARGIN_BOTTOM};
    }
  }
  .react-tabs__tab--selected {
    background: ${white};
    color: ${colorPrimary};
  }
`
export const ButtonWrapper = styled.div`
  padding: 0 2.5rem;
  text-align: left;
`
export const WrapperNotes = styled.div`
  padding: 0 2.5rem;
  margin-bottom: 2rem;
`
export const Reference = styled.div`
  width: 30%;
  padding: 0 2.5rem;
  margin-bottom: 2rem;
  position: relative;
  & > span {
    left: 3.5em;
  }
`
