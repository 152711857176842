// Libraries
import React, { Component } from 'react'
import Notification from 'cogo-toast'
import { withRouter } from 'react-router-dom'
import className from 'classnames'

// Components
import BankAccountDetails from './details'
import Loader from 'shared/loader'
import Modal from 'shared/modal'
import Button from 'shared/button'
import IconButton from 'shared/iconButton'
import NewBankAccount from './newBankAccount'
import API from 'api/core'

/**
 * @typedef {import('api/core').BankAccount} BankAccount
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @typedef {{routeTab: function}} Props
 * @typedef {{
 * bankAccounts: Array<BankAccount>,
 * bankAccountSelected: BankAccount | null,
 * bankAccountUpdate: BankAccount | null,
 * requestInProgress: boolean,
 * openModal: boolean
 * isUpdate: boolean
 * }} State
 *
 * @extends {Component<RouteComponentProps & Props, State>}
 */

class BankAccounts extends Component {
  /**
   * @type {State}
   */
  state = {
    bankAccounts: [],
    bankAccountSelected: null,
    requestInProgress: false,
    openModal: false,
    isUpdate: false,
    bankAccountUpdate: null
  }

  componentDidMount = () => {
    const { routeTab } = this.props
    this.getBankAccounts()
    routeTab()
  }

  toggleModal = () => {
    this.setState(state => ({ openModal: !state.openModal }))
  }

  onCloseDetails = () => {
    this.setState({ bankAccountSelected: null })
  }

  /**
   * @param {BankAccount} account
   */
  handleUpdateBankAccount = account => {
    this.setState(
      {
        bankAccountUpdate: account
      },
      this.handleUpdate
    )
  }

  handleUpdate = () => {
    this.setState(
      state => ({
        isUpdate: !state.isUpdate
      }),
      this.toggleModal
    )
  }

  /**
   * @param {BankAccount} bankAccount
   */
  changeSelectedItem = bankAccount => {
    this.setState(state => ({
      bankAccountSelected:
        state.bankAccountSelected &&
        state.bankAccountSelected.id === bankAccount.id
          ? null
          : bankAccount
    }))
  }

  getBankAccounts = async () => {
    this.setState({ requestInProgress: true })
    try {
      // @ts-ignore
      const { data } = await API.Accounting.GetBankAccounts()
      this.setState({ bankAccounts: data })
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error cargando las cuentas de banco')
    } finally {
      this.setState({ requestInProgress: false })
    }
  }

  render () {
    const {
      requestInProgress,
      bankAccounts,
      openModal,
      bankAccountSelected,
      bankAccountUpdate,
      isUpdate
    } = this.state
    return (
      <div>
        <div className='flex-between'>
          <p className='register-results'>Hay 10 registros</p>
          <Button
            buttonClass='create'
            icon='plus-circle-outline'
            testId='new-bank-account'
            onClick={this.toggleModal}
          >
            Nueva cuenta
          </Button>
        </div>
        {requestInProgress ? (
          <Loader />
        ) : (
          <table className='table'>
            <thead>
              <tr>
                <th>Descripción de la cuenta</th>
                <th>Número de cuenta</th>
                <th>Compañía</th>
                <th>Banco</th>
              </tr>
            </thead>
            <tbody>
              {bankAccounts.map(account => (
                <tr
                  key={account.id}
                  onClick={() => this.changeSelectedItem(account)}
                  className={className({
                    'flowDetails--select':
                      bankAccountSelected &&
                      account.id === bankAccountSelected.id
                  })}
                >
                  <td className='link'>{account.description}</td>
                  <td>{account.account_number}</td>
                  <td>{account.company.name}</td>
                  <td>{account.bank.name}</td>
                  <td>
                    <IconButton
                      icon='pencil'
                      testId='edit-account'
                      tooltip='Editar'
                      onClick={() => this.handleUpdateBankAccount(account)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <BankAccountDetails
          isOpen={!!bankAccountSelected}
          onCloseDetails={this.onCloseDetails}
          bankAccount={bankAccountSelected}
        />
        <Modal
          title={
            isUpdate ? 'Actaulizar cuenta de banco' : 'Crear cuenta de banco'
          }
          isActive={openModal}
          toggleModal={isUpdate ? this.handleUpdate : this.toggleModal}
          noFooter
        >
          <NewBankAccount
            isUpdate={isUpdate}
            toggleModal={this.toggleModal}
            handleUpdate={this.handleUpdate}
            bankAccountUpdate={bankAccountUpdate}
            getBankAccounts={this.getBankAccounts}
          />
        </Modal>
      </div>
    )
  }
}
export default withRouter(BankAccounts)
