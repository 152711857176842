/* eslint-disable */
// @ts-nocheck
// Libraries
import React, {useState, useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import Notification from 'cogo-toast'
import styled from 'styled-components'
import accounting from 'accounting'

// Components
import Card from 'shared/card'
import Loader from 'shared/loader'
import LinkButton from 'shared/linkButton'
import IconButton from 'shared/iconButton'
import Modal from 'shared/modal'
import CreateCharges from './CreateCharges'
import {darkBlack, mediumGrey} from 'css/colors'
import {Title, FlexWrapper} from 'css/utils'
import Charges from './Charges'
import {CLIENT_STATUS} from 'shared/catalogs'
import API from 'api/core'
import {getCountryLabel, formatDate, getCurrencyLabel, absolutify} from 'utils'

const Content = styled.div`
  width: 65%;
  margin: 0 auto;
  padding-bottom: 5rem;
`
const Header = styled.h2`
  font-size: 18px;
  color: ${darkBlack};
  margin-bottom: 0.5rem;
`
const SubHeader = styled.p`
  font-size: 12px;
  color: ${mediumGrey};
`
const Details = styled.div`
  align-self: normal;
`

const WrapperDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid ${mediumGrey};
  padding: 0.3em 0 1rem 0;
  width: 70%;
  margin: 0 auto;
`
const FlexEnd = styled(FlexWrapper)`
  justify-content: flex-end;
  padding: 0.3rem 1rem;
}
`
const ChargesButton = styled(FlexWrapper)`
  justify-content: flex-end;
  padding: 0.3rem 1rem;
  width: 70%;
  margin: 0 auto;
`

/**
 * @typedef {import('formik').FormikProps<{statusChange: string}>} FormikProps
 * @typedef {import('api/core').TypeIncomeDetails} TypeIncomeDetails
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @typedef {FormikProps & TypeIncomeDetails & RouteComponentProps } PropsIncomeDetails
 * @param {PropsIncomeDetails} props
 */
const IncomeDetails = ({match: {params}, history}) => {
  const [details, setDetails] = useState({})
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  function getIncomeDetails() {
    setLoading(true)
    API.Accounting.GetIncomeDetail(params.id)
      .then(({data}) => {
        setDetails(data)
      })
      .catch(error => {
        console.error(error)
        Notification.error('Ha ocurrido un error cargando el detalle')
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    getIncomeDetails()
  }, [])

  /**
   * @param {string} amount
   */
  function amountWithoutVAT(amount, vat) {
    const vatAmount =
      (absolutify(amount) / Number(`1.${vat}`)) * Number(`0.${vat}`)
    const amountFloat = absolutify(amount)
    const updateAmount = amountFloat - vatAmount
    return accounting.formatNumber(updateAmount, {
      precision: 2,
      thousand: ',',
      decimal: '.'
    })
  }

  /**
   * @param {TypeIncomeDetails} details
   */
  function handleUpdateIncome(details) {
    history.push({
      pathname: '/accounting/income/new',
      state: {income: details}
    })
  }

  return (
    <div>
      <LinkButton
        url='/accounting/income'
        icon='arrow-left-thick'
        buttonClass='link bottom'
      >
        Ingresos
      </LinkButton>
      <Content>
        <Title>Detalle del ingreso</Title>
        {loading ? (
          <Loader />
        ) : (
          <Card>
            <FlexEnd>
              <IconButton
                icon='pencil'
                testId='edit-account'
                tooltip='Editar'
                onClick={() => handleUpdateIncome(details)}
              />
            </FlexEnd>
            <WrapperDetails>
              <Details>
                <SubHeader>Tipo de ingreso</SubHeader>
                <Header>{details.income_type}</Header>
                <SubHeader>Fecha de factura</SubHeader>
                <Header>{formatDate(details.invoice_date)}</Header>
                <SubHeader>Estado del cliente</SubHeader>
                <Header>
                  {
                    CLIENT_STATUS[
                      details.client_product &&
                        details.client_product.client_status
                    ]
                  }
                </Header>
                <SubHeader>País</SubHeader>
                <Header>{getCountryLabel(details.country)}</Header>
                <SubHeader>Folio</SubHeader>
                <Header>{details.folio}</Header>
                <SubHeader>Vehículo de ahorro</SubHeader>
                <Header>{details.saving_vehicle}</Header>
              </Details>
              <Details>
                <SubHeader>Moneda</SubHeader>
                <Header>{getCurrencyLabel(details.currency)}</Header>
                <SubHeader>Monto a cobrar sin iva</SubHeader>
                <Header>
                  $
                  {amountWithoutVAT(
                    details.receivable_amount,
                    details.vat_percent
                  )}{' '}
                  {details.currency}
                </Header>
                <SubHeader>Monto a cobrar con iva</SubHeader>
                <Header>{details.receivable_amount}</Header>
                <SubHeader>Monto restante sin iva</SubHeader>
                <Header>
                  $
                  {amountWithoutVAT(
                    details.remaining_amount,
                    details.vat_percent
                  )}{' '}
                  {details.currency}
                </Header>
                <SubHeader>Monto restante con iva</SubHeader>
                <Header>{details.remaining_amount}</Header>
                <SubHeader>Referencia</SubHeader>
                <Header>
                  {details && details.reference
                    ? details.reference
                    : 'Sin referencia'}
                </Header>
              </Details>
            </WrapperDetails>
            {details.bank_account && (
              <WrapperDetails>
                <Details>
                  <SubHeader>Empresa</SubHeader>
                  <Header>{details.bank_account.company.name || 'N/A'}</Header>
                  <SubHeader>Cuenta</SubHeader>
                  <Header>{details.bank_account.description || 'N/A'}</Header>
                </Details>
              </WrapperDetails>
            )}
            <ChargesButton>
              <IconButton
                icon='plus-circle-outline'
                tooltip='Crear cargo'
                className='outline'
                onClick={() => setOpenModal(!openModal)}
              />
            </ChargesButton>
            <Charges charges={details.charges} incomeId={details.id} />
            <Modal
              title='Crear cargo'
              isActive={openModal}
              toggleModal={() => setOpenModal(!openModal)}
              noFooter
            >
              <CreateCharges
                details={details}
                toggleModal={() => setOpenModal(!openModal)}
                getIncomeDetails={() => getIncomeDetails()}
              />
            </Modal>
          </Card>
        )}
      </Content>
    </div>
  )
}
export default withRouter(IncomeDetails)
