// Libraries
import styled from 'styled-components'

// Components
import { mediumGrey, darkBlack, buttonPrimary, error } from 'css/colors'

export const InfoWrapper = styled.div`
  padding: 1em 0;
`

export const SubHeader = styled.p`
  font-size: 14px;
  color: ${mediumGrey};
`
export const Header = styled.h2`
  font-size: 18px;
  color: ${props => (props.color ? `${error}` : `${darkBlack}`)};
  margin-bottom: 0.5em;
`
export const SubTitle = styled.h2`
  font-size: 18px;
  color: ${darkBlack};
  font-weight: 600;
  padding: 0.5em 0;
  border-bottom: 2px solid ${buttonPrimary};
`
export const Content = styled.div`
  width: 50%;
`
