import React from 'react'
import styled from 'styled-components'

const WrapperLogo = styled.div`
  width: 40px;
  height: 40px;
  & > img {
    width: 100%;
    height: auto;
  }
`

export default function Resuelve2 () {
  return (
    <WrapperLogo>
      <img src='/img/resuelve.png' alt='logo-resuelve' />
    </WrapperLogo>
  )
}
