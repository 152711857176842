import React from 'react'
import { withFormik, Form, Field, ErrorMessage } from 'formik'
import Notification from 'cogo-toast'
import styled from 'styled-components'
import * as Yup from 'yup'

import { FieldWrapper } from 'css/utils'
import Required from 'shared/required'
import FieldAmount from 'shared/fieldAmount'
import DatePicker from 'shared/datePicker'
import Button from 'shared/button'
import API from 'api/core'
import { formatDateToSend } from 'utils'

/**
 * @constant
 */
const validationSchema = Yup.object().shape({
  dateNC: Yup.date().required('Campo requerido'),
  detail: Yup.string().required('Campo requerido'),
  amount: Yup.string().required('Campo requerido'),
  amountInLetters: Yup.string().required('Campo requerido'),
  folio: Yup.string().required('Campo requerido')
})

const WrapperButtons = styled.div`
  border-top: 1px solid #dbdbdb;
  background-color: whitesmoke;
  padding: 1.25rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  margin-bottom: -1.25rem;
  margin-top: 1.25rem;
`
/**
 * @typedef {import('formik').FormikProps<FomikValues>} FormikProps
 * @typedef {{
 * floatValue: number,
 * value: string,
 * formattedValue: string,
 * }} formatValue
 * @typedef {{
 * dateNC: string | Date,
 * detail: string,
 * amount: string,
 * amountInLetters: string,
 * folio: string,
 * }} FomikValues
 * @typedef {{
 * toggleModal: function,
 * }} PropsCreateNC
 * @param {PropsCreateNC & FormikProps} props
 */
const CreateNC = ({
  toggleModal,
  handleSubmit,
  setFieldValue,
  isSubmitting,
  values
}) => {
  /**
   * @param {formatValue} formatValues
   */
  function onValueChange (formatValues) {
    const { formattedValue, floatValue } = formatValues
    setFieldValue('amount', formattedValue)
    setFieldValue('floatAmount', floatValue)
  }

  /**
   * @param {Date} date
   */
  function handleChange (date) {
    setFieldValue('dateNC', date)
  }

  return (
    <Form onSubmitCapture={handleSubmit}>
      <FieldWrapper>
        <label htmlFor='folio'>Agrega folio *</label>
        <Field
          name='folio'
          id='folio'
          placeholder='234281'
          style={{ fontSize: '1em' }}
          required
        />
        <ErrorMessage
          name='folio'
          render={message => (
            <Required message={message} className='required-modal' />
          )}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Field name='dateNC'>
          {({ field }) => (
            <DatePicker
              {...field}
              name='dateNC'
              width='100%'
              label='Fecha de la nota *'
              placeholderText='DD/MM/YYYY'
              selected={values.dateNC}
              onChange={date => handleChange(date)}
              dateFormat='dd MMMM yyyy'
              maxDate={new Date()}
              required
            />
          )}
        </Field>
        <ErrorMessage name='dateNC'>
          {message => <Required message={message} />}
        </ErrorMessage>
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor='detail'>Decripción de la nota *</label>
        <Field
          name='detail'
          id='detail'
          placeholder='Devolución'
          style={{ fontSize: '1em' }}
          required
        />
        <ErrorMessage
          name='detail'
          render={message => (
            <Required message={message} className='required-modal' />
          )}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Field name='amount'>
          {({ field }) => (
            <FieldAmount
              {...field}
              name='amount'
              label='Monto de la nota'
              placeholder='Monto de la nota'
              currency='COP'
              onValueChange={formatValues => onValueChange(formatValues)}
              required
            />
          )}
        </Field>
        <ErrorMessage
          name='amount'
          render={message => (
            <Required message={message} className='required-modal' />
          )}
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor='amountInLetters'>Monto en letra *</label>
        <Field
          name='amountInLetters'
          id='amountInLetters'
          placeholder='Mil cien pesos'
          style={{ fontSize: '1em' }}
          required
        />
        <ErrorMessage
          name='amountInLetters'
          render={message => (
            <Required message={message} className='required-modal' />
          )}
        />
      </FieldWrapper>
      <WrapperButtons>
        <Button
          type='submit'
          testId='create-nc'
          icon='send'
          buttonClass='primary right'
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          Enviar
        </Button>
        <Button icon='cancel' buttonClass='secondary' onClick={toggleModal}>
          Cancelar
        </Button>
      </WrapperButtons>
    </Form>
  )
}
export default withFormik({
  mapPropsToValues: () => ({
    dateNC: '',
    detail: '',
    amount: '',
    floatAmount: '',
    amountInLetters: '',
    folio: ''
  }),
  validationSchema,
  handleSubmit: async (values, { setSubmitting, props }) => {
    const nc = {
      invoice_folio: props.invoice.folio,
      detail: values.detail,
      folio: values.folio,
      date: formatDateToSend(values.dateNC),
      amount: values.floatAmount,
      code: '02',
      amount_in_letters: values.amountInLetters
    }
    setSubmitting(true)
    try {
      const { errors } = await API.Invoices.CreateNoteCredit({ nc })
      if (errors) {
        for (const prop in errors) {
          Notification.error(`${prop} = ${errors[prop]}`)
        }
      } else {
        Notification.success('Nota creada con éxito')
      }
    } catch (error) {
      console.error(error)
      Notification.error('Ocurrio un error al crear la nota.Intenta de nuevo')
    } finally {
      setSubmitting(false)
      props.toggleModal()
    }
  }
})(CreateNC)
