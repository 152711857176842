// Libraries
import React from 'react'
import { Link } from 'react-router-dom'

// Components
import Check2 from 'shared/svgComponents/check2'
import Resuelve2 from 'shared/svgComponents/resuelve2'
import Resuelve from 'shared/svgComponents/resuelve'
import LaTasa from 'shared/svgComponents/laTasa'
import { Table, TableHeader, TableBody } from 'css/utils'
import { withCurrentUser } from 'contexts/user'
import { getCountryLabel } from 'utils'
import { CONTRACT_REQUEST_STATUS } from 'shared/catalogs'

/**
 * @param {String} productLine
 */
const LogoByProductLine = productLine => {
  switch (productLine) {
    case 'rtd':
      return <Resuelve />
    case 'check':
      return <Check2 />
    case 'la_tasa_investor':
      return <LaTasa />
    default:
      return <Resuelve2 />
  }
}

/**
 * @typedef {{
 * uid: string,
 * children: React.ReactNode,
 * className?: string,
 * totalRequests?: number,
 * }} LinkToProps
 * @param {LinkToProps} props
 */
const LinkTo = ({ uid, children, className, totalRequests }) => (
  <td className={className}>
    <Link
      to={{
        pathname: `/contracts/pending/view/${uid}`,
        state: totalRequests
      }}
    >
      {children}
    </Link>
  </td>
)

/**
 * @typedef {import('api/core').TypeContractRequest} TypeContractRequest
 * @typedef {import('contexts/user').User} User
 * @typedef {{
 * contractsPending: TypeContractRequest[],
 * user: User,
 * totalEntries: number,
 * }} PropsListTable
 *
 * @param {PropsListTable} props
 */
const ListTable = ({ contractsPending, totalEntries }) => {
  return (
    <div>
      <p className='register-results no-padding'>{`Hay ${totalEntries ||
        '0'} solicitudes`}</p>
      <Table>
        <TableHeader>
          <tr>
            <th>Estado</th>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Fecha</th>
            <th>País</th>
            <th>Producto</th>
          </tr>
        </TableHeader>
        <TableBody>
          {contractsPending &&
            contractsPending.length > 0 &&
            contractsPending.map(
              ({
                country,
                status,
                time,
                client: {
                  email,
                  names,
                  first_surname: firstSurname,
                  second_surname: secondSurname
                },
                contract: { uid },
                product_line: productLine,
                total_requests: totalRequests
              }) => {
                return (
                  <tr key={uid}>
                    <LinkTo uid={uid} totalRequests={totalRequests}>
                      {
                        // @ts-ignore
                        CONTRACT_REQUEST_STATUS[status]
                      }
                    </LinkTo>
                    <LinkTo
                      uid={uid}
                      totalRequests={totalRequests}
                    >{`${names} ${firstSurname} ${secondSurname}`}</LinkTo>
                    <LinkTo uid={uid} totalRequests={totalRequests}>
                      {email}
                    </LinkTo>
                    <LinkTo uid={uid} totalRequests={totalRequests}>
                      {time}
                    </LinkTo>
                    <LinkTo uid={uid} totalRequests={totalRequests}>
                      {getCountryLabel(country.toUpperCase())}
                    </LinkTo>
                    <LinkTo uid={uid} totalRequests={totalRequests}>
                      {LogoByProductLine(productLine)}
                    </LinkTo>
                  </tr>
                )
              }
            )}
        </TableBody>
      </Table>
    </div>
  )
}
export default withCurrentUser(ListTable)
