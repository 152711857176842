export const CONTRACT_REQUEST_STATUS = {
  review_pending: 'Revisión pendiente',
  in_review: 'En revisión',
  canceled: 'Cancelado',
  rejected: 'Rechazado',
  approved: 'Aprobado',
  contract_created: 'Contrato creado',
  canceled_after_approve: 'Cancelado despues de ser aprobado',
  failed: 'Aprobación Fallida',
  on_hold_by_saving_provider: 'En espera por vehículo de ahorro'
}
