// Libraries
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/**
 * @typedef {Object} IconButtonProps
 * @property {number} [tabIndex]
 * @property {string} [testId]
 * @property {string} [className]
 * @property {boolean} [iconOnly=false]
 * @property {function} [onClick]
 * @property {boolean} [disabled]
 * @property {string} [tooltip]
 * @property {string} icon
 * @property {string} [type]
 * @property {string} [name]
 * @property {string} [ariaLabel]
 * @property {string} [positionButtonClass]
 *
 * @param {IconButtonProps} props
 */

const IconButton = ({
  tabIndex,
  testId,
  className,
  iconOnly,
  onClick,
  disabled,
  tooltip,
  icon,
  type,
  name,
  ariaLabel
}) => (
  <button
    tabIndex={tabIndex}
    data-testid={`button-${testId}`}
    className={classNames(`icon-button ${className}`, {
      iconOnly: iconOnly
    })}
    onClick={() => onClick()}
    disabled={disabled}
    type={type}
    name={name}
    aria-label={ariaLabel}
  >
    {tooltip && <span className='tooltip'>{tooltip}</span>}
    <i className={`mdi mdi-${icon}`} />
  </button>
)

export default IconButton

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  iconOnly: PropTypes.bool,
  testId: PropTypes.string,
  tabIndex: PropTypes.string,
  disabled: PropTypes.bool
}

IconButton.defaultProps = {
  className: '',
  onClick: () => {},
  tooltip: '',
  iconOnly: false,
  tabIndex: '0',
  disabled: false,
  testId: ''
}
