// Libraries
import React, { Component, Fragment } from 'react'
import Notification from 'cogo-toast'
import styled from 'styled-components'

// Components
import FieldsDetails from './fieldsDetails'
import Comments from './comments'
import UploadFile from './UploadFile'
import { Title, FlexWrapper, FormWrapper } from 'css/utils'
import { darkBlack, mediumGrey, white } from 'css/colors'
import Card from 'shared/card'
import SelectField from 'shared/selectField'
import IconButton from 'shared/iconButton'
import Button from 'shared/button'
import Modal from 'shared/modal'
import { getCountryLabel, formatDate } from 'utils'
import { CLARIFICATION_STATUS } from 'shared/catalogs'
import API from 'api/core'

const DetailsWrapper = styled(FlexWrapper)`
  margin: 90 auto;
`
const StatusWrapper = styled(FlexWrapper)`
  justify-content: flex-start;
  & > button {
    margin-left: 1rem;
    margin-top: 0.3rem;
    align-self: flex-start;
  }
`
const Select = styled.div`
  margin-top: 0.5rem;
  position: relative;
  padding: 0 !important;
  & > div {
    padding: 0;
  }
  & > i {
    position: absolute;
    right: 10px;
    top: 4px;
    z-index: 9;
    background: ${white};
  }
`
const Header = styled.h2`
  font-size: 20px;
  color: ${darkBlack};
  margin-bottom: 1.3rem;
`
const SubHeader = styled.p`
  font-size: 12px;
  color: ${mediumGrey};
`
const ShowFile = styled(FlexWrapper)`
  margin: 1em 0;
`

/**
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @typedef {import('api/core').TypeClarifcationDetail} TypeClarifcationDetail
 * @typedef {{
 * clarificationDetails: TypeClarifcationDetail;
 * status: string;
 * requestInProgress: boolean;
 * isUpdate: boolean;
 * updating: boolean;
 * openModal: boolean;
 * }} State
 *
 * @extends {Component<RouteComponentProps, State>}
 */
export default class ClarificationDetails extends Component {
  /**
   * @type {State}
   */
  state = {
    clarificationDetails: {},
    status: '',
    requestInProgress: false,
    isUpdate: false,
    updating: false,
    openModal: false
  }
  container = React.createRef()

  componentDidMount = () => {
    this.getClarificationDetails()
    document.addEventListener('mousedown', event =>
      this.handleClickOutside(event)
    )
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', event =>
      this.handleClickOutside(event)
    )
  }

  /**
   * @param {MouseEvent} event
   */
  handleClickOutside = event => {
    if (
      this.container.current &&
      !this.container.current.contains(event.target)
    ) {
      this.setState({
        isUpdate: false
      })
    }
  }

  handleUpdateStatus = () => {
    this.setState(state => ({ isUpdate: !state.isUpdate }))
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  handleChangeValue = event => {
    const { value } = event.currentTarget
    this.setState({ status: value }, () => this.updateStatus(value))
  }

  /**
   * @param {{status: 'Cerrado' | 'En espera' | 'Procesando'}} status
   */
  updateStatus = async status => {
    const {
      match: { params }
    } = this.props
    const data = {
      status,
      id: params.id
    }
    this.setState({ updating: true })
    try {
      await API.Clarifications.UpdateClarificationStatus(params.id, data)
      Notification.success('Estado actualizado correctamente')
      this.getClarificationDetails()
    } catch (error) {
      console.error(error)
      Notification.error(
        'Ha ocurrido un error actualizando el estado. Intenta de nuevo'
      )
    } finally {
      this.setState(state => ({
        isUpdate: !state.isUpdate,
        updating: false
      }))
    }
  }

  getClarificationDetails = async () => {
    const {
      match: { params }
    } = this.props
    this.setState({ requestInProgress: true })
    try {
      const { data } = await API.Clarifications.GetClarificationDetails(params.id)
      this.setState({ clarificationDetails: data })
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error al cargar los detalles')
    } finally {
      this.setState({ requestInProgress: false })
    }
  }

  handleToggle = () => {
    this.setState(state => ({ openModal: !state.openModal }))
  }

  hasFile = () => {
    const { clarificationDetails } = this.state
    if (clarificationDetails.document) {
      return (
        <a target='_blank' href={clarificationDetails.document.url}>
          {clarificationDetails.document.name}
        </a>
      )
    } else {
      return <p>No se subio archivo aun</p>
    }
  }

  render () {
    const {
      clarificationDetails,
      isUpdate,
      status,
      updating,
      openModal
    } = this.state
    const {
      match: { params },
      history
    } = this.props
    const optionStatus =
      clarificationDetails.possible_status &&
      clarificationDetails.possible_status.length > 0 &&
      clarificationDetails.possible_status.map(status => {
        return { id: status, label: CLARIFICATION_STATUS[status] }
      })
    return (
      <Fragment>
        <Button
          icon='arrow-left-thick'
          buttonClass='link'
          onClick={() => history.goBack()}
        >
          Aclaraciones
        </Button>
        <FormWrapper>
          <Title>Detalle de la aclaración</Title>
          <Card title={clarificationDetails.type}>
            <div>
              <SubHeader>Folio</SubHeader>
              <Header>{clarificationDetails.id}</Header>
            </div>
            <div>
              <SubHeader>Correo</SubHeader>
              <Header>{clarificationDetails.creator}</Header>
            </div>
            <DetailsWrapper>
              <div>
                <SubHeader>Área</SubHeader>
                <Header>{clarificationDetails.area}</Header>
                <SubHeader>País</SubHeader>
                <Header>{getCountryLabel(clarificationDetails.country)}</Header>
              </div>
              <div ref={this.container}>
                <SubHeader>Estado</SubHeader>
                {isUpdate ? (
                  <Select>
                    <SelectField
                      placeholder='Selecciona una opción'
                      options={optionStatus || []}
                      onChange={this.handleChangeValue}
                      value={status}
                    />
                    {updating && <i className={'mdi mdi-loading mdi-spin'} />}
                  </Select>
                ) : (
                  <StatusWrapper>
                    <Header>
                      {CLARIFICATION_STATUS[status] ||
                        CLARIFICATION_STATUS[clarificationDetails.status]}
                    </Header>
                    <IconButton
                      icon='pencil'
                      tooltip='Actualizar'
                      testId='update-status'
                      onClick={this.handleUpdateStatus}
                    />
                  </StatusWrapper>
                )}
                <SubHeader>Fecha</SubHeader>
                <Header>{formatDate(clarificationDetails.date)}</Header>
              </div>
            </DetailsWrapper>
            {clarificationDetails.fields &&
              clarificationDetails.fields.length > 0 && (
              <FieldsDetails
                currentFields={clarificationDetails.fields}
                clarificationId={clarificationDetails.id}
              />
            )}
            <DetailsWrapper>
              <div>
                <SubHeader>Desripción</SubHeader>
                <Header>{clarificationDetails.description}</Header>
              </div>
            </DetailsWrapper>
          </Card>
          <Card>
            <Button
              buttonClass='link'
              icon='cloud-upload'
              onClick={this.handleToggle}
            >
              Subir archivo de evidencia
            </Button>
            <ShowFile>
              <p>Archivo evidencia / respaldo</p>
              {this.hasFile()}
            </ShowFile>
          </Card>
          {clarificationDetails.comments && (
            <Comments
              comments={clarificationDetails.comments}
              params={params}
            />
          )}
          <Modal
            noFooter
            title='Subir archivo'
            isActive={openModal}
            toggleModal={() => this.handleToggle()}
          >
            <UploadFile
              toggleModal={() => this.handleToggle()}
              handleUpdate={this.getClarificationDetails}
              clarifcationId={params.id}
            />
          </Modal>
        </FormWrapper>
      </Fragment>
    )
  }
}
