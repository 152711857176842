// Libraries
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { CONTRACT_REQUEST_STATUS } from '../../../helpers/catalogs'

const LinkeableRow = ({ to, children }) => (
  <th>
    <Link to={to} style={{ color: 'inherit', fontWeight: 400 }}>
      {children}
    </Link>
  </th>
)

LinkeableRow.propTypes = {
  to: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

const ContractRequestRow = ({
  status,
  targetUrl,
  contract: { uid },
  client: {
    email,
    names,
    first_surname: firstSurname,
    second_surname: secondSurname
  },
  productLine,
  country,
  time
}) => (
  <tr style={{ cursor: 'default' }} key={uid}>
    <LinkeableRow to={`${targetUrl}${uid}`}>
      {`${names} ${firstSurname} ${secondSurname}`}
    </LinkeableRow>
    <LinkeableRow to={`${targetUrl}${uid}`}>{email}</LinkeableRow>
    <LinkeableRow to={`${targetUrl}${uid}`}>{country}</LinkeableRow>
    <LinkeableRow to={`${targetUrl}${uid}`}>{productLine}</LinkeableRow>
    <LinkeableRow to={`${targetUrl}${uid}`}>{uid}</LinkeableRow>
    <LinkeableRow to={`${targetUrl}${uid}`}>
      {CONTRACT_REQUEST_STATUS[status]}
    </LinkeableRow>
    <LinkeableRow to={`${targetUrl}${uid}`}>{time}</LinkeableRow>
  </tr>
)

ContractRequestRow.propTypes = {
  status: PropTypes.string,
  contract: PropTypes.object,
  client: PropTypes.object,
  targetUrl: PropTypes.string
}

const SolicitudesDeContrato = ({ contractRequests, targetUrl }) => (
  <table className='table'>
    <thead>
      <tr>
        <th>Nombre</th>
        <th>Correo Electrónico</th>
        <th>País</th>
        <th>Producto</th>
        <th>ID</th>
        <th>Status</th>
        <th>Resuelto después de</th>
      </tr>
    </thead>
    <tbody>
      {contractRequests &&
        contractRequests.map(contractRequest => (
          <ContractRequestRow
            status={contractRequest.status}
            contract={contractRequest.contract}
            client={contractRequest.client}
            productLine={contractRequest.product_line}
            country={contractRequest.country}
            targetUrl={targetUrl}
            time={contractRequest.time}
          />
        ))}
    </tbody>
  </table>
)

SolicitudesDeContrato.propTypes = {
  contractRequests: PropTypes.array,
  targetUrl: PropTypes.string
}

export default SolicitudesDeContrato
