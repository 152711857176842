// Libraries
import React from 'react'
import styled from 'styled-components'

// Components
import { SubHeader, Header, SubTitle, InfoWrapper } from './styles'

const ContentWrapper = styled.div`
  align-self: flex-start;
  width: 100%;
  padding-right: 2rem;
`
/**
 * @typedef {import('api/core').TypeInvoiceDetails} TypeInvoiceDetails
 * @typedef {{
 * invoice: TypeInvoiceDetails
 * }} PropsEmisor
 * @param {PropsEmisor} props
 */
const Emisor = ({ invoice }) => {
  return (
    <ContentWrapper>
      <SubTitle>Emisor</SubTitle>
      <InfoWrapper>
        <Header>Resuelve tu deuda</Header>
        <SubHeader>NIT</SubHeader>
        <Header>{invoice.issuer_nit}</Header>
      </InfoWrapper>
    </ContentWrapper>
  )
}
export default Emisor
