// Libraries
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

// Components
import { buttonPrimary, darkBlack, gviewGrey, buttonSecondary } from 'css/colors'
import IconButton from 'shared/button'

const Content = styled.div`
  width: 100%;
  border-bottom: 1px solid ${gviewGrey};
  cursor: pointer;
  position: relative;
  ${props =>
    props.isInvalid &&
    css`
      border-bottom: 1px solid ${buttonSecondary};
    `}
  ${props =>
    props.modified &&
    css`
      border-bottom: 1px solid #ffae42;
    `}
`
const ToggleWrapper = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0;
  background: transparent;
  width: 100%;
  cursor: pointer;
  color: ${darkBlack};
  font-size: 16px;
  & > i {
    color: ${buttonPrimary};
    font-size: 20px;
  }
`
/**
 * @typedef {{
 * children: React.ReactNode,
 * title: string,
 * isInvalid: boolean | undefined,
 * modified?: boolean,
 * isDebt?: boolean,
 * onCopy?: function,
 * }} PropsToggle
 *
 * @param {PropsToggle} props
 */

const Toggle = ({ children, title, isInvalid, modified, onCopy, isDebt }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Content isInvalid={isInvalid} modified={modified}>
      <ToggleWrapper name={title} onClick={() => setIsOpen(!isOpen)}>
        <i
          aria-label={isOpen ? 'Cerrar sección' : 'Abrir sección'}
          className={isOpen ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-right'}
        />
        {title}
      </ToggleWrapper>
      {isDebt && (
        <IconButton
          className='copy'
          onClick={onCopy}
          icon='file-document-box'
          tooltip='copiar deuda'
        />
      )}
      {isOpen && <div>{children}</div>}
    </Content>
  )
}
export default Toggle
