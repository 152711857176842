import React, { useEffect, useState, Fragment } from 'react'
import Notification from 'cogo-toast'

import AddSubsidiary from './addSubsidiary'
import { getCountryLabel } from 'utils'
import Button from 'shared/button'
import IconButton from 'shared/iconButton'
import Modal from 'shared/modal'
import FiltersAccounting from 'shared/filtersAccounting'
import Loader from 'shared/loader'
import API from 'api/core'

/**
 * @param {{
 * routeTab: any
 * }} props
 */
export default function Subsidiaries ({ routeTab }) {
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [value, setValue] = useState('')
  const [subsidiarySelected, setSubsidiarySelected] = useState({})
  const [subsidiaries, setSubsidiaries] = useState([
    {
      country: '',
      id: null,
      name: ''
    }
  ])

  useEffect(() => {
    routeTab()
    getSubsidiaries()
  }, [value])

  async function getSubsidiaries () {
    const params = {
      country: value || 'MX'
    }
    setLoading(true)
    try {
      const { data } = await API.Accounting.GetSubsidiaries(params)
      setSubsidiaries(data)
    } catch (error) {
      Notification.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  function onChangeField (event) {
    const { value } = event.currentTarget
    setValue(value)
  }

  function onCleanFilters () {
    if (!value) return
    setValue('')
  }

  function onFilter () {
    if (!value) return
    getSubsidiaries()
  }

  /**
   * @param {{
   * name: string,
   * id: string | null,
   * country: string
   * }} subsidiary
   */
  function handleUpdateSubsidiary (subsidiary) {
    setSubsidiarySelected(subsidiary)
    setOpenModal(!openModal)
  }

  function handleToggle () {
    setOpenModal(!openModal)
    setSubsidiarySelected({})
  }

  return (
    <Fragment>
      <FiltersAccounting
        className='filter-accounting'
        filters={{ country: value }}
        onFilter={() => onFilter()}
        onChangeField={e => onChangeField(e)}
        onCleanFilters={() => onCleanFilters()}
      />
      <div className='flex-between'>
        <p className='register-results'>Hay {subsidiaries.length} resultados</p>
        <Button
          buttonClass='create'
          icon='plus-circle-outline'
          testId='new-subsidiaries'
          onClick={() => setOpenModal(!openModal)}
        >
          Agregar sucursal
        </Button>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <table className='table'>
          <thead>
            <tr>
              <th>Nombre de la sucursal</th>
              <th>País</th>
              <th>Id de la sucursal</th>
            </tr>
          </thead>
          <tbody>
            {subsidiaries.map(subsidiary => (
              <tr key={subsidiary.id}>
                <td className='link'>{subsidiary.name}</td>
                <td>{getCountryLabel(subsidiary.country)}</td>
                <td>{subsidiary.id}</td>
                <td>
                  <IconButton
                    icon='pencil'
                    testId='edit-subsidiary'
                    tooltip='Editar sucursal'
                    onClick={() => handleUpdateSubsidiary(subsidiary)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Modal
        title={
          subsidiarySelected.id ? 'Actualizar sucursal' : 'Agregar sucursal'
        }
        toggleModal={() => handleToggle()}
        isActive={openModal}
        noFooter
      >
        <AddSubsidiary
          toggleModal={() => handleToggle()}
          getSubsidiaries={() => getSubsidiaries()}
          subsidiarySelected={subsidiarySelected}
        />
      </Modal>
    </Fragment>
  )
}
