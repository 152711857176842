import React, { Component } from 'react'
import Body from './body'

class Wizard extends Component {
  render () {
    return (
      <div>
        <Body contract={this.props.contract} />
      </div>
    )
  }
}

export default Wizard
