// Libararies
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import Notification from 'cogo-toast'

// Components
import { FlexWrapper } from 'css/utils'
import { white, colorPrimary, darkGrey } from 'css/colors'
import Button from 'shared/button'
import { axiosUploadFile, getProp } from 'utils'

const Content = styled(FlexWrapper)`
  margin-top: 1.5rem;
  justify-content: flex-end;
`

const ContentFile = styled.div`
  position: relative;
  padding: 0.4rem 0.75rem;
  background-color: ${colorPrimary};
  border-radius: 3px;
  margin-right: 1.5rem;
  cursor: pointer;
  & label {
    display: block;
    cursor: pointer;
  }
`

const Input = styled.input`
  position: absolute;
  left: 0px;
  right: 0px;
  display: none;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`

const Text = styled.p`
  text-align: center;
  color: white;
  margin-left: 1.2rem;
`

const Icon = styled.i`
  position: absolute;
  top: 5px;
  left: 8px;
  color: ${white};
  font-size: 20px;
`

const File = styled.span`
  & span {
    color: ${darkGrey};
    margin-bottom: 0.7rem;
  }
  & span {
    border-radius: 3px;
    font-weight: 400;
  }
`

/**
 * @typedef {{
 * file: object,
 * requestInProgress: boolean,
 * doneUpload: boolean,
 * doneUploadMessage: string
 * }} State
 *
 * @typedef {{handleUpdate: function,toggleModal: function}} Props
 *
 * @extends {React.Component<Props, State>}
 */
export default class UploadInvoices extends Component {
  /**
   * @type {State}
   */
  state = {
    file: {},
    requestInProgress: false,
    doneUpload: false,
    doneUploadMessage: ''
  }

  /**
   * @param {object} event
   */
  onChangeFile = event => {
    const { files } = event.target
    this.setState({ file: files[0] })
  }

  sendFile = () => {
    const { file } = this.state
    const { handleUpdate } = this.props
    this.setState({ requestInProgress: true })
    axiosUploadFile('file', file, null, 'factura1/upload-csv')
      .then(({ data }) => {
        const { data: response } = data
        Notification.success('Archivo enviado correctamente')
        this.setState({
          requestInProgress: false,
          doneUpload: true,
          doneUploadMessage: response.message,
          file: {}
        })
        handleUpdate()
      })
      .catch(error => {
        console.error(error)
        this.setState({
          requestInProgress: false
        })
        Notification.error(
          getProp(
            error,
            'response.data.errors.detail',
            'Ha ocurrido un error enviando el archivo. Intenta nuevamente.'
          ),
          { hideAfter: 5 }
        )
      })
  }

  render () {
    const { toggleModal } = this.props
    const { file, requestInProgress, doneUpload, doneUploadMessage } = this.state
    return (
      <Fragment>
        {!doneUpload && (
          <File>
            <p>
              <strong>Archivo seleccionado</strong>
            </p>
            <span>{file.name || 'Aún no has seleccionado el archivo'}</span>
          </File>
        )}
        {doneUpload && <strong>{doneUploadMessage}.</strong>}
        <Content>
          {!doneUpload && (
            <Fragment>
              <ContentFile>
                <Input
                  type='file'
                  accept='.csv'
                  onChange={this.onChangeFile}
                  id='uploadInvoices'
                />
                <label htmlFor='uploadInvoices'>
                  <Icon className='mdi mdi-upload' />
                  <Text>Selecciona archivo</Text>
                </label>
              </ContentFile>
              <Button
                buttonClass='primary'
                icon='content-save'
                loading={requestInProgress}
                disabled={!file.name || requestInProgress}
                onClick={this.sendFile}
              >
                Guardar
              </Button>
            </Fragment>
          )}
          {doneUpload && (
            <Button buttonClass='secondary' icon='close' onClick={toggleModal}>
              Cerrar
            </Button>
          )}
        </Content>
      </Fragment>
    )
  }
}
