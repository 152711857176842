// Libaries
import React, { Component } from 'react'
import Notification from 'cogo-toast'

// Components
import FormNewIncome from './formNewIncome'
import CountryButtons from 'shared/countryButtons'
import LinkButton from 'shared/linkButton'
import API from 'api/core'

/**
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @typedef {import('api/core').BankAccount} BankAccount
 * @typedef {import('api/core').OptionName} OptionName
 * @typedef {import('api/core').Client} Client
 * @typedef {import('api/core').Income} Income
 * @typedef {Object} State
 * @property {boolean} requestInProgress
 * @property {string} country
 * @property {Array<string>} incomeTypes
 * @property {Array<OptionName>} companies
 * @property {Array<Client>} clients
 * @property {Income | null} incomeToUpdate
 *
 * @extends {Component<RouteComponentProps, & State>}
 */

class NewIncome extends Component {
  /**
   * @type {State}
   */
  state = {
    clients: [],
    companies: [],
    incomeTypes: [],
    incomeToUpdate: null,
    country: '',
    requestInProgress: false
  }

  componentDidMount = () => {
    const {
      location: { state }
    } = this.props
    this.setState({
      incomeToUpdate: state && state.income ? state.income : null,
      country: state && state.income ? state.income.country : ''
    })
  }

  /**
   * @param {*} _
   * @param {State} prevState
   */
  componentDidUpdate = (_, prevState) => {
    const { country } = this.state
    if (country !== prevState.country) {
      this.getCatalogues(country)
      this.getIncomeTypes('ALL')
    }
  }

  /**
   * @param {String} country
   */
  onChangeCountry = country => {
    this.setState({ country })
  }

  /**
   * @param {string} country
   */
  getCatalogues = async country => {
    try {
      const { companies } = await API.Accounting.GetCatalogues({ country })
      this.setState({ companies })
    } catch (error) {
      console.error(error)
      Notification.error(
        'Ha ocurrido un error al obtener el listado de las empresas.'
      )
    }
  }

  /**
   * @param {string} value
   */
  getClients = async value => {
    if (!value.trim()) return
    this.setState({ requestInProgress: true })
    try {
      const { data } = await API.Clients.SearchClients(value.trim())
      this.setState({ clients: data })
    } catch (error) {
      console.error(error)
      Notification.error(
        'Ha ocurrido un error al obtener información de los clientes'
      )
    } finally {
      this.setState({ requestInProgress: false })
    }
  }

  /**
   * @param {string} company
   */
  getIncomeTypes = async company => {
    try {
      const { income_types: incomeTypes } = await API.Accounting.GetIncomeTypes(
        company || 'ALL'
      )
      this.setState({ incomeTypes: incomeTypes })
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error cargando los tipos de ingresos')
    }
  }

  render () {
    const {
      companies,
      country,
      clients,
      requestInProgress,
      incomeTypes,
      incomeToUpdate
    } = this.state
    const { history } = this.props

    return (
      <div>
        <LinkButton
          url='/accounting/income'
          icon='arrow-left-thick'
          buttonClass='link bottom'
        >
          Ingresos
        </LinkButton>
        <div className='newFlow'>
          <h1 className='title'>
            {incomeToUpdate ? 'Actualizar ingreso' : 'Nuevo ingreso'}
          </h1>
          <CountryButtons
            onChangeCounty={this.onChangeCountry}
            country={country}
          />
          {country && (
            <FormNewIncome
              clients={clients}
              requestInProgress={requestInProgress}
              companies={companies}
              history={history}
              country={country}
              incomeTypes={incomeTypes}
              getClients={this.getClients}
              getIncomeTypes={this.getIncomeTypes}
              incomeToUpdate={incomeToUpdate}
              updateIncome={!!incomeToUpdate}
            />
          )}
        </div>
      </div>
    )
  }
}
export default NewIncome
