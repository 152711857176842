// Libraries
import React, { Component } from 'react'
import Notification from 'cogo-toast'

// Compoents
import FiltersAccounting from 'shared/filtersAccounting'
import LinkButton from 'shared/linkButton'
import Button from 'shared/button'
import Modal from 'shared/modal'
import Loader from 'shared/loader'
import Paginator from 'shared/paginator'
import FlowPayment from './flowPayment'
import UploadStatus from './uploadStatus'
import { hasFilters, stringifyQuery, parseQuery, getOptions } from 'utils'
import { EXPENSES_STATUS } from 'shared/catalogs'
import API from 'api/core'

/**
 * @typedef {import('api/core').TypeFlowPayment} TypeFlowPayment
 * @typedef {import('api/core').Pagination} Pagination
 * @typedef {Object} State
 * @property {boolean} requestInProgress
 * @property {boolean} loadingCatalogs
 * @property {boolean} openModal
 * @property {Array} flowTypes
 * @property {Pagination} pagination
 * @property {Array<TypeFlowPayment>} flowPayments
 * @property {{flow_type_id?: string, status?: string, country?: string}} filters
 *
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 *
 * @extends {React.Component<RouteComponentProps, State>}
 */

class Accounting extends Component {
  /**
   * @type {State}
   */
  state = {
    flowPayments: [],
    flowTypes: [],
    pagination: {
      total_pages: 1,
      total_entries: 0,
      page_number: 1,
      page_size: 1
    },
    requestInProgress: false,
    loadingCatalogs: false,
    openModal: false,
    filters: {
      flow_type_id: '',
      status: '',
      country: ''
    }
  }

  /**
   * @param {object} prevProps
   * @param {object} prevState
   */
  componentDidUpdate = (prevProps, prevState) => {
    const { filters } = this.state
    if (this.props.location.search !== prevProps.location.search) {
      this.getFlowPayments()
    }
    if (filters.country !== prevState.filters.country) {
      this.getCatalogues(filters.country)
    }
  }

  componentDidMount = () => {
    this.getFlowPayments()
  }

  toggleModal = () => {
    this.setState(state => ({ openModal: !state.openModal }))
  }

  /**
   * @param {"EN ESPERA" | "PAGADO" | "COMPLETADO" | "CANCELADO"} status
   * @param {number} id
   */
  updateStatus = (status, id) => {
    this.setState(state => ({
      flowPayments: state.flowPayments.map(flowPayment => {
        return flowPayment.id === id ? { ...flowPayment, status } : flowPayment
      })
    }))
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  onChangeField = event => {
    const { value, name } = event.currentTarget
    this.setState(state => ({
      filters: { ...state.filters, [name]: value }
    }))
  }

  onCleanFilters = () => {
    const { history, location } = this.props
    this.setState(
      {
        filters: {
          flow_type_id: '',
          country: '',
          status: ''
        }
      },
      () => {
        history.push(location.pathname)
      }
    )
  }

  onFilter = () => {
    const { filters } = this.state
    const {
      history,
      location: { pathname }
    } = this.props
    history.push(pathname + stringifyQuery(filters))
  }

  getFlowPayments = async () => {
    const { filters } = this.state
    const { location } = this.props
    const page = parseQuery(location.search).page || 1
    const params = {
      ...hasFilters(filters),
      page
    }

    this.setState({ requestInProgress: true })
    try {
      const { data, pagination } = await API.Accounting.GetFlowPayments(params)
      this.setState({
        flowPayments: data,
        requestInProgress: false,
        pagination
      })
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error al descargar la información')
    } finally {
      this.setState({ requestInProgress: false })
    }
  }

  /**
   * Get all catalogs of api
   * @param {string | null} country
   * @returns {Promise<void>}
   */
  getCatalogues = async country => {
    this.setState({ loadingCatalogs: true })
    if (!country) return
    try {
      const { flow_types: flowTypes } = await API.Accounting.GetCatalogues({
        country
      })
      this.setState({ flowTypes })
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error cargando los tipos de egresos')
    } finally {
      this.setState({ loadingCatalogs: false })
    }
  }

  render () {
    const {
      flowPayments,
      requestInProgress,
      filters,
      pagination,
      flowTypes,
      loadingCatalogs,
      openModal
    } = this.state
    const options = getOptions(flowTypes)
    return (
      <div>
        <div className='flex-between bottom'>
          <h1 className='title'>Egresos</h1>
          <div className='flex-end'>
            <LinkButton
              buttonClass='primary right'
              icon='book-open-variant'
              url='/accounting/flow-payment/catalogues'
            >
              Catálogos
            </LinkButton>
            <Button
              buttonClass='upload left'
              icon='upload'
              onClick={this.toggleModal}
            >
              Estatus
            </Button>
          </div>
        </div>
        <div className='bottom'>
          <FiltersAccounting
            onChangeField={this.onChangeField}
            onCleanFilters={this.onCleanFilters}
            onFilter={this.onFilter}
            filters={filters}
            optionStatus={EXPENSES_STATUS}
            optionTypes={options}
            typePlaceholder='Tipo de egreso'
            name='flow_type_id'
            loading={loadingCatalogs}
          />
        </div>
        <hr />
        {requestInProgress ? (
          <Loader />
        ) : (
          <FlowPayment
            updateStatus={this.updateStatus}
            flowPayments={flowPayments}
            totalEntries={pagination && pagination.total_entries}
          />
        )}
        {pagination && <Paginator totalPages={pagination.total_pages} />}
        <Modal
          title='Actualizar estado'
          isActive={openModal}
          toggleModal={this.toggleModal}
          noFooter
        >
          <UploadStatus
            toggleModal={this.toggleModal}
            handleUpdate={this.getFlowPayments}
          />
        </Modal>
      </div>
    )
  }
}

export default Accounting
