import styled, { css } from 'styled-components'
import Card from 'shared/card'

export const StepsSegment = styled.li(({ isActive }) => ({
  [isActive && css]: {
    '&:after': {
      backgroundColor: '#dbdbdb !important'
    }
  }
}))

export const WrapperSteps = styled.div`
  display: flex;
  width: 100%;
`

export const CardFailed = styled(Card)`
  box-shadow: none;
  background: #f3f2f29c;
  border-radius: 16px;
  width: 100%;
  .icon {
    margin-left: 30px;
    height: 30px;
    width: 30px;
    line-height: 30px;
    & > i {
      font-size: 30px;
    }
  }
  .steps-failed {
    width: 100%;
    margin-bottom: 0 !important;
  }
  @media (min-width: 1080px) {
    width: 100%;
  }
`

export const Logo = styled.div`
  & > svg {
    width: 35px;
    height: 35px;
  }
`

export const WrapperError = styled.div`
  margin-top: 20px;
  & > div {
    margin-left: 0;
    & > span {
      color: #363636;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0px;
      margin-top: 8px;
    }
    & > p {
      white-space: pre-line;
    }
  }
`
export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const WrapperInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 40%;
  margin-top: 10px;
  ${({ last }) =>
    last &&
    css`
      width: 60%;
      justify-content: space-between;
      margin-right: 60px;
    `};
`
export const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 16px;
  color: rgb(127, 127, 157);
  font-size: 14px;
  letter-spacing: 0px;
  & > p {
    color: rgb(67, 68, 118);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    margin-top: 8px;
  }
  ${({ email }) =>
    email &&
    css`
      width: auto;
    `};
`

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1.3rem;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
