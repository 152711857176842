/*
  API Wrapper
  The intent of this module is to have a single place in the app where all API calls are located
  The syntax for a new API call can be inferred from the existing exported classes, it supports the HTTP methods GET, POST, PUT and DELETE
  All four methods call the request function that parses and prepares the data before making the actual request, the URL parameters are converted to a query string and headers / auth are added in this step
  The response is a promise that must be resolved from the place it was called
*/

/**
 * Types endpoints Movements
 * @typedef {{
 * uid: string,
 * status: string,
 * name: string,
 * insertedAt: Date
 * }} Movement
 *
 * @typedef {{
 * total_pages: number;
 * total_entries: number;
 * page_number: number;
 * page_size: number ;
 * }} Pagination
 *
 * @typedef {{
 * url: string,
 * uid: string,
 * totalSuccessMovements: number,
 * totalMovements: number,
 * totalFailedMovements: number,
 * totalExistingMovements: number,
 * status: string,
 * name: string,
 * insertedAt: Date,
 * errors: Array,
 * }} MovementDetail
 */

import queryString from 'query-string'
import nprogress from 'nprogress'
import store from 'store'

// Make the actual request
function request (method, endpoint, queryArray, body) {
  let query = ''
  if (typeof endpoint === 'undefined') {
    console.error('FATAL ERROR: endpoint not defined')
    return
  }
  if (typeof queryArray !== 'undefined' && queryArray !== null) {
    query = `?${queryString.stringify(queryArray)}`
  }

  // Delete ? sign if the query string is empty
  if (query === '?') query = ''

  const url = `${process.env.API_BANK_SERVER}${
    process.env.API_PREFIX_BANK
  }${endpoint}${query}`
  const fetchParams = {
    method: method,
    headers: {
      'content-type': 'application/json',
      Authorization: store.get('CORE_AUTH_TOKEN'),
      'X-Requested-With': 'XMLHttpRequest'
    }
  }

  if (body) fetchParams.body = JSON.stringify(body)

  nprogress.start()
  // Call the API and return a json response
  return window
    .fetch(url, fetchParams)
    .then(response =>
      response.json().then(r => {
        nprogress.done()
        if (response.status >= 200 && response.status < 300) {
          if (process.env.DEBUG) {
            console.info(`${method}: ${url}`)
          }
          return r
        } else if (response.status === 401) {
          store.clearAll()
        } else if (response.status === 400) {
          console.log(`===========${response}`)
        } else {
          return r
        }
      })
    )
    .catch(error => console.error(error))
}

// HTTP GET
function Get (route, query, params) {
  return request('GET', route, query, params)
}
// HTTP POST
function Post (route, query, params, data) {
  return request('POST', route, query, data)
}
// HTTP PUT
function Put (route, query, params, data) {
  return request('PUT', route, query, data)
}

class Accounts {
  static List (data) {
    return Get('/accounts', data, null)
  }

  static Show (uuid) {
    return Get(`/accounts/${uuid}`, {}, null)
  }

  static ShowByReference (reference) {
    return Get(`/accounts/ref/${reference}`, {}, null)
  }

  static Create (data) {
    return Post('/accounts', {}, null, data)
  }

  static ChangeStatus (data) {
    return Put(`/accounts/${data.uid}`, {}, null, data)
  }
}

class Movements {
  /**
   * @param {{page: number}} data
   * @returns {Promise<{data: Array<Movement>, pagination: Pagination}>}
   */
  static List (data) {
    return Get('/movements/csv', data, null)
  }

  /**
   * @param {string} uid
   * @returns {Promise<MovementDetail>}
   */
  static MovementDetails (uid) {
    return Get(`/movements/csv/${uid}`, null, null)
  }

  /**
   * @param {string} data
   */
  static Upload (data) {
    return Post('/upload', null, null, data)
  }
}

export default {
  Accounts,
  Movements
}
