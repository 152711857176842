// Principal colors of proyect
export const colorPrimary = '#007da4'
export const colorPrimaryContrast = '#ffffff'
export const colorPrimaryHover = '#1fcaff'
export const colorSecondary = '#003764'
export const colorSecondaryHover = '#003764'
export const white = '#fff'
export const greenCheckLogo = '#8dba38'
export const blueResuelveLogo = '#007ca2'
export const errorLabel = '#ec1c24'

// Palet colors gray
export const lightGrey = '#f7f7f7'
export const lightGreyAlt = '#f0f0f0'
export const lightgreyHover = '#e0e0e0'
export const mediumGrey = '#999999'
export const darkGrey = '#474747'
export const darkGreyAlt = '#272727'
export const darkGreyHover = '#373737'
export const appleGrey = '#efeff4'
export const gviewGrey = '#d0d0d0'

// Palet colors buttons
export const buttonPrimary = '#00d1b2'
export const buttonPrimaryHover = '#00c4a7'
export const buttonSecondary = '#ff3860'
export const buttonSecondaryHover = '#ff3a29'

// Palet colors dark
export const darkBlack = '#363636'
export const error = '#A50203'
export const redTraffic = '#cc0605'
export const yellowTraffic = '#F6C352'
export const greenTraffic = '#33a532'
