// Libraries
import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'

// Components
import { FlexWrapper } from 'css/utils'
import { lightGreyAlt } from 'css/colors'
import IconButton from 'shared/iconButton'

const ToggleWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 1.5em;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
`
const HeaderWrapper = styled(FlexWrapper)`
  margin-bottom: 0;
  width: 100%;
  padding: 1rem 1.8rem 1rem;
  cursor: pointer;
  :hover {
    background: ${lightGreyAlt};
  }
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 600;
    line-height: 1.125;
  }
  ${({ open }) =>
    open &&
    css`
      background: ${lightGreyAlt};
    `}
`
const ChildrenWrapper = styled.div`
  padding: 0 1.8rem 1rem;
`

/**
 * @typedef {{
 * isOpen: boolean
 * }} State
 *
 * @typedef {{
 * children: React.ReactNode,
 * header: object | string,
 * tooltip?: string,
 * testId?: string
 * }} PropsToggleItem
 *
 * @param {PropsToggleItem} props
 */

export default class ToggleItem extends PureComponent {
  /**
   * @type {State}
   */
  state = {
    isOpen: false
  }

  toggleOpen = () => {
    this.setState(state => ({ isOpen: !state.isOpen }))
  }

  render () {
    const { children, header, tooltip = 'Detalles', testId } = this.props
    const { isOpen } = this.state
    return (
      <ToggleWrapper>
        <FlexWrapper onClick={this.toggleOpen}>
          <HeaderWrapper open={isOpen}>
            {header}
            <IconButton
              className='margin-left'
              icon={isOpen ? 'chevron-up' : 'chevron-down'}
              tooltip={tooltip}
              testId={`toggle-${testId}`}
            />
          </HeaderWrapper>
        </FlexWrapper>
        {isOpen && <ChildrenWrapper>{children}</ChildrenWrapper>}
      </ToggleWrapper>
    )
  }
}
