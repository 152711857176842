// Libraries
import React from 'react'
import styled from 'styled-components'

// Componenst
import { FlexWrapper } from 'css/utils'
import { SubHeader, Header, SubTitle, InfoWrapper, Content } from './styles'
import { formatCurrency, formatDate } from 'utils'
import Taxes from './Taxes'

const ContentWrapper = styled(Content)`
  align-self: flex-start;
`
/**
 * @typedef {import('api/core').TypeInvoiceDetails} TypeInvoiceDetails
 * @typedef {{
 * invoice: TypeInvoiceDetails
 * }} PropsGeneral
 * @param {PropsGeneral} props
 */
const General = ({ invoice }) => {
  return (
    <ContentWrapper>
      <SubTitle>Información general</SubTitle>
      <InfoWrapper>
        <SubHeader>Monto en letra</SubHeader>
        <Header>{invoice.amount_in_letters}</Header>
        <FlexWrapper>
          <div>
            <SubHeader>Moneda</SubHeader>
            <Header>{invoice.currency}</Header>
          </div>
          <div>
            <SubHeader>Subtotal</SubHeader>
            <Header>
              {invoice.currency &&
                formatCurrency(Number(invoice.subtotal), invoice.currency)}
            </Header>
          </div>
          <div>
            <SubHeader>Descuentos</SubHeader>
            <Header>
              {invoice.currency &&
                formatCurrency(+invoice.total_discounts, invoice.currency)}
            </Header>
          </div>
        </FlexWrapper>
        <FlexWrapper>
          <div>
            <SubHeader>Resolución</SubHeader>
            <Header>{invoice.resolution}</Header>
          </div>
          <div>
            <SubHeader>Tipo</SubHeader>
            <Header>{invoice.type}</Header>
          </div>
        </FlexWrapper>
        <FlexWrapper>
          <div>
            <SubHeader>Fecha de factura</SubHeader>
            <Header>{formatDate(invoice.date)}</Header>
          </div>
          <div>
            <SubHeader>Folio</SubHeader>
            <Header>{invoice.folio}</Header>
          </div>
          <div>
            <SubHeader>Prefijo</SubHeader>
            <Header>{invoice.prefix}</Header>
          </div>
        </FlexWrapper>
        {invoice.version === 2 && (
          <InfoWrapper style={{ marginTop: '2em' }}>
            <FlexWrapper>
              <div>
                <SubHeader>Sucursal</SubHeader>
                <Header>{invoice.issuer_branch_office_code}</Header>
              </div>
              <div>
                <SubHeader>D. Verificación</SubHeader>
                <Header>{invoice.verification_digit}</Header>
              </div>
              <div>
                <SubHeader>Vencimiento</SubHeader>
                <Header>{formatDate(invoice.due_date)}</Header>
              </div>
            </FlexWrapper>
            <FlexWrapper>
              <div>
                <SubHeader>Método pago</SubHeader>
                <Header>{invoice.payment_method}</Header>
              </div>
              <div>
                <SubHeader>Forma pago</SubHeader>
                <Header>{invoice.payment_way}</Header>
              </div>
              <div>
                <SubHeader>Términos pago</SubHeader>
                <Header>{invoice.payment_terms}</Header>
              </div>
            </FlexWrapper>
          </InfoWrapper>
        )}
        <FlexWrapper />
        <Taxes invoice={invoice} />
      </InfoWrapper>
    </ContentWrapper>
  )
}
export default General
