// @ts-nocheck
import { getProp } from 'utils'

/**
 * @typedef {import('api/core').TypeContractDetail} TypeContractDetail
 * @param {string} newKey
 * @param {string} key
 * @param {TypeContractDetail} contractDetails
 */
const getValue = (newKey, key, contractDetails) => {
  switch (newKey) {
    case 'client':
      return getProp(contractDetails, key).value
    case 'address':
    case 'beneficiary':
      return getProp(contractDetails.client, key).value
    case 'debts':
      const debt = contractDetails.debts[key]
      return [
        `Número de crédito: ${debt.credit_number.value}`,
        `Nombre de entidad financiera: ${debt.financial_entity_name.value}`,
        `Fecha de último pago: ${debt.last_paid_date.value}`,
        `Fecha de última actualización:  ${debt.last_update_date.value}`,
        `Monto capturado: ${debt.entered_amount.value}`,
        `Monto actualizado: ${debt.updated_amount.value}`,
        `Notas: ${debt.note.value}`
      ]
    default:
      return getProp(contractDetails, key).value
  }
}

/**
 * @param {Object<string, string>} invalid
 * @param {TypeContractDetail} contractDetails
 */
export const generateData = (invalid, contractDetails) => {
  const data = Object.entries(invalid).reduce((acum, [key, comment]) => {
    /**
     * All the key are, example: client.name
     * To make destructuring of the key
     * For create the key names object create
     */

    const [newKey, newValue] = key.split('.')
    const [keyDebt] = newKey.split('_')
    const names =
      newKey === 'beneficiary' ? `beneficiary_${newValue}` : newValue

    /**
     * Return to an object taking into the key
     */
    if (newKey.includes('debts')) {
      const name = `debt.credit_number ${Number(newValue) + 1}`
      const index = acum.findIndex(item => item.name === name)

      /**
       * validate if the keys of the index has the description
       * And return a key description with all the descriptions
       */
      if (index !== -1) {
        acum[index].description = `${acum[index].description}, ${comment}`
      } else {
        acum.push({
          name,
          value: getValue(keyDebt, newValue, contractDetails),
          description: comment
        })
      }
    } else {
      acum.push({
        name: names,
        value: getValue(newKey, key, contractDetails),
        description: comment
      })
    }

    return acum
  }, [])
  return data
}

const getClientAddress = address => {
  return Object.entries(address).reduce((acum, [key, value]) => {
    const keys =
      key === 'street_name'
        ? 'street'
        : key === 'external_number'
          ? 'street_number'
          : key === 'internal_number'
            ? 'interior_number'
            : key

    return {
      ...acum,
      country: { value: 'México', modified: false },
      [keys]: {
        value: value,
        modified: false
      }
    }
  }, {})
}

const getClientInfo = (clientInfo, clientAddress, files) => {
  const address = getClientAddress(clientAddress)
  return Object.entries(clientInfo).reduce((acum, [key, value]) => {
    const keys =
      key === 'last_surname'
        ? 'second_surname'
        : key === 'country_birth'
          ? 'birth_country'
          : key === 'home_phone'
            ? 'phone'
            : key === 'curp'
              ? 'identification_number'
              : key === 'state_birth'
                ? 'birth_state'
                : key

    const addressDetails = key === 'address' ? { ...address } : { value }

    return {
      ...acum,
      documents_identity: files.map(item => ({
        ...item,
        name: item.description
      })),
      beneficiary: {},
      [keys]: {
        ...addressDetails,
        modified: false
      }
    }
  }, {})
}

export const validateContractRequest = request => {
  if (request.product_line === 'credito') {
    const clientInfo = request.credit_review.client_info
    const clientAddress = request.credit_review.client_info.address
    const clientFiles = request.files
    const client = getClientInfo(clientInfo, clientAddress, clientFiles)
    return {
      client,
      product_line: request.product_line,
      contract_id: request.contract_id,
      files: request.files,
      creditReview: {
        reference: request.credit_review.rtd_reference,
        paymentDates: request.credit_review.payments_dates,
        initialBalance: request.credit_review.initial_balance,
        granter: request.credit_review.granter,
        creditDestination: request.credit_review.credit_destination,
        creditDesfinition: request.credit_review.credit_definition,
        balanceAmountList: request.credit_review.balance_amounts_list,
        amountPaymentList: request.credit_review.amount_payments_list
      }
    }
  }
  return request
}
