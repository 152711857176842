// Libraries
import React, { useState, useRef, useEffect } from 'react'
import className from 'classnames'
import Notification from 'cogo-toast'

// components
import API from 'api/core'
import IconButton from 'shared/iconButton'
import Modal from 'shared/modal'
import UpdateFile from './UpdateFile'
import { showFieldByType, hasValue } from '../utils'
import { TableBody, TableWraper, TableHead, Buttons, Form } from './styles'

/**
 * @typedef {{
 * value: string;
 * label: string,
 * type: string,
 * editable: boolean,
 * name: string,
 * id: string
 * }} Fields
 * @typedef {{
 * currentFields: Array<Fields>
 * clarificationId: number
 * }} PropsFieldsDetails
 * @param {PropsFieldsDetails} props
 *
 */
const FieldsDetails = ({ currentFields, clarificationId }) => {
  const elementRef = useRef(null)
  const [showModal, setShowModal] = useState(false)
  const [fields, setFields] = useState(currentFields)
  const [fieldToUpdateFile, setFieldToUpdateFile] = useState('')
  const [showField, setShowField] = useState('')
  const [currency, setCurrency] = useState('')
  const [fieldValue, setFieldValue] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    document.addEventListener('mousedown', handleToggle)
  }, [])

  /**
   * @param {string} name
   * @param {string} value
   * @param {string} id
   * @param {string} type
   */
  function handleEditField (id, name, value, type) {
    const toggle = id === showField ? '' : id
    const { amount, currrentCurrency } = handleMoney(type, value)
    const isValue = amount || value
    setShowField(toggle)
    setFieldValue({
      [name]: isValue
    })
    setCurrency(currrentCurrency)
  }

  /**
   * @param {string} value
   * @param {string} type
   */
  function handleMoney (type, value) {
    if (type === 'money' && value) {
      const [amount, currrentCurrency] = value.split(' ')
      return { amount, currrentCurrency }
    }
    return { amount: '', currrentCurrency: '' }
  }

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  function hanldeChangeValue (event) {
    const { value, name } = event.currentTarget
    setFieldValue({
      [name]: value
    })
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  function hanldeChangeCurrency (event) {
    const { value } = event.currentTarget
    setCurrency(value)
  }

  /**
   * @param {Date} date
   * @param {string} name
   */
  function hanldeChangeDate (date, name) {
    setFieldValue({
      [name]: date
    })
  }

  /**
   * @param {React.ChangeEvent<HTMLElement>} event
   */
  function handleToggle (event) {
    if (elementRef.current && !elementRef.current.contains(event.target)) {
      setShowField('')
    }
  }

  function handleCancel () {
    setShowField('')
  }

  const handleToggleModal = () => setShowModal(!showModal)

  /**
   * @param {string} name
   */
  const hanldeUpdateFile = name => {
    setFieldToUpdateFile(name)
    handleToggleModal()
  }

  /**
   * @param {{ fields: Fields[]}} data
   */
  function handleUpdateFields (data) {
    setFields([...data.fields])
    setShowField('')
  }

  /**
   * @param {string} name
   * @param {string} type
   */
  async function handleUpdateField (name, type) {
    const value = fieldValue[name]
    const isValue = type === 'money' ? `${value} ${currency}` : value
    const params = {
      fields: [
        {
          name: name,
          value: isValue
        }
      ]
    }
    setLoading(true)
    try {
      const { data } = await API.Clarifications.UpdateClarificationStatus(
        clarificationId,
        params
      )
      Notification.success('Campo actualizado con éxito')
      handleUpdateFields(data)
    } catch (error) {
      console.error(error)
      Notification.error('Ocurrio un error. Intenta de nuevo')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <TableWraper>
        <TableHead>
          <tr>
            <th>Nombre del campo</th>
            <th>Contenido del campo</th>
          </tr>
        </TableHead>
        <TableBody ref={elementRef}>
          {fields.map(({ label, value, type, name, editable, id }, index) => {
            return (
              <tr key={index}>
                <td>{label}</td>
                <td className='flex-end'>
                  {showField === id ? (
                    <Form error={!fieldValue[name]}>
                      {showFieldByType({
                        type,
                        name,
                        hanldeChangeValue,
                        fieldValue,
                        hanldeChangeDate,
                        hanldeChangeCurrency,
                        currency
                      })}
                      <Buttons>
                        <div
                          className={!fieldValue[name] && 'btn-disabled'}
                          role='button'
                          onClick={() => handleUpdateField(name, type)}
                        >
                          {loading ? (
                            <i className='mdi mdi-loading mdi-spin mdi-18px' />
                          ) : (
                            <IconButton icon='check-all' tooltip='Actualizar' />
                          )}
                        </div>
                        <div role='button' onClick={handleCancel}>
                          <IconButton icon='close-circle' tooltip='Cancelar' />
                        </div>
                      </Buttons>
                    </Form>
                  ) : (
                    <span>{hasValue(value, label, type)}</span>
                  )}
                  {showField !== id && (
                    <IconButton
                      className={className('left', {
                        'display-none': !editable
                      })}
                      icon='pencil'
                      tooltip='Editar'
                      testId='edit-fields'
                      disabled={!editable}
                      onClick={
                        type === 'file'
                          ? () => hanldeUpdateFile(name)
                          : () => handleEditField(id, name, value, type)
                      }
                    />
                  )}
                </td>
              </tr>
            )
          })}
        </TableBody>
      </TableWraper>
      <Modal
        noFooter
        title='Subir archivo'
        isActive={showModal}
        toggleModal={handleToggleModal}
      >
        <UpdateFile
          fieldToUpdateFile={fieldToUpdateFile}
          toggleModal={handleToggleModal}
        />
      </Modal>
    </div>
  )
}
export default FieldsDetails
