import React, { useEffect, useState, Fragment } from 'react'
import Notification from 'cogo-toast'

import AddBank from './addBank'
import { getCountryLabel } from 'utils'
import Loader from 'shared/loader'
import FiltersAccounting from 'shared/filtersAccounting'
import Modal from 'shared/modal'
import Button from 'shared/button'
import IconButton from 'shared/iconButton'
import API from 'api/core'

/**
 * @param {{
 * routeTab: Function
 * }} BanksProps
 */
const Banks = ({ routeTab }) => {
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [value, setValue] = useState({ country: '' })
  const [bankSeleted, setBankSelected] = useState({})
  const [banks, setBanks] = useState([
    {
      status: '',
      name: '',
      id: '',
      errors: null,
      country: ''
    }
  ])

  useEffect(() => {
    routeTab()
    getBanks()
  }, [value.country])

  async function getBanks () {
    const params = {
      country: value.country || 'MX'
    }
    setLoading(true)
    try {
      const { data } = await API.Accounting.GetBanks(params)
      setBanks(data)
    } catch (error) {
      Notification.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  function onChangeField (event) {
    const { value } = event.currentTarget
    setValue({ country: value })
  }

  function onCleanFilters () {
    if (!value.country) return
    setValue({ country: '' })
  }

  function onFilter () {
    if (!value.country) return
    getBanks()
  }

  /**
   * @param {{
   * status: string,
   * name: string,
   * id: string,
   * errors: null,
   * country: string
   * }} bank
   */
  function hadleUpateBank (bank) {
    setBankSelected(bank)
    setOpenModal(!openModal)
  }

  function handleToggle () {
    setOpenModal(!openModal)
    setBankSelected({})
  }

  return (
    <Fragment>
      <FiltersAccounting
        className='filter-accounting'
        filters={value}
        onFilter={() => onFilter()}
        onChangeField={e => onChangeField(e)}
        onCleanFilters={() => onCleanFilters()}
      />
      <div className='flex-between'>
        <p className='register-results'>Hay {banks.length + 1} resultados</p>
        <Button
          buttonClass='create'
          icon='plus-circle-outline'
          testId='new-bank'
          onClick={() => setOpenModal(!openModal)}
        >
          Agregar banco
        </Button>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <table className='table'>
          <thead>
            <tr>
              <th>Nombre del banco</th>
              <th>País</th>
              <th>Id del banco</th>
            </tr>
          </thead>
          <tbody>
            {banks.map(bank => (
              <tr key={bank.id}>
                <td className='link'>{bank.name}</td>
                <td>{getCountryLabel(bank.country)}</td>
                <td>{bank.id}</td>
                <td>
                  <IconButton
                    icon='pencil'
                    testId='edit-account'
                    tooltip='Editar banco '
                    onClick={() => hadleUpateBank(bank)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Modal
        title={bankSeleted.id ? 'Actualizar banco' : 'Agregar banco'}
        toggleModal={() => handleToggle()}
        isActive={openModal}
        noFooter
      >
        <AddBank
          toggleModal={() => handleToggle()}
          getBanks={() => getBanks()}
          bankSeleted={bankSeleted}
        />
      </Modal>
    </Fragment>
  )
}
export default Banks
