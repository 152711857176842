// @ts-nocheck
// Libraries
import React, { useState, useEffect } from 'react'
import Notification from 'cogo-toast'
import styled from 'styled-components'

// Components
import { Title, FlexWrapper } from 'css/utils'
import { error } from 'css/colors'
import { SubTitle } from './styles'
import { downloadXml } from 'utils'
import Loader from 'shared/loader'
import Card from 'shared/card'
import Button from 'shared/button'
import Modal from 'shared/modal'
import CreateNC from '../createNC'
import EditCufeStatus from '../EditCufeStatus'
import Emisor from './Emisor'
import CufeStatus from './CufeStatus'
import Recipient from './Recipient'
import Details from './Details'
import General from './General'
import Total from './Total'
import Dian from './Dian'
import API from 'api/core'

const SCREEN_SIZE = '100%'

const Content = styled.div`
  width: ${SCREEN_SIZE};
  margin: 0 auto;
  padding-bottom: 5rem;
`
const ErrorWrapper = styled.div`
  & > p {
    padding: 1em 0;
    color: ${error};
  }
`
const ErrorTitle = styled(SubTitle)`
  color: ${error};
  border-bottom: 2px solid ${error};
`
const WrapperButtons = styled(FlexWrapper)`
  justify-content: flex-end;
  & > button {
    padding-left: 1rem;
  }
`

/**
 * @typedef {import('api/core').TypeInvoiceDetails} TypeInvoiceDetails
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @param {RouteComponentProps} props
 */
const InvoiceDetails = ({ match: { params }, history, location }) => {
  const [details, setDetails] = useState({})
  const [cufeAndStatus, setCufeAndStatus] = useState({})
  const [loading, setLoading] = useState(false)
  const [openModalCufeStatus, setOpenModalCufeStatus] = useState(false)
  const [openModalCredit, setOpenModalCredit] = useState(false)

  useEffect(() => {
    setLoading(true)
    API.Invoices.GetInvoiceOneDetails(params.id)
      .then(({ data, errors }) => {
        if (errors) {
          Notification.error(`${error}. Intenta más tarde`)
        }
        const { cufe, status } = data
        setDetails(data)
        setCufeAndStatus({ cufe, status })
      })
      .catch(error => {
        console.error(error)
        Notification.error('Ha ocurrido un error con los detalles')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  function handleUpdatedData (updatedValues) {
    setCufeAndStatus(updatedValues)
  }

  function handleEditCufestatus () {
    setOpenModalCufeStatus(!openModalCufeStatus)
  }

  function handleRedirect () {
    const params = location.state && location.state.params
    if (params) {
      history.push('/invoices/invoice-one')
    } else {
      history.goBack()
    }
  }
  if (loading) return <Loader />
  return (
    <div>
      <Button
        icon='arrow-left-thick'
        buttonClass='link'
        onClick={() => handleRedirect()}
      >
        Facturas
      </Button>
      <Content>
        <FlexWrapper>
          <Title>{`Detalles ${details.type &&
            details.type.toLowerCase()}`}</Title>
          <WrapperButtons>
            {details.status === 'SENT' && (
              <Button
                buttonClass='create'
                icon='plus-circle-outline'
                onClick={() => setOpenModalCredit(!openModalCredit)}
              >
                Nota crédito
              </Button>
            )}
            <Button
              buttonClass='primary'
              icon='download'
              onClick={() =>
                downloadXml(
                  details.signed_xml,
                  `${details.folio}_${details.date}`
                )
              }
            >
              Descargar xml
            </Button>
          </WrapperButtons>
        </FlexWrapper>
        <Card>
          {details && details.response_error && (
            <ErrorWrapper>
              <ErrorTitle>Factura existente</ErrorTitle>
              <p>{details.response_error}</p>
            </ErrorWrapper>
          )}
          <FlexWrapper>
            <Emisor invoice={details} />
            <CufeStatus
              cufeAndStatus={cufeAndStatus}
              onEditCufestatus={handleEditCufestatus}
            />
          </FlexWrapper>
          <FlexWrapper>
            <Recipient invoice={details} />
            <General invoice={details} />
          </FlexWrapper>
          <Details invoice={details} />
          <Total invoice={details} />
          {details.version === 2 && <Dian invoice={details} />}
        </Card>
        <Modal
          title='Editar cufe y/o estado'
          isActive={openModalCufeStatus}
          toggleModal={() => setOpenModalCufeStatus(!openModalCufeStatus)}
          noFooter
        >
          <EditCufeStatus
            id={details.id}
            cufeAndStatus={cufeAndStatus}
            updatedData={handleUpdatedData}
            toggleModal={() => setOpenModalCufeStatus(!openModalCufeStatus)}
          />
        </Modal>
        <Modal
          title='Agregar nota crédito'
          isActive={openModalCredit}
          toggleModal={() => setOpenModalCredit(!openModalCredit)}
          noFooter
        >
          <CreateNC
            toggleModal={() => setOpenModalCredit(!openModalCredit)}
            invoice={details}
          />
        </Modal>
      </Content>
    </div>
  )
}
export default InvoiceDetails
