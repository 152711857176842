// Libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import API from 'api/core'
import Title from 'shared/title'
import ViewContractRequest from './view-contract-request'

const breadcrumbLocation = [
  {
    label: 'Solicitudes de contrato revisadas',
    route: '/contract-requests/reviewed'
  },
  { label: 'Solicitud de contrato', route: '' }
]

/**
 * @typedef {Object} Props
 * @property {object} match
 */

/**
 * @typedef {Object} State
 * @property {object} contract
 * @property {boolean} [requestInProgress=false]
 */

/**
 * @extends { Component<Props, State>}
 */

class ReviewedContractRequest extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        uid: PropTypes.string
      })
    })
  }

  static defaultProps = {
    match: {
      params: { uid: '' }
    }
  }

  state = {
    contract: null,
    requestInProgress: false
  }

  /**
   *
   * @param {string} contractUid
   */

  getContract (contractUid) {
    this.setState({ reuestInProgress: true })
    API.ContractRequests.ContractDetail(contractUid)
      .then(response => {
        this.setState({ contract: response.data })
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        this.setState({ requestInProgress: false })
      })
  }

  componentDidMount () {
    const {
      match: { params }
    } = this.props
    this.getContract(params.uid)
  }

  render () {
    const { contract } = this.state
    return (
      <div>
        <Title
          title='Solicitud de contrato'
          breadcrumbLocation={breadcrumbLocation}
        />
        <ViewContractRequest contract={contract} />
      </div>
    )
  }
}

export default ReviewedContractRequest
