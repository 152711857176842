// Libararies
import React, { Component } from 'react'
import Notification from 'cogo-toast'

// Components
import Button from 'shared/button'
import { axiosUploadFile, getProp } from 'utils'
import { InputFile, Icon, Text, Content, File, ContentFile } from './styles'

/**
 * @typedef {{
 * file: object,
 * requestInProgress: boolean
 * }} State
 *
 * @typedef {{handleUpdate: function, toggleModal: function, clarifcationId: string}} Props
 *
 * @extends {React.Component<Props, State>}
 */
export default class UploadFile extends Component {
  /**
   * @type {State}
   */
  state = {
    file: {},
    requestInProgress: false
  }

  /**
   * @param {object} event
   */
  onChangeFile = event => {
    const { files } = event.target
    this.setState({ file: files[0] })
  }

  sendFile = () => {
    const { file } = this.state
    const { handleUpdate, toggleModal, clarifcationId } = this.props
    this.setState({ requestInProgress: true })
    axiosUploadFile(
      'file',
      file,
      null,
      `clarifications/${clarifcationId}/document`,
      'put'
    )
      .then(respose => {
        Notification.success('Archivo enviado correctamente')
        handleUpdate()
      })
      .catch(error => {
        console.error(error)
        Notification.error(
          getProp(
            error,
            'response.data.errors.detail',
            'Ha ocurrido un error enviando el archivo'
          )
        )
      })
      .finally(() => {
        this.setState({
          requestInProgress: false,
          file: {}
        })
        toggleModal()
      })
  }

  render () {
    const { file, requestInProgress } = this.state
    return (
      <div>
        {file.name && (
          <File>
            <h3>Archivo seleccionado</h3>
            <p>{file.name}</p>
          </File>
        )}
        <Content>
          <ContentFile>
            <Text>Selecciona archivo</Text>
            <InputFile type='file' onChange={this.onChangeFile} />
            <Icon className='mdi mdi-upload' />
          </ContentFile>
          <Button
            buttonClass='primary'
            icon='content-save'
            loading={requestInProgress}
            disabled={!file.name || requestInProgress}
            onClick={this.sendFile}
          >
            Guardar
          </Button>
        </Content>
      </div>
    )
  }
}
