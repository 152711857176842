// Libraries
import React, { Component } from 'react'
import Notification from 'cogo-toast'
import styled from 'styled-components'

// Components
import { Title, FlexWrapper, FieldWrapper, MARGIN } from 'css/utils'
import { mediumGrey } from 'css/colors'
import LinkButton from 'shared/linkButton'
import Button from 'shared/button'
import Loader from 'shared/loader'
import ToggleItem from 'shared/toggleItem'
import SelectField from 'shared/selectField'
import Paginator from 'shared/paginator'
import Fields from './fields'
import { COUNTRIES } from 'shared/catalogs'
import {
  getOptions,
  stringifyQuery,
  hasFilters,
  parseQuery,
  getCountryLabel
} from 'utils'

import API from 'api/core'

const FlexEndWrapper = styled(FlexWrapper)`
  justify-content: flex-end;
`
const FilterWrapper = styled(FlexWrapper)`
  width: 60%;
`
const Header = styled.div`
  & p {
    width: 35%;
  }
  & p:nth-child(n + 2) {
    text-align: center;
  }
`
const Content = styled.div`
  padding-bottom: 2rem;
`
const HeaderWrapper = styled(FlexWrapper)`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 1em;
  width: 100%;
  padding: 1rem 1.8rem 1rem;
  box-shadow: 0px 0px 9px 0px rgba(153, 153, 153, 0.1);
  & > p {
    width: 35%;
    color: ${mediumGrey};
    text-transform: uppercase;
    font-weight: bold;
  }
  & > p:nth-child(n + 2) {
    text-align: center;
  }
  & > p:last-child {
    margin: 0 1em 0 3em;
  }
`

/**
 * @typedef {import('api/core').ClarificationTypes} ClarificationType
 * @typedef {import('api/core').ClarificationArea} ClarificationArea
 * @typedef {import('api/core').Pagination} Pagination
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @typedef {{
 * clarificationTypes: Array<ClarificationType>,
 * areas: Array<ClarificationArea>,
 * filters: {country: string, area: string},
 * requestInProgress: boolean,
 * requestingAreas: boolean,
 * page: Pagination
 * }} State
 *
 * @extends {Component<RouteComponentProps, & State>}
 *
 */

export default class ClarificationTypes extends Component {
  /**
   * @type {State}
   */
  state = {
    clarificationTypes: [],
    areas: [],
    page: {
      total_pages: 1,
      total_entries: 0,
      page_number: 1,
      page_size: 1
    },
    filters: {
      country: '',
      area: ''
    },
    requestInProgress: false,
    requestingAreas: false
  }

  componentDidMount = () => {
    this.getClarificationtypes()
  }

  /**
   * @param {object} prevProps
   * @param {object} prevState
   */
  componentDidUpdate = (prevProps, prevState) => {
    const { filters } = this.state
    if (this.props.location.search !== prevProps.location.search) {
      this.getClarificationtypes()
    }
    if (filters.country && filters.country !== prevState.filters.country) {
      this.getClarificationAreas(filters.country)
    }
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  handleChangeValue = event => {
    const { name, value } = event.currentTarget
    this.setState(state => ({
      filters: { ...state.filters, [name]: value }
    }))
  }

  onCleanFilters = () => {
    const { history, location } = this.props
    this.setState(
      {
        filters: {
          country: '',
          area: ''
        }
      },
      () => {
        history.push(location.pathname)
      }
    )
  }

  onFilter = () => {
    const { filters } = this.state
    const {
      history,
      location: { pathname }
    } = this.props
    history.push(pathname + stringifyQuery(hasFilters(filters)))
  }

  getClarificationtypes = async () => {
    const { filters } = this.state
    const { location } = this.props
    const page = parseQuery(location.search).page || 1
    const params = {
      ...hasFilters(filters),
      page
    }
    this.setState({ requestInProgress: true })
    try {
      const { data, page } = await API.Clarifications.ListClarificationTypes(
        params
      )
      this.setState({ clarificationTypes: data, page })
    } catch (error) {
      console.error(error)
      Notification.error(
        'Ha ocurrido un error cargando los tipos de aclaraciones'
      )
    } finally {
      this.setState({ requestInProgress: false })
    }
  }

  /**
   * @param {string} country
   */
  getClarificationAreas = async country => {
    this.setState({ requestingAreas: true })
    try {
      const { data } = await API.Clarifications.ClarificationAreas(country)
      this.setState({ areas: data })
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error cragando las areas')
    } finally {
      this.setState({ requestingAreas: false })
    }
  }

  render () {
    const {
      filters,
      clarificationTypes,
      requestInProgress,
      requestingAreas,
      page,
      areas
    } = this.state
    const areaOptions = getOptions(areas) || []
    return (
      <Content>
        <LinkButton
          url='/clarification'
          icon='arrow-left-thick'
          buttonClass='link bottom'
        >
          Aclaraciones
        </LinkButton>
        <FlexWrapper>
          <Title>Tipos de aclaraciones</Title>
          <LinkButton
            url='/clarification/types/new'
            buttonClass='create'
            icon='plus-circle-outline'
          >
            Crear tipo de aclaración
          </LinkButton>
        </FlexWrapper>
        <FlexEndWrapper>
          <FilterWrapper>
            <FieldWrapper right={MARGIN}>
              <SelectField
                name='country'
                label='País'
                placeholder='Selecciona una opción'
                options={getOptions(COUNTRIES)}
                value={filters.country}
                onChange={this.handleChangeValue}
              />
            </FieldWrapper>
            <FieldWrapper right={MARGIN}>
              <SelectField
                name='area'
                label='Area'
                placeholder='Selecciona una opción'
                options={areaOptions}
                value={filters.area}
                onChange={this.handleChangeValue}
                disabled={!filters.country}
                loading={requestingAreas}
              />
            </FieldWrapper>
            <Button buttonClass='primary' icon='filter' onClick={this.onFilter}>
              Filtrar
            </Button>
            <Button
              buttonClass='clear'
              icon='eraser'
              onClick={this.onCleanFilters}
            >
              Limpiar
            </Button>
          </FilterWrapper>
        </FlexEndWrapper>
        <HeaderWrapper>
          <p>Nombre del formulario</p>
          <p>País</p>
          <p>Área que lo creo</p>
        </HeaderWrapper>
        {requestInProgress ? (
          <Loader />
        ) : (
          clarificationTypes &&
          clarificationTypes.map(({ area, id, name, country, fields }) => {
            return (
              <ToggleItem
                key={id}
                header={
                  <Header>
                    <p>{name}</p>
                    <p>{getCountryLabel(country)}</p>
                    <p>{area}</p>
                  </Header>
                }
              >
                <Fields fields={fields} />
              </ToggleItem>
            )
          })
        )}
        <hr />
        {page && <Paginator totalPages={page.total_pages} />}
      </Content>
    )
  }
}
