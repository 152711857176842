// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'

import Field from './field'
import RenapoIne from 'shared/uploadRenapoIne'

const FieldsContainer = ({
  fields,
  onChangeValidation,
  onChangeDescription,
  invalid,
  onChangeDocument,
  isEditable,
  lead
}) => {
  return (
    <>
      {fields &&
        fields.map(field => {
          // Find out if the field is invalid
          const invalidField = invalid
            ? invalid.find(({ name }) => name === field.name)
            : null
          return (
            <Field
              {...field}
              isValid={invalidField ? false : field.is_valid}
              description={
                invalidField ? invalidField.description : field.description
              }
              onChangeValidation={onChangeValidation}
              onChangeDescription={onChangeDescription}
              onChangeDocument={onChangeDocument}
              isEditable={isEditable}
              key={field.name}
            />
          )
        })}
      {lead.country === 'mx' && (
        <>
          <p className='renapo-title'>Archivos</p>
          <RenapoIne
            mb='20px'
            size='100%'
            leadUid={lead.leadUid}
            leadFiles={lead.leadFiles}
          />
        </>
      )}
    </>
  )
}

FieldsContainer.propTypes = {
  fields: PropTypes.array,
  onChangeValidation: PropTypes.func,
  onChangeDescription: PropTypes.func,
  onChangeDocument: PropTypes.func,
  invalid: PropTypes.array,
  isEditable: PropTypes.bool
}

FieldsContainer.defaultProps = {
  invalid: []
}

export default FieldsContainer
