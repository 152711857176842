// Libraries
import React, { Fragment } from 'react'

const Audio = ({ entity, notes, onClick }) => {
  let entityLabel = entity ? (
    <label className='label file-content-help'>{`Entidad: ${entity}`}</label>
  ) : null

  let notesLabel = notes ? (
    <label className='label file-content-help'>{`Referencia: ${notes}`}</label>
  ) : null

  return (
    <Fragment>
      <span className='label'>Conferencia de audio</span>
      {entityLabel}
      {notesLabel}
      <a onClick={onClick}>
        <i className='mdi mdi-play-circle mdi-48px' />
      </a>
    </Fragment>
  )
}

export default Audio
