import React from 'react'
import { withFormik, Form, Field } from 'formik'
import Notification from 'cogo-toast'
import styled from 'styled-components'
import Button from 'shared/button'
import ErrorLabel from 'shared/ErrorLabel'
import SelectField from 'shared/selectField'
import { INVOICE_ONE_STATUS } from 'shared/catalogs'
import { FieldWrapper } from 'css/utils'
import { getLabel } from 'utils'
import * as Yup from 'yup'
import API from 'api/core'
import { withCurrentUser } from 'contexts/user'

/**
 * @constant
 */
const validationSchema = Yup.object().shape({
  cufe: Yup.string(),
  status: Yup.string()
})

const WrapperButtons = styled.div`
  border-top: 1px solid #dbdbdb;
  background-color: whitesmoke;
  padding: 1.25rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  margin-bottom: -1.25rem;
  margin-top: 1.25rem;
`
/**
 * @typedef {import('formik').FormikProps<FomikValues>} FormikProps
 * @typedef {{
 * cufe: string,
 * status: string
 * }} FomikValues
 * @typedef {{
 * toggleModal: function,
 * }} PropsEditCufeStatus
 * @param {PropsEditCufeStatus & FormikProps} props
 */
const EditCufeStatus = ({
  toggleModal,
  handleSubmit,
  isSubmitting,
  values,
  setFieldValue,
  errors
}) => {
  /**
   * @param {string} cufeValue
   */
  const validateCufe = (cufeValue = '') => {
    if (!cufeValue) return ''
    if (!/^[0-9A-Za-z-]+$/gm.test(cufeValue)) {
      return 'Cufe no válida'
    }
  }
  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  const onChangeValue = event => {
    const { value, name } = event.target
    setFieldValue(name, value)
  }

  return (
    <Form onSubmitCapture={handleSubmit}>
      <FieldWrapper>
        <label htmlFor='cufe'>Cufe</label>
        <Field
          name='cufe'
          id='cufe'
          placeholder='234281'
          style={{ fontSize: '1em' }}
          validate={validateCufe}
          autoComplete='off'
        />
        {errors.cufe && <ErrorLabel message={errors.cufe} />}
      </FieldWrapper>
      <FieldWrapper>
        <SelectField
          name='status'
          label='Estado'
          placeholder='Selecciona una opción'
          value={values.status}
          options={INVOICE_ONE_STATUS}
          onChange={onChangeValue}
        />
      </FieldWrapper>
      <WrapperButtons>
        <Button icon='cancel' buttonClass='secondary' onClick={toggleModal}>
          Cancelar
        </Button>
        <Button
          type='submit'
          testId='edit-cufe-status'
          icon='send'
          buttonClass='primary right'
          disabled={Boolean(errors.cufe) || isSubmitting}
          loading={isSubmitting}
        >
          Enviar
        </Button>
      </WrapperButtons>
    </Form>
  )
}
export default withCurrentUser(
  withFormik({
    mapPropsToValues: props => {
      const { cufeAndStatus } = props
      return {
        cufe: cufeAndStatus.cufe || 'N/A',
        status: getLabel(INVOICE_ONE_STATUS, cufeAndStatus.status) || ''
      }
    },
    validationSchema,
    handleSubmit: async (values, { setSubmitting, props }) => {
      const { id, user, updatedData, toggleModal } = props
      const cufeStatus = {
        values: {
          cufe: values.cufe || 'N/A',
          status: values.status,
          updated_by: user.email
        }
      }
      setSubmitting(true)
      try {
        const { status = '', message = '' } = await API.Invoices.UpdateCufeStatus(
          id,
          cufeStatus
        )
        if (status === 'success') {
          updatedData({ cufe: values.cufe, status: values.status })
          Notification.success(message)
          return
        }
        Notification.error(
          'Ocurrio un error al actualizar la factura. Intenta de nuevo.'
        )
      } catch (error) {
        console.error(error)
        Notification.error(
          'Ocurrio un error al actualizar la factura. Intenta de nuevo.'
        )
      } finally {
        setSubmitting(false)
        toggleModal()
      }
    }
  })(EditCufeStatus)
)
