// Libraries
import React from 'react'
import styled, { css } from 'styled-components'

// Components
import { Title } from 'css/utils'
import { white, mediumGrey, darkBlack } from 'css/colors'
import IconButton from 'shared/iconButton'
import { getCountryLabel } from 'utils'

const WrapperDetails = styled.div`
  background: ${white};
  width: 350px;
  position: absolute;
  right: -353px;
  top: 0;
  height: 100vh;
  padding: 4rem 1rem 1rem 1rem;
  transition: 0.5s;
  box-shadow: -3px 1px 8px 0px rgba(217, 217, 217, 1);
  overflow: scroll;
  position: fixed;
  z-index: 5;
  ${props =>
    props.isOpen &&
    css`
      right: 0;
      transition: 0.5s;
    `}
  & > h1 {
    border-bottom: 1px solid ${mediumGrey};
    font-size: 18px;
    padding-bottom: 1rem;
    margin: 0;
  }
`
const Account = styled.div`
  border-bottom: 1px solid ${mediumGrey};
  padding: 0.5rem 0;
  margin-bottom: 1.2rem;
`
const Header = styled.h2`
  font-size: 18px;
  color: ${darkBlack};
  margin-bottom: 0.7rem;
`
const SubHeader = styled.p`
  font-size: 12px;
  color: ${mediumGrey};
`

/**
 * @typedef {import('api/core').BankAccount} BankAccount
 * @typedef {{
 * bankAccount: BankAccount | null,
 * onCloseDetails: function,
 * isOpen: boolean,
 * }} PropsBankAccountDetails
 *
 * @param {PropsBankAccountDetails} props
 */
const BankAccountDetails = ({ bankAccount, isOpen, onCloseDetails }) => {
  if (!bankAccount) return null
  const {
    description,
    clabe,
    account_number: accountNumber,
    company: { name: companyName, country: comapnyCountry },
    bank: { name: bankName, id: bankId, country: bankCountry }
  } = bankAccount
  return (
    <div>
      {bankAccount && (
        <WrapperDetails isOpen={isOpen}>
          <IconButton
            icon='close'
            tooltip='Cerrar'
            className='bottom text-left'
            onClick={onCloseDetails}
          />
          <Title>Detalles da la cuenta de banco</Title>
          <Account>
            <SubHeader>Descripción</SubHeader>
            <Header>{description}</Header>
            <SubHeader>Clabe</SubHeader>
            <Header>{clabe}</Header>
            <SubHeader>Número de cuenta</SubHeader>
            <Header>{accountNumber}</Header>
          </Account>
          <Account>
            <SubHeader>Nombre de la compañia</SubHeader>
            <Header>{companyName}</Header>
            <SubHeader>País</SubHeader>
            <Header>{getCountryLabel(comapnyCountry)}</Header>
          </Account>
          <Account>
            <SubHeader>Nombre del banco</SubHeader>
            <Header>{bankName}</Header>
            <SubHeader>País</SubHeader>
            <Header>{getCountryLabel(bankCountry)}</Header>
            <SubHeader>Id del banco</SubHeader>
            <Header>{bankId}</Header>
          </Account>
        </WrapperDetails>
      )}
    </div>
  )
}
export default BankAccountDetails
