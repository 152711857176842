/* eslint-disable */
// @ts-nocheck
// Libraries
import React, {Component} from 'react'
import Notification from 'cogo-toast'
import styled from 'styled-components'
import pickBy from 'lodash/pickBy'

// Components
import ClarificationTable from './clarificationTable'
import {Title, FlexWrapper, FieldWrapper, MARGIN} from 'css/utils'
import LinkButton from 'shared/linkButton'
import Button from 'shared/button'
import SelectField from 'shared/selectField'
import Paginator from 'shared/paginator'
import Loader from 'shared/loader'
import {downloadReport} from './utils'
import {COUNTRIES, AREA_OPTIONS} from 'shared/catalogs'
import {getOptions, stringifyQuery, parseQuery, hasFilters} from 'utils'

import API from 'api/core'

const FlexEndWrapper = styled(FlexWrapper)`
  justify-content: flex-end;
`
const FilterWrapper = styled(FlexWrapper)`
  width: 100%;
  margin-top: 1.5rem;
`

const STATUS_OPTIONS = [
  {id: 'new', label: 'Nuevas'},
  {id: 'in_progress', label: 'En proceso'},
  {id: 'canceled', label: 'Canceladas'},
  {id: 'approved', label: 'Aprobadas'}
]

/**
 * @typedef {import('api/core').ListClarificationType} ListClarificationType
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @typedef {import('api/core').Pagination} Pagination
 * @typedef {import('api/core').ClarificationArea} ClarificationArea
 * @typedef {{
 * clarifcations: Array<ListClarificationType>,
 * clarifcationTypes: [],
 * areas: Array<ClarificationArea>,
 * pagination: Pagination,
 * filters: {country?: string, area?: string, status?: string, type_id?: string },
 * requestInProgress: boolean,
 * requestingAreas: boolean
 * requestingTypes: boolean
 * }} State
 *
 * @extends {Component<RouteComponentProps, & State>}
 */

export default class Clarification extends Component {
  /**
   * @type {State}
   */
  state = {
    clarifcations: [],
    clarifcationTypes: [],
    areas: [],
    pagination: {
      total_pages: 0,
      total_entries: 0,
      page_number: 0,
      page_size: 0
    },
    filters: {
      country: '',
      area: '',
      status: 'new',
      type_id: '',
      creator_area: ''
    },
    downloading: false,
    requestInProgress: false,
    requestingAreas: false,
    requestingTypes: false
  }

  componentDidMount = () => {
    const {search} = this.props.location
    const {country, area, status, type_id} = parseQuery(search)
    this.setState(
      {
        filters: {
          country: country || '',
          area: area || '',
          status: status || 'new',
          type_id: type_id || '',
          creator_area: type_id || ''
        }
      },
      this.getClarifications
    )
  }

  /**
   * @param {object} prevProps
   * @param {object} _
   */
  componentDidUpdate = (prevProps, _) => {
    if (this.props.location.search !== prevProps.location.search) {
      this.getClarifications()
    }
  }

  onCleanFilters = () => {
    const {history, location} = this.props
    this.setState(
      {
        filters: {
          country: '',
          area: '',
          status: 'new',
          type_id: '',
          creator_area: ''
        }
      },
      () => {
        history.push(location.pathname)
      }
    )
  }

  onFilter = () => {
    const {filters} = this.state
    const {
      history,
      location: {pathname}
    } = this.props
    history.push(pathname + stringifyQuery(hasFilters(filters)))
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  handleChangeValue = event => {
    const {name, value} = event.currentTarget
    this.setState(state => ({
      filters: {...state.filters, [name]: value}
    }))
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  handleChangeValueArea = event => {
    const {name, value} = event.currentTarget
    this.setState(
      state => {
        if (state.filters.type_id) {
          return {
            filters: {
              ...state.filters,
              type_id: '',
              [name]: value
            }
          }
        }
        return {
          filters: {
            ...state.filters,
            [name]: value
          }
        }
      },
      () => this.getClarificationsTypes(value)
    )
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  handleChangeValueCountry = event => {
    const {name, value} = event.currentTarget
    this.setState(
      state => {
        if (state.filters.type_id || state.filters.area) {
          return {
            filters: {
              ...state.filters,
              type_id: '',
              area: '',
              [name]: value
            }
          }
        }
        return {
          filters: {
            ...state.filters,
            [name]: value
          }
        }
      },
      () => this.getClarificationAreas(value)
    )
  }

  /**
   * @param {string} id
   */
  handleDetails = id => {
    const {history} = this.props
    history.push(`/clarification/${id}`)
  }

  /**
   * @param {string} country
   */
  getClarificationAreas = async country => {
    this.setState({requestingAreas: true})
    const params = {
      'with-clarifications': 'true'
    }
    try {
      const {data} = await API.Clarifications.ClarificationAreas(
        country,
        params
      )
      this.setState({areas: data})
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error caragando las areas')
    } finally {
      this.setState({requestingAreas: false})
    }
  }

  getClarifications = async () => {
    const {filters} = this.state
    const {location} = this.props
    const page = parseQuery(location.search).page || 1
    const params = {
      ...hasFilters(filters),
      page
    }
    this.setState({requestInProgress: true})
    try {
      const {data, page} = await API.Clarifications.GetClarifications(params)
      this.setState({
        clarifcations: data,
        pagination: page
      })
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error al listar las aclaraciones')
    } finally {
      this.setState({requestInProgress: false})
    }
  }

  /**
   * @param {string} type
   */
  getClarificationsTypes = async type => {
    this.setState({requestingTypes: true})
    try {
      const {data} = await API.Clarifications.ListClarificationTypes({
        area: type
      })
      this.setState({clarifcationTypes: data})
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error al listar las aclaraciones')
    } finally {
      this.setState({requestingTypes: false})
    }
  }

  /**
   * @param {bollean} status
   */
  downloading = status => {
    this.setState({downloading: status})
  }

  downloadClarificationReport = () => {
    const {filters} = this.state
    const params = {
      type_id: filters.type_id,
      status: filters.status,
      creator_area: filters.creator_area
    }
    const filteredParams = pickBy(params, filter => filter.length > 0)
    downloadReport(filteredParams, this.downloading)
  }

  render() {
    const {
      filters,
      requestingAreas,
      areas,
      clarifcations,
      requestInProgress,
      pagination,
      clarifcationTypes,
      requestingTypes,
      downloading
    } = this.state
    const areaOptions = getOptions(areas) || []
    const opstionsAreas = getOptions(AREA_OPTIONS)

    return (
      <div>
        <FlexWrapper>
          <Title>Aclaraciones</Title>
          <LinkButton
            url='/clarification/types'
            buttonClass='primary'
            icon='format-list-bulleted'
          >
            Tipos de aclaraciones
          </LinkButton>
        </FlexWrapper>
        <FlexEndWrapper>
          <FilterWrapper>
            <FieldWrapper right={MARGIN} width='60%'>
              <SelectField
                name='status'
                label='Estado'
                placeholder='Selecciona una opción'
                options={getOptions(STATUS_OPTIONS) || []}
                value={filters.status}
                onChange={this.handleChangeValue}
              />
            </FieldWrapper>
            <FieldWrapper right={MARGIN} width='65%'>
              <SelectField
                name='country'
                label='País'
                placeholder='Selecciona una opción'
                options={getOptions(COUNTRIES)}
                onChange={this.handleChangeValueCountry}
                value={filters.country}
              />
            </FieldWrapper>
            <FieldWrapper right={MARGIN}>
              <SelectField
                name='area'
                label='Área'
                placeholder='Selecciona una opción'
                options={areaOptions}
                value={filters.area}
                onChange={this.handleChangeValueArea}
                disabled={!filters.country}
                loading={requestingAreas}
              />
            </FieldWrapper>
            <FieldWrapper right={MARGIN}>
              <SelectField
                name='type_id'
                label='Tipo'
                placeholder='Selecciona una opción'
                options={getOptions(clarifcationTypes) || []}
                value={filters.type_id}
                disabled={!filters.area}
                onChange={this.handleChangeValue}
                loading={requestingTypes}
              />
            </FieldWrapper>
            <FieldWrapper right={MARGIN}>
              <SelectField
                name='creator_area'
                label='Área del colaborador'
                placeholder='Selecciona una opción'
                options={opstionsAreas}
                value={filters.creator_area}
                onChange={this.handleChangeValue}
              />
            </FieldWrapper>
            <Button
              buttonClass='primary'
              icon='filter'
              testId='filter-clarification'
              onClick={this.onFilter}
            >
              Filtrar
            </Button>
            <Button
              buttonClass='clear'
              icon='eraser'
              onClick={this.onCleanFilters}
            >
              Limpiar
            </Button>
            <Button
              buttonClass='coreButton button-upload'
              icon='download'
              tooltip={filters.type_id ? null : 'Selecciona tipo'}
              disabled={!filters.type_id || downloading}
              loading={downloading}
              onClick={this.downloadClarificationReport}
            >
              Generar reporte
            </Button>
          </FilterWrapper>
        </FlexEndWrapper>
        {requestInProgress && pagination ? (
          <Loader />
        ) : (
          <ClarificationTable
            clarifcations={clarifcations}
            totalEntries={pagination && pagination.total_entries}
            handleDetails={this.handleDetails}
          />
        )}
        <br />
        {pagination && <Paginator totalPages={pagination.total_pages} />}
      </div>
    )
  }
}
