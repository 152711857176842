// @ts-nocheck
// libraries
import React, { Component } from 'react'
import moment from 'moment'
import Notification from 'cogo-toast'

// Components
import FormNewFlowPayment from './form'
import CountryButtons from 'shared/countryButtons'
import { getOptions, absolutify } from 'utils'
import API from 'api/core'
import LinkButton from 'shared/linkButton'

/**
 * @typedef {object} Catalogs
 * @property {Array<Option>} flow_types
 * @property {Array<Option>} areas
 * @property {Array<Option>} payment_methods
 * @property {Array<Option>} companies
 * @property {Array<Option>} subsidiaries
 */

/**
 * @typedef {Object} State
 * @property {Catalogs} catalogues
 * @property {string} country
 * @property {boolean} requestingBank
 * @property {boolean} searching
 * @property {Array<import('api/core').PaymentReceptor>} paymentReceptors
 * @property {Array<Option>} bankAccounts
 *
 * @typedef {{id: string | number, label: string}} Option
 *
 * @typedef {import('react-router-dom').RouteComponentProps} Props
 *
 * @extends {React.Component<Props, State>}
 */
export default class NewFlowPayment extends Component {
  /**
   * @type {State}
   */
  state = {
    catalogues: {
      flow_types: [],
      areas: [],
      payment_methods: [],
      companies: [],
      subsidiaries: []
    },
    paymentReceptors: [],
    bankAccounts: [],
    country: '',
    requestingBank: false,
    searching: false
  }

  /**
   *
   * @param {Props} _
   * @param {State} prevState
   */

  componentDidUpdate (_, prevState) {
    const { country } = this.state
    if (country !== prevState.country) {
      this.getCatalogues()
      this.getPaymentReceptors()
    }
  }

  handleSearch = async value => {
    this.setState({ searching: true })
    const params = {
      name: value
    }
    try {
      const { data } = await API.Accounting.SearchExpenses(params)
      this.setState({
        paymentReceptors: data
      })
    } catch (error) {
      console.error(error)
    } finally {
      this.setState({ searching: false })
    }
  }

  /**
   * Get bank accounts of api
   * @param {string} company
   * @returns {Promise<void>}
   */
  getBankAccounts = async company => {
    this.setState({ requestingBank: true })
    try {
      const { data } = await API.Accounting.GetBankAccounts({ company })
      this.setState({ bankAccounts: getOptions(data) })
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error cargando las cuentas de banco')
    } finally {
      this.setState({ requestingBank: false })
    }
  }

  /**
   * Get payment receptors list of api
   * @returns {Promise<void>}
   */
  getPaymentReceptors = async () => {
    const { country } = this.state
    if (!country) return
    try {
      const { data } = await API.Accounting.GetPaymentReceptors({
        countries: country
      })
      this.setState({
        paymentReceptors: data
      })
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un errorcargando receptores de pago')
    }
  }

  /**
   * Get all catalogs of api
   * @returns {Promise<void>}
   */
  getCatalogues = async () => {
    const { country } = this.state
    if (!country) return

    try {
      const {
        areas,
        companies,
        subsidiaries,
        payment_methods: paymentMethods,
        flow_types: flowTypes
      } = await API.Accounting.GetCatalogues({ country })
      this.setState({
        catalogues: {
          areas: getOptions(areas),
          companies: getOptions(companies),
          subsidiaries: getOptions(subsidiaries),
          payment_methods: paymentMethods.map(method => ({
            id: method,
            label: method
          })),
          flow_types: getOptions(flowTypes)
        }
      })
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error cargando los catálogos')
    }
  }

  /**
   * create new flow payment
   * @param {import('./form').Values} values
   * @returns {Promise} Promise
   */

  createFlowPayment = async values => {
    const [currency, value] = values.amount.split(' ')
    const data = {
      payment_receptor_id: values.paymentReceptor,
      status: values.status,
      concept: values.concept,
      flow_date: moment(values.flowDate).format('YYYY-MM-DD'),
      invoice_date: moment().format('YYYY-MM-DD'),
      amount: values.floatAmount || absolutify(value),
      invoice_number: values.invoiceNumber,
      flow_type_id: values.flowType,
      payment_method: values.paymentMethod,
      area_id: values.area,
      subsidiary_id: values.subsidiary,
      bank_account_id: values.bankAccounts,
      bank_details: values.bank,
      currency: values.currency || currency,
      flow_document_pdf: values.filePDF ? values.filePDF.content : null,
      flow_document_xml: values.fileXML ? values.fileXML.content : null
    }
    try {
      const response = await API.Accounting.CreateFlowPayment(data)
      if (response.status === 'success') {
        Notification.success('Flujo creado correctamente')
        this.props.history.push('/accounting/flow-payment')
      } else {
        Notification.error(response.errors.detail)
      }
    } catch (error) {
      console.error(error)
    }

    return true
  }

  /**
   * @param {String} country
   */
  changeCountry = country => {
    this.setState({ country })
  }

  render () {
    const {
      country,
      bankAccounts,
      requestingBank,
      paymentReceptors,
      searching,
      catalogues: {
        areas,
        companies,
        subsidiaries,
        flow_types: flowTypes,
        payment_methods: paymentMethods
      }
    } = this.state
    return (
      <div>
        <LinkButton
          url='/accounting/flow-payment'
          icon='arrow-left-thick'
          buttonClass='link bottom'
        >
          Egresos
        </LinkButton>
        <div className='newFlowPayment'>
          <h1 className='title'>Nuevo egreso</h1>
          <div style={{ marginBottom: '4rem' }}>
            <CountryButtons
              onChangeCounty={this.changeCountry}
              country={country}
            />
          </div>
          <FormNewFlowPayment
            getBankAccounts={this.getBankAccounts}
            requestingBank={requestingBank}
            flowTypes={flowTypes}
            areas={areas}
            companies={companies}
            subsidiaries={subsidiaries}
            paymentMethods={paymentMethods}
            bankAccounts={bankAccounts}
            country={country}
            requestInProgress={false}
            createFlowPayment={this.createFlowPayment}
            paymentReceptors={paymentReceptors}
            handleSearch={this.handleSearch}
            searching={searching}
          />
        </div>
      </div>
    )
  }
}
