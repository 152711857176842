// Libraries
import React, { Component, createContext } from 'react'
import store from 'store'

// Components
import { decode } from 'utils'

const { Provider, Consumer } = createContext({})

/**
 * @typedef {{
 * area?: string,
 * avatar?: string,
 * branch_office?: string,
 * country?: string,
 * email?: string,
 * employee_id?: string
 * job_title?: string,
 * level?: string,
 * name?: string,
 * nickname?: string,
 * role?: string,
 * staff?: string
 * }} User
 * @typedef {{user: User, role: string}} State
 *
 * @extends {Component<{children: React.ReactNode}, & State>}
 */
export class UserProvider extends Component {
  /**
   * @type {State}
   */
  state = {
    user: {},
    role: ''
  }

  componentDidMount = () => {
    this.hasToken()
  }

  hasToken = () => {
    const parsedToken = decode(store.get('CORE_AUTH_TOKEN'))
    const role = store.get('ROLE')
    if (parsedToken && parsedToken.meta) {
      this.setState({ user: parsedToken.meta, role: role })
    }
  }

  render () {
    const { children } = this.props
    return <Provider value={this.state}>{children}</Provider>
  }
}

export const withCurrentUser = Component => props => (
  <Consumer>{context => <Component {...props} {...context} />}</Consumer>
)
