/* eslint-disable */
import React, {useEffect, useState} from 'react'
import queryString from 'query-string'
import _pickBy from 'lodash/pickBy'
import _isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import Notification from 'cogo-toast'
import accounting from 'accounting'
import axios from 'axios'
import store from 'store'

import Resuelve from 'shared/svgComponents/resuelve'
import Check from 'shared/svgComponents/check'
import LaTasa from 'shared/svgComponents/laTasa'
import ResuelveTuDeuda from 'shared/svgComponents/resuelve2'

import {
  COUNTRIES,
  CURRENCIES,
  CHARGES_STATUS,
  LOCALES,
  DOC_TYPES,
  MIME_TYPES
} from 'shared/catalogs'

const areaPermissions = {
  finances: ['edit'],
  engineering: ['edit'],
  credit: ['edit']
}
/**
 * @param {string} role
 */
const getPermissions = (role = '') => areaPermissions[role] || []

/**
 * get label country flow payments
 * @constant
 * @param {string} value
 */
export const getCountryLabel = value =>
  (COUNTRIES.find(country => country.value === value) || {}).label

/**
 * @constant
 * @param {string} value
 */
export const getCurrencyLabel = value =>
  (CURRENCIES.find(currency => currency.id === value) || {}).label

/**
 * @constant
 * @param {string | number} value
 * @param {Array<{id?: string | number, name?: string, label?: string}>} options
 * @returns {string | object}
 */
export const getLabel = (options, value) =>
  (options.find(label => label.id === value) || {}).label

/**
 * @constant
 * @param {string} value
 */
export const getIncomeStatus = value =>
  (CHARGES_STATUS.find(status => status.id === value) || {}).label

/**
 * @param {string | Date} date
 */
export const formatDate = date => moment(date).format('DD [de] MMMM [de] YYYY')

/**
 * @param {string | Date | undefined} date
 */
export const formatDateToSend = date => moment(date).format('YYYY-MM-DD')

/**
 * @param {Date} date
 */
export const formatDateISO = date =>
  moment(date, 'DD/MM/YYYY').format('DD [de] MMMM [de] YYYY')

/**
 * @param {Date | undefined} date
 */
export const dateToIso = date => moment(date).toISOString()

/**
 * @param {Date} date
 */
export const diffFromNowHumanize = date => {
  const utcDate = moment.utc(date, 'YYYY-MM-DD HH:mm')
  return utcDate.fromNow()
}

/**
 * @param {object} filters
 */
export const hasFilters = filters =>
  _pickBy(filters, filter => filter.length > 0)

/**
 * @param {string} num
 */
export const absolutify = num => Math.abs(accounting.unformat(num))

/**
 * @param {string} params
 */
export const parseQuery = params => queryString.parse(params)

/**
 * @param {string} key
 * @param {object} file
 * @param {string} [put]
 * @param {string | null} [apiBank]
 * @param {string | null} [apiCore]
 */
export const axiosUploadFile = (key, file, apiBank, apiCore, put) => {
  const url = apiBank
    ? `${process.env.API_BANK_SERVER + process.env.API_PREFIX_BANK}/${apiBank}`
    : `${process.env.API_CORE_SERVER + process.env.API_PREFIX_CORE}/${apiCore}`

  const data = new window.FormData()
  data.append(key, file)
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: store.get('CORE_AUTH_TOKEN')
    }
  }
  if (put) {
    return axios.put(url, data, config)
  } else {
    return axios.post(url, data, config)
  }
}

/**
 * @constant
 * @typedef {{content: string, name: string, type: string}} File
 *  @param {import('react-dropzone').DropFileEventHandler} file
 * @returns {Promise<File>}
 */
export const toBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader()
    reader.readAsDataURL(file)

    reader.onloadend = e => {
      resolve({
        content: e.target.result,
        name: file.name || '',
        type: file.type
      })
    }

    reader.onerror = error => {
      reject(error)
      reader.abort()
    }
  })
}

/**
 * @readonly
 * @param {Array<{id?: string | number, name?: string, value?: string, description?: string, label?: string, uid?: string}>} options
 * @returns <Array<{id: string | number, label: string}>>
 */
export const getOptions = options => {
  if (!options) return []
  return options.map(option => {
    return {
      id: option.id || option.value || '' || option.uid,
      label: option.name || option.description || option.label || ''
    }
  })
}

/**
 * @readonly
 * @param {Array<{id?: string | number, name?: string}>} options
 * @returns <Array<{id: string | number, label: string}>>
 */
export const getOptionsCompanies = options => {
  if (!options) return []
  return options.map(option => {
    return {
      id: option.name,
      label: option.name
    }
  })
}

/**
 * @param {string[]} incomeTypes
 * @returns {Array<{id: string, label: string}>}
 */
export const incomeTypeOptions = (incomeTypes = []) => {
  return incomeTypes.filter(Boolean).map(incomeType => {
    return {
      id: incomeType,
      label: incomeType
    }
  })
}

/**
 * @param {Number} amount
 * @param {String} currency
 */
export const formatCurrency = (amount, currency) => {
  return new Intl.NumberFormat(LOCALES[currency], {
    style: 'currency',
    currency: currency
  }).format(amount)
}

/**
 * @param {Object} params
 */
export const stringifyQuery = params => {
  const _filters = _pickBy(params, filter => {
    if (typeof filter === 'string') return filter.length > 0
    return true
  })
  // Add the query params
  return !_isEmpty(_filters)
    ? `?${queryString.stringify(_filters, {arrayFormat: 'bracket'})}`
    : ''
}

/**
 * Por confrmar la structura correcta de esta data
 * @typedef {import('api/core').Client} Client
 * @typedef {{name: string, id: number, email: string}} PersonalData
 * @param {Array<Client>} data
 * @returns {Array<PersonalData>}
 */
export const personalData = data => {
  return data.map(
    ({
      id,
      name,
      email,
      first_surname: firstSurname,
      second_surname: secondSurname
    }) => {
      return {
        name: `${name} ${firstSurname || ''} ${secondSurname || ''}`.trim(),
        id,
        email
      }
    }
  )
}

/**
 * @param {string} url
 * @param {string} name
 */
export const getQueryParam = (url, name) => {
  const param = url.match(new RegExp('[?&]' + name + '=([^&#]*)'))
  return param && param[1]
}

/**
 * @param {string} url
 */
export const getFileName = url => {
  if (!url) return
  const expirationDate = getQueryParam(url, 'Expires')
  const name = expirationDate
    ? url.replace(/^.*[\\\/]/, '').split('?') //eslint-disable-line
    : []
  return name && name[0]
}

export const useGetData = (api, dependencies) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    api
      .then(response => {
        setData(response.data)
      })
      .catch(error => {
        console.error(error)
        Notification.error('Ha ocurrido un errror cargando la información')
      })
      .finally(() => {
        setLoading(false)
      })
  }, dependencies)

  return [data, loading]
}

export const decode = token => {
  const data = token.split('.')[0]

  const decoded = decodeURIComponent(
    Array.prototype.map
      .call(
        window.atob(data.replace(/_/g, '/').replace(/-/g, '+')),
        c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      )
      .join('')
  )

  return JSON.parse(decoded)
}

/**
 * getProp utility - an alternative to lodash.get
 * @param {Object} object
 * @param {String|Array} path
 * @param {*} defaultVal
 */
export function getProp(object, path, defaultVal = undefined) {
  if (object === undefined || object === null) return defaultVal

  const _path = Array.isArray(path)
    ? path
    : path.split('.').filter(i => i.length)

  if (!_path.length) {
    return object === undefined ? defaultVal : object
  }

  return getProp(object[_path.shift()], _path, defaultVal)
}

/**
 * Download a file with a given name
 * @param {string | null} url
 * @param {string?} name
 */
export const downloadCSV = async (url, name) => {
  const fileName = name || getFileName(url)
  const response = await fetch(url) //eslint-disable-line
  const data = await response.text()
  const blob = new Blob([data], {type: 'data:text/csv;charset=utf-8,'}) //eslint-disable-line
  const blobURL = window.URL.createObjectURL(blob)

  // Create new tag for download file
  const anchor = document.createElement('a')
  anchor.download = fileName
  anchor.href = blobURL
  anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(
    ':'
  )
  anchor.click()

  // Remove URL.createObjectURL. The browser should not save the reference to the file.
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    URL.revokeObjectURL(blobURL)
  }, 100)
}

/**
 * @param {string} data
 */
export const downloadFormatCsv = data => {
  const element = document.createElement('a')
  element.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data)
  element.target = '_blank'
  element.download = 'formato.csv'
  element.click()
}

/**
 * @param {string} data
 * @param {string} name
 */
export const downloadXml = (data, name) => {
  const element = document.createElement('a')
  element.href = 'data:text/xml;charset=utf-8,' + encodeURIComponent(data)
  element.target = '_blank'
  element.download = `factura_${name}.xml`
  element.click()
  element.remove()
}

/**
 * @param {string} api
 */
export const getFormatCsv = api => {
  const url = `${process.env.API_CORE_SERVER +
    process.env.API_PREFIX_CORE}${api}`
  const fetchParams = {
    method: 'get',
    headers: {
      'content-type': 'text',
      Authorization: store.get('CORE_AUTH_TOKEN'),
      'X-Requested-With': 'XMLHttpRequest'
    }
  }
  fetch(url, fetchParams) //eslint-disable-line
    .then(response =>
      response.text().then(text => {
        downloadFormatCsv(text)
      })
    )
    .catch(error => {
      console.error(error)
      Notification.error('Ocurrio un error al descargar el formato')
    })
}

/**
 * @param {string} url
 */
export const validateUrl = url => {
  const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
  return regexp.test(url)
}

/**
 * @param {string} value
 */
export const validateMoney = value => {
  const rex = new RegExp(/(\d.+)\s(MXN|COP|EUR)/)
  return rex.exec(value)
}

/**
 * @param {string} role
 * @param {string} resource
 */
export const hasPermissions = (role = '', resource = '') =>
  getPermissions(role).includes(resource)

/**
 * @param {string} url
 * @param {string?} name
 */
export const downloadFile = async (url, name) => {
  if (!canDownload(url)) {
    Notification.info('El archivo expiró')
  }
  const fileName = name || getFileName(url) || 'archivo'
  const mimeType = getMimeType(url)
  const response = await fetch(url)
  const data = await response.blob()
  const newBlob = new Blob([data], {
    type: MIME_TYPES[mimeType]
  })
  const blobURL = window.URL.createObjectURL(newBlob)
  const anchor = document.createElement('a')
  anchor.download = fileName
  anchor.href = blobURL
  anchor.click()

  // For Firefox it is necessary to delay revoking the ObjectURL
  setTimeout(() => {
    URL.revokeObjectURL(blobURL)
  }, 100)
}

/**
 * @param {string} url
 */
export const getMimeType = url => {
  const extension = url.match(/\.([a-zA-Z\d]+)\?/)
  const extensionType = extension ? extension[1] : 'default'

  return DOC_TYPES[extensionType]
}

/**
 * @param {string} url
 */
export const canDownload = url => {
  if (!url) return
  const expirationDate = getQueryParam(url, 'Expires')
  return moment.unix(Number(expirationDate)).isSameOrAfter(moment())
}

/**
 * @param {string} productLine
 */
export const LogoByProductLine = productLine => {
  switch (productLine) {
    case 'rtd':
      return <Resuelve />
    case 'check':
      return <Check />
    case 'la_tasa':
      return <LaTasa />
    case 'credito':
      return <ResuelveTuDeuda />
    default:
      return <ResuelveTuDeuda />
  }
}
