// Libraries
import React from 'react'

// Components
import { SubTitle } from './styles'
import { formatCurrency } from 'utils'

/**
 * @typedef {import('api/core').TypeInvoiceDetails} TypeInvoiceDetails
 * @typedef {{
 * invoice: TypeInvoiceDetails
 * }} PropsDetails
 * @param {PropsDetails} props
 */
const Details = ({ invoice }) => {
  return (
    <div>
      <SubTitle>Detalles</SubTitle>
      <table className='table'>
        <thead>
          <tr>
            <th>Descripción</th>
            <th>Cantidad</th>
            <th>Precio unitario</th>
            <th>Monto total</th>
          </tr>
        </thead>
        <tbody>
          {invoice.details &&
            invoice.details.map(
              (
                { amount, description, quantity, unit_price: unitPrice },
                index
              ) => {
                return (
                  <tr key={index}>
                    <td>{description}</td>
                    <td>{quantity}</td>
                    <td>{formatCurrency(+unitPrice, invoice.currency)}</td>
                    <td>{formatCurrency(+amount, invoice.currency)}</td>
                  </tr>
                )
              }
            )}
        </tbody>
      </table>
    </div>
  )
}
export default Details
