// Libraries
import React, { Component } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { withCurrentUser } from 'contexts/user'

// Menu items as object
const MENU_ITEMS = [
  {
    title: 'Admin',
    route: '/contract-requests/admin',
    icon: 'mdi mdi-tune',
    visible: 'admin'
  },
  {
    title: 'Clientes',
    route: '/search-clients',
    icon: 'mdi mdi-account-multiple-outline',
    visible: 'user'
  },
  {
    title: 'Solicitudes pendientes',
    route: '/contracts/pending',
    icon: 'mdi mdi-check',
    visible: 'user'
  },
  {
    title: 'Solicitudes revisadas',
    route: '/contract-requests/reviewed',
    icon: 'mdi mdi-check-all',
    visible: 'user'
  },
  {
    title: 'Ingresos',
    route: '/accounting/income',
    icon: 'mdi mdi-home-currency-usd',
    visible: 'user'
  },
  {
    title: 'Egresos',
    route: '/accounting/flow-payment',
    icon: 'mdi mdi-cash-multiple',
    visible: 'user'
  },
  {
    title: 'Aclaraciones',
    route: '/clarification',
    icon: 'mdi mdi-comment-text-multiple-outline',
    visible: 'user'
  },
  {
    title: 'Facturas',
    route: '/invoices/invoice-one',
    icon: 'mdi mdi-note',
    visible: 'user'
  },
  {
    title: 'Gateway de pagos',
    route: '/payments',
    icon: 'mdi mdi-coin',
    visible: 'user'
  },
  {
    title: 'Core bancario',
    route: '/bank-accounts',
    icon: 'mdi mdi-coin',
    visible: 'user'
  }
]

// The Sidebar appears on the left side of the app, it only appears when an user is logged in
// It stays fixed when scrolling through the page, shows the labels if it's hovered for a few hundred milliseconds
class Sidebar extends Component {
  constructor (props) {
    super(props)
    this.data = {}
    this.state = Object.assign({}, this.data)
  }

  renderMenuItems () {
    const permissions = this.props.role
    return MENU_ITEMS.map((item, index) => {
      const visible = item.visible === 'user' || item.visible === permissions
      return (
        visible && (
          <ul key={index}>
            <Link to={item.route}>
              <li
                className={classNames({
                  active: this.props.pathname.includes(item.route)
                })}
              >
                <i className={item.icon} aria-hidden='true' /> {item.title}
              </li>
            </Link>
          </ul>
        )
      )
    })
  }

  // Main render, every item is an <li> with a browserHistory.push triggered by onClick
  // Routes must match app/index.js
  render () {
    return (
      <div id='app-sidebar'>
        <div className='menu-items'>{this.renderMenuItems()}</div>
      </div>
    )
  }
}

export default withCurrentUser(Sidebar)
