// Libraries
import React, { Component } from 'react'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'
import { stringifyQuery, parseQuery, hasFilters } from 'utils'

/**
 * Renders all pages instead of skipping the middle ones, MINIMUM IS 6
 * @constant
 */
const MIN_FIXED_PAGES = 6

class Paginator extends Component {
  /**
   * Sets current page, it's a callback to the parent component which implements the asking for pages behavior
   * Redirects the current url to a base url + page, don't use base url + page in other routes
   * If page number is 1, redirect to a clean base url with no visible page number
   * @param {number} page
   */
  setPage = page => {
    const { history, location } = this.props
    const allQueries = parseQuery(location.search)
    const query = {
      ...hasFilters(allQueries),
      page
    }
    if (page === 1) {
      history.push(location.pathname)
    } else {
      history.push(location.pathname + stringifyQuery(query))
    }
  }

  /**
   * Renders the buttons for every page
   * @param {number} page
   */
  renderLink (page) {
    const currentPage = parseQuery(this.props.location.search).page || 1
    if (page) {
      return (
        <li key={page} onClick={() => this.setPage(page)}>
          <a
            className={classNames('pagination-link', {
              'is-current': page === Number(currentPage)
            })}
          >
            {page}
          </a>
        </li>
      )
    }
    return (
      <li>
        <span className='pagination-ellipsis'>&hellip;</span>
      </li>
    )
  }

  // Renders all buttons
  renderLinks () {
    const { location, totalPages } = this.props
    let renderLinks = null
    const locationQuery = parseQuery(location.search)
    const currentPage = Number(locationQuery.page) || 1
    // Validates if currentPage is between 1 and totalPages
    if (currentPage < 1 || currentPage > this.props.totalPages) {
      console.error('FATAL @ PAGINATOR: current page outside total pages')
    }

    if (totalPages < MIN_FIXED_PAGES) {
      const iterable = []
      for (let i = 1; i <= totalPages; i++) {
        iterable.push(i)
      }
      const links = iterable.map(page => this.renderLink(page))

      renderLinks = <ul className='pagination-list'>{links}</ul>
    } else {
      // Special case for rendering first 3 pages if current page is on the verge
      if (currentPage <= 3) {
        renderLinks = (
          <ul className='pagination-list'>
            {this.renderLink(1)}
            {this.renderLink(2)}
            {this.renderLink(3)}
            {this.renderLink(4)}
            {this.renderLink(0)}
            {this.renderLink(totalPages)}
          </ul>
        )
      } else if (totalPages - currentPage > 2) {
        // Special case for rendering pages if current page is on the middle
        renderLinks = (
          <ul className='pagination-list'>
            {this.renderLink(1)}
            {this.renderLink(0)}
            {this.renderLink(currentPage - 1)}
            {this.renderLink(currentPage)}
            {this.renderLink(currentPage + 1)}
            {this.renderLink(0)}
            {this.renderLink(totalPages)}
          </ul>
        )
      } else {
        // Special case for rendering last 3 pages if current page is on the verge
        renderLinks = (
          <ul className='pagination-list'>
            {this.renderLink(1)}
            {this.renderLink(0)}
            {this.renderLink(totalPages - 3)}
            {this.renderLink(totalPages - 2)}
            {this.renderLink(totalPages - 1)}
            {this.renderLink(totalPages)}
          </ul>
        )
      }
    }
    return renderLinks
  }

  render () {
    if (this.props.totalPages < 2) {
      return null
    }
    return (
      <div id='paginator' className='bottom'>
        <nav className='pagination'>{this.renderLinks()}</nav>
      </div>
    )
  }
}

export default withRouter(Paginator)
