/*
  API Wrapper
  The intent of this module is to have a single place in the app where all API calls are located
  The syntax for a new API call can be inferred from the existing exported classes, it supports the HTTP methods GET, POST, PUT and DELETE
  All four methods call the request function that parses and prepares the data before making the actual request, the URL parameters are converted to a query string and headers / auth are added in this step
  The response is a promise that must be resolved from the place it was called
*/

/**
 * contract requests
 * @typedef {{
 * status: string,
 * product_line: string,
 * time: Date,
 * country: string,
 * contract: {uid: string},
 * client: {
 * second_surname: string,
 * names: string,
 * first_surname: string,
 * email: string},
 * total_requests: number,
 * }} TypeContractRequest
 *
 * @typedef {{
 * edc_assigned: string,
 * status: string,
 * reference: string | null,
 * product_line: string,
 * previous_notes: string,
 * notes: string,
 * monthly_payment: TypeDetailsValue,
 * country: string,
 * contract_uid: string,
 * previous_invalid: Array<object>,
 * invalid: Array<object>,
 * ard: {ard_notes: string, ard_email: string, branch_office: string},
 * debts: Array<TypeContractPendingDebts>,
 * files: Array<TypeContractClientDocument>,
 * client: TypeContractClient,
 * lead_uid: string,
 * lead_files: {
 * inserted_at: Date | string
 * name: string
 * type: string
 * url: string
 * }[]
 * }} TypeContractPending
 *
 * @typedef {{
 * updated_amount: TypeDetailsValue,
 * note: TypeDetailsValue,
 * credit_limit?: TypeDetailsValue,
 * last_update_date: TypeDetailsValue,
 * last_paid_date: TypeDetailsValue,
 * financial_entity_name: TypeDetailsValue,
 * entered_amount: TypeDetailsValue,
 * credit_number: TypeDetailsValue
 * }} TypeContractPendingDebts
 *
 * @typedef {{
 * second_surname: TypeDetailsValue,
 * phone: TypeDetailsValue,
 * rfc: TypeDetailsValue,
 * nationality: TypeDetailsValue,
 * names: TypeDetailsValue,
 * gender: TypeDetailsValue,
 * first_surname: TypeDetailsValue,
 * email: TypeDetailsValue,
 * identification_number?: TypeDetailsValue,
 * birth_state: TypeDetailsValue,
 * birth_date: TypeDetailsValue,
 * birth_country: TypeDetailsValue,
 * document_type?: TypeDetailsValue,
 * identification_exp_date?: TypeDetailsValue,
 * identification_exp_place?: TypeDetailsValue,
 * same_bank_nomina?: TypeDetailsValue,
 * beneficiary?: TypeContractClientBeneficiary,
 * address: TypeContraClientAddress,
 * documents_identity: TypeContractClientDocument[],
 * }} TypeContractClient
 *
 * @typedef {{
 * relationship: TypeDetailsValue,
 * name: TypeDetailsValue,
 * gender: TypeDetailsValue,
 * birth_date: TypeDetailsValue,
 * }} TypeContractClientBeneficiary
 *
 * @typedef {{
 * street_number: TypeDetailsValue,
 * street: TypeDetailsValue,
 * state: TypeDetailsValue,
 * neighborhood?: TypeDetailsValue | null,
 * postal_code?: TypeDetailsValue | null,
 * municipality: TypeDetailsValue,
 * interior_number: TypeDetailsValue,
 * country: TypeDetailsValue,
 * }} TypeContraClientAddress
 *
 * @typedef {{
 * url: string,
 * name: string,
 * entity: string,
 * description: string,
 * content_type: string,
 * uid: string,
 * }} TypeContractClientDocument
 *
 * @typedef {{
 * ard_notes?: any;
 * ard_email: string;
 * branch_office: string;
 * }} TypeArd
 *
 * @typedef {{
 * updated_amount: TypeDetailsValue;
 * note: TypeDetailsValue;
 * credit_limit: TypeDetailsValue;
 * last_update_date: TypeDetailsValue;
 * last_paid_date: TypeDetailsValue;
 * financial_entity_name: TypeDetailsValue;
 * entered_amount: TypeDetailsValue;
 * credit_number: TypeDetailsValue;
 * }} TypeDebt
 *
 * @typedef {{
 *  edc_assigned: string;
 *  status: string;
 *  reference?: any;
 *  product_line: string;
 *  previous_notes: string;
 *  notes: string;
 *  monthly_payment: TypeDetailsValue;
 *  country: string;
 *  contract_uid: string;
 *  previous_invalid: array{value?: string, name?: string, description?: string};
 *  invalid: any[];
 *  ard: TypeArd;
 *  debts: TypeDebt[];
 *  files: TypeContractClientDocument[];
 *  client: TypeContractClient;
 * }} TypeContractDetail
 *
 * @typedef {{value: string | Date, modified: boolean}} TypeDetailsValue
 */

/**
 * endpoint clients
 * @typedef {{
 * id: number,
 * name: string,
 * first_surname: string,
 * second_surname: string,
 * email: string,
 * mobile_phone: number
 * }} Client
 *
 * @typedef {{
 * name: string,
 * first_surname: string,
 * second_surname: string,
 * birth_date: string,
 * nationality: string,
 * nit: string,
 * birthplace: string,
 * economic_activity: string,
 * ocupation: string,
 * gross_income: number,
 * net_income: number,
 * addresses: Array,
 * email: string,
 * mobile_phone: number,
 * status: string
 * }} PersonalData
 *
 * @typedef {{
 * uid: string,
 * stage: string,
 * product_line: string,
 * metadata: {
 * product_information: {
 *  saving_product: {
 *      provider_reference: string,
 *      provider_metadata: {reference: string, provider: string},
 *      provider: string,
 *      country: string,
 *    },
 *    bank_account: string,
 *  },
 * },
 * country: string
 * }} Product
 *
 * @typedef {{
 * la_tasa: Array<DocumentType>,
 * rtd: Array<DocumentType>,
 * check: Array<DocumentType>
 * }} Document
 *
 * @typedef {{
 * document_type: string,
 * url: string
 * }} DocumentType
 *
 * @typedef {{
 * personal_data: PersonalData,
 * products: Array<Product>,
 * documents: Document
 * }} DataClient
 */
/**
 * types acounting flow payment
 *
 * @typedef {Object} TypeFlowPayment
 * @property {{id: number, name: string}} subsidiary
 * @property {Receptor} payment_receptor
 * @property {string} payment_method
 * @property {string} iva_amount
 * @property {number} iva
 * @property {Date} invoice_date
 * @property {string} invoice_number
 * @property {number} id
 * @property {{id: number, description: string}} flow_type
 * @property {{pdf: string, xml: string}} flow_documents
 * @property {Date} flow_date
 * @property {string} currency
 * @property {string} concept
 * @property {BankAcount} bank_account
 * @property {{id: number, name: string}} area
 * @property {string} amount
 * @property {string} folio
 * @property {"EN ESPERA" | "PAGADO" | "COMPLETADO" | "CANCELADO"} status
 *
 * @typedef {{
 * id: number;
 * description: string;
 * company: {id: number, name: string};
 * clabe: number | string;
 * bank: {name: string, id: number};
 * account_number: string;
 * }} BankAcount
 *
 * @typedef {Object} Receptor
 * @property {number} account_number
 * @property {string} beneficiary
 * @property {number} bank_id
 * @property {number} clabe
 * @property {number} id
 * @property {string} country
 * @property {string} name
 * @property {{id: number, type: string, vat: number, country: string}} receptor_type
 *
 * @typedef {{
 * total_pages?: number;
 * total_entries?: number;
 * page_number?: number;
 * page_size?: number ;
 * }} Pagination
 */
/**
 * @typedef {{
 * id: number,
 * description: string,
 * company: {name: string, id: number, country: string},
 * clabe: string,
 * bank: {name: string, id: number, country: string},
 * account_number: string,
 * }} BankAccount
 */
/**
 * @typedef {Object} PaymentReceptor
 * @property {number | null} id
 * @property {string} status
 * @property {ReceptorType} receptor_type
 * @property {string} name
 * @property {null | string} errors
 * @property {string} country
 * @property {string} clabe
 * @property {string} beneficiary
 * @property {{name: string, id: number}} bank
 * @property {string} account_number
 */
/**
 * @typedef {{ id: number, name: string }} OptionName
 * @typedef {{
 * status: string;
 * id: number;
 * errors?: object;
 * }} TypeResponseSuccess
 *
 */
/**
 * @typedef {Array<OptionName>} Areas
 * @typedef {Array<OptionName>} Companies
 * @typedef {Array<OptionName>} Flow_types
 * @typedef {Array<OptionName>} Subsidiaries
 * @typedef {{
 * TRANSFERENCIA: string;
 * CHEQUE: string;
 * EFECTIVO: string;
 * TARJETA DE CRÉDITO: string;
 * }} Payment_methods
 *
 * @typedef {{
 * EN ESPERA: string;
 * PAGADO: string;
 * CANCELADO: string;
 * }} StatusCatalogues
 */
/**
 * @typedef {Object} ReceptorType
 * @property {string} country
 * @property {string} type
 * @property {number} vat
 * @property {number} id
 */
/**
 * @typedef {{
 * status: string;
 * name: string;
 * id: number | string;
 * errors: null | object;
 * country: string;
 * }} Bank
 *
 * @typedef {{
 * id: number | null,
 * country: string,
 * name: string,
 * }} TypeAreas
 */

/**
 * types endpoint accounting finances incomes
 *
 * @typedef {Object} Income
 * @property {string} vat_percent
 * @property {string} status
 * @property {string} saving_vehicle
 * @property {string} remaining_amount
 * @property {string | null} reference
 * @property {string} receivable_amount
 * @property {Date} invoice_date
 * @property {string} income_type
 * @property {number} id
 * @property {number} folio
 * @property {string} currency
 * @property {string} country
 * @property {string} client_status
 * @property {string} account_number
 * @property {TypeIncomeBankAccount} bank_account
 *
 * @typedef {{
 * id: string | number;
 * description: string;
 * company: {id: string | number, name: string, country: string};
 * clabe: string;
 * bank: {name: string, id: string, country: string};
 * account_number: string
 * }} TypeIncomeBankAccount
 *
 * @typedef {{
 * country: string,
 * description: string,
 * bank_id: string,
 * company_id: string,
 * clabe: string,
 * account_number: string,
 * }} TypeCreateBankAccount
 */
/**
 * @typedef {{
 * income_type: string;
 * bank_account_id: string;
 * country: string;
 * receivable_amount: string;
 * remaining_amount: string;
 * saving_vehicle: string;
 * }} CreateIncome
 *
 * @typedef {{
 * id: number,
 * url: string,
 * date: Date,
 * saving_vehicle: string,
 * country: string
 * }} Layout
 *
 * @typedef {{
 * vat_percent: number,
 * status: string,
 * saving_vehicle: string,
 * remaining_amount: string,
 * receivable_amount: string,
 * invoice_date: string,
 * income_type: string,
 * id: number,
 * folio: number,
 * currency: string,
 * country: string,
 * client_product: {reference: string, client_status: string},
 * charges: {Array: TypeIncomeCharges}
 * }} TypeIncomeDetails
 *
 * @typedef {{
 * status: string,
 * pay_day: Date,
 * amount: {currency: string, amount: number },
 * }} TypeIncomeCharges
 */

/**
 * types endpoints clarification
 *
 * @typedef {{
 * id: number,
 * country: string,
 * name: string
 * }} ClarificationArea
 *
 * @typedef {{
 * name: string,
 * id: number,
 * fields: Array<Field>,
 * country: string,
 * area: number,
 * active: boolean
 * }} ClarificationTypes
 *
 * @typedef {{
 * id: string,
 * type: string,
 * label: string,
 * required: boolean,
 * name: string,
 * values: Array<{id: string, label: string}>
 * }} Field
 *
 * @typedef {{
 * area_id: number,
 * country: string,
 * fields: Array<Field>,
 * name: string,
 * }} CreateClarificationType
 *
 * @typedef {{
 * id: number,
 * area: string,
 * type: string,
 * status: string,
 * date: Date,
 * creator: string,
 * country: string,
 * creator_area: string,
 * }} ListClarificationType
 *
 * @typedef {{
 * country: string,
 * area: string,
 * type: string,
 * date: Date,
 * id: number,
 * fields: Array<{label: string, value: string | number, id: string}>,
 * status: string,
 * possible_status: Array<'in_progress' | 'closed'>,
 * description: string,
 * creator: string,
 * comments: Array<TypeComment>
 * document: {name: string, id: string, url: string}>
 * }} TypeClarifcationDetail
 *
 * @typedef {{
 * email: string,
 * name: string,
 * text: string,
 * date: Date,
 * avatar: string
 * }} TypeComment
 */

/**
 * Types endpoints invoices
 *
 * @typedef {{
 * type: string,
 * total_withheld_taxes: string,
 * total_taxes: string,
 * total: string,
 * subtotal: string,
 * status: string,
 * resolution: string,
 * currency: string,
 * recipient_name: string,
 * recipient_last_name: string,
 * prefix: string,
 * key: string,
 * id: number,
 * folio: string,
 * factura1_id: string | null,
 * date: Date,
 * cufe: string | null,
 * amount_in_letters: string,
 * version: string,
 * due_date: Date | string,
 * }} TypeInvoiceOne
 *
/**
* @typedef {Object} TypeInvoiceDetails
* @property {String} amount_in_letters
* @property {String | Null} cufe
* @property {String} currency
* @property {Date} date
* @property {Array<InvoiceDetails>} details
* @property {Null} factura1_id
* @property {String} folio
* @property {Number} id
* @property {String} issuer_nit
* @property {String} key
* @property {Null} nc_cod
* @property {Null} nc_date
* @property {Null} nc_iddoc
* @property {Null} nc_idfact
* @property {Null} nc_uuid
* @property {String} prefix
* @property {String} recipient_address
* @property {String} recipient_city
* @property {String} recipient_country
* @property {String} recipient_department
* @property {String} recipient_email
* @property {String} recipient_last_name
* @property {String} recipient_municipality
* @property {String} recipient_name
* @property {String} recipient_nit
* @property {String} recipient_regime
* @property {String} recipient_type
* @property {String} resolution
* @property {String | Null} response_data
* @property {String} response_error
* @property {String | Null} signed_xml
* @property {String} status
* @property {String} subtotal
* @property {Array<{withheld: boolean, type: string, rate: number, amount: string}>} taxes
* @property {String} time
* @property {String} total
* @property {String} total_discounts
* @property {String} total_taxes
* @property {String} total_withheld_taxes
* @property {String} type
* @typedef {{
* unit_price: string,
* quantity: string,
* description: string,
* amount: string
}} InvoiceDetails
*/

import queryString from 'query-string'
import nprogress from 'nprogress'
import store from 'store'

// Make the actual request
/**
 * @param {string} method
 * @param {any} endpoint
 * @param {null} queryArray
 * @param {any} body
 */
function request (method, endpoint, queryArray, body) {
  let query = ''
  if (typeof endpoint === 'undefined') {
    console.error('FATAL ERROR: endpoint not defined')
    return
  }
  if (typeof queryArray !== 'undefined' && queryArray !== null) {
    query = `?${queryString.stringify(queryArray)}`
  }

  // Delete ? sign if the query string is empty
  if (query === '?') query = ''

  const url = `${process.env.API_CORE_SERVER}${
    process.env.API_PREFIX_CORE
  }${endpoint}${query}`

  const fetchParams = {
    method: method,
    headers: {
      'content-type': 'application/json',
      Authorization: store.get('CORE_AUTH_TOKEN'),
      'X-Requested-With': 'XMLHttpRequest'
    }
  }

  if (body) fetchParams.body = JSON.stringify(body)

  nprogress.start()
  // Call the API and return a json response
  return window
    .fetch(url, fetchParams)
    .then(response =>
      response.json().then(r => {
        nprogress.done()
        if (response.status >= 200 && response.status < 300) {
          if (process.env.DEBUG) {
            console.info(`${method}: ${url}`)
          }
          return r
        } else if (response.status === 401) {
          store.clearAll()
          window.alert('Tu sesión caducó. Inicia sesión de nuevo')
          window.location.replace(window.location.origin)
        } else {
          return r
        }
      })
    )
    .catch(e => console.error(e))
}

// HTTP GET
/**
 * @param {string} route
 * @param {{ country?: string; countries?: string; page?: number; company?: string; saving_vehicle?: string; area?: string; } | null} query
 * @param {null} params
 */
function Get (route, query, params) {
  return request('GET', route, query, params)
}
// HTTP POST
/**
 * @param {string} route
 * @param {object | null} query
 * @param {null} params
 * @param {object} [data]
 */
function Post (route, query, params, data) {
  return request('POST', route, query, data)
}
// HTTP PUT
/**
 * @param {string} route
 * @param {object} query
 * @param {null} params
 * @param {{ status: string; id?: string; }} data
 */
function Put (route, query, params, data) {
  return request('PUT', route, query, data)
}

// Exported functions
class User {
  /**
   * @param {{token: string}} data
   */
  static Login (data) {
    return Post('/auth', {}, null, data)
  }
}

// transactions
class Transactions {
  /**
   * @param {{ status: string; date: string; uid: string; page: any; }} data
   */
  static List (data) {
    return Get('/transactions', data, null)
  }
}

class ContractRequests {
  /**
   * @param {object} params
   */
  static ListFailed (params) {
    return Get('/admin/contract-requests/failed', params, null)
  }

  /**
   * @param {Object} data
   * @returns {Promise<{data: Array<TypeContractRequest>, pagination: Pagination}>}
   */
  static ListPending (data) {
    return Get('/contract_request/pending', data, null)
  }

  /**
   * @param {Object} data
   * @returns {Promise<{data: Array<TypeContractRequest>, pagination: Pagination}>}
   */
  static ListReviewed (data) {
    return Get('/contract_request/reviewed', data, null)
  }

  /**
   * @param {string} contractUid
   */
  static ContractDetail (contractUid) {
    return Get(`/contract_request/${contractUid}`, {}, null)
  }

  /**
   * @param {string} contractUid
   * @param {string} documentId
   */
  static UpdateDocument (contractUid, documentId) {
    return Get(
      `/contract_request/${contractUid}/documents/${documentId}`,
      {},
      null
    )
  }
  /**
   * @param {string} contractUid
   * @param {{ reference?: string; }} data
   */
  static Approve (contractUid, data) {
    return Post(`/contract_request/${contractUid}/approve`, {}, null, data)
  }

  /**
   * @param {string} contractId
   * @param {{ reference?: string; }} data
   */
  static ApproveContract (contractId, data) {
    return Post(`/contract_request/${contractId}/approve/async`, {}, null, data)
  }

  /**
   * @param {string} uid
   * @param {{ notes: string; invalid: { name: any; value: any; description: any; }[]; }} data
   */
  static Reject (uid, data) {
    return Post(`/contract_request/${uid}/reject`, {}, null, data)
  }

  /**
   * @param {any} uid
   */
  static Cancel (uid) {
    return Post(`/contract_request/${uid}/cancel`, {}, null, null)
  }

  /**
   * @param {string} uid
   */
  static GenerateReference (uid) {
    return Post(`/contract_request/${uid}/pisa`, {}, null, null)
  }
}

// contracts
class Contracts {
  /**
   * @param {{ page: number; }} data
   */
  static List (data) {
    return Get('/contract/created', data, null)
  }

  /**
   * @param {string} contractUid
   */
  static Get (contractUid) {
    return Get(`/contract/${contractUid}`, {}, null)
  }

  /**
   * @param {object} [data]
   */
  static Reject (data) {
    return Post('/package/rejected', data, null)
  }

  /**
   * @param {object} [data]
   */
  static Accept (data) {
    return Post('/package/send', data, null)
  }
}

class Clients {
  /**
   * @param {string} data
   * @returns {Promise<{data: Array<Client>}>}
   */
  static SearchClients (data) {
    return Get(`/client/accounts/search/${data}`, null, null)
  }

  /**
   * @param {number} id
   * @returns {Promise<{data: DataClient }>}
   */
  static GetClientDetails (id) {
    return Get(`/client/${id}/account`, null, null)
  }

  /**
   * @param {object} [data]
   */
  static Update (data) {
    return Put('/client/accounts', {}, null, data)
  }

  /**
   * @param {object} [data]
   */
  static UpdateProduct (data) {
    return Put('/client/products', {}, null, data)
  }

  /**
   * @param {object} [data]
   */
  static Create (data) {
    return Post('/client/accounts', {}, null, data)
  }

  /**
   * @param {string} id
   */
  static Show (id) {
    return Get(`/credit-repairs/${id}`, {}, null)
  }
}

class Address {
  /**
   * @param {any} data
   */
  static Create (data) {
    return Post('/client/address', {}, null, data)
  }
  /**
   * @param {any} data
   */
  static Update (data) {
    return Put('/client/address', {}, null, data)
  }
}

class Products {
  /**
   * @param {any} data
   */
  static Create (data) {
    return Post('/client/products', {}, null, data)
  }
  /**
   * @param {any} data
   */
  static Update (data) {
    return Put('/client/products', {}, null, data)
  }
}

class Accounting {
  /**
   * @param {Object} params
   * @returns {Promise<{data: Array<TypeFlowPayment>, pagination: Pagination}>}
   */
  static GetFlowPayments (params) {
    return Get('/finances/flow_payment', params, null)
  }

  /**
   * @param {{
   * country?: string,
   * income_type?: string,
   * billing_start_date?: Date | string,
   * billing_end_date: Date | string,
   * payment_start_date: Date | string,
   * payment_finish_date:Date | string
   * }} params
   */
  static ReportExpensesCsv (params) {
    return Get('/finances/flow_payment/export', params, null)
  }

  /**
   * @param {{country: string}} params
   * @returns {Promise<{areas: Areas, companies: Companies, flow_types: Flow_types, payment_methods: Payment_methods, status: StatusCatalogues, subsidiaries: Subsidiaries}>}
   */
  static GetCatalogues (params) {
    return Get('/finances/catalogue', params, null)
  }

  /**
   * @param {import('../../app/views/accounting/expenses/newFlowPayment/form').Values} data
   */
  static CreateFlowPayment (data) {
    return Post('/finances/flow_payment', {}, null, data)
  }

  /**
   * @param {{status: string}} data
   */
  static UpdateStatus (data) {
    return Put('/finances/flow_payment', {}, null, data)
  }

  /**
   * @param {{name: string}} params
   */
  static SearchExpenses (params) {
    return Get('/finances/payment_receptor', params, null)
  }

  /**
   * @param {{countries: string, page?: number}} params
   * @returns {Promise<{data: Array<PaymentReceptor>, pagination: Pagination}>}
   */
  static GetPaymentReceptors (params) {
    return Get('/finances/payment_receptor', params, null)
  }

  /**
   * @param {PaymentReceptor} data
   */
  static UpdatePaymentReceptors (data) {
    return Put('/finances/payment_receptor', {}, null, data)
  }

  /**
   * @param {PaymentReceptor & {iva: String}} data
   */
  static CreatePaymentReceptors (data) {
    return Post('/finances/payment_receptor', {}, null, data)
  }

  /**
   * @param {{ company?: string }} params
   * @returns {Promise<{data: Array<BankAccount> }>}
   */
  static GetBankAccounts (params) {
    return Get('/finances/bank_account', params, null)
  }

  /**
   * @param {TypeCreateBankAccount} data
   */
  static CreateBankAccount (data) {
    return Post('/finances/bank_account', {}, null, data)
  }

  /**
   * @param {TypeCreateBankAccount} data
   */
  static UpdateBankAccount (data) {
    return Put('/finances/bank_account', {}, null, data)
  }

  /**
   * @param {{country: string}} params
   * @returns {Promise<{data: Array<Bank>}>}
   */
  static GetBanks (params) {
    return Get('/finances/bank', params, null)
  }

  /**
   * @param {{country: string, name: string}} data
   */
  static CreateBank (data) {
    return Post('/finances/bank', {}, null, data)
  }

  /**
   * @param {{country: string, name: string, id: string }} data
   */
  static UpdateBank (data) {
    return Put('/finances/bank', {}, null, data)
  }

  /**
   * @param {{country: string}} params
   * @returns {Promise<{data: Array<ReceptorType>}>}
   */
  static GetReceptorTypes (params) {
    return Get('/finances/receptor_type', params, null)
  }

  /**
   * @param {{country: string, vat: number, type: string}} data
   */
  static CreateReceptorTypes (data) {
    return Post('/finances/receptor_type', {}, null, data)
  }

  /**
   * @param {{country: string, vat: string, type: string, id: string}} data
   */
  static UpdateReceptorTypes (data) {
    return Put('/finances/receptor_type', {}, null, data)
  }

  /**
   * @param {{country: string}} params
   * @returns {Promise<{companies: Array<OptionName>}>}
   */
  static GetCompanies (params) {
    return Get('/finances/catalogue/companies', params, null)
  }

  /**
   * @param {{company: {country: string, name: string}}} data
   */
  static CreateCompany (data) {
    return Post('/finances/catalogue/companies', {}, null, data)
  }

  /**
   * @param {{company: {country: string, name: string, id: string}}} data
   */
  static UpdateCompany (data) {
    return Put('/finances/catalogue/companies', {}, null, data)
  }

  /**
   * @param {{country: string}} params
   * @returns {Promise<{data: Array<TypeAreas>}>}
   */
  static GetAreas (params) {
    return Get('/finances/catalogue/areas', params, null)
  }

  /**
   * @param {{area: {country: string, name: string}}} data
   */
  static CreateAreas (data) {
    return Post('/finances/catalogue/areas', {}, null, data)
  }

  /**
   * @param {{area: {country: string, name: string, id: string}}} data
   */
  static UpdateAreas (data) {
    return Put('/finances/catalogue/areas', {}, null, data)
  }

  /**
   * @param {{country: string}} params
   * @returns {Promise<{data: Array<TypeAreas>}>}
   */
  static GetSubsidiaries (params) {
    return Get('/finances/catalogue/subsidiaries', params, null)
  }
  /**
   * @param {{subsidiaries: {country: string, name: string}}} data
   */
  static CreateSubsidiaries (data) {
    return Post('/finances/catalogue/subsidiaries', {}, null, data)
  }
  /**
   * @param {{subsidiaries: {country: string, name: string, id: string}}} data
   */
  static UpdateSubsidiaries (data) {
    return Put('/finances/catalogue/subsidiaries', {}, null, data)
  }

  /**
   * @param {object} params
   * @returns {Promise<{data: Array<Income>, pagination: Pagination}>}
   */
  static GetIncomes (params) {
    return Get('/finances/income', params, null)
  }

  /**
   * @param {string} incomeId
   * @returns {Promise<{data: TypeIncomeDetails}>}
   */
  static GetIncomeDetail (incomeId) {
    return Get(`/finances/income/${incomeId}`, null, null)
  }

  /**
   * @param {CreateIncome} data
   */
  static CreateIncome (data) {
    return Post('/finances/income', {}, null, data)
  }

  /**
   * @param {String} incomeId
   * @param {number} movementId
   */
  static CancelIncomeMevements (incomeId, movementId) {
    return Put(
      `/finances/income/${incomeId}/movement/${movementId}/cancel`,
      {},
      null,
      null
    )
  }

  /**
   * @param {{
   * income_type: string,
   * amount: number
   * }} data
   */
  static CreateCharges (data) {
    return Post('/finances/income/movements', {}, null, data)
  }

  /**
   * @param {CreateIncome} data
   */
  static UpdateIncome (data) {
    return Put('/finances/income', {}, null, data)
  }

  /**
   * @param {{
   * country?: string,
   * income_type?: string,
   * billing_start_date?: Date | string,
   * billing_end_date: Date | string,
   * payment_start_date: Date | string,
   * payment_finish_date:Date | string
   * }} params
   */
  static DownloadIncomeCsv (params) {
    return Get('/finances/income/export', params, null)
  }

  /**
   * @param {{id: string, status: string}} data
   */
  static UpdateIncomeStatus (data) {
    return Put('/finances/income', {}, null, data)
  }

  /**
   * @param {string} country
   * @returns {Promise<{income_types: Array<String>}>}
   */
  static GetIncomeTypes (country) {
    return Get(`/finances/catalogue/income_types/${country}`, null, null)
  }

  /**
   * @param {{country?: string, saving_vehicle?: string, page: number}} params
   * @returns {Promise<{data: Array<Layout>, pagination: Pagination}>}
   */
  static GetLayout (params) {
    return Get('/finances/layout_file', params, null)
  }

  /**
   * @param {object} data
   */
  static GenerateLayoutFile (data) {
    return Post(`/finances/layout_file`, {}, null, data)
  }

  /**
   * @param {number} id
   */
  static GetLayoutFile (id) {
    return Get(`/finances/layout_file/${id}`, null, null)
  }

  /**
   * @param {string} type
   */
  static GetFileUnpayable (type) {
    return Get(`/finances/income/movements/cancelled_charges/${type}`, {}, null)
  }
  /**
   * @param {{date: Date}} data
   */
  static GetFileOxxoPay (data) {
    return Get('/finances/oxxo_pay_download_charges', data, null)
  }
}

class Clarifications {
  /**
   * @param {{country?: string, area?: string, page: number}} params
   * @returns {Promise<{data: Array<ClarificationTypes>, page: Pagination}>}
   */
  static ListClarificationTypes (params) {
    return Get('/clarification_types', params, null)
  }

  /**
   * @param {{country?: string, area?: string, page: number}} params
   * @returns {Promise<{data: Array<ListClarificationType>, pagination: Pagination}>}
   */
  static GetClarifications (params) {
    return Get('/clarifications', params, null)
  }

  /**
   * @param {number} id
   * @returns {Promise<{data: TypeClarifcationDetail}>}
   */
  static GetClarificationDetails (id) {
    return Get(`/clarifications/${id}`, {}, null)
  }
  /**
   * @param {number} id
   * @param {{ text: string; date: string; email?: string; name?: string; avatar: string; }} data
   */
  static CreateClarifitionComments (id, data) {
    return Post(`/clarifications/${id}`, {}, null, data)
  }
  /**
   * @param {number} id
   * @param {{status: 'Cerrado' | 'En espera' | 'Procesando'; id: string}} data
   */
  static UpdateClarificationStatus (id, data) {
    return Put(`/clarifications/${id}`, {}, null, data)
  }

  /**
   * @param {string} country
   * @param {object} params
   * @returns {Promise<{data: Array<ClarificationArea>}>}
   */
  static ClarificationAreas (country, params) {
    return Get(`/areas/${country}`, params, null)
  }

  /**
   * @param {CreateClarificationType} data
   */
  static createClarificationTypes (data) {
    return Post('/clarification_types', {}, null, data)
  }
}

class PaymentMethods {
  /**
   * @param {any} data
   */
  static Create (data) {
    return Post('/client/payment-methods', {}, null, data)
  }
  /**
   * @param {any} data
   */
  static Update (data) {
    return Put('/client/payment-methods', {}, null, data)
  }
}

class Movements {
  /**
   * @param {any} data
   */
  static List (data) {
    return Get('/movements', {}, null)
  }
}

class ZipCodes {
  /**
   * @param {any} zipCode
   */
  static List (zipCode) {
    return Get(`/sepomex?zip_code=${zipCode}`, {}, null)
  }
}

class Invoices {
  /**
   * @param {{status?: string; type?: string; query?: string; date?: Date; page: number; }} params
   * @returns {Promise<{data: Array<TypeInvoiceOne>, pagination: Pagination}>}
   */
  static GetInvoicesOne (params) {
    return Get('/factura1/invoices/search', params, null)
  }

  /**
   * @param {number} id
   * @returns {Promise<{data: TypeInvoiceDetails}>}
   */
  static GetInvoiceOneDetails (id) {
    return Get(`/factura1/invoices/${id}`, {}, null)
  }
  /**
   * @param {{
   * invoice_cufe: string,
   * detail: string,
   * folio: string,
   * date: Date
   * amount: number,
   * code: string,
   * amount_in_letters: string,
   * }} data
   */
  static CreateNoteCredit (data) {
    return Post('/factura1/notas-credito', {}, null, data)
  }
  /**
   * @param {number} id
   * @param {{
   * values: {cufe: string, status: string, updated_by: string}
   * }} dataValues
   */
  static async UpdateCufeStatus (id, dataValues) {
    const { data = {}, status, errors = {} } = await Put(
      `/factura1/invoice-with-error/${id}`,
      {},
      null,
      dataValues
    )
    const { message = '' } = data || {}
    const { detail = '' } = errors || {}
    return { status, message, detail }
  }
}

export default {
  User,
  Transactions,
  ContractRequests,
  Contracts,
  Clients,
  Address,
  Products,
  PaymentMethods,
  Movements,
  ZipCodes,
  Accounting,
  Clarifications,
  Invoices
}
