import React from 'react'

const Resuelve = ({ size = '30' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='60 12 170 170'
    width={size}
    height={size}
  >
    <path
      fill='#8dba38'
      d='M115.8 60.5s21.5 28.2 24.6 59.5c0 0 13.2-23.6 76.8-63 0 0-30 2.5-67.9 33.3 0 0-13.1-20.5-33.5-29.8'
    />
    <path
      fill='#003a63'
      d='M102.2 185.4c27.2-24.9 50.2-50 50.2-50 14.7 30.5 39.5 38.5 39.5 38.5-26.9-31-30.9-64.3-30.9-64.3-48.6 39.6-58.8 75.8-58.8 75.8'
    />
    <path
      fill='#8dba38'
      d='M162.2 69.1c0 4.9-4 8.9-8.9 8.9s-8.9-4-8.9-8.9 4-8.9 8.9-8.9 8.9 4 8.9 8.9'
    />
    <path
      fill='#003a63'
      d='M80.3 160.3c-.3-.6-.5-1.3-.8-1.9-.9-2.4-1.8-4.8-2.5-7.3-2.8-9.2-4.3-18.9-4.3-29 0-54.8 43.9-99.2 98.1-99.2 23.7 0 46.2 9 63.1 23.1l.6-.4 7.3-5.4c-18.5-18.3-43.9-29.6-71.9-29.6-20.8 0-40.2 6.2-56.4 17-27.3 18.7-45.2 50.3-45.2 86.1 0 14.7 3 28.6 8.4 41.2 1.9 4.1 4 8.1 6.4 11.9-.4-1-.9-2-1.3-3.1-.5-1.1-1-2.2-1.5-3.4'
    />
    <path
      fill='#8dba38'
      d='M169.9 34.4c20.9 0 40.1 6.6 55.8 17.8l8.2-6.1c-17-14.2-39.4-23.1-63.1-23.1-54.2 0-98.1 44.4-98.1 99.2 0 10.1 1.5 19.8 4.3 29 .2.7 1.4 4.1 2.5 7.3.2.6.5 1.3.8 1.9-.5-1.5-.9-3-1.3-4.7-2.5-8.4-3.8-17.3-3.8-26.4v-1.5-.5c0-51.4 42.4-92.9 94.7-92.9'
    />
  </svg>
)

export default Resuelve
