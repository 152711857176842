// LIbraries
import React from 'react'
import styled from 'styled-components'

// Components
import { SubTitle } from './styles'
import { getLabel, formatCurrency } from 'utils'
import { TAXES_TYPES } from 'shared/catalogs'

const ContentWrapper = styled.div`
  margin-top: 1rem;
  width: 100%;
`
/**
 * @typedef {import('api/core').TypeInvoiceDetails} TypeInvoiceDetails
 * @typedef {{
 * invoice: TypeInvoiceDetails
 * }} PropsTaxes
 * @param {PropsTaxes} props
 */
const Taxes = ({ invoice }) => {
  return (
    <ContentWrapper>
      <SubTitle>Impuestos</SubTitle>
      <table className='table'>
        <thead>
          <tr>
            <th>Tarifa</th>
            <th>Tipo</th>
            <th>Retención</th>
            <th>Monto</th>
          </tr>
        </thead>
        <tbody>
          {invoice.taxes &&
            invoice.taxes.map(({ amount, rate, type, withheld }, index) => {
              return (
                <tr key={index}>
                  <td>{`${rate}%`}</td>
                  <td>{getLabel(TAXES_TYPES, type)}</td>
                  <td>{withheld ? 'Si aplica' : 'No aplica'}</td>
                  <td>{formatCurrency(+amount, invoice.currency)}</td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </ContentWrapper>
  )
}
export default Taxes
