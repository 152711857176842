// Libraries
import React, { Component } from 'react'
import _pickBy from 'lodash/pickBy'
import Notification from 'cogo-toast'
import styled from 'styled-components'

// Components
import { Title, MARGIN_BOTTOM } from 'css/utils'
import { stringifyQuery, parseQuery } from 'utils'
import ContractsFilters from 'shared/contractFilters'
import ListTable from '../components/tables/listTable'
import Paginator from 'shared/paginator'
import Loader from 'shared/loader'

import API from 'api/core'

const Content = styled.div`
  margin-bottom: ${MARGIN_BOTTOM};
`

/**
 * @typedef {import('api/core').TypeContractRequest} TypeContractRequest
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @typedef {import('api/core').Pagination} Pagination
 * @typedef {{
 * contractsPending: TypeContractRequest[],
 * pagination: Pagination,
 * filters: {country: Set<string>, query: string, product_line: string},
 * requestInProgress: boolean
 * }} State
 *
 * @extends {Component<RouteComponentProps, State>}
 *
 */
class ContractsPending extends Component {
  /**
   * @type {State}
   */
  state = {
    contractsPending: [],
    pagination: {},
    filters: {
      country: new Set(),
      query: '',
      product_line: ''
    },
    requestInProgress: false
  }

  /**
   * @param {RouteComponentProps} prevProps
   */
  componentDidUpdate = prevProps => {
    if (this.props.location.search !== prevProps.location.search) {
      this.getContractsPending()
    }
  }

  componentDidMount = () => {
    this.getContractsPending()
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  handleCountrySelect = event => {
    const { name: country } = event.currentTarget
    this.setState(state => {
      if (state.filters.country.has(country)) {
        state.filters.country.delete(country)
      } else {
        state.filters.country.add(country)
      }
      return {
        filters: {
          ...state.filters,
          country: new Set(state.filters.country)
        }
      }
    })
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  onChangeValue = event => {
    const { name, value } = event.currentTarget
    this.setState(state => ({
      filters: { ...state.filters, [name]: value }
    }))
  }

  onCleanFilters = () => {
    const { history, location } = this.props
    this.setState(
      {
        filters: {
          country: new Set(),
          query: '',
          product_line: ''
        }
      },
      () => {
        history.push(location.pathname)
      }
    )
  }

  onFilter = () => {
    const { filters } = this.state
    const {
      history,
      location: { pathname }
    } = this.props
    const query = _pickBy(
      { ...filters, country: [...filters.country].join('|') },
      filter => filter.length > 0
    )
    history.push(pathname + stringifyQuery(query))
  }

  // Get all contracts pending
  getContractsPending = async () => {
    const { filters } = this.state
    const { location } = this.props
    const page = parseQuery(location.search).page || 1
    const query = _pickBy(
      {
        ...filters,
        country: [...filters.country].join('|'),
        query: filters.query.trim()
      },
      filter => filter.length > 0
    )
    const params = {
      ...query,
      page
    }
    this.setState({ requestInProgress: true })
    try {
      const { data, pagination } = await API.ContractRequests.ListPending(params)
      this.setState({ contractsPending: data, pagination })
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error cargando los contratos')
    } finally {
      this.setState({ requestInProgress: false })
    }
  }

  render () {
    const {
      filters,
      contractsPending,
      pagination,
      requestInProgress
    } = this.state
    return (
      <Content>
        <Title>Solicitudes de contrato pendientes</Title>
        <ContractsFilters
          testId='contracts'
          filters={filters}
          onFilter={this.onFilter}
          onChangeValue={this.onChangeValue}
          onCleanFilters={this.onCleanFilters}
          handleCountrySelect={this.handleCountrySelect}
        />
        {requestInProgress ? (
          <Loader />
        ) : (
          <ListTable
            contractsPending={contractsPending}
            totalEntries={pagination && pagination.total_entries}
          />
        )}
        {pagination && <Paginator totalPages={pagination.total_pages} />}
      </Content>
    )
  }
}
export default ContractsPending
