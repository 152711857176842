// Libararies
import React, { useState } from 'react'
import Notification from 'cogo-toast'
import { useParams } from 'react-router-dom'

// Components
import Button from 'shared/button'
import { axiosUploadFile, getProp } from 'utils'
import { InputFile, Icon, Text, Content, File, ContentFile } from './styles'

/**
 * @typedef {{
 * toggleModal:  () => void
 * fieldToUpdateFile: string
 * }} UpdateFilePops
 * @param {UpdateFilePops} props
 */
export default function UpdateFile ({ toggleModal, fieldToUpdateFile }) {
  const params = useParams()
  const [file, setFile] = useState({})
  const [loading, setLoading] = useState(false)

  const onChangeFile = event => {
    const { files } = event.target
    setFile(files[0])
  }

  const sendFile = () => {
    setLoading(true)
    axiosUploadFile(
      fieldToUpdateFile,
      file,
      null,
      `clarifications/${params.id}/file`
    )
      .then(respose => {
        Notification.success('Archivo enviado correctamente')
        window.location.reload()
      })
      .catch(error => {
        console.error(error)
        Notification.error(
          getProp(
            error,
            'response.data.errors.detail',
            'Ocurrió un error al enviar el archivo'
          )
        )
      })
      .finally(() => {
        setLoading(true)
        toggleModal()
      })
  }

  return (
    <div>
      {file.name && (
        <File>
          <h3>Archivo seleccionado</h3>
          <p>{file.name}</p>
        </File>
      )}
      <Content>
        <ContentFile>
          <Text>Selecciona archivo</Text>
          <InputFile type='file' onChange={onChangeFile} />
          <Icon className='mdi mdi-upload' />
        </ContentFile>
        <Button
          buttonClass='primary'
          icon='content-save'
          loading={loading}
          disabled={!file.name || loading}
          onClick={sendFile}
        >
          Guardar
        </Button>
      </Content>
    </div>
  )
}
