/* eslint-disable */
import React, {useState} from 'react'
import styled from 'styled-components'
import Notification from 'cogo-toast'

import DatePicker from 'shared/datePicker'
import Button from 'shared/button'
import API from 'api/core'
import {getFileName, downloadCSV, formatDateToSend} from 'utils'
import {buttonPrimary, error} from 'css/colors'
import {FlexWrapper} from 'css/utils'

const WrapperButtons = styled.div`
  margin-top: 1.5rem;
  text-align: right;
  display: flex;
  justify-content: flex-end;
`
const DownloadFile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${buttonPrimary};
  border-radius: 4px;
  width: 100%;
  & > p {
    color: ${buttonPrimary};
    width: 100%;
    text-align: center;
  }
`
const ErrorText = styled.div`
  text-align: center;
  border: 1px solid ${error};
  border-radius: 4px;
  width: 100%;
  margin-top: 1em;
  padding: 1em 0;
  color: ${error};
`
/**
 * @param {function | any} toggleModal
 */
const OxxoPay = ({toggleModal}) => {
  const [fieldvalue, setFieldValue] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState({
    url: null,
    error: ''
  })

  async function getFileOxxoPay() {
    const params = {
      date: formatDateToSend(fieldvalue)
    }
    setLoading(true)
    try {
      const {data} = await API.Accounting.GetFileOxxoPay(params)
      if (data && data.url) {
        const response = await fetch(data.url)
        if (response.status === 404) {
          setFile({
            url: null,
            error: 'No existe archivo para esta fecha. Intenta con otra'
          })
        } else if (response.status !== 200) {
          setFile({url: null, error: 'Ocurrio un error. Intenta de nuevo'})
        } else {
          Notification.success('Archivo generado con éxito')
          setFile({url: data.url, error: ''})
        }
      }
    } catch (error) {
      console.error(error)
      Notification.error(
        'Ocurrio un error generando el archivo. Intenta de nuevo'
      )
      toggleModal()
    } finally {
      setLoading(false)
    }
  }

  function handleChageValue(date) {
    setFieldValue(date)
    setFile({url: null, error: ''})
  }

  async function handleDownload() {
    await downloadCSV(file.url, null)
    toggleModal()
  }

  return (
    <div>
      <form>
        <FlexWrapper>
          <DatePicker
            width='100%'
            name='date'
            label='¿De que fecha quieres obtener el archivo?'
            placeholderText='DD/MM/YYYY'
            selected={fieldvalue}
            onChange={date => handleChageValue(date)}
            dateFormat='dd MMMM yyyy'
            maxDate={new Date()}
            iconStyle={{top: 6}}
            withPortal
          />
        </FlexWrapper>
        <WrapperButtons>
          <Button
            icon='check-circle'
            buttonClass='primary'
            onClick={getFileOxxoPay}
            loading={loading}
            disabled={loading || !fieldvalue}
          >
            Obtener archivo
          </Button>
        </WrapperButtons>
        {file && file.url && (
          <WrapperButtons>
            <DownloadFile>
              <p>{getFileName(file.url)}</p>
              <Button
                buttonClass='primary'
                icon='download'
                onClick={() => handleDownload()}
              >
                Descargar archivo
              </Button>
            </DownloadFile>
          </WrapperButtons>
        )}
        {file && file.error && (
          <ErrorText>
            <p>{file.error}</p>
          </ErrorText>
        )}
      </form>
    </div>
  )
}
export default OxxoPay
