// @ts-nocheck
/* eslint-disable */
// Libraries
import React, {Component} from 'react'
import Notification from 'cogo-toast'
import {Tab, Tabs, TabList, TabPanel, uid} from 'react-tabs'
import pickBy from 'lodash/pickBy'

// Components
import {FlexWrapper, Title} from 'css/utils'
import {getCountryLabel} from 'utils'
import LinkButton from 'shared/linkButton'
import Button from 'shared/button'
import Client from './client'
import Debts from './debts'
import NoteList from './noteList'
import PreviousNotes from './previousNotes'
import {generateData, validateContractRequest} from '../../components/utils'
import {ShowError, validReference} from './utils'
import {
  Content,
  Reference,
  WrapperNotes,
  HeaderWrapper,
  Header,
  Status,
  TabsWrapper,
  ButtonWrapper
} from './styles'

import API from 'api/core'

/**
 * @typedef {import('api/core').TypeContractClient} TypeContractClient
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @typedef {{
 * contractDetails: TypeContractClient,
 * generating: boolean,
 * invalid: Object<string, string>,
 * comment: string,
 * reference: string,
 * approveStatus: boolean,
 * referenceRequired: boolean,
 * approving: boolean,
 * tapIndex: number,
 * }} State
 *
 * @extends {Component<RouteComponentProps, State>}
 *
 */
export default class ContractDetails extends Component {
  /**
   * @type {State}
   */
  state = {
    contractDetails: null,
    invalid: {},
    comment: '',
    reference: '',
    approveStatus: true,
    referenceRequired: false,
    generating: false,
    approving: false,
    tapIndex: 0,
    currentTab: 0
  }

  componentDidMount = () => {
    this.getContractDetails()
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  onChangeReference = event => {
    const {value, name} = event.currentTarget
    this.setState({[name]: value}, this.validateApproveStatus)
  }

  validateApproveStatus = () => {
    const {invalid, comment} = this.state
    const approveStatus = comment
      ? false
      : Object.keys(invalid)
          .map(field => invalid[field])
          .every(field => field.length <= 0)
    this.setState({approveStatus})
  }

  /**
   * @param {String} country
   * @param {String} contractId
   * @param {String} contractUid
   */
  generateContract = (country, contractId, contractUid) => {
    const {contractDetails} = this.state
    const contract = validateContractRequest(contractDetails)
    this.referenceByCountry(contract)
    this.approveContract(contractId)
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  handleChangeValue = event => {
    const {name, value} = event.currentTarget
    this.setState(
      state => ({
        invalid: {...state.invalid, [name]: value}
      }),
      this.validateApproveStatus
    )
  }

  /**
   * @param {string} name
   * @param {function} setOpenField
   */
  clearInvalid = (name, setOpenField) => {
    this.setState(
      prevState => ({
        ...prevState,
        invalid: {
          ...prevState.invalid,
          [name]: ''
        }
      }),
      this.validateApproveStatus
    )
    setOpenField()
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  handleChangeComment = event => {
    const {value} = event.currentTarget
    this.setState({comment: value}, this.validateApproveStatus)
  }

  /**
   * @param {TypeContractClient} data
   */
  referenceByCountry = data => {
    const reference =
      ['es', 'ar', 'pt', 'it', 'br'].includes(data.country) &&
      (data.product_line === 'rtd' || data.product_line === 'bravo')
    this.setState({referenceRequired: reference})
  }

  getContractDetails = async () => {
    const {
      match: {params}
    } = this.props
    try {
      const {data, status} = await API.ContractRequests.ContractDetail(
        params.uid
      )
      if (status === 'error') {
        Notification.error('Ha ocurrido un error cargando el contrato')
      }
      this.setState({contractDetails: data})
      this.referenceByCountry(data)
      if (['es', 'it', 'pt', 'br'].includes(data.country)) {
        this.setState({reference: data.reference})
      }
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error cargando el contrato')
    }
  }

  approveContract = contractId => {
    const {referenceRequired, reference} = this.state
    const {history} = this.props
    if (referenceRequired && !reference) {
      Notification.warn('Debes capturar la referencia a usar')
      return
    }
    const data = referenceRequired ? reference : null

    this.setState({approving: true})
    API.ContractRequests.ApproveContract(contractId, {
      reference: data
    })
      .then(response => {
        if (response.status === 'error') {
          const errorMessage = response.errors.detail
          if (errorMessage.indexOf('INVALID_PROVIDER_REFERENCE') !== -1) {
            Notification.warn(
              'Por favor ingresa manualmente la referencia a usar'
            )
            this.setState({referenceRequired: true})
          } else {
            Notification.error(errorMessage)
            this.setState({
              comment: errorMessage,
              approveStatus: false,
              approving: false
            })
          }
        } else {
          history.push('/contracts/pending')
          Notification.success('Contrato creado correctamente')
        }
      })
      .catch(error => {
        console.error(error)
        Notification.error('Ocurrió un error, intenta nuevamente')
      })
      .finally(() => {
        this.setState({approving: false})
      })
  }

  rejectContract = () => {
    const {invalid, contractDetails, comment} = this.state
    const {history, match} = this.props
    const invalidFields = pickBy(invalid, field => field.length > 0)
    const contract = validateContractRequest(contractDetails)
    const dataInvalid = generateData(invalidFields, contract)
    this.setState({requestInProgress: true})
    API.ContractRequests.Reject(match.params.uid, {
      notes: comment || '',
      invalid: dataInvalid
    })
      .then(() => {
        Notification.success('Contrato rechazado')
        history.push('/contracts/pending')
      })
      .catch(() => Notification.error('Ocurrió un error, intenta nuevamente'))
      .finally(() => this.setState({requestInProgress: false}))
  }

  render() {
    const {
      comment,
      contractDetails,
      approveStatus,
      referenceRequired,
      reference,
      generating,
      invalid,
      tapIndex,
      currentTab,
      requestInProgress,
      approving
    } = this.state
    if (!contractDetails) return false
    const {
      location: {state},
      match: {params}
    } = this.props
    const contract = validateContractRequest(contractDetails)
    const {
      country,
      contract_id: contractId,
      client: {
        names,
        first_surname: firstSurname,
        second_surname: secondSurname
      },
      ard,
      product_line: productLine
    } = contract

    const contractUid = params.uid

    return (
      <Content>
        <HeaderWrapper>
          <FlexWrapper>
            <LinkButton
              url='/contracts/pending'
              buttonClass='link'
              icon='arrow-left-thick'
            >
              Contratos
            </LinkButton>
            <Header>
              <Title color='white' size='2.5rem'>
                {`${names.value} ${firstSurname.value} ${secondSurname.value ||
                  ''}`}
              </Title>
            </Header>
            <Status totalRequests={state && state > 1}>
              <div>
                <p>Revisiones</p>
                <p>{state || 'Nuevo'}</p>
              </div>
              <div>
                <p>País</p>
                <p>{getCountryLabel(country?.toUpperCase() || 'MX')}</p>
              </div>
              <div>
                <p>Sucursal</p>
                <p>{ard ? ard.branch_office : 'N/A'}</p>
              </div>
              <div>
                <p>Solicitado por</p>
                <p>{ard ? ard.ard_email : 'N/A'}</p>
              </div>
            </Status>
          </FlexWrapper>
          <TabsWrapper>
            <Tabs
              defaultIndex={tapIndex}
              selectedIndex={currentTab}
              onSelect={index =>
                this.setState(prevState => ({
                  ...prevState,
                  currentTab: index
                }))
              }
            >
              <TabList>
                <Tab>Cliente</Tab>
                {productLine === 'la_tasa_investor' ||
                productLine === 'credito' ? null : (
                  <Tab>Deudas</Tab>
                )}
                {productLine === 'la_tasa_investor' ||
                productLine === 'credito' ? null : (
                  <Tab>Historial de notas</Tab>
                )}
              </TabList>
              <TabPanel>
                <Client
                  client={contract.client}
                  country={country || 'MX'}
                  handleChangeValue={this.handleChangeValue}
                  contractUid={contractUid}
                  invalid={invalid}
                  clearInvalid={this.clearInvalid}
                  productLine={productLine}
                />
              </TabPanel>
              {productLine === 'la_tasa_investor' ||
              productLine === 'credito' ? null : (
                <TabPanel>
                  <Debts
                    data={contract}
                    country={country}
                    handleChangeValue={this.handleChangeValue}
                    invalid={invalid}
                    clearInvalid={this.clearInvalid}
                    contractUid={contractUid}
                  />
                </TabPanel>
              )}
              {productLine === 'la_tasa_investor' ||
              productLine === 'credito' ? null : (
                <TabPanel>
                  <PreviousNotes previousNotes={contract.previous_notes} />
                </TabPanel>
              )}
            </Tabs>
          </TabsWrapper>
        </HeaderWrapper>
        <WrapperNotes>
          <NoteList
            productLine={productLine}
            comment={comment}
            data={contract}
            country={country}
            handleChangeComment={this.handleChangeComment}
            currentTab={currentTab}
          />
        </WrapperNotes>
        {referenceRequired && (
          <Reference>
            <label className='label' htmlFor={reference}>
              Referencia
            </label>
            <input
              name='reference'
              maxLength={country === 'br' ? 9 : 10}
              className='input'
              value={reference}
              onChange={this.onChangeReference}
              placeholder={
                country === 'br'
                  ? `${country.toUpperCase()}1234567`
                  : `U${country.toUpperCase()}1234567`
              }
            />
            {reference && <ShowError reference={reference} country={country} />}
          </Reference>
        )}
        <ButtonWrapper>
          <Button
            buttonClass='primary'
            icon='check'
            onClick={() =>
              this.generateContract(country, contractId, contractUid)
            }
            disabled={
              !approveStatus ||
              approving ||
              generating ||
              !validReference(reference, country)
            }
            loading={approving || generating}
          >
            Aceptar
          </Button>
          <Button
            buttonClass='secondary'
            icon='cancel'
            onClick={this.rejectContract}
            disabled={approveStatus || requestInProgress}
            loading={requestInProgress}
          >
            Rechazar
          </Button>
        </ButtonWrapper>
      </Content>
    )
  }
}
