// @ts-nocheck
// Libraries
import React from 'react'
import Notification from 'cogo-toast'

// Components
import {
  getCountryLabel,
  formatDateISO,
  downloadCSV,
  getFileName,
  getQueryParam
} from 'utils'
import IconButton from 'shared/iconButton'
import Button from 'shared/button'
import moment from 'moment'

/**
 * @type {{
 * COMPLETED: string,
 * IN PROCESS: string
 * }} STATUS
 */
const STATUS = {
  COMPLETED: 'Completo',
  'IN PROCESS': 'En proceso'
}

/**
 * @param {React.MouseEvent<HTMLButtonElement>} event
 * @param {string} file
 */
const downloadDocument = (event, file) => {
  event.stopPropagation()
  if (!file) return
  const expirationDate = getQueryParam(file, 'Expires')
  const name = getFileName(file)
  if (!expirationDate) return
  const canDownload = moment
    .unix(Number(expirationDate))
    .isSameOrAfter(moment())
  if (canDownload) {
    downloadCSV(file, name)
  } else {
    Notification.error('No se pude descargar el documento, intenta de nuevo')
  }
}

/**
 * @typedef {import('api/core').Layout} Layout
 * @typedef {import('api/core').Pagination} Pagination
 * @typedef {{
 * layouts: Array<Layout>,
 * pagination: Pagination,
 * loadingLayout: Array<number>,
 * getLayoutFile: function,
 * file: string,
 * layoutId: number | null
 * }} PropsLayoutTable
 *
 * @param {PropsLayoutTable} Props
 */

const LayoutTable = ({
  layouts,
  pagination,
  loadingLayout,
  getLayoutFile,
  file,
  layoutId
}) => {
  /**
   * @param {string} status
   * @param {string} id
   */
  function showFile (status, id) {
    if (status === 'IN PROCESS') {
      return <span>Generando archivo...</span>
    } else if (layoutId === id) {
      return (
        <a onClick={event => downloadDocument(event, file)} className='link'>
          <IconButton className='right' icon='download' tooltip='ver' />
          Descargar archivo
        </a>
      )
    } else {
      return (
        <Button
          buttonClass='link'
          icon='file'
          onClick={() => getLayoutFile(id)}
          loading={loadingLayout.includes(id)}
        >
          Generar archivo
        </Button>
      )
    }
  }

  return (
    <div>
      <p className='register-results no-padding'>
        {`Hay ${pagination ? pagination.total_entries : '0'} registros`}
      </p>
      <table className='table'>
        <thead>
          <tr>
            <th>Vehículo de ahorro</th>
            <th>País</th>
            <th>Fecha</th>
            <th>Estado</th>
            <th>Archivo</th>
          </tr>
        </thead>
        <tbody>
          {layouts &&
            layouts.map(
              ({ id, saving_vehicle: savingVehicle, country, date, status }) => {
                return (
                  <tr key={id}>
                    <td>{savingVehicle}</td>
                    <td>{getCountryLabel(country)}</td>
                    <td>{formatDateISO(date)}</td>
                    <td>{STATUS[status]}</td>
                    <td style={{ paddingTop: '10px' }}>{showFile(status, id)}</td>
                  </tr>
                )
              }
            )}
        </tbody>
      </table>
    </div>
  )
}
export default LayoutTable
