// Libraries
import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import store from 'store'
import LogRocket from 'logrocket'
import moment from 'moment'
import Notification from 'cogo-toast'

// Public pages, do not require an active session
import Login from './app/login'
import ErrorPage from './app/error'
import App from './app'

import { decode } from 'utils'
import useValidateToken from 'hooks/useValidateToken'

if (process.env['NODE_ENV'] === 'production') {
  LogRocket.init('1a1p9l/core-zmzhf')
}

function PrivateRoute ({ component: Component, ...rest }) {
  const hasToken = store.get('CORE_AUTH_TOKEN')
  const { newError } = useValidateToken(hasToken)

  if (hasToken) {
    const { timestamp } = decode(hasToken)

    const dateValidToken = moment(timestamp).add(12, 'hours')
    const validDate = dateValidToken.isSameOrAfter(moment())

    if (!validDate) {
      Notification.success('Tu sesión caducó. Inicia sesión de nuevo')
      return <Redirect to='/login' />
    }
  }

  if (newError) {
    return (
      <Redirect
        to={{
          pathname: '/error',
          state: {
            error: newError
          }
        }}
      />
    )
  }

  return (
    <Route
      {...rest}
      render={props =>
        hasToken ? <Component {...props} /> : <Redirect to='/login' />
      }
    />
  )
}

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/error' component={ErrorPage} />
        <PrivateRoute path='/' component={App} />
      </Switch>
    </BrowserRouter>
  )
}
render(<Routes />, document.getElementById('app'))
