// Libararies
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import Notification from 'cogo-toast'

// Components
import { FlexWrapper } from 'css/utils'
import { white, colorPrimary, darkBlack } from 'css/colors'
import Button from 'shared/button'
import { axiosUploadFile, getProp } from 'utils'

const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 30px 0;
  & > p {
    margin-bottom: 30px;
  }
}
`
const Content = styled(FlexWrapper)`
  margin-top: 1.5rem;
  justify-content: flex-end;
`

const ContentFile = styled.div`
  position: relative;
  padding: 0.4rem 0.75rem;
  background-color: ${colorPrimary};
  border-radius: 3px;
  margin-right: 1.5rem;
`

const Input = styled.input`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0;
`

const Text = styled.p`
  text-align: center;
  color: white;
  margin-left: 1.2rem;
`

const Icon = styled.i`
  position: absolute;
  top: 5px;
  left: 8px;
  color: ${white};
  font-size: 20px;
`

const File = styled.div`
  & h3 {
    font-weight: 700;
    color: ${darkBlack};
    margin-bottom: 0.7rem;
  }
`

/**
 * @typedef {{
 * file: object,
 * requestInProgress: boolean
 * message: string
 * }} State
 *
 * @typedef {{handleUpdate: function,toggleModal: function}} Props
 *
 * @extends {React.Component<Props, State>}
 */
export default class UploadNC extends Component {
  /**
   * @type {State}
   */
  state = {
    file: {},
    message: '',
    requestInProgress: false
  }

  /**
   * @param {object} event
   */
  onChangeFile = event => {
    const { files } = event.target
    this.setState({ file: files[0] })
  }

  sendFile = () => {
    const { file } = this.state
    this.setState({ requestInProgress: true })
    axiosUploadFile('file', file, null, 'factura1/upload-nc-csv')
      .then(({ data: { data } }) => {
        Notification.success('Archivo enviado correctamente')
        this.setState({ message: data.message })
      })
      .catch(error => {
        console.error(error)
        Notification.error(
          getProp(
            error,
            'response.data.errors.detail',
            'Ha ocurrido un error enviando el archivo'
          )
        )
      })
      .finally(() => {
        this.setState({
          requestInProgress: false,
          file: {}
        })
      })
  }

  handleClose = () => {
    const { handleUpdate, toggleModal } = this.props
    toggleModal()
    handleUpdate()
  }

  render () {
    const { file, requestInProgress, message } = this.state
    return (
      <div>
        {message ? (
          <Message>
            <p>
              <strong>{message}</strong>
            </p>
            <Button
              buttonClass='secondary'
              icon='content-save'
              loading={requestInProgress}
              onClick={this.handleClose}
            >
              Cerrar
            </Button>
          </Message>
        ) : (
          <Fragment>
            <File>
              <h3>Archivo seleccionado</h3>
              <p>{file.name || 'Aún no has seleccionado el archivo'}</p>
            </File>
            <Content>
              <ContentFile>
                <Text>Selecciona archivo</Text>
                <Input type='file' accept='.csv' onChange={this.onChangeFile} />
                <Icon className='mdi mdi-upload' />
              </ContentFile>
              <Button
                buttonClass='primary'
                icon='content-save'
                loading={requestInProgress}
                disabled={!file.name || requestInProgress}
                onClick={this.sendFile}
              >
                Guardar
              </Button>
            </Content>
          </Fragment>
        )}
      </div>
    )
  }
}
