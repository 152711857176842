import React from 'react'
import { withFormik, Form, Field, ErrorMessage } from 'formik'
import styled from 'styled-components'
import Notification from 'cogo-toast'
import get from 'lodash/get'
import * as Yup from 'yup'

import { FieldWrapper } from 'css/utils'
import { getOptions } from 'utils'
import { COUNTRIES } from 'shared/catalogs'
import SelectField from 'shared/selectField'
import Button from 'shared/button'
import Required from 'shared/required'

import API from 'api/core'

const WrapperButtons = styled.div`
  border-top: 1px solid #dbdbdb;
  background-color: whitesmoke;
  padding: 1.25rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  margin-bottom: -1.25rem;
  margin-top: 1.25rem;
`

const vat = /^[0-9]{1,2}$/
/**
 * @constant
 */
const validationSchema = Yup.object().shape({
  type: Yup.string().required('Campo requerido'),
  country: Yup.string().required('Campo requerido'),
  vat: Yup.string()
    .trim()
    .matches(vat, 'Ingresa máximo 2 números')
    .typeError('Ingresa solo números')
    .required('campo requerido')
})

/**
 * @typedef {import('formik').FormikProps<FormikValues>} FormikProps
 * @typedef {{
 * type: string,
 * country: string,
 * vat: string,
 * }} FormikValues
 * @typedef {{
 * toggleModal: any,
 * receptorSelected: object
 * }} PropsAddBank
 * @param {PropsAddBank & FormikProps} props
 */

function AddReceptor ({ isSubmitting, toggleModal }) {
  return (
    <Form data-testid='form'>
      <FieldWrapper>
        <Field name='country'>
          {({ field }) => (
            <SelectField
              {...field}
              name='country'
              id='country'
              placeholder='Selecciona un opción'
              label='Selecciona país'
              options={getOptions(COUNTRIES)}
            />
          )}
        </Field>
        <ErrorMessage
          name='country'
          render={message => (
            <Required message={message} className='required-modal' />
          )}
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor='type'>Tipo de receptor</label>
        <Field id='type' name='type' />
        <ErrorMessage
          name='type'
          render={message => (
            <Required message={message} className='required-modal' />
          )}
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor='vat'>IVA</label>
        <Field id='vat' name='vat' />
        <ErrorMessage
          name='vat'
          render={message => (
            <Required message={message} className='required-modal' />
          )}
        />
      </FieldWrapper>
      <WrapperButtons>
        <Button
          type='submit'
          icon='send'
          buttonClass='primary right'
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          Enviar
        </Button>
        <Button icon='cancel' buttonClass='secondary' onClick={toggleModal}>
          Cancelar
        </Button>
      </WrapperButtons>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: ({ receptorSelected }) => ({
    type: receptorSelected.type || '',
    vat: receptorSelected.vat || '',
    country: receptorSelected.country || ''
  }),
  validationSchema,
  handleSubmit: async (values, { setSubmitting, props }) => {
    const data = props.receptorSelected.id
      ? {
        type: values.type,
        vat: values.vat,
        country: values.country,
        id: props.receptorSelected.id
      }
      : { ...values }
    const endpoint = props.receptorSelected.id
      ? API.Accounting.UpdateReceptorTypes(data)
      : API.Accounting.CreateReceptorTypes(data)
    const message = props.receptorSelected.id
      ? 'Receptor actualizado con éxito'
      : 'Receptor creado con éxito'
    setSubmitting(true)
    try {
      await endpoint
      Notification.success(message)
      props.getReceptorTypes()
    } catch (error) {
      const hasError = get(
        error,
        'response.data.errors.detail',
        'Ha ocurrido un error'
      )
      Notification.error(hasError)
    } finally {
      setSubmitting(false)
      props.toggleModal()
    }
  }
})(AddReceptor)
