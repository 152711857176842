// @ts-nocheck
import React, { Component } from 'react'
import Notification from 'cogo-toast'

import FormClarificationTypes from './FormClarificationTypes'
import IconButton from 'shared/iconButton'
import Modal from 'shared/modal'
import { getLabel } from 'utils'
import { INPUT_TYPES, REQUIRED } from 'shared/catalogs'

import API from 'api/core'

/**
 * @typedef {import('api/core').ClarificationArea} ClarificationArea
 * @typedef {import('react-router-dom').RouteComponentProps} Props
 * @typedef {import('./FormClarificationTypes').ValuesFormik} ValuesFormik
 * @typedef {import('formik').FormikProps<ValuesFormik>} FormikProps
 * @typedef {{
 * fieldId: number
 * label: string;
 * nameForm: string;
 * options: string;
 * required: boolean | null;
 * type: string;
 * }} Fields
 *
 * @typedef {{
 * fields: Array<Fields>,
 * areas: Array<ClarificationArea>,
 * openModal: boolean;
 * requestInProgress: boolean;
 * sendingClarification: boolean;
 * nameForm: string;
 * country: string;
 * area: number | null;
 * }} State
 *
 * @extends {Component<FormikProps & Props, & State}
 */

class NewClarificationType extends Component {
  /**
   * @type {State}
   */
  state = {
    fields: [],
    areas: [],
    nameForm: '',
    country: '',
    area: null,
    openModal: false,
    requestInProgress: false,
    sendingClarification: false
  }

  /**
   * @param {ValuesFormik} values
   * @param {function} setValues
   */
  addField = (values, setValues) => {
    this.setState(state => ({
      fields: [
        ...state.fields,
        {
          ...values,
          fieldId: state.fields.length + 1
        }
      ],
      nameForm: values.nameForm,
      area: Number(values.area)
    }))
    setValues({
      ...values,
      type: '',
      label: '',
      required: '',
      hideInForm: 'false',
      editable: 'false'
    })
  }

  /**
   * @param {number} fieldId
   */
  deleteField = fieldId => {
    this.setState(
      state => ({
        fields: state.fields.filter(field => fieldId !== field.fieldId)
      }),
      () => {
        if (this.state.fields.length < 1) return this.toggleModal()
      }
    )
  }

  /**
   * @param {function} resetForm
   */
  handleResetForm = resetForm => {
    this.setState({ fields: [] }, () =>
      resetForm({
        type: '',
        label: '',
        required: '',
        nameForm: '',
        hideInForm: 'false',
        editable: 'false'
      })
    )
    Notification.success('Todos los campos se han borrado')
  }

  /**
   * @param {string} country
   */
  onChangeCountry = country => {
    this.setState({ country }, this.getClarificationAreas)
  }

  toggleModal = () => {
    this.setState(state => ({ openModal: !state.openModal }))
  }

  getClarificationAreas = async () => {
    const { country } = this.state
    this.setState({ requestInProgress: true })
    try {
      const { data } = await API.Clarifications.ClarificationAreas(country)
      this.setState({ areas: data })
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error cragando las areas')
    } finally {
      this.setState({ requestInProgress: false })
    }
  }

  createClarificationType = async () => {
    const { fields, nameForm, country, area } = this.state
    const { history } = this.props
    const fieldsToSend = fields.map(field => {
      const arrayOptions = field.options ? field.options.split(',') : []
      const nameField = field.label
        .trim()
        .replace(/\s+/g, '_')
        .toLowerCase()
      return {
        id: String(field.fieldId),
        type: field.type,
        hide_in_form: field.hideInForm,
        editable: field.editable,
        name: nameField,
        label: field.label.trim(),
        required: field.required,
        values: arrayOptions.map(option => {
          return {
            id: option.trim(),
            label: option.trim()
          }
        })
      }
    })
    const data = {
      country,
      name: nameForm,
      area_id: area,
      fields: fieldsToSend
    }
    this.setState({ sendingClarification: true })
    try {
      await API.Clarifications.createClarificationTypes(data)
      Notification.success('Tipo de aclaración creada correctamente')
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error al crear el tipo de aclaración')
    } finally {
      this.setState({ sendingClarification: false })
      this.toggleModal()
      history.push('/clarification/types')
    }
  }

  render () {
    const {
      fields,
      openModal,
      nameForm,
      country,
      areas,
      requestInProgress,
      sendingClarification
    } = this.state
    return (
      <div>
        <FormClarificationTypes
          fields={fields}
          country={country}
          areas={areas}
          requestInProgress={requestInProgress}
          addField={this.addField}
          toggleModal={this.toggleModal}
          handleResetForm={this.handleResetForm}
          onChangeCountry={this.onChangeCountry}
        />
        <Modal
          title={`Campos para ${nameForm}`}
          textSave='Crear formulario'
          textCancel='Cerrar'
          testId='create-clarification'
          isActive={openModal}
          toggleModal={this.toggleModal}
          onSubmit={this.createClarificationType}
          requestInProgress={sendingClarification}
          disabled={sendingClarification}
        >
          {fields.length > 0 && (
            <div>
              <table className='table'>
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Encabezado</th>
                    <th>Opciones</th>
                    <th>Requerido</th>
                    <th>Ocultar</th>
                    <th>Editable</th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map(field => {
                    return (
                      <tr key={field.fieldId}>
                        <td>{getLabel(INPUT_TYPES, field.type)}</td>
                        <td>{field.label}</td>
                        <td>
                          {field.type === 'select' ? `${field.options}` : 'No'}
                        </td>
                        <td>{getLabel(REQUIRED, field.required)}</td>
                        <td>{getLabel(REQUIRED, field.hideInForm)}</td>
                        <td>{getLabel(REQUIRED, field.editable)}</td>
                        <td>
                          <IconButton
                            tooltip='Borrar'
                            icon='delete'
                            onClick={() => this.deleteField(field.fieldId)}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )}
        </Modal>
      </div>
    )
  }
}
export default NewClarificationType
