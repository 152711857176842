// Libraries
import React from 'react'
import styled, { css } from 'styled-components'

// Components
import { Title } from 'css/utils'
import { white, darkBlack, mediumGrey } from 'css/colors'
import IconButton from 'shared/iconButton'
import { getCountryLabel } from 'utils'

const WrapperDetails = styled.div`
  background: ${white};
  width: 350px;
  position: absolute;
  right: -353px;
  top: 0;
  height: 100vh;
  padding: 4rem 1rem 1rem 1rem;
  transition: 0.5s;
  box-shadow: -3px 1px 8px 0px rgba(217, 217, 217, 1);
  overflow: scroll;
  position: fixed;
  z-index: 5;
  ${props =>
    props.isOpen &&
    css`
      right: 0;
      transition: 0.5s;
    `}
  & > h1 {
    border-bottom: 1px solid ${mediumGrey};
    font-size: 18px;
    padding-bottom: 1rem;
    margin: 0;
  }
`
const Receptor = styled.div`
  border-bottom: 1px solid ${mediumGrey};
  padding: 0.5rem 0;
`
const Header = styled.h2`
  font-size: 18px;
  color: ${darkBlack};
  margin-bottom: 0.5rem;
`
const SubHeader = styled.p`
  font-size: 12px;
  color: ${mediumGrey};
`

/**
 * @typedef {import('api/core').PaymentReceptor} PaymentReceptor
 * @typedef {{
 * paymentReceptor: PaymentReceptor | null,
 * onCloseDetails: function,
 * isOpen: boolean,
 * }} PropsReceptorDetails
 *
 * @param {PropsReceptorDetails} props
 */
const ReceptorDetails = ({ paymentReceptor, onCloseDetails, isOpen }) => {
  if (!paymentReceptor) return null
  const {
    receptor_type: { vat, type, country: receptorTypeCountry },
    bank: { name: bankName, id: bankId },
    name,
    country,
    clabe,
    beneficiary,
    account_number: accountNumber
  } = paymentReceptor
  return (
    <div>
      {paymentReceptor && (
        <WrapperDetails isOpen={isOpen}>
          <IconButton
            icon='close'
            tooltip='Cerrar'
            className='bottom text-left'
            onClick={onCloseDetails}
          />
          <Title>Detalles del receptor</Title>
          <Receptor>
            <SubHeader>Nombre</SubHeader>
            <Header>{name}</Header>
            <SubHeader>País</SubHeader>
            <Header>{getCountryLabel(country)}</Header>
            <SubHeader>Beneficiario</SubHeader>
            <Header>{beneficiary}</Header>
            <SubHeader>Clabe</SubHeader>
            <Header>{clabe}</Header>
            <SubHeader>Número de cuenta</SubHeader>
            <Header>{accountNumber}</Header>
          </Receptor>
          <Receptor>
            <SubHeader>Tipo de receptor</SubHeader>
            <Header>{type}</Header>
            <SubHeader>Vat</SubHeader>
            <Header>{vat}</Header>
            <SubHeader>País</SubHeader>
            <Header>{getCountryLabel(receptorTypeCountry)}</Header>
          </Receptor>
          <Receptor>
            <SubHeader>Nombre del banco</SubHeader>
            <Header>{bankName}</Header>
            <SubHeader>Id del banco</SubHeader>
            <Header>{bankId}</Header>
          </Receptor>
        </WrapperDetails>
      )}
    </div>
  )
}
export default ReceptorDetails
