import React, { useEffect, useState, Fragment } from 'react'
import Notification from 'cogo-toast'

import AddReceptor from './addReceptor'
import { getCountryLabel } from 'utils'
import Button from 'shared/button'
import IconButton from 'shared/iconButton'
import FiltersAccounting from 'shared/filtersAccounting'
import Loader from 'shared/loader'
import Modal from 'shared/modal'
import API from 'api/core'

/**
 * @param {{
 * routeTab: any
 * }} props
 */
export default function ReceptorTypes ({ routeTab }) {
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [receptorSelected, setReceptorSelected] = useState({})
  const [value, setValue] = useState({ country: '' })
  const [receptorTypes, setReceptorTypes] = useState([
    {
      vat: 0,
      type: '',
      status: '',
      id: null,
      errors: null,
      country: ''
    }
  ])

  useEffect(() => {
    routeTab()
    getReceptorTypes()
  }, [value.country])

  async function getReceptorTypes () {
    const params = {
      country: value.country || 'MX'
    }
    setLoading(true)
    try {
      const { data } = await API.Accounting.GetReceptorTypes(params)
      setReceptorTypes(data)
    } catch (error) {
      Notification.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  function onChangeField (event) {
    const { value } = event.currentTarget
    setValue({ country: value })
  }

  function onCleanFilters () {
    if (!value.country) return
    setValue({ country: '' })
  }

  function onFilter () {
    if (!value.country) return
    getReceptorTypes()
  }

  /**
   * @param {{
   * status: string,
   * vat: number,
   * type: string,
   * id: string | null,
   * errors: null,
   * country: string
   * }} receptor
   */
  function hadleUpateReceptor (receptor) {
    setReceptorSelected(receptor)
    setOpenModal(!openModal)
  }

  function handleToggle () {
    setOpenModal(!openModal)
    setReceptorSelected({})
  }

  return (
    <Fragment>
      <FiltersAccounting
        className='filter-accounting'
        filters={value}
        onFilter={() => onFilter()}
        onChangeField={e => onChangeField(e)}
        onCleanFilters={() => onCleanFilters()}
      />
      <div className='flex-between'>
        <p className='register-results'>
          Hay {receptorTypes.length} resultados
        </p>
        <Button
          buttonClass='create'
          icon='plus-circle-outline'
          testId='new-receptor'
          onClick={() => setOpenModal(!openModal)}
        >
          Agregar receptor
        </Button>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <table className='table'>
          <thead>
            <tr>
              <th>Tipo de receptor</th>
              <th>País</th>
              <th>IVA</th>
            </tr>
          </thead>
          <tbody>
            {receptorTypes.map(receptor => (
              <tr key={receptor.id}>
                <td className='link'>{receptor.type}</td>
                <td>{getCountryLabel(receptor.country)}</td>
                <td>{receptor.vat}</td>
                <td>
                  <IconButton
                    icon='pencil'
                    testId='edit-receptor'
                    tooltip='Editar receptor'
                    onClick={() => hadleUpateReceptor(receptor)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Modal
        title={receptorSelected.id ? 'Actualizar receptor' : 'Agregar receptor'}
        toggleModal={() => handleToggle()}
        isActive={openModal}
        noFooter
      >
        <AddReceptor
          toggleModal={() => handleToggle()}
          getReceptorTypes={() => getReceptorTypes()}
          receptorSelected={receptorSelected}
        />
      </Modal>
    </Fragment>
  )
}
