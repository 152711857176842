import React from 'react'
import { errorLabel } from 'css/colors'

/**
 * @typedef {Object} RequiredProps
 * @property {string} message
 * @property {string} [className]
 * @param {RequiredProps} props
 */

const ErrorLabel = ({ message, className }) => (
  <span style={{ color: errorLabel, fontSize: '14px' }}>{message}</span>
)
export default ErrorLabel
