import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { Title, FlexWrapper } from 'css/utils'
import {
  white,
  appleGrey,
  mediumGrey,
  darkBlack,
  buttonPrimary
} from 'css/colors'

const SIZE = '1.3rem'

const Content = styled.div`
  background: ${white};
  padding: 4rem;
  align-items: flex-start;
`
const FlexStartWrapper = styled(FlexWrapper)`
  justify-content: flex-start;
`
const CommentWrapper = styled.div`
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  border-left: 3px solid ${buttonPrimary};
  border-bottom: 1px solid ${appleGrey};
  margin-bottom: 2rem;
`
const ContentComment = styled.div`
  & > time {
    border-bottom: 1px solid ${buttonPrimary};
    font-size: 13px;
    color: ${mediumGrey};
  }
  & p:first-child {
    font-weight: bold;
  }
  & p:last-child {
    margin-top: 0.7rem;
    color: ${darkBlack};
    white-space: pre-wrap;
  }
`
const ImageWrapper = styled.figure`
  margin-right: 1rem;
  align-self: flex-start;
  & > img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`

/**
 * @typedef {{
 * previousNotes: {reviewer: string, timestamp: Date | string, note: string}[],
 * }} previousNotesProps
 *
 * @param {previousNotesProps} props
 */
export default function index ({ previousNotes }) {
  return (
    <Content>
      <Title size={SIZE}>Notas de rechazo previo</Title>
      {previousNotes &&
        previousNotes.length > 0 &&
        previousNotes.map(({ reviewer, timestamp, note }, index) => {
          const insertedAt = moment(timestamp).format('MMMM DD, YYYY, hh:mm:a')
          return (
            <CommentWrapper key={index}>
              <FlexStartWrapper>
                <ImageWrapper>
                  <img src='/img/resuelve.png' alt={`${reviewer} avatar`} />
                </ImageWrapper>
                <ContentComment>
                  <p>{reviewer}</p>
                  <time dateTime={insertedAt}>{insertedAt}</time>
                  <p>{note}</p>
                </ContentComment>
              </FlexStartWrapper>
            </CommentWrapper>
          )
        })}
    </Content>
  )
}
