// @ts-nocheck
/* global ResuelveLogin */
import { useEffect, useState } from 'react'
import store from 'store'
import { useLocation } from 'react-router-dom'

export default function (currentToken) {
  const { key } = useLocation()

  const [newToken, setNewToken] = useState(null)
  const [newError, setNewError] = useState(null)

  useEffect(() => {
    // @ts-ignore
    ResuelveLogin(
      {
        domain: process.env.RESUELVE_LOGIN
      },
      false,
      true
    )
      .refreshToken(currentToken)
      .then(({ success, error: refreshError, token }) => {
        if (success && token) {
          store.set('CORE_AUTH_TOKEN', token)
          setNewToken(token)
        }
        if (!success && !token) {
          setNewError(refreshError)
        }
      })
      .catch(error => console.error(error))
  }, [key])

  return { newToken, newError }
}
