// Libraries
import React from 'react'

const AudioPlayer = ({ title, src, notes }) => (
  <React.Fragment>
    <div className='field is-grouped is-grouped-centered'>
      <audio controls>
        <source src={src} />
      </audio>
    </div>
    <div
      style={{ marginTop: 10 }}
      className='field is-grouped is-grouped-centered'
    >
      <p className='control'>
        <a className='button is-primary' href={src} target='_blank'>
          <i className='mdi mdi-download' />
          Descargar
        </a>
      </p>
    </div>
  </React.Fragment>
)

export default AudioPlayer
