/* eslint-disable */
import React from 'react'
import moment from 'moment'
import axios from 'axios'
import store from 'store'
import Notification from 'cogo-toast'

import DatePicker from 'shared/datePicker'
import SelectField from 'shared/selectField'
import {InputAmount, Input} from '../clarificationDetails/styles'
import {validateUrl, formatDate, validateMoney} from 'utils'

const CURRENCIES = [
  {id: 'MXN', label: 'MXN'},
  {id: 'COP', label: 'COP'},
  {id: 'EUR', label: 'EUR'}
]

/**
 * @typedef {{
 * type: string
 * name: string
 * fieldValue: object
 * hanldeChangeDate: Function
 * hanldeChangeValue: Function
 * hanldeChangeCurrency: Function
 * currency: string
 * }} showFieldByTypeProps
 *  @param {showFieldByTypeProps} props
 */
export const showFieldByType = ({
  type,
  name,
  fieldValue,
  hanldeChangeDate,
  hanldeChangeValue,
  hanldeChangeCurrency,
  currency
}) => {
  const value = fieldValue[name]
  switch (type) {
    case 'date':
      const dateSelected = value ? new Date(value) : new Date()
      return (
        <DatePicker
          placeholderText='09/07/2020'
          name={name}
          onChange={date => hanldeChangeDate(date, name)}
          selected={dateSelected}
        />
      )
    case 'money':
      return (
        <div className='flex-between'>
          <InputAmount
            type='number'
            name={name}
            onChange={hanldeChangeValue}
            value={value}
          />
          <SelectField
            name={name}
            options={CURRENCIES}
            onChange={hanldeChangeCurrency}
            value={currency}
            placeholder='MXN'
          />
        </div>
      )
    default:
      return <Input name={name} onChange={hanldeChangeValue} value={value} />
  }
}

/**
 * @param {string} value
 * @param {string} label
 * @param {string} type
 */
export const hasValue = (value, label, type) => {
  const isUrl = validateUrl(value)
  const isDate = moment(value).isValid()
  const isMoney = validateMoney(value)
  if (isUrl || type === 'file') {
    const showLabel = type === 'file' ? 'Archivo' : label
    return isUrl ? (
      <a
        href={value}
        target='_blank'
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <i
          className='mdi mdi-eye'
          style={{
            marginRight: '10px'
          }}
        />{' '}
        {showLabel}
      </a>
    ) : (
      <div>{`Sin ${showLabel}`}</div>
    )
  } else if (isDate && type === 'date') {
    return formatDate(value)
  } else if (isMoney) {
    const [, value, coin] = isMoney
    return `$ ${value} ${coin}`
  }
  return value
}

/**
 * @param {{k: string}} params
 * @param {(arg: boolean) => void} downloading
 */
export const downloadReport = (params, downloading) => {
  const url = `${process.env.API_CORE_SERVER +
    process.env.API_PREFIX_CORE}/clarifications/report`

  downloading(true)

  axios
    .get(url, {
      params: {...params},
      responseType: 'blob',
      headers: {
        Authorization: store.get('CORE_AUTH_TOKEN')
      }
    })
    .then(response => {
      const blobURL = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = blobURL
      link.setAttribute('download', 'report.csv')
      document.body.appendChild(link)
      link.click()
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL)
      }, 100)
    })
    .catch(error => {
      console.error(error)
      Notification.error('Ocurrió un error al descargar el reporte')
    })
    .finally(() => {
      downloading(false)
    })
}
