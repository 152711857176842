// Libararies
import React, { Component } from 'react'
import styled from 'styled-components'
import Notification from 'cogo-toast'

// Components
import { FlexWrapper } from 'css/utils'
import { white, colorPrimary, mediumGrey } from 'css/colors'
import Button from 'shared/button'
import { axiosUploadFile, getProp } from 'utils'

const Content = styled(FlexWrapper)`
  margin-top: 1.5rem;
`

const ContentFile = styled.div`
  position: relative;
  padding: 0.4rem 0.75rem;
  background-color: ${colorPrimary};
  border-radius: 3px;
  margin-right: 1.5rem;
`

const Input = styled.input`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0;
  padding: 9px 0;
  cursor: pointer;
`

const Text = styled.p`
  text-align: center;
  color: white;
  margin-left: 1.2rem;
`

const Icon = styled.i`
  position: absolute;
  top: 5px;
  left: 8px;
  color: ${white};
  font-size: 20px;
`

const File = styled.div`
  & h3 {
    color: ${colorPrimary};
    margin-bottom: 0.7rem;
  }
  & p {
    border: 1px solid ${mediumGrey};
    border-radius: 3px;
    padding: 0.3rem 0.75rem;
  }
`

/**
 * @typedef {{
 * file: object,
 * requestInProgress: boolean
 * }} State
 *
 * @typedef {{handleUpdate: function,toggleModal: function}} Props
 *
 * @extends {React.Component<Props, State>}
 */
export default class UploadStatus extends Component {
  /**
   * @type {State}
   */
  state = {
    file: {},
    requestInProgress: false
  }

  /**
   * @param {object} event
   */
  onChangeFile = event => {
    const { files } = event.target
    this.setState({ file: files[0] })
  }

  sendFile = () => {
    const { file } = this.state
    const { handleUpdate, toggleModal } = this.props
    this.setState({ requestInProgress: true })
    axiosUploadFile('file', file, null, 'finances/flow_payment', 'put')
      .then(response => {
        Notification.success('Archivo enviado correctamente')
        handleUpdate()
      })
      .catch(error => {
        console.error(error)
        Notification.error(
          getProp(
            error,
            'response.data.errors.detail',
            'Ha ocurrido un error enviando el archivo'
          )
        )
      })
      .finally(() => {
        this.setState({
          requestInProgress: false,
          file: {}
        })
        toggleModal()
      })
  }

  render () {
    const { file, requestInProgress } = this.state
    return (
      <div>
        {file.name && (
          <File>
            <h3>Archivo seleccionado</h3>
            <p>{file.name}</p>
          </File>
        )}
        <Content>
          <ContentFile>
            <Text>Selecciona archivo</Text>
            <Input type='file' accept='.csv' onChange={this.onChangeFile} />
            <Icon className='mdi mdi-upload' />
          </ContentFile>
          <Button
            buttonClass='primary'
            icon='content-save'
            loading={requestInProgress}
            disabled={!file.name || requestInProgress}
            onClick={this.sendFile}
          >
            Guardar
          </Button>
        </Content>
      </div>
    )
  }
}
