import React from 'react'

const Check2 = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 149.78 149.78'
    width='40'
    height='40'
  >
    <title>Logo Check</title>
    <path
      d='M127.43 47.56c4.49 8.41 7.04 18 7.04 28.18 0 33.1-26.93 60.04-60.04 60.04S14.4 108.84 14.4 75.74 41.33 15.7 74.44 15.7c16.24 0 30.98 6.49 41.8 17l8.13-6.96c-12.8-12.78-30.46-20.7-49.94-20.7-38.98 0-70.7 31.71-70.7 70.7s31.71 70.7 70.7 70.7 70.7-31.71 70.7-70.7c0-13.6-3.87-26.31-10.56-37.11l-7.14 8.93z'
      fill='#003a63'
    />
    <path fill='#8dba38' d='M69.57 79.66L45.01 64.81l29.98 42.04 76.9-96.02z' />
  </svg>
)

export default Check2
