// Libraries
import React from 'react'
import styled from 'styled-components'

// Componnets
import { SubTitle } from './styles'
import { mediumGrey } from 'css/colors'
import { formatCurrency } from 'utils'

const ContentWrapper = styled.div`
  align-self: flex-start;
  width: 100%;
`

const Table = styled.table`
  padding-top: 0.8em;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  border-collapse: collapse;
  & > tfoot tr {
    :last-child {
      border-bottom: hidden;
    }
    & > td {
      border: none;
      border-bottom: 1px solid ${mediumGrey};
      font-size: 0.9rem;
    }
  }
`
const Td = styled.td`
  padding: 0.5rem 8em 0.5em 0;
  font-weight: 600;
`
const TdRight = styled.td`
  text-align: right;
  line-height: 36px;
  font-weight: 800;
`
/**
 * @typedef {import('api/core').TypeInvoiceDetails} TypeInvoiceDetails
 * @typedef {{
 * invoice: TypeInvoiceDetails
 * }} PropsTotal
 * @param {PropsTotal} props
 */
const Total = ({ invoice }) => {
  return (
    <ContentWrapper>
      <SubTitle>Total</SubTitle>
      <Table>
        <tfoot>
          <tr>
            <Td>Total descuentos</Td>
            <TdRight>
              {invoice.currency &&
                formatCurrency(+invoice.total_discounts, invoice.currency)}
            </TdRight>
          </tr>
          <tr>
            <Td>Total impuestos</Td>
            <TdRight>
              {invoice.currency &&
                formatCurrency(+invoice.total_taxes, invoice.currency)}
            </TdRight>
          </tr>
          <tr>
            <Td>Impuestos retenidos</Td>
            <TdRight>
              {invoice.currency &&
                formatCurrency(+invoice.total_withheld_taxes, invoice.currency)}
            </TdRight>
          </tr>
          <tr>
            <Td>Subtotal</Td>
            <TdRight>
              {invoice.currency &&
                formatCurrency(+invoice.subtotal, invoice.currency)}
            </TdRight>
          </tr>
          <tr>
            <Td>Total</Td>
            <TdRight>
              {invoice.currency &&
                formatCurrency(+invoice.total, invoice.currency)}
            </TdRight>
          </tr>
        </tfoot>
      </Table>
    </ContentWrapper>
  )
}
export default Total
