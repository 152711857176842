// Libraries
import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

// Components
import Breadcrumb from 'shared/breadcrumb'

export const DocumentTitle = ({ title }) => (
  <Helmet>
    <title>{title ? `${title} – Core Bancario` : 'Resuelve Core'}</title>
  </Helmet>
)

DocumentTitle.propTypes = {
  title: PropTypes.string
}

const Title = ({ title, breadcrumbLocation }) => (
  <Fragment>
    <DocumentTitle title={title} />
    {breadcrumbLocation && <Breadcrumb items={breadcrumbLocation} />}
    <h1 className='title is-3'>{title}</h1>
  </Fragment>
)

Title.propTypes = {
  title: PropTypes.string,
  breadcrumbLocation: PropTypes.array
}

export default Title
