// Libraries
import React, { Component } from 'react'
import Notification from 'cogo-toast'
import styled from 'styled-components'
import moment from 'moment'
import copy from 'copy-to-clipboard'

// Components
import { FlexWrapper } from 'css/utils'
import { white } from 'css/colors'
import SelectField from 'shared/selectField'
import Button from 'shared/button'
import PreviewFile from 'shared/previewFile'
import Toggle from '../../../components/toggle'
import Field from '../../../components/field'
import { getCountryLabel } from 'utils'

import { DOCUMENT_TITLES } from 'shared/catalogs'
import API from 'api/core'

const Content = styled(FlexWrapper)`
  background: ${white};
  padding: 4rem;
  align-items: flex-start;
`
const DebtsWrapper = styled.div`
  width: 40%;
  margin-right: 3rem;
`
const DocumentWrapper = styled.div`
  align-self: flex-start;
  width: 60%;
  position: sticky;
  position: -webkit-sticky;
  top: 60px;
  & > div:nth-child(1) {
    margin-bottom: 1rem;
  }
  & > div:last-child {
    margin-left: 0;
  }
`
const Select = styled(FlexWrapper)`
  & > button {
    align-self: flex-end;
  }
`
/**
 * @typedef {import('api/core').TypeContractPending} TypeContractPending
 * @typedef {{
 * data: TypeContractPending,
 * contractUid: string,
 * handleChangeValue: function,
 * clearInvalid: function,
 * invalid: object,
 * }} PropsDebts
 * @typedef {{
 * updating: boolean,
 * documentSelected: object
 * }} State
 *
 * @extends {Component<PropsDebts, State>}
 *
 */
export default class Debts extends Component {
  /**
   * @type {State}
   */
  state = {
    updating: false,
    documentSelected: {}
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  selectDocument = event => {
    const { data } = this.props
    const { value } = event.currentTarget
    const documents = data.files.filter(item => item.uid === value)
    const debtsDocuments = Object.assign({}, ...documents)
    this.setState({
      documentSelected: debtsDocuments
    })
  }

  updateDocument = async () => {
    const { contractUid } = this.props
    const { documentSelected } = this.state
    const uid =
      documentSelected.name === 'DOCUMENT_SETTLEMENT_PLAN'
        ? 'DOCUMENT_SETTLEMENT_PLAN'
        : documentSelected.uid
    this.setState({ updating: true })
    try {
      const { data } = await API.ContractRequests.UpdateDocument(contractUid, uid)
      this.setState(state => ({
        documentSelected: { ...state.documentSelected, url: data.url }
      }))
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error actualizando el archivo')
    } finally {
      this.setState({ updating: false })
    }
  }

  /**
   * @param {number} index
   */
  onCopy = index => {
    const {
      data: { debts }
    } = this.props
    const debt = debts[index]
    const debtCopy = [
      `Deuda: ${index + 1}`,
      `Número de crédito: ${debt.credit_number.value} `,
      `Nombre de entidad financiera: ${debt.financial_entity_name.value}`,
      `Tipo de producto: ${debt.product_type_name.value}`,
      `Fecha de último pago: ${debt.last_paid_date.value}`,
      `Fecha de última actualización: ${debt.last_update_date.value}`,
      `Monto capturado: ${debt.entered_amount.value}`,
      `Monto actualizado: ${debt.updated_amount.value}`,
      `Notas: ${debt.note.value}`
    ]
    const copyText = debtCopy.join('\n')
    return [copy(copyText), Notification.success('Deuda copiada')]
  }

  render () {
    const { data, handleChangeValue, invalid, clearInvalid } = this.props

    const { documentSelected, updating } = this.state

    const optionDocuments =
      data &&
      data.files.map(({ name, uid, entity }) => {
        return {
          id: uid,
          // @ts-ignore
          label: `${DOCUMENT_TITLES[name]} ${entity}`
        }
      })

    const nameKey = Object.keys(invalid).map(item => {
      const [_, index] = item.split('.') //eslint-disable-line
      if (invalid[item]) return index
    })

    const hasModified = data.debts.map(item => {
      return Object.entries(item).map(item => item[1].modified)
    })

    return (
      <Content>
        <DebtsWrapper>
          {data.debts &&
            data.debts.length > 0 &&
            data.debts.map(
              (
                {
                  credit_number: creditNumber,
                  updated_amount: updatedAmount,
                  last_update_date: lastUpdateDate,
                  last_paid_date: lastPaidDate,
                  financial_entity_name: financialEntityName,
                  product_type_name: productTypeName,
                  entered_amount: enteredAmount,
                  note
                },
                index
              ) => {
                return (
                  <Toggle
                    key={index}
                    title={`${index + 1}) Deuda con ${
                      financialEntityName.value
                    }`}
                    isInvalid={nameKey.includes(String(index))}
                    modified={hasModified[index].includes(true)}
                    onCopy={() => this.onCopy(index)}
                    isDebt
                  >
                    <Field
                      name={`debts_creditNumber.${index}`}
                      subHeader='Número de credito'
                      invalid={invalid}
                      header={creditNumber.value}
                      handleChangeValue={handleChangeValue}
                      modified={creditNumber.modified}
                      clearInvalid={clearInvalid}
                    />
                    <Field
                      name={`debts_financialEntityName.${index}`}
                      subHeader='Entidad financiera'
                      invalid={invalid}
                      header={financialEntityName.value}
                      handleChangeValue={handleChangeValue}
                      modified={financialEntityName.modified}
                      clearInvalid={clearInvalid}
                    />
                    <Field
                      name={`debts_productTypeName.${index}`}
                      subHeader='Producto'
                      invalid={invalid}
                      header={productTypeName.value || '-'}
                      handleChangeValue={handleChangeValue}
                      modified={productTypeName.modified}
                      clearInvalid={clearInvalid}
                    />
                    <Field
                      name={`debts_lastPaidDate.${index}`}
                      subHeader='Fecha de último pago'
                      invalid={invalid}
                      header={moment(lastPaidDate.value).format('DD-MMM-YYYY')}
                      handleChangeValue={handleChangeValue}
                      modified={lastPaidDate.modified}
                      clearInvalid={clearInvalid}
                    />
                    <Field
                      name={`debts_lastUpdateDate.${index}`}
                      subHeader='Fecha de última actualización'
                      invalid={invalid}
                      header={moment(lastUpdateDate.value).format(
                        'DD-MMM-YYYY'
                      )}
                      handleChangeValue={handleChangeValue}
                      modified={lastUpdateDate.modified}
                      clearInvalid={clearInvalid}
                    />
                    <Field
                      name={`debts_enteredAmount.${index}`}
                      subHeader='Monto capturado'
                      invalid={invalid}
                      header={enteredAmount.value}
                      handleChangeValue={handleChangeValue}
                      modified={enteredAmount.modified}
                      clearInvalid={clearInvalid}
                    />
                    <Field
                      name={`debts_updatedAmount.${index}`}
                      subHeader='Monto actualizado'
                      invalid={invalid}
                      header={updatedAmount.value}
                      handleChangeValue={handleChangeValue}
                      modified={updatedAmount.modified}
                      clearInvalid={clearInvalid}
                    />
                    <Field
                      name={`debts_note.${index}`}
                      subHeader='Notas'
                      invalid={invalid}
                      header={note.value}
                      handleChangeValue={handleChangeValue}
                      modified={note.modified}
                      clearInvalid={clearInvalid}
                    />
                  </Toggle>
                )
              }
            )}
          <Toggle
            title='Pago mensual'
            isInvalid={
              nameKey.includes('country') || nameKey.includes('monthly_payment')
            }
            modified={data.monthly_payment.modified}
          >
            <Field
              name='.country'
              subHeader='País'
              invalid={invalid}
              header={getCountryLabel(data.country.toUpperCase())}
              handleChangeValue={handleChangeValue}
              clearInvalid={clearInvalid}
            />
            <Field
              name='.monthly_payment'
              subHeader='Pago mensual'
              invalid={invalid}
              header={data.monthly_payment.value}
              handleChangeValue={handleChangeValue}
              modified={data.monthly_payment.modified}
              clearInvalid={clearInvalid}
            />
          </Toggle>
        </DebtsWrapper>
        <DocumentWrapper>
          <Select>
            <SelectField
              name='documents'
              label='Documentos'
              placeholder='Selecciona una opción'
              options={optionDocuments}
              value={documentSelected && documentSelected.uid}
              onChange={this.selectDocument}
              loading={updating}
            />
            <Button
              buttonClass='primary left'
              tooltip='Actualizar'
              icon='refresh'
              disabled={!documentSelected || !documentSelected.url}
              onClick={this.updateDocument}
            />
          </Select>
          {documentSelected && (
            <PreviewFile
              key={documentSelected.url}
              url={documentSelected.url}
              title={documentSelected.name}
            />
          )}
        </DocumentWrapper>
      </Content>
    )
  }
}
