// Libraries
import React from 'react'

const Input = ({ title, value, disabled }) => (
  <div className='field'>
    <label className='label'>{title}</label>
    <div className='control'>
      <input
        className='input'
        type='text'
        placeholder={title}
        value={value}
        disabled={disabled}
      />
    </div>
  </div>
)

export default Input
