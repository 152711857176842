// Libraries
import React, { Component } from 'react'
import { withFormik, Field, Form, ErrorMessage } from 'formik'
import Notification from 'cogo-toast'
import * as Yup from 'yup'

// Components
import { FlexWrapper, FieldWrapper, MARGIN } from 'css/utils'
import SelectField from 'shared/selectField'
import { getOptions } from 'utils'
import { COUNTRIES } from 'shared/catalogs'
import API from 'api/core'
import Button from 'shared/button'
import Required from 'shared/required'

/**
 * @constant
 */
const validationSchema = Yup.object().shape({
  country: Yup.string().required('Campo requerido'),
  company: Yup.string().required('Campo requerido'),
  bankId: Yup.string().required('Campo requerido'),
  description: Yup.string().required('Campo requerido'),
  clabe: Yup.number()
    .typeError('Agrega solo números')
    .required('Campo requerido'),
  accountNumber: Yup.number()
    .typeError('Agrega solo números')
    .required('Campo requerido')
})

/**
 * @typedef {import('api/core').BankAccount} BankAccount
 * @typedef {import('api/core').companies} Companies
 * @typedef {import('api/core').Bank} Bank
 * @typedef {import('formik').FormikProps<ValuesNewBankAccount>} FormikProps
 *
 * @typedef {{
 * country: string,
 * company: string | number,
 * bankId: string | number,
 * description: string,
 * clabe: number | string,
 * accountNumber: number | string,
 * }} ValuesNewBankAccount
 *
 * @typedef {{
 * companies: Companies,
 * banks: Array<Bank>,
 * requestInProgress: boolean
 * requestingBank: boolean
 * }} State
 *
 * @typedef {{
 * toggleModal: Function,
 * isUpdate: boolean,
 * bankAccountUpdate: BankAccount,
 * handleUpdate: Function,
 * }} Props
 *
 * @extends {Component<Props & FormikProps, State>}
 */

class NewBankAccount extends Component {
  /**
   * @type {State}
   */
  state = {
    companies: [],
    banks: [],
    requestInProgress: false,
    requestingBank: false
  }

  /**
   * @description
   * If a bank account is update
   * The data is added to the values
   * In the existing keys in the form
   */
  componentDidMount = () => {
    const { isUpdate } = this.props
    if (isUpdate) {
      this.handleUpdate()
    }
  }

  /**
   * @param {object} prevProps
   * @param {State} _
   */
  componentDidUpdate (prevProps, _) {
    const { values } = this.props
    if (values.country !== prevProps.values.country) {
      this.getCatalogues(values.country)
      this.getBanks(values.country)
    }
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  onChangeCountry = event => {
    const { value } = event.currentTarget
    const { setFieldValue } = this.props
    setFieldValue('country', value)
  }

  /**
   * @description
   * set values in keys existing in the form
   */
  handleUpdate = () => {
    const { bankAccountUpdate, setValues } = this.props
    setValues({
      country: bankAccountUpdate.bank.country,
      description: bankAccountUpdate.description,
      bankId: bankAccountUpdate.bank.id,
      company: bankAccountUpdate.company.id,
      clabe: bankAccountUpdate.clabe,
      accountNumber: bankAccountUpdate.account_number
    })
  }

  /**
   * @param {string} country
   */
  getCatalogues = async country => {
    this.setState({ requestInProgress: true })
    try {
      const { companies } = await API.Accounting.GetCatalogues({ country })
      this.setState({ companies })
    } catch (error) {
      console.log(error)
      Notification.error('Ha ocurrido un error cargando los catalogos')
    } finally {
      this.setState({ requestInProgress: false })
    }
  }

  /**
   * @param {string} country
   */
  getBanks = async country => {
    this.setState({ requestingBank: true })
    try {
      const { data } = await API.Accounting.GetBanks({ country })
      this.setState({ banks: data })
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error cargado los bancos')
    } finally {
      this.setState({ requestingBank: false })
    }
  }

  render () {
    const {
      handleSubmit,
      toggleModal,
      isSubmitting,
      isValid,
      isUpdate,
      handleUpdate
    } = this.props
    const { companies, banks, requestInProgress, requestingBank } = this.state
    return (
      <Form onSubmitCapture={handleSubmit} data-testid='form'>
        <FlexWrapper>
          <FieldWrapper right={MARGIN}>
            <Field name='country'>
              {({ field }) => (
                <SelectField
                  {...field}
                  name='country'
                  label='País'
                  placeholder='País'
                  options={getOptions(COUNTRIES)}
                  onChange={this.onChangeCountry}
                  required
                />
              )}
            </Field>
            <ErrorMessage
              name='country'
              render={message => (
                <Required message={message} className='required-modal' />
              )}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Field name='company'>
              {({ field }) => (
                <SelectField
                  {...field}
                  name='company'
                  label='Empresa'
                  placeholder='Empresa'
                  options={getOptions(companies)}
                  loading={requestInProgress}
                  required
                />
              )}
            </Field>
            <ErrorMessage
              name='company'
              render={message => (
                <Required message={message} className='required-modal' />
              )}
            />
          </FieldWrapper>
        </FlexWrapper>
        <FlexWrapper>
          <FieldWrapper right={MARGIN}>
            <Field name='bankId'>
              {({ field }) => (
                <SelectField
                  {...field}
                  name='bankId'
                  label='Banco'
                  placeholder='Banco'
                  options={getOptions(banks)}
                  loading={requestingBank}
                  required
                />
              )}
            </Field>
            <ErrorMessage
              name='bankId'
              render={message => (
                <Required message={message} className='required-modal' />
              )}
            />
          </FieldWrapper>
          <div className='newFlow--field input-full-width'>
            <label htmlFor='description'>Descripción *</label>
            <Field
              id='description'
              name='description'
              placeholder='Descripción'
            />
            <ErrorMessage
              name='description'
              render={message => (
                <Required message={message} className='required-modal' />
              )}
            />
          </div>
        </FlexWrapper>
        <FlexWrapper>
          <div className='newFlow--field input-full-width text-right'>
            <label htmlFor='clabe'>Clabe *</label>
            <Field id='clabe' name='clabe' placeholder='Clabe' />
            <ErrorMessage
              name='clabe'
              render={message => <Required message={message} />}
            />
          </div>
          <div className='newFlow--field input-full-width'>
            <label htmlFor='accountNumber'>Número de cuenta *</label>
            <Field
              id='accountNumber'
              name='accountNumber'
              placeholder='Número de cuenta'
            />
            <ErrorMessage
              name='accountNumber'
              render={message => <Required message={message} />}
            />
          </div>
        </FlexWrapper>
        <div className='flex-end'>
          <Button
            buttonClass='primary'
            icon='check'
            type='submit'
            loading={isSubmitting}
            disabled={!isValid}
          >
            {isUpdate ? 'Actualizar cuenta' : 'Crear cuenta'}
          </Button>
          <Button
            buttonClass='secondary'
            icon='cancel'
            type='button'
            onClick={isUpdate ? handleUpdate : toggleModal}
          >
            Cancelar
          </Button>
        </div>
      </Form>
    )
  }
}
export default withFormik({
  mapPropsToValues: () => ({
    country: '',
    company: '',
    bankId: '',
    description: '',
    clabe: '',
    accountNumber: ''
  }),
  validationSchema,
  handleSubmit: async (values, { setSubmitting, props }) => {
    const data = {
      id: props.bankAccountUpdate ? props.bankAccountUpdate.id : null,
      country: values.country,
      description: values.description,
      bank_id: values.bankId,
      company_id: values.company,
      clabe: values.clabe,
      account_number: values.accountNumber
    }
    setSubmitting(true)
    const apiCall = props.isUpdate
      ? API.Accounting.UpdateBankAccount(data)
      : API.Accounting.CreateBankAccount(data)
    const success = props.isUpdate ? 'actualizada' : 'creada'
    const errorMessage = props.isUpdate ? 'actualizar' : 'crear'

    try {
      await apiCall
      Notification.success(`Cuenta ${success} correctamente`)
      props.getBankAccounts()
      props.toggleModal()
    } catch (error) {
      console.log(error)
      Notification.error(
        `Ha ocurrido un error al ${errorMessage} la cuenta de banco`
      )
    }
    setSubmitting(false)
  },
  displayName: 'NewBankAccount'
})(NewBankAccount)
