import React, { useEffect, useState, Fragment } from 'react'
import Notification from 'cogo-toast'

import AddArea from './addArea'
import { getCountryLabel } from 'utils'
import Button from 'shared/button'
import IconButton from 'shared/iconButton'
import FiltersAccounting from 'shared/filtersAccounting'
import Loader from 'shared/loader'
import Modal from 'shared/modal'
import API from 'api/core'

/**
 * @param {{
 * routeTab: any
 * }} props
 */
export default function Areas ({ routeTab }) {
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [value, setValue] = useState('')
  const [areaSelected, setAreaSelected] = useState({})
  const [areas, setAreas] = useState([
    {
      country: '',
      id: null,
      name: ''
    }
  ])

  useEffect(() => {
    routeTab()
    getAreas()
  }, [value])

  async function getAreas () {
    const params = {
      country: value || 'MX'
    }
    setLoading(true)
    try {
      const { data } = await API.Accounting.GetAreas(params)
      setAreas(data)
    } catch (error) {
      Notification.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  function onChangeField (event) {
    const { value } = event.currentTarget
    setValue(value)
  }

  function onCleanFilters () {
    if (!value) return
    setValue('')
  }

  function onFilter () {
    if (!value) return
    getAreas()
  }

  /**
   * @param {{
   * name: string,
   * id: string | null,
   * country: string
   * }} area
   */
  function hadleUpdateArea (area) {
    setAreaSelected(area)
    setOpenModal(!openModal)
  }

  function handleToggle () {
    setOpenModal(!openModal)
    setAreaSelected({})
  }

  return (
    <Fragment>
      <FiltersAccounting
        className='filter-accounting'
        filters={{ country: value }}
        onFilter={() => onFilter()}
        onChangeField={e => onChangeField(e)}
        onCleanFilters={() => onCleanFilters()}
      />
      <div className='flex-between'>
        <p className='register-results'>Hay {areas.length} resultados</p>
        <Button
          buttonClass='create'
          icon='plus-circle-outline'
          testId='new-area'
          onClick={() => setOpenModal(!openModal)}
        >
          Agregar área
        </Button>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <table className='table'>
          <thead>
            <tr>
              <th>Nombre del área</th>
              <th>País</th>
              <th>Id del área</th>
            </tr>
          </thead>
          <tbody>
            {areas.map(area => (
              <tr key={area.id}>
                <td className='link'>{area.name}</td>
                <td>{getCountryLabel(area.country)}</td>
                <td>{area.id}</td>
                <td>
                  <IconButton
                    icon='pencil'
                    testId='edit-area'
                    tooltip='Editar área'
                    onClick={() => hadleUpdateArea(area)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Modal
        title={areaSelected.id ? 'Actualizar área' : 'Agregar área'}
        toggleModal={() => handleToggle()}
        isActive={openModal}
        noFooter
      >
        <AddArea
          toggleModal={() => handleToggle()}
          getAreas={() => getAreas()}
          areaSelected={areaSelected}
        />
      </Modal>
    </Fragment>
  )
}
