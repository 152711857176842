// Libraries
import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import classNames from 'classnames'

/**
 * @typedef {{
 * lable: string;
 * paceholder: string;
 * value: string;
 * onValueChange: function;
 * currency: string;
 * name: string;
 * isNumericString: boolean;
 * disabled: boolean;
 * required: boolean;
 * message: string;
 * }} PropsFieldAmount
 */

/**
 * @param {PropsFieldAmount} props
 */
class FieldAmount extends Component {
  render () {
    const {
      label,
      placeholder,
      value,
      onValueChange,
      currency,
      name,
      isNumericString,
      disabled,
      required,
      message
    } = this.props
    return (
      <div className='newFlow--field input-full-width no-margin'>
        <label htmlFor={name}>{required ? `${label} *` : label}</label>
        <div
          className={classNames('newFlow--field input-full-width no-margin', {
            disabled: disabled
          })}
        >
          <NumberFormat
            style={{ fontSize: '1em' }}
            placeholder={placeholder}
            isNumericString={isNumericString || false}
            thousandSeparator
            prefix={currency ? `${currency} ` : '$ '}
            decimalScale={currency && currency === 'COP' ? 0 : 2}
            fixedDecimalScale
            value={value}
            name={name}
            onValueChange={onValueChange}
            id={name}
          />
          {disabled && message && <span className='massage'>{message}</span>}
        </div>
      </div>
    )
  }
}
export default FieldAmount
