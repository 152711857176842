// Libraries
import React, { useState } from 'react'
import styled from 'styled-components'
import Notification from 'cogo-toast'

// Componnets
import { getIncomeStatus, formatDate } from 'utils'
import { error } from 'css/colors'
import IconButton from 'shared/iconButton'
import Modal from 'shared/modal'
import API from 'api/core'

const Content = styled.div`
  width: 70%;
  margin: 0 auto;
`
const TextCancel = styled.p`
  color: ${error};
  font-size: 20px;
  padding: 2rem;
  border: 1px solid ${error};
  border-radius: 5px;
  text-align: center;
`
/**
 * @typedef {{
 * amount: string,
 * pay_day: Date,
 * status: string,
 * id: number,
 * }} TypeCharges
 * @typedef {{
 * charges: TypeCharges[]
 * incomeId: string,
 * }} PropsMovements
 * @param {PropsMovements} props
 */
const Charges = ({ charges: initilCharges, incomeId }) => {
  const [charges, setCharges] = useState(initilCharges)
  const [openModal, setOpenModal] = useState(false)
  const [sending, setSending] = useState(false)
  const [chargeId, setChargeId] = useState(null)

  async function chandleCanel () {
    setSending(true)
    try {
      await API.Accounting.CancelIncomeMevements(incomeId, chargeId)
      Notification.success('Cargo anulado con éxito')
      const updateCharges = charges.filter(charge => charge.id !== chargeId)
      setCharges(updateCharges)
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error, intenta de nuevo')
    } finally {
      setSending(false)
      setOpenModal(!openModal)
    }
  }

  /**
   * @param {Number} id
   */
  function toggleModal (id) {
    setOpenModal(!openModal)
    setChargeId(id)
  }

  /**
   * @param {String} status
   */
  function hasStatus (status) {
    switch (status) {
      case 'CANCELLED':
      case 'UNPAYABLE':
      case 'CONDONED':
        return true
      default:
        return false
    }
  }

  return (
    <Content>
      {charges && (
        <table className='table'>
          <thead>
            <tr>
              <th>Estado</th>
              <th>Fecha de pago</th>
              <th>Monto</th>
            </tr>
          </thead>
          <tbody>
            {charges.map(({ amount, pay_day: payDay, status, id }) => (
              <tr key={id}>
                <td>{getIncomeStatus(status)}</td>
                <td>
                  {payDay ? formatDate(payDay) : 'No se ha confirmado el pago'}
                </td>
                <td>{amount}</td>
                {hasStatus(status) && (
                  <td>
                    <IconButton
                      icon='close-box'
                      tooltip='Anular'
                      className='mdi'
                      onClick={() => toggleModal(id)}
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Modal
        title='Anular cargo'
        isActive={openModal}
        toggleModal={() => setOpenModal(!openModal)}
        textSave='Continuar'
        onSubmit={() => chandleCanel()}
        requestInProgress={sending}
        disabled={sending}
      >
        <TextCancel>
          Este proceso no se puede revertir. Si estas seguro que es lo que
          quieres hacer oprime continuar, de lo contrario oprime cancelar
        </TextCancel>
      </Modal>
    </Content>
  )
}
export default Charges
