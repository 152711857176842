// Libraries
import React, { Component } from 'react'
import _pickBy from 'lodash/pickBy'
import Notification from 'cogo-toast'

// Components
import SolicitudesDeContrato from './table'
import Title from 'shared/title'
import Paginator from 'shared/paginator'
import Loader from 'shared/loader'
import ContractsFilters from 'shared/contractFilters'
import { stringifyQuery, parseQuery } from 'utils'
import API from 'api/core'

const title = 'Solicitudes de contrato pendientes'
const breadcrumbLocation = [
  { label: title, route: '/contract-requests/validate' }
]

/**
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @typedef {import('api/core').Pendingcontract} Pendingcontract
 * @typedef {import('api/core').Pagination} Pagination
 * @typedef {Object} State
 * @property {Array<{Pendingcontract}>} pendingRequests
 * @property {boolean} requestInProgress
 * @property {Pagination} pagination
 * @property {{query: string, product_line: string, country: string}} filters
 * @typedef {RouteComponentProps} Props
 */
/**
 * @extends {Component<Props & State>}
 */
class PendingContractRequests extends Component {
  /**
   * @type {State}
   */
  state = {
    pendingRequests: [],
    pagination: {
      total_pages: 1,
      total_entries: 0,
      page_number: 1,
      page_size: 1
    },
    filters: {
      country: '',
      query: '',
      product_line: ''
    },
    requestInProgress: false
  }

  /**
   * @param {Props} prevProps
   */
  componentDidUpdate = prevProps => {
    if (this.props.location.search !== prevProps.location.search) {
      this.getPendingRequests()
    }
  }

  componentDidMount = () => {
    this.getPendingRequests()
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  onChangeValue = event => {
    const { name, value } = event.currentTarget
    this.setState(state => ({
      filters: { ...state.filters, [name]: value }
    }))
  }

  onCleanFilters = () => {
    const { history, location } = this.props
    this.setState(
      {
        filters: {
          country: '',
          query: '',
          product_line: ''
        }
      },
      () => {
        history.push(location.pathname)
      }
    )
  }

  onFilter = () => {
    const { filters } = this.state
    const {
      history,
      location: { pathname }
    } = this.props
    const query = _pickBy(
      { ...filters, country: filters.country.toLowerCase() },
      filter => filter.length > 0
    )
    history.push(pathname + stringifyQuery(query))
  }

  // hace un get clients/accounts para obtener todos los clientes
  getPendingRequests = () => {
    const { filters } = this.state
    const { location } = this.props
    const page = parseQuery(location.search).page || 1
    const query = _pickBy(
      {
        ...filters,
        country: filters.country.toLowerCase(),
        query: filters.query.trim()
      },
      filter => filter.length > 0
    )
    const params = {
      ...query,
      page
    }
    this.setState({ requestInProgress: true })
    API.ContractRequests.ListPending(params)
      .then(({ data, pagination }) => {
        this.setState({
          pendingRequests: data,
          pagination
        })
      })
      .catch(error => {
        console.error(error)
        Notification.error('Ha ocurrido un error cargando los contratos')
      })
      .finally(() => {
        this.setState({ requestInProgress: false })
      })
  }

  render () {
    const { filters, pendingRequests, pagination, requestInProgress } = this.state
    return (
      <div>
        <Title title={title} breadcrumbLocation={breadcrumbLocation} />
        <ContractsFilters
          onChangeValue={this.onChangeValue}
          onFilter={this.onFilter}
          onCleanFilters={this.onCleanFilters}
          filters={filters}
        />
        {requestInProgress && <Loader />}
        <div className='clientsTable'>
          {pendingRequests && pendingRequests.length > 0 ? (
            <SolicitudesDeContrato
              targetUrl='/contract-requests/validate/view/'
              contractRequests={pendingRequests}
            />
          ) : (
            <div className='notification'>No hay solicitudes por revisar</div>
          )}
          {pagination && <Paginator totalPages={pagination.total_pages} />}
        </div>
      </div>
    )
  }
}

export default PendingContractRequests
