// Libraries
import React from 'react'
import styled from 'styled-components'
import Button from 'shared/button'
import { INVOICE_ONE_STATUS } from 'shared/catalogs'
import { SubHeader, Header, SubTitle, InfoWrapper } from './styles'
import { error, darkBlack } from 'css/colors'
import { getLabel, hasPermissions } from 'utils'
import { withCurrentUser } from 'contexts/user'

const ContentWrapper = styled.div`
  align-self: flex-start;
  width: 100%;
`
const HeaderCufe = styled.h2`
  font-size: 18px;
  color: ${props => (props.color ? `${error}` : `${darkBlack}`)};
  margin-bottom: 0.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const ContainerButton = styled.div`
  text-align: right;
`
/**
 * @typedef {import('api/core').TypeInvoiceDetails} TypeInvoiceDetails
 * @typedef {{
 * user: {area: string}
 * cufeAndStatus: {cufe: string, status: string}
 * onEditCufestatus: function
 * }} PropsEmisor
 * @param {PropsEmisor} props
 */
const CufeStatus = ({ user, cufeAndStatus, onEditCufestatus }) => {
  const { area = '' } = user || {}
  const { cufe, status } = cufeAndStatus
  const hasError = status === 'ERROR' ? { error } : ''
  const canEdit = status === 'ERROR' && hasPermissions(area, 'edit')
  return (
    <ContentWrapper>
      <SubTitle>Cufe y Estado</SubTitle>
      <InfoWrapper>
        <div>
          <SubHeader>Cufe</SubHeader>
          <HeaderCufe>{cufe || 'N/A'}</HeaderCufe>
        </div>
        <div>
          <SubHeader>Estado</SubHeader>
          <Header
            // @ts-ignore
            color={hasError}
          >
            {getLabel(INVOICE_ONE_STATUS, status)}
          </Header>
        </div>
        {canEdit && (
          <ContainerButton>
            <Button
              buttonClass='primary'
              icon='pencil'
              onClick={onEditCufestatus}
            >
              Editar
            </Button>
          </ContainerButton>
        )}
      </InfoWrapper>
    </ContentWrapper>
  )
}
export default withCurrentUser(CufeStatus)
