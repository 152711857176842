// Libraries
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import update from 'immutability-helper'
import classNames from 'classnames'

// Components
import API from 'api/bank'
import AccountsTable from 'shared/table/accounts'
import Title from 'shared/title'
import Breadcrumb from 'shared/breadcrumb'
import Button from 'shared/button'

const title = 'Cuentas de banco'
const breadcrumbLocation = [{ label: title, route: '/bank-accounts' }]

class Account extends Component {
  constructor (props) {
    super(props)
    this.state = {
      accounts: [],
      filters: {
        reference: '',
        uuid: ''
      },
      isValid: {
        uuid: true
      }
    }
  }

  // ----------------------------------------------------------------------
  // Obtiene las cuentas de banco antes de que se carge el componente.
  // ----------------------------------------------------------------------

  componentDidMount () {
    if (this.props.match.path === '/accounts/:page') {
      this.setPage(Number(this.props.match.params.page))
    } else {
      this.getAccounts()
    }
  }

  // --------------------------------------------------------------------------------
  // Realiza una petición a la api banco listar las cuentas de la página selecionada.
  // --------------------------------------------------------------------------------

  getAccounts (args) {
    const page =
      typeof args !== 'undefined' && typeof args.page !== 'undefined'
        ? args.page
        : this.state.currentPage

    API.Accounts.List({ page })
      .then(response => {
        this.setState({ accounts: response.data })
        if (typeof response.pagination.page_number !== 'undefined') {
          this.setState({
            currentPage: response.pagination.page_number,
            totalPages: response.pagination.total_pages,
            isLoading: false
          })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  // --------------------------------------------------------------------------
  // Establece la página para listar las cuentas.
  // --------------------------------------------------------------------------

  setPage (page) {
    this.getAccounts({ page: page })
  }

  // --------------------------------------------------------------------------
  // Actualiza la propiedad active de una cuenta para activarla o desactivar.
  // --------------------------------------------------------------------------

  changeStatus (status, item) {
    this.setState({
      accounts: update(this.state.accounts, {
        [item]: { active: { $set: status } }
      })
    })
  }

  // --------------------------------------------------------------------------
  // Onchange value inputs.
  // Establece el valor de this.state.filters.input
  // --------------------------------------------------------------------------

  onChangeReference (e) {
    this.setState({
      filters: update(this.state.filters, {
        reference: {
          $set: e.target.value
        }
      })
    })

    if (e.target.value === '') {
      this.getAccounts()
    } else {
      API.Accounts.ShowByReference(e.target.value).then(response => {
        if (response.error) {
          this.setState({
            isValid: update(this.state.isValid, { reference: { $set: false } })
          })
        } else if (Array.isArray(response.data)) {
          this.setState({ accounts: response.data })
        } else {
          this.setState({ accounts: [response.data] })
        }
      })
    }
  }

  onChangeUUID (e) {
    this.setState({
      filters: update(this.state.filters, {
        uuid: {
          $set: e.target.value
        }
      })
    })

    if (e.target.value === '') {
      this.getAccounts()
    } else {
      API.Accounts.Show(e.target.value).then(response => {
        if (response.error) {
          this.setState({
            isValid: update(this.state.isValid, { uuid: { $set: false } })
          })
        } else {
          this.setState({ accounts: [response.data] })
        }
      })
    }
  }

  // --------------------------------------------------------------------------
  // Filtros para buscar una cuenta.
  // --------------------------------------------------------------------------

  renderFilters () {
    return (
      <div className='columns'>
        <div className='column is-4'>
          <div className='field'>
            <label>Referencia:</label>
            <input
              className='input'
              onChange={this.onChangeReference.bind(this)}
              type='text'
              value={this.state.filters.reference}
            />
          </div>
        </div>
        <div className='column is-4'>
          <div className='field'>
            <label>UUID:</label>
            <input
              className={classNames('input', {
                'is-danger': !this.state.isValid.uuid
              })}
              onChange={this.onChangeUUID.bind(this)}
              type='text'
              value={this.state.filters.uuid}
            />
          </div>
        </div>
      </div>
    )
  }

  // --------------------------------------------------------------------------
  // Render Principal.
  // --------------------------------------------------------------------------

  render () {
    return (
      <div>
        <Breadcrumb items={breadcrumbLocation} />
        <div className='columns'>
          <div className='column'>
            <Title title={title} />
          </div>
          <div className='flex-end'>
            <Link to='/bank-accounts/new'>
              <Button buttonClass='primary right'>Crear cuenta</Button>
            </Link>
            <Link to='/bank-accounts/movements'>
              <Button buttonClass='primary'>Movimientos</Button>
            </Link>
          </div>
        </div>
        {this.renderFilters()}
        <AccountsTable
          accounts={this.state.accounts}
          changeStatus={this.changeStatus.bind(this)}
        />
      </div>
    )
  }
}

export default Account
