// Libfrariesx
import React from 'react'
import className from 'classnames'
import { withFormik, Field, Form } from 'formik'
import Notification from 'cogo-toast'
import styled from 'styled-components'

// Components
import IconButton from 'shared/iconButton'
import SelectField from 'shared/selectField'
import Button from 'shared/button'
import { darkBlack, mediumGrey, buttonPrimary, colorPrimary } from 'css/colors'
import { FlexWrapper } from 'css/utils'
import { getCountryLabel, formatCurrency, absolutify, formatDate } from 'utils'
import API from 'api/core'

const WrapperClose = styled.div`
  margin-bottom: 1.5rem;
  & button.icon-button i {
    border-radius: 50%;
    :hover {
      border-radius: 50%;
      border: 1px solid ${buttonPrimary};
    }
  }
`
const Header = styled.p`
  font-size: 15px;
  color: ${darkBlack};
  margin-bottom: 3px;
`
const SubHeader = styled.p`
  font-size: 12px;
  color: ${mediumGrey};
`

const Details = styled.div`
  border-bottom: 1px solid ${buttonPrimary};
  padding: 0.3em 0 1rem 0;
  margin: 0 auto;
`
const DownloadFile = styled(FlexWrapper)`
  & > a {
    padding: 5px 5px;
    border-radius: 3px;
    width: 48%;
    text-align: center;
    color: ${colorPrimary};
    border: 1px solid ${colorPrimary};
    :hover {
      color: ${colorPrimary};
    }
    & > i {
      margin-right: 7px;
    }
  }
`

/**
 * @param {String} status
 */
const optionStatus = status => {
  switch (status) {
    case 'EN ESPERA':
      return [
        { id: 'PAGADO', label: 'PAGADO' },
        { id: 'CANCELADO', label: 'CANCELADO' }
      ]
    case 'PAGADO':
      return [
        { id: 'COMPLETADO', label: 'COMPLETADO' },
        { id: 'CANCELADO', label: 'CANCELADO' }
      ]
    case 'COMPLETADO':
      return [{ id: 'CANCELADO', label: 'CANCELADO' }]
    default:
      return []
  }
}

/**
 * @typedef {import('formik').FormikProps<{statusChange: "EN ESPERA" | "PAGADO" | "COMPLETADO" | "CANCELADO"}>} FormikProps
 * @typedef {import('api/core').TypeFlowPayment} TypeFlowPayment
 *
 * @typedef {Object} FlowDetailsProps
 * @property {TypeFlowPayment} flowPayment
 * @property {boolean} isOpen
 * @property {string} fileName
 * @property {boolean} modalOpen
 * @property {function} toggleModal
 * @property {function} setFile
 * @property {function} onCloseDetails
 * @property {function} hadleChangeSelect
 * @property {function} updateStatus
 * @property {string} valueOption
 * @param {FlowDetailsProps & FormikProps} props
 */

const FlowDetails = ({
  flowPayment,
  isOpen,
  onCloseDetails,
  values,
  isSubmitting
}) => {
  const {
    status,
    amount,
    area,
    subsidiary,
    currency,
    flow_type: flowType,
    invoice_date: invoiceDate,
    flow_documents: flowDocuments,
    payment_method: paymentMethod,
    iva_amount: ivaAmount,
    payment_receptor: receptor,
    bank_account: bankAccount,
    flow_date: flowDate
  } = flowPayment
  const totalAmount = absolutify(amount) + absolutify(ivaAmount)
  return (
    <div>
      {flowPayment && (
        <div
          className={className('flowDetails', {
            'flowDetails--show': isOpen
          })}
        >
          <WrapperClose>
            <IconButton icon='close' onClick={onCloseDetails} />
          </WrapperClose>
          {status !== 'CANCELADO' && (
            <Form>
              <div className='vertical-bottom bottom'>
                <div className='full-width'>
                  <Field component='div' name='statusChange'>
                    <SelectField
                      placeholder='Actualizar estado'
                      label='Actualizar estado'
                      name='statusChange'
                      options={optionStatus(status)}
                      value={values.statusChange}
                    />
                  </Field>
                </div>
                <Button
                  buttonClass='primary left right'
                  icon='update'
                  tooltip='Actualizar'
                  type='submit'
                  loading={isSubmitting}
                  disabled={isSubmitting || !values.statusChange}
                />
              </div>
            </Form>
          )}
          <Details>
            <SubHeader>Estado</SubHeader>
            <Header>{status}</Header>
            <SubHeader>País</SubHeader>
            <Header>{receptor && getCountryLabel(receptor.country)}</Header>
            <SubHeader>Tipo de flujo</SubHeader>
            <Header>{flowType && flowType.description}</Header>
            <SubHeader>Fecha del flujo</SubHeader>
            <Header>{formatDate(flowDate)}</Header>
          </Details>
          {receptor && (
            <Details>
              <SubHeader>País</SubHeader>
              <Header>{receptor && getCountryLabel(receptor.country)}</Header>
              <SubHeader>Beneficiario</SubHeader>
              <Header>{receptor.beneficiary}</Header>
              <SubHeader>Banco</SubHeader>
              <Header>{bankAccount.bank.name}</Header>
              <SubHeader>Cuenta</SubHeader>
              <Header>{receptor.account_number}</Header>
              <SubHeader>Clabe</SubHeader>
              <Header>{receptor.clabe}</Header>
            </Details>
          )}
          <Details>
            <SubHeader>Feha de Factura</SubHeader>
            <Header>{formatDate(invoiceDate)}</Header>
            <SubHeader>Monto</SubHeader>
            <Header>{formatDate(invoiceDate)}</Header>
            <SubHeader>Monto</SubHeader>
            <Header>{`$${amount} ${currency}`}</Header>
            <SubHeader>IVA</SubHeader>
            <Header>{`$${ivaAmount} ${currency}`}</Header>
            <SubHeader>Total</SubHeader>
            <Header>{`${formatCurrency(
              totalAmount,
              currency
            )} ${currency}`}</Header>
            <DownloadFile>
              {flowDocuments.pdf && (
                <a download href={flowDocuments.pdf}>
                  <i className='mdi mdi-cloud-download' />
                  Decargar PDF
                </a>
              )}
              {flowDocuments.xml && (
                <a download href={flowDocuments.xml}>
                  <i className='mdi mdi-cloud-download' />
                  Descargar XML
                </a>
              )}
            </DownloadFile>
          </Details>
          <Details>
            <SubHeader>Empresa</SubHeader>
            <Header>{bankAccount && bankAccount.company.name}</Header>
            <SubHeader>Área</SubHeader>
            <Header>{area.name}</Header>
            <SubHeader>Sucursal</SubHeader>
            <Header>{subsidiary.name}</Header>
            <SubHeader>Forma de pago</SubHeader>
            <Header>{paymentMethod}</Header>
            <SubHeader>Cuenta de retiro</SubHeader>
            <Header>{bankAccount && bankAccount.description}</Header>
          </Details>
        </div>
      )}
    </div>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    statusChange: ''
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    setSubmitting(true)
    const data = {
      status: values.statusChange,
      id: props.id
    }
    try {
      await API.Accounting.UpdateStatus(data)
      Notification.success('Estado actualizado correctamente')
      props.updateStatus(values.statusChange, props.id)
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error al actualizar el estado')
    } finally {
      setSubmitting(false)
      props.onCloseDetails()
    }
  }
})(FlowDetails)
