// Libraries
import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

// Components
import { appleGrey, gviewGrey } from 'css/colors'
import { getQueryParam } from 'utils'
import ZoomableImage from 'shared/zoomableImage'
import { PREVIEW_TYPES } from 'shared/catalogs'

const Notifcation = styled.div`
  background: ${appleGrey};
  border-radius: 3px;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  position: relative;
  width: 100%;
  align-self: flex-start;
  margin-left: 1rem;
`
const WrapperView = styled.div`
  width: 100%;
  background: ${gviewGrey};
  align-self: flex-start;
`
const View = styled.div`
  width: 94%;
  margin: 0 auto;
`
const Download = styled.div`
  text-align: right;
  padding: 1rem 0.5rem;
  & i {
    margin-right: 0.3rem;
  }
`
const AudioPlayer = styled.audio`
  height: 60px;
  width: 100%;
`
/**
 * @param {string} url
 */
const canDownload = url => {
  const expirationDate = getQueryParam(url, 'Expires')
  const canDownload = moment
    .unix(Number(expirationDate))
    .isSameOrAfter(moment())
  return canDownload
}

const NoData = () => <Notifcation>Selecciona un documento</Notifcation>

/**
 * @typedef {{url: string}} AudioProps
 * @param {AudioProps} props
 */
const Audio = ({ url }) => {
  return (
    <div>
      <AudioPlayer src={url} controls />
      {canDownload(url) && (
        <Download>
          <a href={url} target='_blank' className='coreButton primary'>
            <i className='mdi mdi-download' aria-hidden />
            Descargar
          </a>
        </Download>
      )}
    </div>
  )
}
/**
 * @typedef {{url: string, title?: string}} PropsPdf
 * @param {PropsPdf} props
 */
const PdfView = ({ url, title }) => {
  return (
    <WrapperView>
      <View>
        <iframe
          title={title}
          className='pdf-viewer'
          style={{ width: '100%', minHeight: '500px', height: '100%' }}
          src={`https://docs.google.com/gview?url=${encodeURIComponent(
            url
          )}&embedded=true`}
        />
      </View>
      {canDownload(url) && (
        <Download>
          <a href={url} target='_blank' className='coreButton primary'>
            <i className='mdi mdi-download' aria-hidden />
            Descargar
          </a>
        </Download>
      )}
    </WrapperView>
  )
}

const NotSupported = () => (
  <Notifcation>
    <p>Formato de archivo no soportado</p>
  </Notifcation>
)

/**
 * @typedef {{url: string, title?: string}} PropsPreview
 * @param {PropsPreview} props
 */
const Preview = ({ url, title }) => {
  if (!url) return <NoData />
  const extension = url.match(/[^\/]+\.([a-zA-Z\d]+)\?/) //eslint-disable-line
  const extensionType = extension ? extension[1] : 'default'

  // @ts-ignore
  const type = PREVIEW_TYPES[extensionType]

  switch (type) {
    case 'pdf':
      return <PdfView url={url} title={title} />
    case 'image':
      return <ZoomableImage url={url} title={title} />
    case 'audio':
      return <Audio url={url} />
    default:
      return <NotSupported />
  }
}

export default Preview
