// @ts-nocheck
// Libraries
import React, { Component } from 'react'
import Notification from 'cogo-toast'
import { withRouter } from 'react-router-dom'

// Components
import ZoomableImageForm from 'shared/forms/image-zoom.js'
import FieldsContainer from 'shared/wizard/fieldsContainer.js'
import ContractFieldsMapper from '../../../mappers/contract-validation-mapper.js'
import PdfViewer from 'shared/forms/pdfViewer'
import Button from 'shared/button'
import API from 'api/core'
import { CONTRACT_REQUEST_STATUS } from '../../../helpers/catalogs'

class ViewContractRequest extends Component {
  constructor (props) {
    super(props)
    this.state = {
      contract: null,
      fields: [],
      loading: false
    }

    this.onChangeDocument = this.onChangeDocument.bind(this)
    this.cancelRequest = this.cancelRequest.bind(this)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { contract } = nextProps
    const fields = ContractFieldsMapper.map(contract)
    if (!contract) return null

    // Update the state only when the contract has changed
    return contract !== prevState.contract
      ? {
        contract,
        notes: contract.notes,
        fields
      }
      : null
  }

  onChangeDocument (selectedDocument) {
    this.setState({ selectedDocument })
  }

  cancelRequest = async () => {
    const { history } = this.props
    const { contract } = this.state
    this.setState({ loading: true })
    try {
      const { status, errors } = await API.ContractRequests.Cancel(
        contract.contract_uid
      )
      if (status === 'success') {
        Notification.success('Contrato cancelado')
      } else if (status === 'error') {
        Notification.error(errors.detail)
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.setState({ loading: false })
      history.push('/contract-requests/reviewed')
    }
  }

  render () {
    const { fields, contract, selectedDocument, loading } = this.state
    if (!contract) {
      return null
    }

    const isCreated = contract.status === 'contract_created'
    const isReference =
      contract.product_line === 'cretio'
        ? contract.creditReview.reference
        : contract.reference

    const isArd = contract.ard && contract.ard
    // Convert object to array
    const fieldsList = Object.keys(fields).map(field => fields[field])
    const leadInfo = {
      country: contract.country,
      leadUid: contract.lead_uid,
      leadFiles: contract.lead_files
    }
    return (
      <div className='columns'>
        <div className='column is-half'>
          {isReference && <label>Referencia: {isReference}</label>}
          {isArd && <label>Solicitada por: {contract.ard.ard_email}</label>}
          <FieldsContainer
            invalid={contract.invalid}
            onChangeDocument={this.onChangeDocument}
            fields={fieldsList}
            isEditable={false}
            lead={leadInfo}
          />
          <div className='field'>
            <label className='label'>Notas del ARD:</label>
            <div className='control'>
              <textarea
                value={isArd ? contract.ard.ard_notes : ''}
                className='textarea m-b-20'
                disabled
              />
            </div>
          </div>
          <div className='field'>
            <label className='label'>Comentarios:</label>
            <div className='control'>
              <textarea
                value={contract.notes || ''}
                className='textarea m-b-20'
                disabled
              />
            </div>
          </div>
          <div className='field'>
            <label className='label'>Estado de la solicitud:</label>
            <div className='control has-text-centered'>
              <h3>
                <strong
                  className={isCreated ? 'has-text-success' : 'has-text-danger'}
                >
                  {CONTRACT_REQUEST_STATUS[contract.status]}
                </strong>
              </h3>
            </div>
          </div>
          {isCreated && (
            <div className='field has-text-centered'>
              <Button
                icon='cancel'
                loading={loading}
                buttonClass='secondary'
                onClick={this.cancelRequest}
                disabled={loading}
              >
                Cancelar
              </Button>
            </div>
          )}
        </div>
        {selectedDocument && (
          <div className='file-previewer column is-half'>
            {selectedDocument.type === 'pdf' ? (
              <PdfViewer
                src={selectedDocument.value}
                title={selectedDocument.name}
              />
            ) : (
              <ZoomableImageForm
                src={selectedDocument.value}
                title={selectedDocument.name}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(ViewContractRequest)
