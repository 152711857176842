import styled, { css } from 'styled-components'
import {
  mediumGrey,
  appleGrey,
  colorPrimary,
  darkGrey,
  white,
  error
} from 'css/colors'
import { FlexWrapper } from 'css/utils'

const ErrorStyles = css`
  border: 1px solid ${error};
  border-radius: 8px;
`

export const TableWraper = styled.table`
  width: 100%;
  margin: 0 auto;
`
export const TableHead = styled.thead`
  & > tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    & > th {
      width: 100%;
      font-size: 12px;
      color: ${mediumGrey};
      font-weight: 400;
    }
    & th:last-child {
      text-align: right;
    }
  }
`
export const TableBody = styled.tbody`
  & > tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0;
    border: 1px solid ${appleGrey};
    border-width: 0 0 1px;
    &:last-child {
      border: none;
    }
    & > td {
      width: 100%;
    }
    & td:last-child {
      text-align: right;
    }
  }
  .display-none {
    opacity: 0;
    cursor: default;
  }
`
export const Form = styled.form`
  border: none;
  border: 1px solid ${appleGrey};
  border-radius: 5px;
  display: flex;
  justify-content: flex-end;
  & > input {
    padding: 8px;
  }
  & > div {
    width: auto;
    & > div {
      margin-top: 0;
      height: 100%;
    }
    & input {
      border: none;
    }
    & p,
    span,
    select {
      height: 100% !important;
    }
    & select {
      border: none;
    }
  }
  ${({ error }) => error && ErrorStyles}
`
export const Buttons = styled.div`
  display: flex;
  border-left: 1px solid ${appleGrey};
  & > div {
    padding: 8px;
    cursor: pointer;
    & i {
      color: ${white};
    }
  }
  & > div:first-child {
    background: ${colorPrimary};
    border-left: 1px solid ${colorPrimary};
  }
  & > div:last-child {
    border-radius: 0 5px 5px 0;
    background: ${darkGrey};
  }
  .btn-disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.6;
  }
`

export const InputAmount = styled.input`
  padding-left: 20px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & > [type='number'] {
    -moz-appearance: textfield;
  }
`

export const Input = styled.input`
  border-radius: 7px;
`

// styles upload file
export const Content = styled(FlexWrapper)`
  margin-top: 1.5rem;
`

export const ContentFile = styled.div`
  position: relative;
  padding: 0.4rem 0.75rem;
  background-color: ${colorPrimary};
  border-radius: 3px;
  margin-right: 1.5rem;
`

export const InputFile = styled.input`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0;
  padding: 9px 0;
  cursor: pointer;
`

export const Text = styled.p`
  text-align: center;
  color: white;
  margin-left: 1.2rem;
`

export const Icon = styled.i`
  position: absolute;
  top: 5px;
  left: 8px;
  color: ${white};
  font-size: 20px;
`

export const File = styled.div`
  & h3 {
    color: ${colorPrimary};
    margin-bottom: 0.7rem;
  }
  & p {
    border: 1px solid ${mediumGrey};
    border-radius: 3px;
    padding: 0.3rem 0.75rem;
  }
`
