// @ts-nocheck
import React, { useState, useEffect } from 'react'
import Notification from 'cogo-toast'
import store from 'store'
import axios from 'axios'

import Button from 'shared/button'
import IconButton from 'shared/iconButton'
import Modal from 'shared/modal'
import { getProp, formatDate, downloadFile } from 'utils'
import {
  ContentFile,
  File,
  Content,
  Icon,
  Text,
  Input,
  WrapperButtons,
  Wrapper,
  WrapperUpload
} from './styles'

export default function RenapoIne ({ mb, size, leadUid, leadFiles = [] }) {
  const [files, setFiles] = useState([])
  const [initilaData, setInitialData] = useState([])
  const [sending, setSending] = useState(false)
  const [openModal, setOpenModal] = useState('')

  useEffect(() => {
    const filteredFiles =
      leadFiles.length > 0 &&
      leadFiles.reduce((acc, current) => {
        return {
          ...acc,
          [current.type]: {
            name: current.name,
            url: current.url,
            inserted_at: current.inserted_at
          }
        }
      }, {})

    const initialData = initialState.reduce((acc, current) => {
      const currentFiles = filteredFiles[current.type]
      return [
        ...acc,
        {
          type: current.type,
          name: currentFiles ? currentFiles.name : current.name,
          url: currentFiles ? currentFiles.url : current.url,
          inserted_at: currentFiles
            ? currentFiles.inserted_at
            : current.inserted_at
        }
      ]
    }, [])
    setFiles(initialData)
    setInitialData(initialData)
  }, [])

  const handleOpenModal = activeModal => {
    setOpenModal(activeModal)
  }

  const handleCloseModal = (type, file) => {
    const close = openModal === type && ''
    setOpenModal(close)
    if (file.type) {
      cleanFiles(type)
    }
  }

  const handleChangeFile = (event, typeFile) => {
    const { files: currentFiles } = event.target
    const currentFile = {
      name: currentFiles[0].name,
      type: typeFile,
      url: currentFiles[0],
      inserted_at: currentFiles[0].lastModifiedDate
    }
    setFiles(prevState => [
      ...prevState.filter(item => item.type !== typeFile),
      currentFile
    ])
  }

  const cleanFiles = () => {
    setFiles([...initilaData])
  }

  const sendFile = (url, type, name) => {
    setSending(true)
    const dataToSend = {
      type: type,
      lead: leadUid,
      file: url
    }
    AxiosUploadFile(dataToSend)
      .then(response => {
        Notification.success('Archivo enviado correctamente')
        const currentFile = {
          name,
          type,
          inserted_at: new Date(),
          url: response.data.url
        }
        setFiles(prevState => [
          ...prevState.filter(item => item.type !== type),
          currentFile
        ])
      })
      .catch(error => {
        console.error(error)
        Notification.error(
          getProp(
            error,
            'response.data.errors.detail',
            'Ha ocurrido un error enviando el archivo'
          )
        )
        cleanFiles()
      })
      .finally(() => {
        setSending(false)
        const close = openModal === type && ''
        setOpenModal(close)
      })
  }

  return (
    <Wrapper mb={mb} size={size}>
      <WrapperButtons>
        {files.map(({ name, type, url, inserted_at: insertedAt }) => (
          <>
            <WrapperUpload>
              <Button
                className='mright'
                buttonClass='link'
                icon='cloud-upload'
                onClick={() => handleOpenModal(type)}
              >
                {TYPE_FILES[type]}
              </Button>
              <p>{insertedAt && `Agregado: ${formatDate(insertedAt)}`}</p>
            </WrapperUpload>
            {name && (
              <div className='fileName'>
                {name}
                <IconButton icon='download' onClick={() => downloadFile(url)} />
              </div>
            )}
            <Modal
              noFooter
              title={`Subir ${TYPE_FILES[type]}`}
              isActive={openModal === type}
              toggleModal={() => handleCloseModal(type, url)}
            >
              <UploadFile
                name={name}
                file={url}
                sending={sending}
                sendFile={() => sendFile(url, type, name)}
                handleChangeFile={event => handleChangeFile(event, type)}
              />
            </Modal>
          </>
        ))}
      </WrapperButtons>
    </Wrapper>
  )
}

const UploadFile = ({ handleChangeFile, name, sending, sendFile, file }) => {
  const hasFile = file && file.type
  return (
    <div>
      {name && (
        <File>
          <h3>Archivo seleccionado</h3>
          <p>{name}</p>
        </File>
      )}
      <Content>
        <ContentFile>
          <Text>Selecciona archivo</Text>
          <Input
            type='file'
            accept='application/pdf'
            onChange={handleChangeFile}
          />
          <Icon className='mdi mdi-upload' />
        </ContentFile>
        <Button
          buttonClass='primary'
          icon='content-save'
          loading={sending}
          disabled={!name || !hasFile || sending}
          onClick={sendFile}
        >
          Aceptar
        </Button>
      </Content>
    </div>
  )
}

const TYPE_FILES = {
  renapo: 'Renapo',
  lista_nominal: 'Lista nominal'
}

const initialState = [
  {
    name: '',
    type: 'renapo',
    url: '',
    inserted_at: ''
  },
  {
    name: '',
    type: 'lista_nominal',
    url: '',
    inserted_at: ''
  }
]

export const AxiosUploadFile = dataToSend => {
  const url = `${process.env.API_CORE_SERVER +
    process.env.API_PREFIX_CORE}/leads/${dataToSend.lead}/file`
  const data = new window.FormData()

  data.append('file', dataToSend.file)
  data.append('type', dataToSend.type)

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: store.get('CORE_AUTH_TOKEN')
    }
  }
  return axios.post(url, data, config)
}
