// @ts-nocheck
// Librarries
import React, { Component } from 'react'
import Notification from 'cogo-toast'
import _pickBy from 'lodash/pickBy'
import styled from 'styled-components'
import moment from 'moment'

// Components
import { FlexWrapper, FieldWrapper, Title, MARGIN } from 'css/utils'
import {
  stringifyQuery,
  hasFilters,
  parseQuery,
  getFormatCsv,
  formatDateToSend
} from 'utils'
import { INVOICE_ONE_STATUS, INVOICE_ONE_TYPES } from 'shared/catalogs'
import Lodaer from 'shared/loader'
import Paginator from 'shared/paginator'
import SelectField from 'shared/selectField'
import Button from 'shared/button'
import ListInvoices from './listInvoice'
import UploadInvoices from './uploadInvoices'
import Modal from 'shared/modal'
import DatePicker from 'shared/datePicker'
import UploadNC from './uploadNC'
import UploadDeleteInvoices from './uploadDeleteInvoices'

import API from 'api/core'

const TitleWrapper = styled(FlexWrapper)`
  position: relative;
  margin-top: 0.7rem;
  margin-bottom: 2rem;
`

const DownloadFormat = styled.div`
  position: absolute;
  top: 100%;
  height: 0;
  overflow: hidden;
  & p {
    font-size: 14px;
    font-weight: 500;
    & > span {
      margin-left: 0.5rem;
    }
  }
`
const ButtonUploadWrapper = styled.div`
  position: relative;
  margin-left: 1.5rem;
  &:hover ${DownloadFormat} {
    height: auto;
  }
`
const FlexEnd = styled(FlexWrapper)`
  justify-content: flex-end;
  padding: 0.3rem 0;
`

/**
 * @typedef {import('api/core').TypeInvoiceOne} TypeInvoiceOne
 * @typedef {import('api/core').Pagination} Pagination
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @typedef {{
 * invoicesOne: TypeInvoiceOne[],
 * requestInProgress: boolean,
 * openModal: boolean,
 * openModalNC: boolean,
 * openModalDelete: boolean,
 * pagination: Pagination,
 * showDate: string | Date,
 * filters: {status?: string; type?: string; date?: Date | string; query?: string; },
 * }} State
 *
 * @extends {Component<RouteComponentProps, State>}
 */
class InvoiceOne extends Component {
  /**
   * @type {State}
   */
  state = {
    invoicesOne: [],
    requestInProgress: false,
    openModal: false,
    openModalNC: false,
    openModalDelete: false,
    showDate: '',
    pagination: {
      page_number: 1,
      page_size: 25,
      total_entries: 6,
      total_pages: 1
    },
    filters: {
      status: '',
      type: '',
      date: '',
      query: ''
    }
  }

  componentDidMount = () => {
    const { search } = this.props.location
    const { status, type, date, query } = parseQuery(search)
    const formatDate = formatDateToSend(date)
    const { _d: filteredDate } = moment(date)
    this.setState(
      {
        filters: {
          status: status || '',
          type: type || '',
          date: date ? formatDate : '',
          query: query || ''
        },
        showDate: date ? filteredDate : ''
      },
      this.getInvoicesOne
    )
  }

  toggleModal = () => this.setState(state => ({ openModal: !state.openModal }))
  toggleModalNC = () =>
    this.setState(state => ({ openModalNC: !state.openModalNC }))
  toggleModalDelete = () =>
    this.setState(state => ({ openModalDelete: !state.openModalDelete }))

  /**
   * @param {object} prevProps
   */
  componentDidUpdate = prevProps => {
    if (this.props.location.search !== prevProps.location.search) {
      this.getInvoicesOne()
    }
  }

  onCleanFilters = () => {
    const { history, location } = this.props
    this.setState(
      {
        filters: {
          status: '',
          type: '',
          date: '',
          query: ''
        },
        showDate: ''
      },
      () => {
        history.push(location.pathname)
      }
    )
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  onChangeValue = event => {
    const { value, name } = event.currentTarget
    this.setState(state => ({
      filters: { ...state.filters, [name]: value.toUpperCase() }
    }))
  }
  /**
   * @param {Date} date
   */
  handleChangeValue = date => {
    const formatDate = formatDateToSend(date)
    this.setState(state => ({
      filters: { ...state.filters, date: formatDate },
      showDate: date
    }))
  }
  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  onFilter = event => {
    event.preventDefault()
    const { filters } = this.state
    const {
      history,
      location: { pathname }
    } = this.props
    history.push(pathname + stringifyQuery(hasFilters(filters)))
  }

  getInvoicesOne = async () => {
    const { filters } = this.state
    const { location } = this.props
    const page = parseQuery(location.search).page || 1
    const query = _pickBy(
      { ...filters, query: filters.query.trim() },
      filter => filter.length > 0
    )
    const params = {
      ...query,
      page
    }
    this.setState({ requestInProgress: true })
    try {
      const { data, pagination } = await API.Invoices.GetInvoicesOne(params)
      this.setState({ invoicesOne: data, pagination })
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error cargando las facturas')
    } finally {
      this.setState({ requestInProgress: false })
    }
  }

  render () {
    const {
      filters,
      invoicesOne,
      requestInProgress,
      pagination,
      openModal,
      showDate,
      openModalNC,
      openModalDelete
    } = this.state

    return (
      <div>
        <TitleWrapper>
          <Title>Facturas uno</Title>
          <FlexEnd>
            <ButtonUploadWrapper>
              <Button
                buttonClass='upload no-margin'
                icon='eraser'
                onClick={this.toggleModalDelete}
              >
                Eliminar facturas
              </Button>
              <DownloadFormat>
                <div
                  className='link'
                  role='button'
                  tabIndex={0}
                  aria-label='descargar formato'
                  onClick={() =>
                    getFormatCsv('/factura1/batch/template')
                  }
                >
                  <p>
                    ¿Sin formato?<span>Click Aquí para descargar!</span>
                  </p>
                </div>
              </DownloadFormat>
            </ButtonUploadWrapper>
            <ButtonUploadWrapper>
              <Button
                buttonClass='upload no-margin'
                icon='upload'
                onClick={this.toggleModal}
              >
                Subir facturas
              </Button>
              <DownloadFormat>
                <div
                  className='link'
                  role='button'
                  tabIndex={0}
                  aria-label='descargar formato'
                  onClick={() =>
                    getFormatCsv('/factura1/download-csv-template')
                  }
                >
                  <p>
                    ¿Sin formato?<span>Aquí!</span>
                  </p>
                </div>
              </DownloadFormat>
            </ButtonUploadWrapper>
            <ButtonUploadWrapper>
              <Button
                buttonClass='upload no-margin'
                icon='upload'
                onClick={this.toggleModalNC}
              >
                Subir notas crédito
              </Button>
              <DownloadFormat>
                <div
                  className='link'
                  role='button'
                  tabIndex={0}
                  aria-label='decasgar formato'
                  onClick={() =>
                    getFormatCsv('/factura1/download-nc-csv-template')
                  }
                >
                  <p>
                    ¿Sin formato?<span>Aquí!</span>
                  </p>
                </div>
              </DownloadFormat>
            </ButtonUploadWrapper>
          </FlexEnd>
        </TitleWrapper>
        <FlexWrapper>
          <FieldWrapper right={MARGIN}>
            <label htmlFor='query'>Buscar</label>
            <input
              type='search'
              id='query'
              name='query'
              placeholder='Buscar'
              value={filters.query}
              onChange={this.onChangeValue}
              onKeyDown={e =>
                e.key === 'Enter' && filters.query.trim()
                  ? this.onFilter(e)
                  : null
              }
            />
          </FieldWrapper>
          <FieldWrapper right={MARGIN}>
            <SelectField
              name='status'
              label='Estado'
              placeholder='Selecciona una opción'
              value={filters.status}
              options={INVOICE_ONE_STATUS}
              onChange={this.onChangeValue}
            />
          </FieldWrapper>
          <FieldWrapper right={MARGIN}>
            <SelectField
              name='type'
              label='Tipo'
              placeholder='Selecciona una opción'
              value={filters.type}
              options={INVOICE_ONE_TYPES}
              onChange={this.onChangeValue}
            />
          </FieldWrapper>
          <FieldWrapper right={MARGIN}>
            <DatePicker
              name='date'
              width='100%'
              label='Fecha'
              selected={showDate}
              maxDate={new Date()}
              dateFormat='dd MMMM yyyy'
              placeholderText='DD/MM/YYYY'
              onChange={this.handleChangeValue}
            />
          </FieldWrapper>
          <Button
            testId='filter-invoice'
            buttonClass='primary'
            icon='filter'
            onClick={this.onFilter}
          >
            Filtrar
          </Button>
          <Button
            buttonClass='clear'
            icon='eraser'
            onClick={this.onCleanFilters}
          >
            Limpiar
          </Button>
        </FlexWrapper>
        <div>
          {requestInProgress ? (
            <Lodaer />
          ) : (
            <ListInvoices
              invoicesOne={invoicesOne}
              totalEntries={pagination && pagination.total_entries}
            />
          )}
          {pagination && <Paginator totalPages={pagination.total_pages} />}
        </div>
        <Modal
          title='Carga masiva de ingresos'
          isActive={openModal}
          toggleModal={this.toggleModal}
          noFooter
        >
          <UploadInvoices
            toggleModal={this.toggleModal}
            handleUpdate={this.getInvoicesOne}
          />
        </Modal>
        <Modal
          title='Agregar notas crédito'
          isActive={openModalNC}
          toggleModal={this.toggleModalNC}
          noFooter
        >
          <UploadNC
            toggleModal={this.toggleModalNC}
            handleUpdate={this.getInvoicesOne}
          />
        </Modal>
        <Modal
          title='Eliminación masiva de facturas con error'
          isActive={openModalDelete}
          toggleModal={this.toggleModalDelete}
          noFooter
        >
          <UploadDeleteInvoices
            toggleModal={this.toggleModalDelete}
            handleUpdate={this.getInvoicesOne}
          />
        </Modal>
      </div>
    )
  }
}
export default InvoiceOne
