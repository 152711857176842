// @ts-nocheck
import React, { useState } from 'react'
import Notification from 'cogo-toast'

import API from 'api/core'
import Button from 'shared/button'
import { Buttons } from './styles'

export default function RejectRequest ({ request, handleCloseModal }) {
  const [comment, setComment] = useState('')
  const [loading, setLoading] = useState(false)

  const handleInputChange = event => {
    const { value } = event.currentTarget
    setComment(value)
  }

  const rejectContract = async () => {
    setLoading(true)
    try {
      await API.ContractRequests.Reject(request.contract.uid, {
        notes: comment || '',
        invalid: []
      })
      Notification.success('Contrato rechazado con éxito')
    } catch (error) {
      console.error(error)
      Notification.error('Ocurrió un error, intenta nuevamente')
    } finally {
      setLoading(false)
      handleCloseModal()
    }
  }

  return (
    <>
      <label className='label' htmlFor='comment'>
        Agrega un comentario
      </label>
      <textarea
        id='comment'
        name='comment'
        value={comment}
        className='textarea m-b-20'
        onChange={handleInputChange}
      />
      <Buttons>
        <Button
          buttonClass='secondary'
          icon='cancel'
          onClick={handleCloseModal}
        >
          Cancelar
        </Button>
        <Button
          icon='check'
          buttonClass='primary'
          loading={loading}
          disabled={loading || !comment}
          onClick={rejectContract}
        >
          Rechazar
        </Button>
      </Buttons>
    </>
  )
}
