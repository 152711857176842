// Librerías
import React from 'react'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'
import Notification from 'cogo-toast'

// Componentes
import Modal from 'shared/modal'
import { toBase64 } from 'utils'
import { FILES_ICONS } from 'shared/catalogs'

// Estilos
const DROPZONE_STYLE = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minHeight: '200px',
  borderWidth: '2px',
  borderStyle: 'dashed',
  borderRadius: '5px'
}

/**
 * @typedef {import('utils').File} File
 */

/**
 * @typedef {Object} Props
 * @property {function} setFile
 * @property {function} toggleModal
 * @property {boolean} isActive
 * @property {string} title
 * @property {React.ReactNode} [renderAddOn]
 * @property {string} [accept]
 * @property {string} [placeholder]
 * @property {function} [onDrop]
 */

/**
 * @typedef {{ file?: File }} State
 */

/**
 * @extends {React.Component<Props, State>}
 */
class SelectFile extends React.Component {
  /**
   * @type {State}
   */
  state = { file: undefined }

  /**
   * @param {*} file
   */

  // Sets the File object to the state
  onDropFiles = async ([file]) => {
    if (!file) return

    try {
      const file64 = await toBase64(file)

      this.setState({ file: file64 })

      if (this.props.onDrop) {
        this.props.onDrop(file64)
      }
    } catch (err) {
      Notification.error(JSON.stringify(err), 'ERROR')
      console.error(err)
    }
  }

  onSubmit = async () => {
    const { file } = this.state

    if (!file) {
      Notification.warn('No se ha subido aún un documentos')
    }

    this.props.setFile(file)
    this.props.toggleModal()
  }

  render () {
    const {
      isActive,
      toggleModal,
      title,
      renderAddOn,
      placeholder,
      accept,
      ...restProps
    } = this.props
    const { file } = this.state
    return (
      <Modal
        isActive={isActive}
        toggleModal={toggleModal}
        onSubmit={this.onSubmit}
        title={title}
        disabled={!file}
      >
        <Dropzone
          {...restProps}
          onDrop={this.onDropFiles}
          multiple={false}
          accept={accept}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} style={DROPZONE_STYLE}>
              <input {...getInputProps()} />
              <h4 className='title is-4 has-text-grey-light'>
                {/* If a file is already there, displays its original name */}
                {file && (
                  <i
                    className={`mdi mdi-24px ${FILES_ICONS[file.type] ||
                      'mdi-file'}`}
                    style={{ color: '#7A7A7A' }}
                  />
                )}
                {file ? file.name : placeholder}
              </h4>
            </div>
          )}
        </Dropzone>
        <div style={{ marginTop: '1rem' }}>{renderAddOn}</div>
      </Modal>
    )
  }
}

SelectFile.propTypes = {
  setFile: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  title: PropTypes.string,
  renderAddOn: PropTypes.element,
  accept: PropTypes.string,
  placeholder: PropTypes.string,
  onDrop: PropTypes.func
}

SelectFile.defaultProps = {
  title: 'Agregar archivos',
  renderAddOn: null,
  placeholder: 'Arrastre su archivo o dé click en esta área',
  accept: '',
  onDrop: null
}

export default SelectFile
