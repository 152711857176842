// Libraries
import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

// Componets
import { Title } from 'css/utils'
import LinkButton from 'shared/linkButton'
import PaymentReceptors from './paymentReceptors'
import BankAccount from './bankAccounts'
import Banks from './banks'
import ReceptorTypes from './receptorTypes'
import Companies from './companies'
import Areas from './areas'
import Subsidiaries from './subsidiaries'

/**
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @typedef {{country: string}} State
 * @extends {Component<RouteComponentProps, State>}
 *
 */

class Catalogues extends Component {
  /**
   * @type {State}
   */
  state = {
    country: ''
  }

  /**
   * @param {string} routeTab
   */
  routeTab = routeTab => {
    const { history } = this.props
    const baseUrl = '/accounting/flow-payment/catalogues'
    history.push(`${baseUrl}/${routeTab}`)
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  onChangeCountry = event => {
    const { value } = event.target
    this.setState({ country: value })
  }

  render () {
    const { country } = this.state
    return (
      <div>
        <LinkButton
          url='/accounting/flow-payment'
          icon='arrow-left-thick'
          buttonClass='link bottom'
        >
          Egresos
        </LinkButton>
        <Title>Catálogos</Title>
        <Tabs>
          <TabList>
            <Tab>Receptores</Tab>
            <Tab>Cuentas de banco</Tab>
            <Tab>Bancos</Tab>
            <Tab>Tipos de receptor</Tab>
            <Tab>Compañías</Tab>
            <Tab>Áreas</Tab>
            <Tab>Sucursales</Tab>
          </TabList>
          <TabPanel>
            <PaymentReceptors
              routeTab={() => this.routeTab('receptors')}
              country={country.toUpperCase()}
            />
          </TabPanel>
          <TabPanel>
            <BankAccount routeTab={() => this.routeTab('banks-accounts')} />
          </TabPanel>
          <TabPanel>
            <Banks routeTab={() => this.routeTab('banks')} />
          </TabPanel>
          <TabPanel>
            <ReceptorTypes routeTab={() => this.routeTab('receptor-types')} />
          </TabPanel>
          <TabPanel>
            <Companies routeTab={() => this.routeTab('companies')} />
          </TabPanel>
          <TabPanel>
            <Areas routeTab={() => this.routeTab('areas')} />
          </TabPanel>
          <TabPanel>
            <Subsidiaries routeTab={() => this.routeTab('subsidiaries')} />
          </TabPanel>
        </Tabs>
      </div>
    )
  }
}
export default Catalogues
