// LIbraries
import React, { useState } from 'react'
import styled from 'styled-components'
import className from 'classnames'

// Components
import { FieldWrapper, FlexWrapper } from 'css/utils'
import { white, mediumGrey, darkBlack } from 'css/colors'
import IconButton from 'shared/iconButton'

const Input = styled(FieldWrapper)`
  & > input {
    position: relative;
  }
  & > button {
    position: absolute;
    right: 10px;
    top: 6px;
    &:not(.iconOnly):focus {
      outline: none;
    }
  }
`

const Content = styled.section`
  background: ${white};
  padding: 0.3rem 0;
`
const InputWrapper = styled.div`
  width: 85%;
  & div:nth-child(2) {
    margin-top: 0.5rem;
    margin-bottom: 0;
    & > input {
      margin: 0;
    }
  }
`
const SubHeader = styled.p`
  font-size: 14px;
  color: ${mediumGrey};
`
const Header = styled.h2`
  font-size: 20px;
  color: ${darkBlack};
`

/**
 * @typedef {{
 * invalid: object,
 * subHeader: string,
 * header?: string | Date,
 * handleChangeValue: function | any,
 * placeholder?: string,
 * modified?: boolean,
 * name: string | Date | null,
 * clearInvalid: function
 * }} PropsField
 *
 * @param {PropsField} props
 */
const Field = ({
  subHeader,
  header,
  handleChangeValue,
  placeholder = 'Ejemplo: incorrecto',
  invalid,
  name,
  modified,
  clearInvalid
}) => {
  const [openField, setOpenField] = useState(false)
  return (
    <Content>
      <FlexWrapper>
        <InputWrapper>
          <div onClick={() => setOpenField(!openField)}>
            <SubHeader>{subHeader}</SubHeader>
            <Header>{header}</Header>
          </div>
          {openField && (
            <Input>
              <input
                placeholder={placeholder}
                name={name}
                onChange={handleChangeValue}
                value={invalid[`${header}`] || invalid[`${name}`]}
              />
              <IconButton
                name={header}
                icon='close'
                onClick={() =>
                  clearInvalid(name, () => setOpenField(!openField))
                }
              />
            </Input>
          )}
        </InputWrapper>
        <FlexWrapper>
          {modified && (
            <IconButton
              aria-label='cambio'
              className='modified-value'
              icon='alert-circle-outline'
            />
          )}
          <IconButton
            className={className('cancel size right', {
              'invalid-key': invalid[`${name}`]
            })}
            aria-label='Rechazar'
            name={header}
            icon='close-circle'
            onClick={() => setOpenField(!openField)}
          />
        </FlexWrapper>
      </FlexWrapper>
    </Content>
  )
}
export default Field
