// Libraries
import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Notification from 'cogo-toast'
import className from 'classnames'

// Components
import ReceptorDetails from './details'
import Paginator from 'shared/paginator'
import Loader from 'shared/loader'
import IconButton from 'shared/iconButton'
import LinkButton from 'shared/linkButton'
import FiltersAccounting from 'shared/filtersAccounting'
import API from 'api/core'
import { getCountryLabel, hasFilters, stringifyQuery, parseQuery } from 'utils'

/**
 * @typedef {import('api/core').PaymentReceptor} PaymentReceptor
 * @typedef {import('api/core').Pagination} Pagination
 *
 * @typedef {Object} State
 * @property {boolean} requestInProgress
 * @property {Array<PaymentReceptor>} paymentReceptors
 * @property {PaymentReceptor | null} paymentReceptorSelected
 * @property {{country: string }} filters
 * @property {Pagination} pagination
 * @property {boolean} isUpdate
 *
 * @typedef {import('react-router-dom').LinkProps} LinkProps
 *
 */

class Receptors extends Component {
  /**
   * @type {State}
   */
  state = {
    paymentReceptors: [],
    paymentReceptorSelected: null,
    pagination: {
      total_pages: 1,
      total_entries: 0,
      page_number: 1,
      page_size: 1
    },
    requestInProgress: false,
    isUpdate: false,
    filters: { country: '' }
  }

  /**
   * @param {Object} prevProps
   * @param {Object} _
   */
  componentDidUpdate = (prevProps, _) => {
    const { country, history } = this.props
    if (country !== prevProps.country) {
      this.getPaymentReceptors()
      this.setState(
        { currentPage: 1 },
        history.push('/accounting/flow-payment/catalogues/receptors')
      )
    }
    if (this.props.location.search !== prevProps.location.search) {
      this.getPaymentReceptors()
    }
  }

  componentDidMount () {
    const { routeTab } = this.props
    this.getPaymentReceptors()
    routeTab()
  }

  onCloseDetails = () => {
    this.setState({ paymentReceptorSelected: null })
  }

  /**
   * @param {PaymentReceptor} paymentReceptor
   */
  changeSelectedItem = paymentReceptor => {
    this.setState(state => ({
      paymentReceptorSelected:
        state.paymentReceptorSelected &&
        state.paymentReceptorSelected.id === paymentReceptor.id
          ? null
          : paymentReceptor
    }))
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  onChangeField = event => {
    const { value, name } = event.currentTarget
    this.setState(state => ({
      filters: { ...state.filters, [name]: value }
    }))
  }

  /**
   * @param {PaymentReceptor} paymentReceptor
   */
  handleUpdateReceptor = paymentReceptor => {
    const { history } = this.props
    history.push({
      pathname: '/accounting/flow-payment/catalogues/new-receptor',
      state: {
        isUpdate: !this.state.isUpdate,
        paymentReceptor
      }
    })
  }

  onCleanFilters = () => {
    const { history, location } = this.props
    this.setState(
      {
        filters: { country: '' }
      },
      () => {
        history.push(location.pathname)
      }
    )
  }

  onFilter = () => {
    const { filters } = this.state
    const {
      history,
      location: { pathname }
    } = this.props
    history.push(pathname + stringifyQuery(hasFilters(filters)))
  }

  /**
   * Get payment receptors list of api
   * @returns {Promise<void>}
   */
  getPaymentReceptors = async () => {
    const { filters } = this.state
    const { location } = this.props
    const page = parseQuery(location.search).page || 1
    const params = {
      countries: filters.country || 'MX',
      page
    }
    this.setState({ requestInProgress: true })
    try {
      const { data, pagination } = await API.Accounting.GetPaymentReceptors(
        params
      )
      this.setState({
        paymentReceptors: data,
        pagination
      })
    } catch (error) {
      console.error(error)
      Notification.error(
        'Ha  ocurrido un error cargando los receprores de pago'
      )
    } finally {
      this.setState({ requestInProgress: false })
    }
  }

  render () {
    const {
      paymentReceptors,
      requestInProgress,
      filters,
      pagination,
      paymentReceptorSelected
    } = this.state
    return (
      <div>
        {requestInProgress ? (
          <Loader />
        ) : (
          <Fragment>
            <FiltersAccounting
              className='filter-accounting'
              filters={filters}
              onFilter={this.onFilter}
              onChangeField={this.onChangeField}
              onCleanFilters={this.onCleanFilters}
            />
            <div className='flex-between'>
              <p className='register-results'>{`Hay ${
                pagination.total_entries
              } registros`}</p>
              <LinkButton
                url='/accounting/flow-payment/catalogues/new-receptor'
                buttonClass='create'
                icon='plus-circle-outline'
                testId='newReceptor'
              >
                Nuevo receptor
              </LinkButton>
            </div>
            <table className='table'>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Tipo</th>
                  <th>País</th>
                  <th>Beneficiario</th>
                  <th>Banco</th>
                </tr>
              </thead>
              <tbody>
                {paymentReceptors &&
                  paymentReceptors.map(paymentReceptor => {
                    const {
                      name,
                      country,
                      beneficiary,
                      bank,
                      id,
                      receptor_type: receptorType
                    } = paymentReceptor
                    return (
                      <tr
                        key={id}
                        onClick={() => this.changeSelectedItem(paymentReceptor)}
                        className={className('', {
                          'flowDetails--select':
                            paymentReceptorSelected &&
                            id === paymentReceptorSelected.id
                        })}
                      >
                        <td className='link'>{name}</td>
                        <td>{receptorType && receptorType.type}</td>
                        <td>{getCountryLabel(country)}</td>
                        <td>{beneficiary}</td>
                        <td>{bank.name}</td>
                        <td>
                          <IconButton
                            icon='pencil'
                            onClick={() =>
                              this.handleUpdateReceptor(paymentReceptor)
                            }
                            tooltip='Editar'
                            testId='edit-receptor'
                          />
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
            <ReceptorDetails
              isOpen={!!paymentReceptorSelected}
              onCloseDetails={this.onCloseDetails}
              paymentReceptor={paymentReceptorSelected}
            />
            {pagination && <Paginator totalPages={pagination.total_pages} />}
          </Fragment>
        )}
      </div>
    )
  }
}
export default withRouter(Receptors)
