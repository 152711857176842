// @ts-nocheck
/* eslint-disable */
// Libraries
import React, {Component} from 'react'
import Notification from 'cogo-toast'

// Components
import {Table, TableHeader, TableBody} from 'css/utils'
import UploadIncome from './uploadIncome'
import UploadConfirmation from './uploadConfirmation'
import DownloadUnpayable from './downloadUnpayable'
import OxxoPay from './oxxoPay'
import Loader from 'shared/loader'
import Paginator from 'shared/paginator'
import LinkButton from 'shared/linkButton'
import Button from 'shared/button'
import Modal from 'shared/modal'
import FiltersAccounting from 'shared/filtersAccounting'
import {CLIENT_STATUS} from 'shared/catalogs'
import API from 'api/core'
import {
  stringifyQuery,
  getCountryLabel,
  hasFilters,
  parseQuery,
  incomeTypeOptions
} from 'utils'

/**
 * @typedef {import('api/core').Income} Income
 * @typedef {import('api/core').Pagination} Pagination
 * @typedef {Object} State
 * @property {Array<Income>} incomes
 * @property {Pagination} pagination
 * @property {boolean} requestInProgress
 * @property {boolean} openModal
 * @property {boolean} loadingIncome
 * @property {boolean} openConfirmation
 * @property {boolean} openUnpayable
 * @property {Array<String>} incomeTypes
 * @property {{income_type: string, country: string}} filters
 *
 * @typedef {import('react-router-dom').RouteComponentProps} Props
 * @extends {React.Component<Props, State>}
 */

export default class Incomes extends Component {
  /**
   * @type {State}
   */
  state = {
    incomes: [],
    incomeTypes: [],
    pagination: {
      total_pages: 1,
      total_entries: 1,
      page_number: 1,
      page_size: 1
    },
    filters: {
      income_type: '',
      country: ''
    },
    openModal: false,
    openConfirmation: false,
    requestInProgress: false,
    loadingIncome: false,
    openUnpayable: false,
    openModalOxxo: false
  }

  /**
   * @param {object} prevProps
   * @param {object} prevState
   */
  componentDidUpdate = (prevProps, prevState) => {
    const {filters} = this.state
    if (this.props.location.search !== prevProps.location.search) {
      this.getIncomes()
    }
    if (filters.country !== prevState.filters.country) {
      this.getIncomeTypes(filters.country)
    }
  }

  componentDidMount = () => {
    this.getIncomes()
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  onChangeField = event => {
    const {value, name} = event.currentTarget
    this.setState(state => ({
      filters: {...state.filters, [name]: value}
    }))
  }

  onCleanFilters = () => {
    const {history, location} = this.props
    this.setState(
      {
        filters: {
          income_type: '',
          country: ''
        }
      },
      () => {
        history.push(location.pathname)
      }
    )
  }

  onFilter = () => {
    const {filters} = this.state
    const {
      history,
      location: {pathname}
    } = this.props
    history.push(pathname + stringifyQuery(filters))
  }

  toggleModal = () => this.setState(state => ({openModal: !state.openModal}))
  toggleModalConfirmation = () =>
    this.setState(state => ({openConfirmation: !state.openConfirmation}))

  toggleModalUnpayable = () =>
    this.setState(state => ({openUnpayable: !state.openUnpayable}))

  toggleModalOxxo = () =>
    this.setState(state => ({openModalOxxo: !state.openModalOxxo}))

  /**
   * @param {string} status
   */
  handleSendFile = () => {
    this.getIncomes()
  }

  /**
   * @param {Income} income
   */
  handleUpdateIncome = income => {
    const {history} = this.props
    history.push({
      pathname: '/accounting/income/new',
      state: {income: income}
    })
  }

  getIncomes = async () => {
    const {filters} = this.state
    const {location} = this.props
    const page = parseQuery(location.search).page || 1
    const params = {
      ...hasFilters(filters),
      page
    }
    this.setState({requestInProgress: true})
    try {
      const {data, pagination} = await API.Accounting.GetIncomes(params)
      this.setState({
        incomes: data,
        pagination
      })
    } catch (error) {
      Notification.error('Ha ocurrido un error cargando los ingresos')
    } finally {
      this.setState({requestInProgress: false})
    }
  }

  /**
   * @param {string} company
   */
  getIncomeTypes = async company => {
    this.setState({loadingIncome: true})
    try {
      const {income_types: incomeTypes} = await API.Accounting.GetIncomeTypes(
        company || 'ALL'
      )
      this.setState({incomeTypes: incomeTypes})
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error cargando los tipos de ingresos')
    } finally {
      this.setState({loadingIncome: false})
    }
  }
  render() {
    const {
      incomes,
      requestInProgress,
      pagination,
      filters,
      openModal,
      openConfirmation,
      incomeTypes,
      loadingIncome,
      openUnpayable,
      openModalOxxo
    } = this.state
    const {history} = this.props
    return (
      <div>
        <div className='flex-between bottom'>
          <h1 className='title'>Ingresos</h1>
          <div className='flex-end'>
            <LinkButton
              url='/accounting/income/layout'
              buttonClass='primary right'
              icon='folder'
            >
              Formatos
            </LinkButton>
            <Button
              buttonClass='upload left'
              icon='upload'
              onClick={this.toggleModal}
            >
              Subir ingresos
            </Button>
            <Button
              buttonClass='upload'
              icon='upload'
              onClick={this.toggleModalConfirmation}
            >
              Confirmación
            </Button>
          </div>
        </div>
        <div>
          <FiltersAccounting
            name='income_type'
            typePlaceholder='Tipo de ingreso'
            onChangeField={this.onChangeField}
            onCleanFilters={this.onCleanFilters}
            onFilter={this.onFilter}
            filters={filters}
            loadingIncome={loadingIncome}
            optionTypes={incomeTypeOptions(incomeTypes)}
          />
        </div>
        <hr />
        <div className='flex-between bottom'>
          <p className='register-results no-padding'>
            {`Hay ${pagination ? pagination.total_entries : '0'} registros`}
          </p>
          <div className='flex-between'>
            <LinkButton
              url='/accounting/income/new'
              buttonClass='create'
              icon='plus-circle-outline'
              testId='create-income'
            >
              Nuevo ingreso
            </LinkButton>
            <LinkButton
              url='/accounting/income/report'
              icon='cloud-download'
              buttonClass='create left'
              testId='generate-report'
            >
              Reporte
            </LinkButton>
            <Button
              style={{marginRight: '0'}}
              buttonClass='create left'
              icon='cloud-download'
              onClick={this.toggleModalUnpayable}
            >
              Incobrables
            </Button>
            <Button
              buttonClass='create left'
              icon='cloud-download'
              onClick={this.toggleModalOxxo}
            >
              OxxoPay
            </Button>
          </div>
        </div>
        {requestInProgress && <Loader />}
        <Table>
          <TableHeader align='left'>
            <tr>
              <th>Tipo de ingreso</th>
              <th>Vehículo</th>
              <th>País</th>
              <th>Cliente</th>
              <th>Folio</th>
            </tr>
          </TableHeader>
          <TableBody noLine align='left'>
            {incomes &&
              incomes.length > 0 &&
              incomes.map(income => {
                return (
                  <tr
                    key={income.id}
                    onClick={() =>
                      history.push(`/accounting/income/${income.id}`)
                    }
                  >
                    <td className='link'>{income.income_type}</td>
                    <td>{income.saving_vehicle}</td>
                    <td>{getCountryLabel(income.country)}</td>
                    <td>
                      {CLIENT_STATUS[
                        income.client_product &&
                          income.client_product.client_status
                      ] || 'N/A'}
                    </td>
                    <td>{income.folio}</td>
                  </tr>
                )
              })}
          </TableBody>
        </Table>
        {pagination && <Paginator totalPages={pagination.total_pages} />}
        <Modal
          title='Carga masiva de ingresos'
          isActive={openModal}
          toggleModal={this.toggleModal}
          noFooter
        >
          <UploadIncome
            toggleModal={this.toggleModal}
            handleSendFile={this.handleSendFile}
          />
        </Modal>
        <Modal
          title='Archivo de confirmación'
          isActive={openConfirmation}
          toggleModal={this.toggleModalConfirmation}
          noFooter
        >
          <UploadConfirmation
            toggleModal={this.toggleModalConfirmation}
            handleUpdate={this.handleSendFile}
          />
        </Modal>
        <Modal
          title='Cancelados / Incobrables'
          isActive={openUnpayable}
          toggleModal={this.toggleModalUnpayable}
          noFooter
        >
          <DownloadUnpayable toggleModal={this.toggleModalUnpayable} />
        </Modal>
        <Modal
          title='Archivo OxxoPay'
          isActive={openModalOxxo}
          toggleModal={this.toggleModalOxxo}
          noFooter
        >
          <OxxoPay toggleModal={this.toggleModalOxxo} />
        </Modal>
      </div>
    )
  }
}
