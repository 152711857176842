import React from 'react'

/**
 * @typedef {import('./index').TypeReport} TypeReport
 * @param {{report: TypeReport}} props
 */
const ReportDetails = ({ report }) => {
  return (
    <div>
      {report && (
        <table className='table'>
          <thead>
            <tr>
              <th>Total facturado</th>
              <th>Total devuelto</th>
              <th>Total condonado</th>
              <th>Total a cobrar</th>
              <th>Total ingresos</th>
              <th>Total impagable</th>
              <th>Total cancelado</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{report.total_charged}</td>
              <td>{report.total_returned}</td>
              <td>{report.total_condoned}</td>
              <td>{report.total_to_charge}</td>
              <td>{report.total_income}</td>
              <td>{report.total_unpayable}</td>
              <td>{report.total_cancelled}</td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  )
}
export default ReportDetails
