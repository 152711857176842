// Libraries
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Field, Form, withFormik, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import debounce from 'lodash/debounce'

// Components
import { colorPrimary, mediumGrey } from 'css/colors'
import SelectField from 'shared/selectField'
import SelectFile from 'shared/selectFile'
import Button from 'shared/button'
import Required from 'shared/required'
import LinkButton from 'shared/linkButton'
import FieldAmount from 'shared/fieldAmount'
import DatePicker from 'shared/datePicker'
import SearchSuggestions from 'shared/searchSuggestions'
import { CURRENCIES } from 'shared/catalogs'

const WrapperFile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
`
const FilepDF = styled.div`
  color: ${colorPrimary};
  border: 1px solid ${mediumGrey};
  padding: 0.35rem;
  border-radius: 3px;
  width: 100%;
  text-align: center;
`

/**
 * @constant
 */
const MODAL_XML = 'openModalXML'

/**
 * @constant
 */
const MODAL_PDF = 'openModalPDf'

const validationSchema = Yup.object().shape({
  receptor: Yup.string()
    .trim()
    .required('Campo requerido. Busca un receptor de pago'),
  invoiceNumber: Yup.string()
    .trim()
    .required('Campo requerido'),
  concept: Yup.string()
    .trim()
    .required('Campo requerido'),
  flowDate: Yup.date().required('Campo requerido'),
  amount: Yup.string()
    .trim()
    .required('Campo requerido')
})

/**
 * @typedef {{
 * floatValue: number,
 * value: string,
 * formattedValue: string,
 * }} formatValue
 *
 * @typedef {import('shared/selectFile').File} File
 * @typedef {{
    receptor: string;
    beneficiary: string;
    accountNumber: string;
    bank: string;
    clabe: string;
    status: string;
    flowDate: Date;
    invoiceNumber: string;
    concept: string;
    invoiceDate: string;
    amount: string;
    iva: string;
    receptorType: string;
    paymentReceptor?: null | number;
    flowType?: string;
    company?: string;
    area?: string;
    subsidiary?: string;
    paymentMethod?: string;
    bankAccounts?: string;
    filePDF: File | null,
    fileXML: File | null,
    currency: string;
    floatAmount: number;
  }} Values
 */

/**
 * @typedef {import('formik').FormikProps<Values>} FormikProps
 * @typedef { import('../newFlowPayment').Option} Option
 *
 * @typedef {{
 * openModalXML: boolean,
 * openModalPDf: boolean
 * }} State
 *
 * @typedef {Object} FormNewFlowPaymentProps
 * @property {function} getBankAccounts
 * @property {function} createFlowPayment
 * @property {function} handleSearch
 * @property {boolean} requestInProgress
 * @property {boolean} requestingBank
 * @property {boolean} searching
 * @property {Option[]} flowTypes
 * @property {Option[]} areas
 * @property {Option[]} companies
 * @property {Option[]} subsidiaries
 * @property {Option[]} paymentMethods
 * @property {Option[]} bankAccounts
 * @property {string} country
 * @property {Array<import('api/core').PaymentReceptor>} paymentReceptors
 */

/**
 * @extends {Component<FormNewFlowPaymentProps & FormikProps, State>}
 */
class FormNewFlowPayment extends Component {
  /**
   * @type {State}
   */
  state = {
    [MODAL_PDF]: false,
    [MODAL_XML]: false
  }

  /**
   * @param {string} openModal
   */
  toggleModal = openModal => {
    this.setState(state => ({ ...state, [openModal]: !state[openModal] }))
  }

  /**
   * @param {{email: undefined, id: number, name: string}} selection
   */
  selectReceptor = selection => {
    const { setValues, values, paymentReceptors } = this.props
    if (!selection) {
      setValues({
        ...values,
        receptor: '',
        beneficiary: '',
        bank: '',
        accountNumber: '',
        clabe: ''
      })
      return
    }
    const receptor = paymentReceptors.find(
      ({ id }) => id === Number(selection.id)
    )
    if (!receptor) return
    setValues({
      ...values,
      paymentReceptor: receptor.id,
      receptor: receptor.name,
      beneficiary: receptor.beneficiary,
      bank: receptor.bank.name,
      accountNumber: String(receptor.account_number),
      clabe: String(receptor.clabe)
    })
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  handleChangeSelect = event => {
    const { value, name } = event.target
    if (name === 'company') {
      this.props.getBankAccounts(value)
    }

    const { setFieldValue } = this.props
    setFieldValue(name, value)
  }

  /**
   * @param {string} field
   */
  // @ts-ignore
  setFile = field => file => {
    const { values, setValues } = this.props
    setValues({
      ...values,
      [field]: file
    })
  }

  /**
   * @param {formatValue} formatValues
   */
  onValueChange = formatValues => {
    const { formattedValue, value, floatValue } = formatValues

    if (!formattedValue || !value) return

    const { setValues, values, paymentReceptors } = this.props
    const paymentReceptor = paymentReceptors.find(
      ({ id }) => id === Number(values.paymentReceptor)
    )
    const ivaPaymentReceptor =
      paymentReceptor && paymentReceptor.receptor_type.vat
    if (!ivaPaymentReceptor) return
    setValues({
      ...values,
      amount: formattedValue,
      floatAmount: floatValue,
      iva: String((value * ivaPaymentReceptor) / 100)
    })
  }

  /**
   * @param {Date} date
   */
  handleChange = date => {
    const { setFieldValue } = this.props
    setFieldValue('flowDate', date)
  }

  /**
   * @param {string} value
   */
  onChangeValue = debounce(value => {
    const { handleSearch } = this.props
    handleSearch(value)
  }, 200)

  render () {
    const {
      handleSubmit,
      isSubmitting,
      flowTypes,
      areas,
      companies,
      subsidiaries,
      paymentMethods,
      country,
      bankAccounts,
      requestingBank,
      values,
      paymentReceptors,
      searching
    } = this.props
    return (
      <div className='newFlow'>
        {country && (
          <Form onSubmitCapture={handleSubmit} data-testid='form'>
            <div>
              <SearchSuggestions
                label='Buscar receptor por nombre'
                placeholder='Buscar receptor por nombre'
                requestInProgress={searching}
                data={paymentReceptors}
                onChangeValue={this.onChangeValue}
                onChange={this.selectReceptor}
                positionLoadingClass='loading-receptor'
              />
            </div>
            <div className='link marginTop'>
              <Link to='/accounting/flow-payment/catalogues/new-receptor'>
                Agregar receptor a catálogo
              </Link>
            </div>
            <br />
            <div className='newFlow--field flex-between'>
              <div className='newFlow--field input-full-width text-right'>
                <label htmlFor='receptor'>Nombre del receptor</label>
                <Field
                  id='receptor'
                  name='receptor'
                  type='text'
                  placeholder='Receptor'
                  disabled
                  required
                />
                <ErrorMessage name='receptor'>
                  {message => <Required message={message} />}
                </ErrorMessage>
              </div>
              <div className='newFlow--field input-full-width text-right'>
                <label htmlFor='beneficiary'>Nombre del beneficiario</label>
                <Field
                  label='beneficiary'
                  name='beneficiary'
                  type='text'
                  placeholder='Beneficiario'
                  disabled
                  required
                />
              </div>
            </div>
            <div className='newFlow--field flex-between'>
              <div className='newFlow--field input-full-width text-right'>
                <label htmlFor='bank'>Banco</label>
                <Field name='bank' type='text' placeholder='Banco' disabled />
              </div>
              <div className='newFlow--field input-full-width text-right'>
                <label htmlFor='accountNumber'>Número de cuenta</label>
                <Field
                  name='accountNumber'
                  type='text'
                  placeholder='Número de cuenta'
                  disabled
                  required
                />
              </div>
              <div className='newFlow--field input-full-width text-right'>
                <label htmlFor='clabe'>Clabe</label>
                <Field
                  name='clabe'
                  type='text'
                  placeholder='Clabe'
                  disabled
                  required
                />
              </div>
            </div>
            <hr />
            <div>
              <h2 className='subtitle'>Datos del fujo</h2>
              <div className='newFlow--select flex-between'>
                <div className='newFlow--field input-full-width text-right'>
                  <label htmlFor='status'>Estado del flujo</label>
                  <Field
                    name='status'
                    type='text'
                    placeholder='Estado'
                    disabled
                  />
                </div>
                <div className='newFlow--field input-full-width'>
                  <Field name='flowDate'>
                    {({ field }) => (
                      <DatePicker
                        {...field}
                        name='flowDate'
                        width='100%'
                        label='Fecha del flujo *'
                        placeholderText='DD/MM/YYYY'
                        selected={values.flowDate}
                        onChange={this.handleChange}
                        dateFormat='dd MMMM yyyy'
                        maxDate={new Date()}
                      />
                    )}
                  </Field>
                  <ErrorMessage name='flowDate'>
                    {message => <Required message={message} />}
                  </ErrorMessage>
                </div>
              </div>
              <div>
                <div className='newFlow--select flex-between'>
                  <SelectField
                    label='Tipo de flujo'
                    placeholder='Tipo de flujo'
                    options={flowTypes}
                    name='flowType'
                    value={values.flowType}
                    onChange={this.handleChangeSelect}
                    required
                  />
                  <SelectField
                    label='Empresa'
                    placeholder='Seleccione empresa'
                    options={companies}
                    name='company'
                    value={values.company}
                    onChange={this.handleChangeSelect}
                    loading={requestingBank}
                    required
                  />
                </div>
                <div className='newFlow--select flex-between'>
                  <SelectField
                    label='Área'
                    placeholder='Seleccione área'
                    options={areas}
                    name='area'
                    value={values.area}
                    onChange={this.handleChangeSelect}
                    required
                  />
                  <SelectField
                    label='Sucursal'
                    placeholder='Seleccione sucursal'
                    options={subsidiaries}
                    name='subsidiary'
                    value={values.subsidiary}
                    onChange={this.handleChangeSelect}
                    required
                  />
                </div>
                <div className='newFlow--select flex-between'>
                  <SelectField
                    label='Método de pago'
                    placeholder='Método de pago'
                    options={paymentMethods}
                    name='paymentMethod'
                    value={values.paymentMethod}
                    onChange={this.handleChangeSelect}
                    required
                  />
                  <SelectField
                    label='Cuenta de retiro'
                    placeholder='Seleccione cuenta'
                    options={bankAccounts}
                    name='bankAccounts'
                    value={values.bankAccounts}
                    onChange={this.handleChangeSelect}
                    disabled={!values.company}
                    required
                  />
                </div>
              </div>
              <hr />
            </div>
            <div>
              <h2 className='subtitle'>Información del movimiento</h2>
              <div className='flex-between'>
                <p className='newFlow--field input-full-width text-right'>
                  <label htmlFor='invoiceNumber'>Número de factura *</label>
                  <Field
                    name='invoiceNumber'
                    id='invoiceNumber'
                    type='text'
                    placeholder='Número de factura'
                  />
                  <ErrorMessage name='invoiceNumber'>
                    {message => <Required message={message} />}
                  </ErrorMessage>
                </p>
                <p className='newFlow--field input-full-width text-right'>
                  <label htmlFor='concept'>Concepto *</label>
                  <Field
                    name='concept'
                    type='text'
                    placeholder='Concepto'
                    id='concept'
                  />
                  <ErrorMessage name='concept'>
                    {message => <Required message={message} />}
                  </ErrorMessage>
                </p>
                <div className='newFlow--field input-full-width'>
                  <DatePicker
                    name='invoiceDate'
                    width='100%'
                    label='Fecha de la factura *'
                    placeholderText='DD/MM/YYYY'
                    selected={new Date()}
                    dateFormat='dd MMMM yyyy'
                    disabled
                  />
                </div>
              </div>
              <div className='flex-between bottom'>
                <div className='newFlow--field input-medium text-right'>
                  <SelectField
                    label='Moneda'
                    placeholder='Moneda'
                    name='currency'
                    options={CURRENCIES}
                    value={values.currency}
                    onChange={this.handleChangeSelect}
                    required
                  />
                </div>
                <div className='flex-between full-width'>
                  <div className='newFlow--field input-full-width text-right'>
                    <Field component='div' name='amount'>
                      <FieldAmount
                        name='amount'
                        label='Monto total'
                        placeholder='Monto'
                        currency={values.currency}
                        value={values.amount}
                        onValueChange={this.onValueChange}
                        required
                      />
                    </Field>
                    <ErrorMessage name='amount'>
                      {message => <Required message={message} />}
                    </ErrorMessage>
                  </div>
                  <div className='newFlow--field input-full-width'>
                    <Field name='iva' component='div' disabled>
                      <FieldAmount
                        name='iva'
                        label='IVA'
                        placeholder='IVA'
                        currency={values.currency}
                        value={values.iva}
                        disabled
                      />
                    </Field>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <WrapperFile>
                    {values.filePDF && <FilepDF>{values.filePDF.name}</FilepDF>}
                    {values.fileXML && <FilepDF>{values.fileXML.name}</FilepDF>}
                  </WrapperFile>
                  <SelectFile
                    key='fileXML'
                    isActive={this.state[MODAL_XML]}
                    toggleModal={() => this.toggleModal(MODAL_XML)}
                    setFile={this.setFile('fileXML')}
                    title='Agregar recibo de pago'
                    accept='text/xml'
                  />
                  <SelectFile
                    key='filePDF'
                    isActive={this.state[MODAL_PDF]}
                    toggleModal={() => this.toggleModal(MODAL_PDF)}
                    setFile={this.setFile('filePDF')}
                    title='Agregar recibo de pago'
                    accept='application/pdf'
                  />
                </div>
                <div className='flex-between bottom'>
                  <div className='flex-between'>
                    <Button
                      key='buttonPDf'
                      buttonClass='upload'
                      icon='upload'
                      onClick={() => this.toggleModal(MODAL_PDF)}
                    >
                      Subir PDF
                    </Button>
                    <Button
                      buttonClass='upload'
                      icon='upload'
                      onClick={() => this.toggleModal(MODAL_XML)}
                    >
                      Subir XML
                    </Button>
                  </div>
                  <div className='flex-between'>
                    <Button
                      type='submit'
                      buttonClass='primary'
                      icon='check'
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      testId='create-flow'
                    >
                      Crear flujo
                    </Button>
                    <LinkButton
                      url='/accounting/flow-payment'
                      buttonClass='secondary'
                      icon='cancel'
                    >
                      Cancelar
                    </LinkButton>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </div>
    )
  }
}

export default withFormik({
  mapPropsToValues: () => {
    return {
      paymentReceptor: null,
      receptor: '',
      beneficiary: '',
      accountNumber: '',
      bank: '',
      clabe: '',
      status: 'EN ESPERA',
      flowDate: '',
      invoiceNumber: '',
      concept: '',
      invoiceDate: '',
      amount: '',
      iva: '',
      receptorType: '',
      flowType: '',
      company: '',
      area: '',
      subsidiary: '',
      paymentMethod: '',
      bankAccounts: '',
      filePDF: '',
      fileXML: '',
      currency: '',
      floatAmount: '',
      search: ''
    }
  },
  validationSchema,
  handleSubmit: async (values, { setSubmitting, props }) => {
    setSubmitting(true)
    await props.createFlowPayment(values)
    setSubmitting(false)
  },
  displayName: 'FormNewFlowPayment'
})(FormNewFlowPayment)
