import React, { Component } from 'react'

import formatMoney from '../../format/money'
import dateFormat from 'dateformat'

export default class extends Component {
  // --------------------------------------------------------------------------
  // Render Principal.
  // --------------------------------------------------------------------------

  render () {
    dateFormat.i18n = {
      dayNames: [
        'Dom',
        'Lun',
        'Mart',
        'Miérc',
        'Juev',
        'Vier',
        'Sáb',
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado'
      ],
      monthNames: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ],
      timeNames: ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM']
    }
    const { movements } = this.props
    const isMovements = movements || []
    const body = isMovements.map(
      ({
        id,
        amount,
        current_balance: currentBalance,
        movement_type: movementType
      }) => (
        <tr key={id} className='is-red'>
          <td>{amount ? formatMoney.toMoney(amount) : ''}</td>
          <td>{currentBalance ? formatMoney.toMoney(currentBalance) : ''}</td>
          <td>{movementType}</td>
          <td>{dateFormat(new Date(), 'dd/mmm/yyyy - h:MM:ss TT')}</td>
        </tr>
      )
    )

    return (
      <table className='table'>
        <thead>
          <tr>
            <th>Monto</th>
            <th>Balance actual</th>
            <th>Tipo</th>
            <th>Fecha</th>
          </tr>
        </thead>
        <tbody>{body}</tbody>
      </table>
    )
  }
}
