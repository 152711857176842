// Libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import API from 'api/bank'
import formatMoney from 'shared/format/money'
import MovementsTable from 'shared/table/movements'
import Title from 'shared/title'

const title = 'Cuenta'
const breadcrumbLocation = [
  { label: 'Cuentas de banco', route: '/accounts' },
  { label: 'Cuenta', route: '' }
]

const AccountDetail = ({ uid, metadata, balance, movements }) => (
  <div>
    <div className='columns is-multiline'>
      <div className='column is-12'>
        <h2 className='title is-3'>{uid}</h2>
      </div>
      <div className='column is-4'>
        <strong>Proveedor: </strong> <br />
        {metadata.source}
      </div>
      <div className='column is-4'>
        <strong>Referencia: </strong> <br />
        {metadata.reference}
      </div>
      <div className='column is-4'>
        <strong>Balance: </strong> <br />
        {balance && formatMoney.toMoney(balance)}
      </div>
    </div>
    <MovementsTable movements={movements} />
  </div>
)

AccountDetail.propTypes = {
  uid: PropTypes.string,
  metadata: PropTypes.object,
  balance: PropTypes.object,
  movements: PropTypes.array
}

class Account extends Component {
  constructor (props) {
    super(props)
    this.state = {
      breadLocation: [
        { label: 'Cuentas de banco', route: '/accounts' },
        { label: 'Cuenta', route: '' }
      ],
      account: null,
      title: 'Cuentas de banco'
    }
  }

  // --------------------------------------------------------------------------
  // Obtiene el detalle de la regla antes de montar el componente
  // --------------------------------------------------------------------------

  componentDidMount () {
    this.getAccount()
  }

  // --------------------------------------------------------------------------
  // Realiza una petición a la api de banco para obtener el detalle de una cuenta.
  // --------------------------------------------------------------------------

  getAccount () {
    API.Accounts.Show(this.props.match.params.uuid)
      .then(response => {
        this.setState({ account: response.data })
      })
      .catch(error => {
        console.error(error)
      })
  }
  // --------------------------------------------------------------------------
  // Render Principal.
  // --------------------------------------------------------------------------

  render () {
    const { account } = this.state
    return (
      <div>
        <Title title={title} breadcrumbLocation={breadcrumbLocation} />
        {account && (
          <AccountDetail
            uid={account.uid}
            metadata={account.metadata}
            balance={account.balance}
            movements={account.movements}
          />
        )}
      </div>
    )
  }
}

export default Account
