import React from 'react'
import styled from 'styled-components'

import { darkBlack, mediumGrey } from 'css/colors'
import Card from 'shared/card'
import { getCountryLabel, LogoByProductLine } from 'utils'

const WrapperProducts = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;
`
const Subtitle = styled.p`
  font-size: 12px;
  color: ${mediumGrey};
`
const Title = styled.h2`
  font-size: 20px;
  color: ${darkBlack};
  margin-bottom: 1.3rem;
`

const Product = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`
const Logo = styled.div`
  width: 200px;
  height: 100px;
  line-height: 100px;
`
const Content = styled.div`
  width: 70%;
  margin: 0 auto;
`

/**
 * @typedef {import('api/core').Product} Product
 * @typedef {Object} PropsProducts
 * @property {Array<Product>} products
 * @param {PropsProducts} products
 */

const Products = ({ products }) => {
  if (!products) return
  return (
    <Content>
      {products.map(({ uid, product_line: productLine, country, stage }) => {
        return (
          <Card key={uid}>
            <WrapperProducts>
              <Logo>{LogoByProductLine(productLine)}</Logo>
              <Product>
                <Subtitle>Tipo de cliente</Subtitle>
                <Title>{stage}</Title>
              </Product>
              <Product>
                <Subtitle>País</Subtitle>
                <Title>{getCountryLabel(country.toUpperCase())}</Title>
              </Product>
            </WrapperProducts>
          </Card>
        )
      })}
    </Content>
  )
}
export default Products
