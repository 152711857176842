// Libraries
import React, { Fragment } from 'react'

// Components
import { DOCUMENT_TITLES } from './constants'

const Image = ({ title, entity, notes, src, onClick, width = 100 }) => {
  let entityLabel = entity ? (
    <label className='label file-content-help'>{`Entidad: ${entity}`}</label>
  ) : null

  let notesLabel = notes ? (
    <label className='label file-content-help'>{`Referencia: ${notes}`}</label>
  ) : null

  return (
    <Fragment>
      <span className='label'>{DOCUMENT_TITLES[title] || 'Otros'}</span>
      {entityLabel}
      {notesLabel}
      <img {...{ width, src, onClick, height: 65 }} />
    </Fragment>
  )
}

export default Image
