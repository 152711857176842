// Libraries
import React from 'react'

// Components
import { DOCUMENT_TITLES } from './constants'

const Pdf = ({ title, entity, notes, value, onClick }) => {
  let entityLabel = entity ? (
    <label className='label file-content-help'>{`Entidad: ${entity}`}</label>
  ) : null

  let notesLabel = notes ? (
    <label className='label file-content-help'>{`Referencia: ${notes}`}</label>
  ) : null

  return (
    <div className='field'>
      <label className='label'>{DOCUMENT_TITLES[title] || 'Otros'}</label>
      {entityLabel}
      {notesLabel}
      <a onClick={onClick}>
        <i className='mdi mdi-magnify mdi-48px' />
      </a>
    </div>
  )
}

export default Pdf
