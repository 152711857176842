import React from 'react'
import styled from 'styled-components'

import { colorSecondary, white } from 'css/colors'

const CardTitle = styled.h2`
  color: ${white};
  font-size: 14px;
  padding: 0.5em 1em;
  text-transform: uppercase;
  font-weight: 600;
  background: ${colorSecondary};
`
const Wrapper = styled.div`
  margin: 0 auto;
  margin-top: 1em;
  width: ${props => props.width || '100%'};
  box-shadow: 0px 0px 7px -4px rgba(0, 0, 0, 0.75);
  & > div {
    padding: 1em;
  }
`

/**
 * @typedef {{
 * title?: string,
 * children: React.ReactNode,
 * width?:  React.ReactNode,
 * role?:  'button',
 * onClick?:  () => void
 * style?:  Object
 * className?:  any
 * }} PropsCard
 *
 * @param {PropsCard} props
 */
const Card = ({ title, children, width, onClick, role, style, className }) => {
  return (
    <Wrapper
      width={width}
      onClick={onClick}
      role={role}
      style={style}
      className={className}
      aria-label='card'
      tabIndex={0}
    >
      {title && <CardTitle>{title}</CardTitle>}
      <div>{children}</div>
    </Wrapper>
  )
}
export default Card
