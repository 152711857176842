import styled, { keyframes, css } from 'styled-components'
import {
  darkBlack,
  lightgreyHover,
  mediumGrey,
  blueResuelveLogo,
  lightGreyAlt,
  colorPrimary,
  redTraffic,
  yellowTraffic,
  greenTraffic
} from 'css/colors'

/**
 * @param {number} hours
 */
function hoursColors (hours) {
  switch (true) {
    case hours > 20:
      return css`
        border-left: 4px solid ${redTraffic} !important;
      `
    case hours > 16 && hours < 20:
      return css`
        border-left: 4px solid ${yellowTraffic} !important;
      `
    default:
      return css`
        border-left: 4px solid ${greenTraffic} !important;
      `
  }
}

/**
 * @param {string} status
 */
function hasStatus (status) {
  if (status === 'canceled' || status === 'approved') {
    return css`
      border-left: 4px solid ${darkBlack} !important;
    `
  }
}

/**
 * @constant
 */
export const MARGIN = '1.3rem'

export const MARGIN_BOTTOM = '2rem'

/**
 * @typedef {{ color: string, size: string }} TitleProps
 * @param {TitleProps} props
 */
export const Title = styled.h1.attrs(({ color, size }) => ({
  color: color || `${darkBlack}`,
  fontSize: size || '2rem'
}))`
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  margin-bottom: 1.5rem;
  font-weight: 600;
  line-height: 1.125;
`

export const FlexWrapper = styled.div.attrs(({ bottom, top }) => ({
  bottom: bottom || 0,
  top: top || 0
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.bottom};
  margin-top: ${props => props.top};
`

export const FormWrapper = styled.div`
  max-width: 950px;
  margin: 0 auto;
  margin-bottom: 3rem;
`

export const FieldWrapper = styled.div.attrs(
  ({ right = 0, left = 0, width = '100%' }) => ({
    right,
    left,
    width
  })
)`
  width: ${props => props.width};
  position: relative;
  margin-bottom: 2rem;
  margin-right: ${props => props.right};
  margin-left: ${props => props.left};
  & > input {
    border: 1px solid ${lightgreyHover};
    border-radius: 3px;
    padding: 0.5rem;
    margin-top: 0.5rem;
    font-size: 1rem;
    width: 100%;
    &::placeholder {
      color: ${mediumGrey};
    }
  }
  & > label {
    font-weight: 700;
  }
`

export const Table = styled.table`
  width: 100%;
  margin-bottom: 0.7rem;
  border-collapse: separate;
  border-spacing: 0 0.7rem;
  cursor: pointer;
`

export const TableHeader = styled.thead`
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 3px;
  & > tr {
    box-shadow: 0px 0px 9px 0px rgba(153, 153, 153, 0.1);
    & > th {
      padding: 1.5rem 1.5rem;
      color: ${mediumGrey};
    }
    & th:first-child {
      border-radius: 4px 0 0 4px;
    }
    & th:last-child {
      border-radius: 0 4px 4px 0;
      text-align: ${props => (props.align ? props.align : 'center')};
    }
  }
`

export const TableBody = styled.tbody`
  & > tr {
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
    line-height: 2rem;
    &:hover {
      background: ${lightGreyAlt};
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    }
    & > td {
      padding: 1.2rem 1.5rem;
      & > a {
        color: ${darkBlack};
      }
    }
    & td:first-child {
      border-radius: 4px 0 0 4px;
      border-left: 4px solid ${blueResuelveLogo};
    }
    & td:last-child {
      border-radius: 0 4px 4px 0;
      line-height: ${props => (props.noLine ? 'unset' : '1rem')};
      text-align: ${props => (props.align ? props.align : 'center')};
    }
  }
`
export const TableData = styled.td`
  border-radius: 4px 0 0 4px;
  color: ${colorPrimary};
  ${({ hours }) => hoursColors(hours)}
  ${({ status }) => hasStatus(status)}
`

export const TableDataCell = styled.td`
  border-radius: 4px 0 0 4px;
  color: ${colorPrimary};
  border-left: 4px solid ${({ color }) => color} !important;
`

export const scaleInVerTop = keyframes`
  0% {
    transform: scaleY(0);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }

`
