// Libraries
import React, { Fragment } from 'react'

// Components
import Field from '../../../components/field'

/**
 * @typedef {import('api/core').TypeContraClientAddress} TypeContraClientAddress
 * @typedef {{
 * address: TypeContraClientAddress,
 * invalid: object,
 * country: string,
 * handleChangeValue: function,
 * clearInvalid: function,
 * }} PropsAddress
 *
 * @param {PropsAddress} props
 */
const Address = ({
  address,
  country,
  handleChangeValue,
  invalid,
  clearInvalid
}) => {
  return (
    <Fragment>
      <Field
        name='address.street'
        subHeader='Calle'
        invalid={invalid}
        header={address.street.value}
        handleChangeValue={handleChangeValue}
        modified={address.street.modified}
        clearInvalid={clearInvalid}
      />
      {country !== 'co' && (
        <Fragment>
          <Field
            name='address.street_number'
            subHeader='Número exterior'
            invalid={invalid}
            header={address.street_number.value}
            handleChangeValue={handleChangeValue}
            modified={address.street_number.modified}
            clearInvalid={clearInvalid}
          />
          <Field
            name='address.interior_number'
            subHeader='Número interior'
            invalid={invalid}
            header={address.interior_number.value}
            handleChangeValue={handleChangeValue}
            modified={address.interior_number.modified}
            clearInvalid={clearInvalid}
          />
          <Field
            name='address.neighborhood'
            subHeader='Colonia'
            invalid={invalid}
            header={address.neighborhood.value || 'Sin colonia'}
            handleChangeValue={handleChangeValue}
            modified={address.neighborhood.modified}
            clearInvalid={clearInvalid}
          />
        </Fragment>
      )}
      <Field
        name='address.municipality'
        subHeader='Municipio'
        invalid={invalid}
        header={address.municipality.value}
        handleChangeValue={handleChangeValue}
        modified={address.municipality.modified}
        clearInvalid={clearInvalid}
      />
      <Field
        name='address.state'
        subHeader='Estado'
        invalid={invalid}
        header={address.state.value}
        handleChangeValue={handleChangeValue}
        modified={address.state.modified}
        clearInvalid={clearInvalid}
      />
      {country !== 'co' && (
        <Field
          name='address.postal_code'
          subHeader='Código postal'
          invalid={invalid}
          header={address.postal_code.value || 'Sin código'}
          handleChangeValue={handleChangeValue}
          modified={address.postal_code.modified}
          clearInvalid={clearInvalid}
        />
      )}
      <Field
        name='address.country'
        subHeader='País'
        invalid={invalid}
        header={address.country.value}
        handleChangeValue={handleChangeValue}
        modified={address.country.modified}
        clearInvalid={clearInvalid}
      />
    </Fragment>
  )
}
export default Address
