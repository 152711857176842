import React from 'react'
import PropTypes from 'prop-types'

/**
 * @typedef {Object} ButtonProps
 * @property {string} [type]
 * @property {boolean} [disabled=false]
 * @property {string} [icon]
 * @property {string} [buttonClass]
 * @property {string} [className]
 * @property {string} [flag]
 * @property {object} [style]
 * @property {function} onClick
 * @property {function} [onBlur]
 * @property {boolean} [loading=false]
 * @property {string} [testId]
 * @property {string} [tooltip]
 * @property {string} [name]
 * @property {number} [tabIndex]
 * @property {any} [onKeyUp]
 * @property {any} [onMouseDown]
 * @property {React.ReactNode} [children]
 *
 * @param {ButtonProps} props
 */

const Button = ({
  children,
  type,
  disabled,
  icon,
  buttonClass,
  className,
  style,
  onClick,
  onBlur,
  loading,
  testId,
  flag,
  tooltip,
  name,
  tabIndex,
  onKeyUp,
  onMouseDown
}) => {
  if (loading) {
    icon = 'loading mdi-spin'
  }
  return (
    <button
      className={`coreButton ${buttonClass} ${className}`}
      disabled={disabled}
      onClick={onClick}
      onBlur={onBlur}
      style={style}
      type={type}
      name={name}
      data-testid={`button-${testId}`}
      tabIndex={tabIndex}
      onKeyUp={onKeyUp}
      onMouseDown={onMouseDown}
    >
      {tooltip && (
        <span className='tooltip' style={{ marginTop: '1rem' }}>
          {tooltip}
        </span>
      )}
      <span className='flex-center'>
        {icon && (
          <i className={`mdi mdi-${icon}`} style={{ marginRight: '0.3rem' }} />
        )}
        {flag && (
          <img className='flag' width='30px' src={flag} alt={`flag-${flag}`} />
        )}
        {children}
      </span>
    </button>
  )
}

export default Button

Button.propTypes = {
  children: PropTypes.string,
  type: PropTypes.string,
  flag: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  buttonClass: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  testId: PropTypes.string
}

Button.defaultProps = {
  type: 'button',
  disabled: false,
  icon: '',
  buttonClass: '',
  className: '',
  style: {},
  loading: false,
  testId: '',
  flag: '',
  onClick: () => {},
  onBlur: () => {}
}
