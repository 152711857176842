import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _isNull from 'lodash/isNull'

/**
 * @typedef {Object} State
 * @property {string} [value]
 * @property {boolean} [isValid]
 * @property {string} [errorText]
 *
 * @typedef {Object} Props
 * @property {string} [label]
 * @property {Array<Object>} options
 * @property {string} [className]
 * @property {undefined | string} placeholder[]
 * @property {string} [value]
 * @property {boolean} [disabled]
 * @property {boolean} [required]
 * @property {boolean} [loading]
 * @property {function} onChange
 * @property {function} onFocus[]
 * @property {function} onBlur[]
 * @property {object} [optionsConfig]
 * @property {boolean} [showId]
 * @property {Array<Object>} disabledOptions[]
 * @property {Array<Object>} hiddenOptions[]
 * @property {string} [info]
 * @property {string} [name]
 * @property {string} [optionalText]
 */

/**
 * @extends {React.Component<Props, State>}
 */

class SelectField extends Component {
  state = {
    value: this.props.value,
    isValid: true,
    errorText: ''
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   */
  static getDerivedStateFromProps (props, state) {
    if (
      props.options.some(option =>
        typeof option === 'object'
          ? props.value === option[props.optionsConfig.id].toString()
          : props.value === option
      ) ||
      props.value !== state.value
    ) {
      return { value: props.value }
    }
    return null
  }

  /**
   * @typedef {React.ChangeEvent<HTMLInputElement>} event
   * @param {object} event
   */

  onChange = event => {
    this.setState({ value: event.target.value }, this.validate)
    this.props.onChange(event)
  }

  // Validates if selected value is any other but the default.
  // Sets on state the validation result and error message
  // Returns boolean with the validation result.

  /**
   * @type {function(): boolean}
   */

  validate = () => {
    const { required } = this.props
    let isValid = !required
    let errorText = ''
    if (required) {
      isValid = !!this.state.value
      errorText = isValid ? '' : 'Este campo es requerido'
    }
    this.setState({ isValid, errorText })
    return isValid
  }

  /**
   * @type {function(): React.ReactNode}
   */

  renderPlaceholder () {
    const { placeholder } = this.props
    return (
      placeholder.length > 0 && (
        <option value='' disabled>
          {placeholder}
        </option>
      )
    )
  }

  /**
   * @param {Array<Object>} opts
   */

  renderOptions (opts) {
    const {
      optionsConfig,
      className,
      disabled,
      onFocus,
      onBlur,
      required,
      showId,
      disabledOptions,
      hiddenOptions,
      name,
      placeholder,
      label
    } = this.props

    let { value, isValid } = this.state
    if (_isNull(value)) value = ''
    const options = opts.map(opt => {
      if (hiddenOptions.includes(opt[optionsConfig.id])) return
      return typeof opt === 'object' ? (
        <option
          value={opt[optionsConfig.id]}
          key={opt[optionsConfig.id]}
          disabled={disabledOptions.includes(opt[optionsConfig.id])}
          className='is-black'
        >
          {showId && `${opt[optionsConfig.id]} – `}
          {opt[optionsConfig.label]}
        </option>
      ) : (
        <option value={opt} key={opt}>
          {opt}
        </option>
      )
    })
    return (
      <select
        className={classNames(className, { 'is-danger': !isValid })}
        onChange={this.onChange}
        disabled={disabled}
        value={value}
        name={name}
        onFocus={onFocus}
        onBlur={onBlur}
        required={required}
        style={value === '' ? { color: '#bbb' } : {}}
        placeholder={placeholder}
        id={required ? `${label} *` : label}
      >
        {this.renderPlaceholder()}
        {options}
      </select>
    )
  }

  render () {
    const {
      label,
      options,
      required,
      info,
      className,
      loading,
      optionalText
    } = this.props
    const { isValid, errorText } = this.state
    const icon = 'loading mdi-spin'
    return (
      <div className='field full-width'>
        {label && (
          <label
            className={classNames('label', {
              'has-text-centered': className.includes('has-centered-label')
            })}
            htmlFor={required ? `${label} *` : label}
          >
            {required ? `${label} *` : label}
            {loading && (
              <i className={`mdi mdi-${icon}`} style={{ marginLeft: '0.3rem' }} />
            )}
            {optionalText && !required && (
              <span className='optional-text'>(opcional)</span>
            )}
          </label>
        )}
        <p
          className={classNames('control is-marginless', {
            'has-info': info
          })}
        >
          <span className='select is-fullwidth'>
            {this.renderOptions(options)}
          </span>
          {info && (
            <span className='icon info-icon'>
              <span className='tooltip'>{info}</span>
              <i className='mdi mdi-information-outline' />
            </span>
          )}
        </p>
        <span className={classNames('help is-danger', { 'is-hidden': isValid })}>
          {errorText || ''}
        </span>
      </div>
    )
  }
}

export default SelectField

SelectField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  optionsConfig: PropTypes.object,
  showId: PropTypes.bool,
  disabledOptions: PropTypes.array,
  hiddenOptions: PropTypes.array,
  info: PropTypes.string
}

SelectField.defaultProps = {
  options: [],
  className: '',
  placeholder: '',
  value: '',
  disabled: false,
  required: false,
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  optionsConfig: { id: 'id', label: 'label' },
  showId: false,
  disabledOptions: [],
  hiddenOptions: [],
  info: ''
}
