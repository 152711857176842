/*
  API Wrapper
  The intent of this module is to have a single place in the app where all API calls are located
  The syntax for a new API call can be inferred from the existing exported classes, it supports the HTTP methods GET, POST, PUT and DELETE
  All four methods call the request function that parses and prepares the data before making the actual request, the URL parameters are converted to a query string and headers / auth are added in this step
  The response is a promise that must be resolved from the place it was called
*/
import queryString from 'query-string'
import nprogress from 'nprogress'
import store from 'store'

// Make the actual request
function request (method, endpoint, queryArray, body) {
  let query = ''
  if (typeof endpoint === 'undefined') {
    console.error('FATAL ERROR: endpoint not defined')
    return
  }
  if (typeof queryArray !== 'undefined' && queryArray !== null) {
    query = `?${queryString.stringify(queryArray)}`
  }

  // Delete ? sign if the query string is empty
  if (query === '?') query = ''

  const url = `${process.env.API_PAYMENTS_SERVER}${
    process.env.API_PREFIX_CORE
  }${endpoint}${query}`

  const fetchParams = {
    method: method,
    headers: {
      'content-type': 'application/json',
      Authorization: store.get('CORE_AUTH_TOKEN'),
      'X-Requested-With': 'XMLHttpRequest'
    }
  }

  if (body) fetchParams.body = JSON.stringify(body)

  nprogress.start()
  // Call the API and return a json response
  return window
    .fetch(url, fetchParams)
    .then(response =>
      response.json().then(r => {
        nprogress.done()
        if (response.status >= 200 && response.status < 300) {
          if (process.env.DEBUG) {
            console.info(`${method}: ${url}`)
            console.info('resolvedRes', r)
          }
          return r
        } else if (response.status === 401) {
          store.clearAll()
        } else {
          return r
        }
      })
    )
    .catch(e => console.error(e))
}

// HTTP POST
function Post (route, query, params, data) {
  return request('POST', route, query, data)
}

class Payments {
  static Create (data) {
    return Post('/transaction', {}, null, data)
  }

  static Cancel (data) {
    return Post('/transaction/cancel', {}, null, data)
  }
}

export default Payments
