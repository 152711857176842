// Libaries
import React, { Component } from 'react'
import { withFormik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Notification from 'cogo-toast'
import debounce from 'lodash/debounce'
import moment from 'moment'

// Components
import SelectField from 'shared/selectField'
import FieldAmount from 'shared/fieldAmount'
import Required from 'shared/required'
import Button from 'shared/button'
import LinkButton from 'shared/linkButton'
import DatePicker from 'shared/datePicker'
import SerachSuggestions from 'shared/searchSuggestions'
import { CURRENCIES, SAVING_VEHICLE } from 'shared/catalogs'
import { incomeTypeOptions, getOptionsCompanies } from 'utils'
import API from 'api/core'

const validationSchema = Yup.object().shape({
  receivableAmount: Yup.string()
    .trim()
    .required('Campo requerido'),
  incomeType: Yup.string()
    .trim()
    .required('Campo requerido'),
  currency: Yup.string()
    .trim()
    .required('Campo requerido'),
  savingVehicle: Yup.string()
    .trim()
    .required('Campo requerido'),
  invoiceDate: Yup.date().required('Campo requerido')
})

/**
 * @typedef {{
 * email: string,
 * first_surname: string,
 * id: number,
 * mobile_phone: string,
 * name: string,
 * second_surname: string
 * }} SelectedItem
 *
 * @typedef {{
 * floatValue: number,
 * value: string,
 * formattedValue: string,
 * }} formatValue
 *
 * @typedef {{
 * incomeType: string;
 * currency: string,
 * receivableAmount: string,
 * floatReceivableAmount: number,
 * company: string,
 * savingVehicle: string,
 * product: string,
 * selectedItem: SelectedItem,
 * invoiceDate: Date,
 * incomeToUpdate: Income,
 * }} ValuesFormik
 * @typedef {import('formik').FormikProps<ValuesFormik>} FormikProps
 * @typedef {import('api/core').OptionName} OptionName
 * @typedef {import('utils').PersonalData} PersonalData
 * @typedef {import('api/core').Client} Client
 * @typedef {import('api/core').Product} Product
 * @typedef {import('api/core').Income} Income
 * @typedef {{
 * clientProducts: Array<Product>,
 * selectedItem: SelectedItem | null
 * }} State
 *
 * @typedef {Object} Props
 * @property {boolean} loadingBank
 * @property {string} country
 * @property {string} product
 * @property {Array<OptionName>} companies
 * @property {function} getClients
 * @property {function} getBankAccounts
 * @property {boolean} requestInProgress
 * @property {Array<Client>} clients
 * @property {Array<string>} incomeTypes
 * @property {function} getIncomeTypes
 * @property {Income} incomeToUpdate
 * @property {boolean} updateIncome
 *
 * @extends {Component<Props & FormikProps, & State}
 */

class FormNewIncome extends Component {
  /**
   * @type {State}
   */
  state = {
    clientProducts: [],
    selectedItem: null
  }

  /**
   * @param {FormikProps} prevProps
   */
  componentDidUpdate = prevProps => {
    const { values, getIncomeTypes } = this.props
    if (values.company !== prevProps.values.company) {
      getIncomeTypes(values.company)
    }
  }

  /**
   * @param {formatValue} formatValues
   */
  changeReceivableAmount = formatValues => {
    const { formattedValue, value, floatValue } = formatValues
    if (!formattedValue || !value) return
    const { setValues, values } = this.props
    setValues({
      ...values,
      receivableAmount: formattedValue,
      floatReceivableAmount: floatValue
    })
  }

  /**
   * @param {string} value
   */
  onChangeValue = debounce(value => {
    const { getClients } = this.props
    getClients(value)
  }, 200)

  /**
   * @param {SelectedItem} selectedItem
   */
  changeSelectedItem = selectedItem => {
    const { resetForm } = this.props
    this.setState(
      {
        selectedItem
      },
      () => {
        if (selectedItem) {
          this.getClientDetails(selectedItem)
        }
        resetForm()
      }
    )
  }

  /**
   * @param {Date} date
   */
  handleChange = date => {
    const { setFieldValue } = this.props
    setFieldValue('invoiceDate', date)
  }

  /**
   * @param {SelectedItem} selectedItem
   */
  getClientDetails = async selectedItem => {
    try {
      const { data } = await API.Clients.GetClientDetails(selectedItem.id)
      this.setState({ clientProducts: data.products })
    } catch (error) {
      console.error(error)
      Notification.error(
        'Ha ocurrido un errror cargando los productos del cliente'
      )
    }
  }

  render () {
    const {
      values,
      handleSubmit,
      isSubmitting,
      isValid,
      companies,
      clients,
      requestInProgress,
      incomeTypes,
      updateIncome
    } = this.props
    const { clientProducts, selectedItem } = this.state
    const clientProductsOptions = clientProducts.map(product => {
      return {
        id: product.uid,
        label: product.product_line
      }
    })
    return (
      <div className='newFlow'>
        <Form onSubmitCapture={handleSubmit} data-testid='form'>
          <div className='flex-start relative'>
            <div className='newFlow--field text-right'>
              <SerachSuggestions
                label='Buscar clientes'
                data={clients}
                requestInProgress={requestInProgress}
                onChangeValue={this.onChangeValue}
                onChange={this.changeSelectedItem}
              />
            </div>
            <div className='newFlow--field align-self-start'>
              <Field name='product'>
                {({ field }) => (
                  <SelectField
                    {...field}
                    label='Productos'
                    placeholder={`Productos del cliente ${
                      selectedItem ? selectedItem.name : ''
                    }`}
                    name='product'
                    id='product'
                    options={clientProductsOptions}
                    disabled={!selectedItem}
                    required={!!selectedItem}
                    optionalText
                  />
                )}
              </Field>
            </div>
          </div>
          <div className='flex-between'>
            <div className='newFlow--field text-right'>
              <Field name='company'>
                {({ field }) => (
                  <SelectField
                    {...field}
                    id='company'
                    name='company'
                    label='Empresa'
                    placeholder='Seleccione empresa'
                    options={getOptionsCompanies(companies)}
                    optionalText
                  />
                )}
              </Field>
              <ErrorMessage
                name='company'
                render={message => <Required message={message} />}
              />
            </div>
            <div className='newFlow--field input-full-width'>
              <label htmlFor='card_number'>
                Numero de tarjeta
                <span className='optional-text'>(opcional)</span>
              </label>
              <Field
                name='card_number'
                od='card_number'
                type='number'
                placeholder='Número de tarjeta'
                style={{ fontSize: '16px' }}
              />
            </div>
          </div>
          <div className='flex-between'>
            <div className='newFlow--field input-full-width text-right'>
              <label htmlFor='negotiator'>
                Negociador
                <span className='optional-text'>(opcional)</span>
              </label>
              <Field
                name='negotiator'
                id='negotiator'
                type='text'
                placeholder='Negociador'
                style={{ fontSize: '16px' }}
              />
            </div>
            <div className='newFlow--field'>
              <Field name='incomeType'>
                {({ field }) => (
                  <SelectField
                    {...field}
                    id='incomeType'
                    name='incomeType'
                    label='Tipo de ingreso'
                    placeholder='Tipo de ingreso'
                    options={incomeTypeOptions(incomeTypes)}
                    required
                  />
                )}
              </Field>
              <ErrorMessage
                name='incomeType'
                render={message => <Required message={message} />}
              />
            </div>
          </div>
          <div className='flex-between'>
            <div className='newFlow--field text-right'>
              <Field name='savingVehicle'>
                {({ field }) => (
                  <SelectField
                    {...field}
                    placeholder='Vehículo de ahorro'
                    label='Vehículo'
                    name='savingVehicle'
                    id='savingVehicle'
                    options={SAVING_VEHICLE}
                    required
                  />
                )}
              </Field>
              <ErrorMessage
                name='savingVehicle'
                render={message => <Required message={message} />}
              />
            </div>
            <div className='newFlow--field input-full-width'>
              <Field name='invoiceDate'>
                {({ field }) => (
                  <DatePicker
                    {...field}
                    name='invoiceDate'
                    id='invoiceDate'
                    width='100%'
                    label='Fecha de ingreso *'
                    placeholderText='DD/MM/YYYY'
                    selected={values.invoiceDate}
                    onChange={this.handleChange}
                    dateFormat='dd MMMM yyyy'
                    maxDate={new Date()}
                  />
                )}
              </Field>
              <ErrorMessage name='invoiceDate'>
                {message => <Required message={message} />}
              </ErrorMessage>
            </div>
          </div>
          <div className='flex-between'>
            <div className='newFlow--field text-right'>
              <Field name='currency'>
                {({ field }) => (
                  <SelectField
                    {...field}
                    label='Moneda'
                    placeholder='Moneda'
                    name='currency'
                    id='currency'
                    options={CURRENCIES}
                    required
                  />
                )}
              </Field>
              <ErrorMessage
                name='currency'
                render={message => <Required message={message} />}
              />
            </div>
            <div className='newFlow--field'>
              <Field component='div' name='receivableAmount'>
                <FieldAmount
                  name='receivableAmount'
                  label='Monto a cobrar'
                  placeholder='Monto a cobrar'
                  currency={values.currency}
                  value={values.receivableAmount}
                  onValueChange={this.changeReceivableAmount}
                  required
                />
              </Field>
              <ErrorMessage
                name='receivableAmount'
                render={message => <Required message={message} />}
              />
            </div>
          </div>
          <div className='flex-end bottom'>
            <Button
              type='submit'
              icon='check-all'
              buttonClass='primary top'
              loading={isSubmitting}
              disabled={isSubmitting && !isValid}
              testId='create-income'
            >
              {updateIncome ? 'Actualizar' : 'Guardar'}
            </Button>
            <LinkButton
              url='/accounting/income'
              icon='cancel'
              buttonClass='secondary top'
              testId='cancel-income'
            >
              Cancelar
            </LinkButton>
          </div>
        </Form>
      </div>
    )
  }
}

export default withFormik({
  mapPropsToValues: props => {
    if (!props.incomeToUpdate) return {}
    return {
      floatReceivableAmount: props.incomeToUpdate.receivable_amount || '',
      incomeType: props.incomeToUpdate.income_type || '',
      currency: props.incomeToUpdate.currency || '',
      receivableAmount: props.incomeToUpdate.receivable_amount || '',
      company: props.incomeToUpdate.company || '',
      savingVehicle: props.incomeToUpdate.saving_vehicle || '',
      product: props.incomeToUpdate.product_line || '',
      invoiceDate: props.incomeToUpdate.invoice_date
        ? new Date(props.incomeToUpdate.invoice_date)
        : '',
      negotiator: props.incomeToUpdate.negotiator,
      card_number: props.incomeToUpdate.card_number
    }
  },
  validationSchema,
  handleSubmit: async (values, { setSubmitting, props }) => {
    const data = {
      income_type: values.incomeType.toUpperCase(),
      receivable_amount: values.floatReceivableAmount,
      saving_vehicle: values.savingVehicle,
      currency: values.currency,
      uid: values.product ? values.product : null,
      country: props.country,
      company: values.company || null,
      invoice_date: moment(values.invoiceDate).format('YYYY-MM-DD'),
      id: props.incomeToUpdate ? props.incomeToUpdate.id : null,
      negotiator: values.negotiator,
      card_number: values.card_number
    }

    setSubmitting(true)
    const message = props.updateIncome ? 'actualizado' : 'creado'
    try {
      props.updateIncome
        ? await API.Accounting.UpdateIncome(data)
        : await API.Accounting.CreateIncome(data)
      Notification.success(`Ingreso ${message} correctamente`)
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error creando el ingreso')
    } finally {
      props.history.push('/accounting/income')
    }
    setSubmitting(false)
  }
})(FormNewIncome)
