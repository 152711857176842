// @ts-nocheck
import React, { useState, useEffect, Fragment } from 'react'
import Notification from 'cogo-toast'

import API from 'api/core'
import Paginator from 'shared/paginator'
import IconButton from 'shared/iconButton'
import Button from 'shared/button'
import Loading from 'shared/loader'
import Modal from 'shared/modal'
import RejectRequest from './RejectRequest'
import { STEPS_REQUEST_FAILED } from 'shared/catalogs'
import { LogoByProductLine } from 'utils'
import { Title } from 'css/utils'
import {
  CardFailed,
  WrapperError,
  Wrapper,
  WrapperInfo,
  WrapperSteps,
  Item,
  Logo,
  StepsSegment,
  WrapperButtons
} from './styles'

/**
 * @param {{step: string, productLine: string }} props
 */
const Steps = ({ step, productLine = 'rtd' }) => {
  const stepsByProductLine = STEPS_REQUEST_FAILED[productLine]
  const failedStep = stepsByProductLine.filter(item => item.code === step)
  return (
    <ul className='steps steps-failed'>
      {stepsByProductLine.map(({ id, code, label }) => {
        const isActive = step === 'start' ? true : failedStep[0].id === id + 1
        return (
          <StepsSegment
            isActive={step === 'start'}
            key={id}
            className={`steps-segment ${isActive ? 'is-active' : ''}`}
          >
            <span
              data-tooltip={label}
              className={`steps-marker has-tooltip-top has-tooltip-primary ${
                code === step ? 'is-danger has-tooltip-danger' : ''
              }`}
            >
              {id}
            </span>
          </StepsSegment>
        )
      })}
    </ul>
  )
}

const Failed = () => {
  const [failed, setFailed] = useState([])
  const [pagination, setPagination] = useState(null)
  const [loading, setLoading] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [toggleItem, setToggleItem] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedRequest, setSelectedRequest] = useState(null)

  useEffect(() => {
    getListFailed()
  }, [])

  async function getListFailed () {
    setLoading(true)
    try {
      const {
        data,
        pagination,
        status,
        errors
      } = await API.ContractRequests.ListFailed({
        page_size: 10
      })
      if (status === 'success') {
        setFailed(data)
        setPagination(pagination)
      } else {
        Notification.error(errors.detail)
      }
    } catch (error) {
      console.error(error)
      Notification.error(error)
    } finally {
      setLoading(false)
    }
  }

  /**
   * @param {null | number} id
   */
  function handleToggle (id) {
    const toggleItemId = id === toggleItem ? null : id
    setToggleItem(toggleItemId)
  }

  const handleToggleModal = request => {
    setIsOpen(!isOpen)
    setSelectedRequest(request)
  }

  const handleApprove = request => {
    setSelectedRequest(request)
    approveContract(request)
  }

  const approveContract = async request => {
    setInProgress(true)
    try {
      await API.ContractRequests.ApproveContract(request.contract.id, {
        reference: request.contract.reference
      })
      Notification.success('Contrato creado correctamente')
      getListFailed()
    } catch (error) {
      console.error(error)
      Notification.error('Ocurrió un error, intenta nuevamente')
    } finally {
      setInProgress(false)
    }
  }

  return (
    <Fragment>
      <Title>Solicitudes fallidas</Title>
      {loading && <Loading />}
      {!loading &&
        failed &&
        failed.length === 0 &&
        'No hay solicitudes fallidas'}
      {failed &&
        failed.map(({ request, step_in_process: stepInProcess, error }) => {
          const hasLoading = inProgress && selectedRequest.id === request.id
          return (
            <CardFailed key={request.id}>
              <WrapperButtons>
                <Button
                  buttonClass='secondary'
                  icon='cancel'
                  onClick={() => handleToggleModal(request)}
                >
                  Rechazar
                </Button>
                <Button
                  icon='check'
                  buttonClass='primary'
                  loading={hasLoading}
                  disabled={hasLoading}
                  onClick={() => handleApprove(request)}
                >
                  Aprobar
                </Button>
              </WrapperButtons>
              <div
                role='button'
                style={{ cursor: 'pointer' }}
                onClick={() => handleToggle(request.id)}
              >
                <WrapperSteps>
                  <Steps
                    step={stepInProcess}
                    productLine={request.product_line}
                  />
                  <IconButton icon='chevron-down' className='icon' />
                </WrapperSteps>
                <Wrapper>
                  <WrapperInfo>
                    <Logo>{LogoByProductLine(request.product_line)}</Logo>
                    <Item>
                      <span>Contrato</span>
                      <p>{request.contract.id}</p>
                    </Item>
                    <Item>
                      <span>Contrato uid</span>
                      <p>{request.contract.uid}</p>
                    </Item>
                  </WrapperInfo>
                  <WrapperInfo last>
                    <Item email>
                      <span>Correo del cliente</span>
                      <p>{request.email}</p>
                    </Item>
                    <Item email>
                      <span>Correo EDC</span>
                      <p>{request.edc}</p>
                    </Item>
                    <Item email>
                      <span>Solicitud</span>
                      <p>{request.id}</p>
                    </Item>
                  </WrapperInfo>
                </Wrapper>
              </div>
              {toggleItem === request.id && (
                <WrapperError>
                  <Item>
                    <span>Error</span>
                    <p>{error}</p>
                  </Item>
                </WrapperError>
              )}
            </CardFailed>
          )
        })}
      {pagination && <Paginator totalPages={pagination.total_pages} />}
      <Modal
        noFooter
        isActive={isOpen}
        title='Rechazar solicitud'
        toggleModal={handleToggleModal}
      >
        <RejectRequest
          handleCloseModal={() => setIsOpen(!isOpen)}
          request={selectedRequest}
        />
      </Modal>
    </Fragment>
  )
}
export default Failed
