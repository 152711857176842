import React from 'react'
import className from 'classnames'

import Button from 'shared/button'
import { FLAGS } from 'shared/catalogs'

/**
 * @typedef {{
 * onChangeCounty: function,
 * country: string,
 * }} PropsCuntryButtons
 * @param {PropsCuntryButtons} Props
 */
const CuntryButtons = ({ onChangeCounty, country }) => {
  return (
    <div>
      <div style={{ marginBottom: '4rem' }}>
        <h2
          className={className('subtitle', {
            warning: !country
          })}
        >
          Primero debes selecionar país
        </h2>
        <div className='flex-between'>
          <Button
            flag={FLAGS.mx}
            buttonClass={country === 'MX' ? 'is-country' : 'create'}
            onClick={() => onChangeCounty('MX')}
            testId='mx'
          >
            México
          </Button>
          <Button
            flag={FLAGS.co}
            buttonClass={country === 'CO' ? 'is-country' : 'create'}
            onClick={() => onChangeCounty('CO')}
            testId='co'
          >
            Colombia
          </Button>
          <Button
            flag={FLAGS.ar}
            buttonClass={country === 'AR' ? 'is-country' : 'create'}
            onClick={() => onChangeCounty('AR')}
            testId='ar'
          >
            Argentina
          </Button>
          <Button
            flag={FLAGS.es}
            buttonClass={country === 'ES' ? 'is-country' : 'create'}
            onClick={() => onChangeCounty('ES')}
            testId='es'
          >
            España
          </Button>
          <Button
            flag={FLAGS.it}
            buttonClass={country === 'IT' ? 'is-country' : 'create'}
            onClick={() => onChangeCounty('IT')}
            testId='it'
          >
            Italia
          </Button>
          <Button
            flag={FLAGS.pt}
            buttonClass={country === 'PT' ? 'is-country' : 'create'}
            onClick={() => onChangeCounty('PT')}
            testId='pt'
          >
            Portugal
          </Button>
        </div>
      </div>
    </div>
  )
}
export default CuntryButtons
