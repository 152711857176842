// Libraries
import React from 'react'
import es from 'date-fns/locale/es'
import DatePicker, { registerLocale } from 'react-datepicker'
import styled, { css } from 'styled-components'

// Components
import { lightgreyHover, mediumGrey, buttonPrimary, appleGrey } from 'css/colors'
import { FlexWrapper } from 'css/utils'

registerLocale('es', es)

const Content = styled.div.attrs(({ width }) => ({
  width: width || '30%'
}))`
  width: ${props => props.width};
  & > label {
    font-weight: 700;
  }
  & input {
    border: 1px solid ${lightgreyHover};
    border-radius: 3px;
    padding: 0.5rem;
    width: 100%;
    cursor: pointer;
    background: transparent;
    font-size: 1em;
    &:hover {
      border-color: ${mediumGrey};
    }
  }
`
const WrapperDatePicker = styled(FlexWrapper)`
  position: relative;
  ${props =>
    props.disabled &&
    css`
      & input {
        background-color: ${appleGrey};
        cursor: not-allowed;
      }
    `}
  margin-top: 0.5rem;
  & > div {
    position: relative;
    width: 100%;
  }
  .react-datepicker-popper {
    z-index: 9999 !important;
  }
  .react-datepicker__input-container {
    width: 100%;
    z-index: 39;
  }
`
const Icon = styled.i`
  position: absolute;
  right: 10px;
  top: 6px;
  color: ${buttonPrimary};
`

/**
 * @typedef {{
 * selected?: Date,
 * onChange?: any,
 * onSelect?: any,
 * placeholderText?: string,
 * width?: string,
 * label?: string,
 * name?: string,
 * minDate?: Date,
 * maxDate?: Date,
 * className?: string,
 * dateFormat?: string,
 * filterDate?: Date | boolean,
 * disabled?: boolean,
 * withPortal?: boolean,
 * customInput?: React.ReactNode,
 * openToDate?: Date,
 * iconStyle?: object,
 * }} PropsCustomDatePicker
 *
 * @param {PropsCustomDatePicker} props
 */
const CustomDatePicker = ({
  selected,
  onChange,
  placeholderText,
  width,
  label,
  name,
  minDate,
  maxDate,
  className,
  dateFormat,
  filterDate,
  disabled,
  withPortal,
  customInput,
  openToDate,
  onSelect,
  iconStyle,
  ...props
}) => {
  return (
    <Content width={width}>
      <label htmlFor={name}>{label}</label>
      <WrapperDatePicker disabled={disabled}>
        <DatePicker
          {...props}
          locale='es'
          autoComplete='off'
          name={name}
          id={name}
          className={className}
          dateFormat={dateFormat}
          selected={selected}
          onChange={onChange}
          onSelect={onSelect}
          placeholderText={placeholderText}
          minDate={minDate}
          maxDate={maxDate}
          filterDate={filterDate}
          disabled={disabled}
          withPortal={withPortal}
          openToDate={openToDate}
          customInput={customInput}
        />
        <Icon className='mdi mdi-calendar-edit' style={iconStyle} />
      </WrapperDatePicker>
    </Content>
  )
}
export default CustomDatePicker
