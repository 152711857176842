// Libraries
import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import styled from 'styled-components'

// Components
import PersonalInformation from './personalInformation'
import Products from './products'
import ClienDocuments from './clientDocuments'
import LinkButton from 'shared/linkButton'
import Loader from 'shared/loader'
import { useGetData } from 'utils'
import { Title } from 'css/utils'
import { colorSecondary, white } from 'css/colors'
import API from 'api/core'

const FONT_SIZE = '2.5rem'
const Contact = styled.div`
  & p {
    margin-bottom: 1rem;
  }
`
const WrapperClient = styled.div`
  background: ${colorSecondary};
  margin-left: -1.59rem;
  margin-right: -1.59rem;
  margin-top: -1.32rem;
`
const Client = styled.div`
  color: ${white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 12rem;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 1.5rem;
  padding-bottom: 2rem;
`

const Status = styled.p`
  font-size: 16px;
  color: #12a84c;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`
const WrapperTabs = styled.section`
  margin: 0 auto;
`

/**
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @param {RouteComponentProps} props
 */
const Details = props => {
  const {
    match: { params }
  } = props
  const [data, loading] = useGetData(
    API.Clients.GetClientDetails(params.uid),
    []
  )
  const { personal_data: personalData, products, documents } = data
  return (
    <div>
      {!personalData ? (
        <Loader />
      ) : (
        <Fragment>
          <WrapperClient>
            <LinkButton
              url='/search-clients'
              icon='arrow-left-thick'
              buttonClass='link top'
            >
              Buscar clientes
            </LinkButton>
            <Client>
              <Title color={white} size={FONT_SIZE}>
                <Status>Estado {personalData && personalData.status}</Status>
                {personalData &&
                  `${personalData.name} ${personalData.first_surname}`}
              </Title>
              {personalData && (
                <Contact>
                  <p>{personalData.email}</p>
                  <p>{personalData.mobile_phone}</p>
                </Contact>
              )}
            </Client>
          </WrapperClient>
          <WrapperTabs>
            <Tabs>
              <TabList className='main-tab'>
                <Tab>DATOS PERSONALES</Tab>
                <Tab>PRODUCTOS</Tab>
                <Tab>DOCUMENTOS</Tab>
              </TabList>
              <TabPanel>
                <PersonalInformation
                  personalData={personalData}
                  loading={loading}
                />
              </TabPanel>
              <TabPanel>
                <Products products={products} loading={loading} />
              </TabPanel>
              <TabPanel>
                <ClienDocuments documents={documents} loading={loading} />
              </TabPanel>
            </Tabs>
          </WrapperTabs>
        </Fragment>
      )}
    </div>
  )
}
export default withRouter(Details)
