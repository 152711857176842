// Libraries
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import update from 'immutability-helper'

// Components
import API from 'api/core'
import PaymentsTable from 'shared/table/transactions'
import Title from 'shared/title'
import Button from 'shared/button'

const title = 'Gateway de pagos'
const breadcrumbLocation = [{ label: 'Gateway de pagos', route: '/payments' }]

class Payments extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selects: {
        status: [
          { value: '', label: 'Todos' },
          { value: 'completed', label: 'Completado' },
          { value: 'pending', label: 'Pendiente' },
          { value: 'failed', label: 'Fallido' }
        ]
      },
      filters: {
        status: '',
        date: '',
        uid: ''
      },
      transactions: []
    }
  }

  componentDidMount () {
    if (this.props.match.path === '/payments/:page') {
      this.setPage(Number(this.props.match.params.page))
    } else {
      this.getTransactions()
    }
  }

  getTransactions (args) {
    const page =
      typeof args !== 'undefined' && typeof args.page !== 'undefined'
        ? args.page
        : this.state.currentPage
    const params = Object.assign(this.state.filters, { page: page })
    API.Transactions.List(params)
      .then(response => {
        this.setState({ transactions: response.data })
        if (typeof response.pagination.page_number !== 'undefined') {
          this.setState({
            currentPage: response.pagination.page_number,
            totalPages: response.pagination.total_pages,
            isLoading: false
          })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  setPage (page) {
    this.getTransactions({ page: page })
  }

  handleChangeSelect (name) {
    return selected => {
      const value = selected.value
      const newStateFilters = Object.assign(this.state.filters, {
        [name]: value
      })
      this.setState({ filters: newStateFilters })
      this.getTransactions()
    }
  }

  handleChangeInput (name) {
    return selected => {
      const value = selected.target.value
      const newStateFilters = Object.assign(this.state.filters, {
        [name]: value
      })
      this.setState({ filters: newStateFilters })
      this.getTransactions()
    }
  }

  renderFilters () {
    return (
      <div className='field is-horizontal'>
        <div className='field-body'>
          <div className='field'>
            <label>Status</label>
            <Select
              options={this.state.selects.status}
              onChange={this.handleChangeSelect('status')}
              name='status'
              value={this.state.filters.status}
            />
          </div>
          <div className='field'>
            <label>Fecha</label>
            <input
              type='date'
              className='input'
              onChange={this.handleChangeInput('date')}
              name='date'
              value={this.state.filters.date}
            />
          </div>
          <div className='field'>
            <label>UID</label>
            <input
              type='text'
              className='input'
              onChange={this.handleChangeInput('uid')}
              name='uid'
              value={this.state.filters.uid}
            />
          </div>
        </div>
      </div>
    )
  }

  updateState (id) {
    this.setState({
      transactions: update(this.state.transactions, {
        [id]: { status: { $set: 'canceled' } }
      })
    })
  }

  render () {
    return (
      <div>
        <Title title={title} breadcrumbLocation={breadcrumbLocation} />
        <div className='columns'>
          <div className='column'>
            <Link to={'/payments/new'}>
              <Button buttonClass='primary' className='is-pulled-right'>
                Crear pago
              </Button>
            </Link>
          </div>
        </div>
        {this.renderFilters()}
        <PaymentsTable
          transactions={this.state.transactions}
          updateState={this.updateState.bind(this)}
        />
      </div>
    )
  }
}

export default Payments
