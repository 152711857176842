import React from 'react'

const Debt = ({ title, value }) => (
  <div className='field'>
    <label className='label'>{title}</label>
    <div className='control'>
      {value.map((val, index) => (
        <div key={index}>
          <span className='is-bold'>{val}</span>
        </div>
      ))}
    </div>
  </div>
)

export default Debt
