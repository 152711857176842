// Libraries
import React, { Component } from 'react'
import styled from 'styled-components'
import Notification from 'cogo-toast'

// Components
import { FlexWrapper } from 'css/utils'
import PreviewFile from 'shared/previewFile'
import Toggle from '../../../components/toggle'
import SelectField from 'shared/selectField'
import Button from 'shared/button'
import Identification from './identification'
import Address from './address'
import Beneficiary from './beneficiary'

import API from 'api/core'
import { DOCUMENT_TITLES } from 'shared/catalogs'

const Content = styled(FlexWrapper)`
  background: white;
  padding: 4rem;
`
const ToggleWrapper = styled.div`
  width: 40%;
  margin-right: 3rem;
  align-self: baseline;
`
const DocumentWrapper = styled.div`
  align-self: flex-start;
  width: 60%;
  position: sticky;
  position: -webkit-sticky;
  top: 60px;
  & > div:nth-child(1) {
    margin-bottom: 1rem;
  }
  & > div:last-child {
    margin-left: 0;
  }
`
const Select = styled(FlexWrapper)`
  & > button {
    align-self: flex-end;
  }
`

/**
 * @typedef {import('api/core').TypeContractClient} TypeContractClient
 * @typedef {{
 * client: TypeContractClient,
 * invalid: object,
 * country: string
 * productLine?: string
 * handleChangeValue: function,
 * clearInvalid: function,
 * contractUid: string,
 * }} PropsClient
 *
 * @typedef {{
 * documentSelected: object,
 * updating: boolean,
 * }} State
 * @extends {Component<PropsClient, State>}
 *
 */

export default class Client extends Component {
  /**
   * @type {State}
   */
  state = {
    documentSelected: {},
    updating: false
  }

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  selectDocument = event => {
    const { client } = this.props
    const { value } = event.currentTarget
    const documents = client.documents_identity.filter(
      item => item.uid === value
    )
    const clientDocuments = Object.assign({}, ...documents)
    this.setState({
      documentSelected: clientDocuments
    })
  }

  /**
   * @param {Object} data
   */
  hasModified = data => {
    const modified = Object.entries(data).map(item => item[1].modified)
    return modified.includes(true)
  }

  updateDocument = async () => {
    const { contractUid } = this.props
    const { documentSelected } = this.state
    this.setState({ updating: true })
    try {
      const { data } = await API.ContractRequests.UpdateDocument(
        contractUid,
        documentSelected.uid
      )
      this.setState(state => ({
        documentSelected: { ...state.documentSelected, url: data.url }
      }))
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error actualizando el archivo')
    } finally {
      this.setState({ updating: false })
    }
  }

  render () {
    const {
      client,
      country,
      productLine,
      handleChangeValue,
      invalid,
      clearInvalid
    } = this.props

    const { documentSelected, updating } = this.state

    const optionDocuments = client.documents_identity.map(
      ({ name, uid, description }) => {
        return {
          id: uid,
          // @ts-ignore
          label: DOCUMENT_TITLES[name] || description
        }
      }
    )

    const nameKey = Object.keys(invalid).map(item => {
      const [key] = item.split('.')
      if (invalid[item]) return key
    })

    return (
      <Content>
        <ToggleWrapper>
          <Toggle
            title='Información de identidad del cliente'
            isInvalid={nameKey.includes('client')}
            modified={this.hasModified(client)}
          >
            <Identification
              invalid={invalid}
              client={client}
              country={country}
              productLine={productLine}
              handleChangeValue={handleChangeValue}
              clearInvalid={clearInvalid}
            />
          </Toggle>
          <Toggle
            title='Información de dirección del cliente'
            isInvalid={nameKey.includes('address')}
            modified={this.hasModified(client.address)}
          >
            <Address
              invalid={invalid}
              address={client.address}
              country={country}
              handleChangeValue={handleChangeValue}
              clearInvalid={clearInvalid}
            />
          </Toggle>
          {country === 'mx' &&
            (productLine !== 'credito' && (
              <Toggle
                title='Información del beneficiario'
                isInvalid={nameKey.includes('beneficiary')}
                modified={this.hasModified(client.beneficiary)}
              >
                <Beneficiary
                  invalid={invalid}
                  beneficiary={client.beneficiary}
                  handleChangeValue={handleChangeValue}
                  clearInvalid={clearInvalid}
                />
              </Toggle>
            ))}
        </ToggleWrapper>
        <DocumentWrapper>
          <Select>
            <SelectField
              name='docuemnts'
              label='Documentos de identificación del cliente'
              placeholder='Selecciona una opción'
              options={optionDocuments}
              value={documentSelected && documentSelected.uid}
              onChange={this.selectDocument}
              loading={updating}
            />
            <Button
              buttonClass='primary left'
              tooltip='Actualizar'
              icon='refresh'
              disabled={!documentSelected || !documentSelected.url}
              onClick={this.updateDocument}
            />
          </Select>
          {documentSelected && (
            <PreviewFile
              key={documentSelected.uid}
              url={documentSelected.url}
              title={documentSelected.name}
            />
          )}
        </DocumentWrapper>
      </Content>
    )
  }
}
