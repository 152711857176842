// Libraries
import React, { Component } from 'react'
import Notification from 'cogo-toast'

// Components
import { diffFromNowHumanize, parseQuery } from 'utils'
import { MOVEMENTS_CSV_STATUS } from 'shared/catalogs'
import { Title } from 'css/utils'
import API from 'api/bank'
import LinkButton from 'shared/linkButton'
import Paginator from 'shared/paginator'
import UploadFile from './uploadFile'
import Button from 'shared/button'
import Modal from 'shared/modal'

/**
 * @typedef {import('react-router-dom').RouteComponentProps} RouteComponentProps
 * @typedef {import('api/bank').Movement} Movement
 * @typedef {import('api/bank').Pagination} Pagination
 * @typedef {{
 * movements: Array<Movement>,
 * requestInProgress: boolean,
 * pagination: Pagination,
 * modalOpen: boolean
 * }} State
 *
 * @extends {React.Component<RouteComponentProps, State>}
 */
class Movements extends Component {
  /**
   * @type {State}
   */
  state = {
    movements: [],
    requestInProgress: false,
    modalOpen: false,
    pagination: {
      total_pages: 1,
      total_entries: 1,
      page_number: 1,
      page_size: 1
    }
  }

  /**
   * @param {RouteComponentProps} prevProps
   */
  componentDidUpdate = prevProps => {
    if (this.props.location.search !== prevProps.location.search) {
      this.getMovementsList()
    }
  }

  componentDidMount = () => {
    this.getMovementsList()
  }

  /**
   * @param {Movement} movement
   */
  handleSendFile = movement => {
    this.setState(state => ({
      movements: [movement, ...state.movements]
    }))
  }

  toggleModal = () => {
    this.setState(state => ({ modalOpen: !state.modalOpen }))
  }

  getMovementsList = async () => {
    const { location } = this.props
    const page = parseQuery(location.search).page || 1
    this.setState({ requestInProgress: true })
    try {
      const { data, pagination } = await API.Movements.List({ page })
      this.setState({
        movements: data,
        pagination
      })
    } catch (error) {
      console.error(error)
      Notification.error('Ha ocurrido un error cargando los movimientos')
    } finally {
      this.setState({ requestInProgress: true })
    }
  }

  render () {
    const { movements, pagination, modalOpen } = this.state
    return (
      <div>
        <div className='flex-between'>
          <Title>Movimientos subidos</Title>
          <Button buttonClass='upload' icon='upload' onClick={this.toggleModal}>
            Subir movimientos
          </Button>
        </div>
        <hr />
        <p className='register-results no-margin'>
          {`Hay ${pagination ? pagination.total_entries : '0'} resultados`}
        </p>
        <table className='table'>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Estado</th>
              <th>Creación</th>
              <th>Uid</th>
            </tr>
          </thead>
          <tbody>
            {movements &&
              movements.map(movement => {
                return (
                  <tr key={movement.uid}>
                    <td>
                      <LinkButton
                        url={`/bank-accounts/movement/${movement.uid}`}
                        buttonClass='link'
                      >
                        {movement.name}
                      </LinkButton>
                    </td>
                    <td>{MOVEMENTS_CSV_STATUS[movement.status]}</td>
                    <td>{diffFromNowHumanize(movement.insertedAt)}</td>
                    <td>{movement.uid}</td>
                  </tr>
                )
              })}
          </tbody>
        </table>
        {pagination && <Paginator totalPages={pagination.total_pages} />}
        <Modal
          title='Subir movimientos'
          isActive={modalOpen}
          toggleModal={this.toggleModal}
          noFooter
        >
          <UploadFile
            handleSendFile={this.handleSendFile}
            toggleModal={this.toggleModal}
          />
        </Modal>
      </div>
    )
  }
}
export default Movements
