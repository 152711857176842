// Libraries
import React from 'react'

// Components
import { DOCUMENT_TITLES } from './constants'

const PdfViewer = ({ title, src }) => (
  <React.Fragment>
    <div className='document-container'>
      <iframe
        className='pdf-viewer'
        style={{ width: '100%', minHeight: '500px', height: '100%' }}
        src={`https://docs.google.com/gview?url=${encodeURIComponent(
          src
        )}&embedded=true`}
      />
    </div>
    <span className='label'>{DOCUMENT_TITLES[title] || 'Otros'}</span>
    <div
      style={{ marginTop: 10 }}
      className='field is-grouped is-grouped-centered'
    >
      <p className='control'>
        <a className='button is-primary' href={src} target='_blank'>
          <i className='mdi mdi-download' />
          Descargar
        </a>
      </p>
    </div>
  </React.Fragment>
)

export default PdfViewer
