// libraries
import React from 'react'

// Components
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Button from 'shared/button'

/**
 * @typedef {object} ModalProps
 * @property {boolean} [isActive=false]
 * @property {function} toggleModal
 * @property {function} onSubmit
 * @property {string} [title]
 * @property {string} [textCancel]
 * @property {string} [textSave]
 * @property {boolean} [noFooter=false]
 * @property {React.ReactNode} children
 * @property {boolean} [requestInProgress]
 * @property {boolean} [disabled=false]
 * @property {boolean} [loading=false]
 * @property {string} [testId]
 *
 * @param {ModalProps} props
 */

const Modal = ({
  isActive,
  toggleModal,
  onSubmit,
  title,
  noFooter,
  children,
  requestInProgress,
  disabled,
  textCancel = 'Cancelar',
  textSave = 'Guardar',
  testId
}) => {
  if (!isActive) return null
  const header = (
    <header className='modal-card-head'>
      <div className='modal-card-title'>{title}</div>
      <button
        className='delete'
        aria-label='close'
        onClick={() => toggleModal()}
      />
    </header>
  )
  const footer = !noFooter && (
    <footer className='modal-card-foot'>
      <Button
        type='button'
        buttonClass='primary'
        loading={requestInProgress}
        onClick={onSubmit}
        icon={disabled ? 'radiobox-blank' : 'check'}
        disabled={disabled}
        testId={testId}
      >
        {textSave}
      </Button>
      <Button
        type='button'
        buttonClass='secondary'
        disabled={requestInProgress}
        onClick={toggleModal}
        icon='cancel'
      >
        {textCancel}
      </Button>
    </footer>
  )
  return (
    <div className={classNames('modal', { 'is-active': isActive })}>
      <div className='modal-background' />
      <div className='modal-card'>
        {header}
        <section className='modal-card-body'>{children}</section>
        {footer}
      </div>
    </div>
  )
}

export default Modal

Modal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  noFooter: PropTypes.bool,
  requestInProgress: PropTypes.bool,
  children: PropTypes.node
}

Modal.defaultProps = {
  isActive: false,
  onSubmit: () => {},
  title: '',
  noFooter: false,
  requestInProgress: false
}
