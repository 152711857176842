import React, { useState } from 'react'
import styled from 'styled-components'
import Notification from 'cogo-toast'

import SelectField from 'shared/selectField'
import Button from 'shared/button'
import API from 'api/core'
import { getFileName, downloadCSV } from 'utils'
import { buttonPrimary } from 'css/colors'

const WrapperButtons = styled.div`
  margin-top: 1.5rem;
  text-align: right;
  display: flex;
  justify-content: flex-end;
`
const DownloadFile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${buttonPrimary};
  border-radius: 4px;
  width: 100%;
  & > p {
    color: ${buttonPrimary};
    width: 100%;
    text-align: center;
  }
`
/**
 * @param {function | any} toggleModal
 */
const DownloadUnpayable = ({ toggleModal }) => {
  const [fieldvalue, setFieldValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState('')

  /**
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  function hadleChange (event) {
    const { value } = event.currentTarget
    setFieldValue(value)
    getFileUnpayable(value)
  }

  /**
   * @param {string} type
   */
  async function getFileUnpayable (type) {
    setLoading(true)
    try {
      const { url } = await API.Accounting.GetFileUnpayable(type)
      setFile(url)
      Notification.success('Archivo generado con éxito')
    } catch (error) {
      console.error(error)
      Notification.error(
        'Ocurrio un error generando el archivo. Intenta de nuevo'
      )
      toggleModal()
    } finally {
      setLoading(false)
    }
  }

  async function handleDownload () {
    const fileName = file ? getFileName(file) : ''
    await downloadCSV(file, fileName)
    toggleModal()
  }

  return (
    <div>
      <form>
        <SelectField
          name='unpayable'
          placeholder='Selecciona una opción'
          label='Selecciona el proceso que deseas ejecutar'
          loading={loading}
          disabled={loading}
          value={fieldvalue}
          onChange={e => hadleChange(e)}
          options={[
            { id: 'UNPAYABLE', label: 'Incobrables' },
            { id: 'CANCELLED', label: 'Cancelados' }
          ]}
        />
        <WrapperButtons>
          {file && (
            <DownloadFile>
              <p>{getFileName(file)}</p>
              <Button
                buttonClass='primary'
                icon='download'
                onClick={() => handleDownload()}
              >
                Descargar
              </Button>
            </DownloadFile>
          )}
        </WrapperButtons>
      </form>
    </div>
  )
}
export default DownloadUnpayable
