export const DOCUMENT_TITLES = {
  DOCUMENT_PASSPORT: 'Pasaporte',
  DOCUMENT_IDENTITY_FRONT: 'Documento de identidad frente',
  DOCUMENT_IDENTITY_BACK: 'Documento de identidad reverso',
  DOCUMENT_ADDRESS_FRONT: 'Comprobante de domicilio frente',
  DOCUMENT_ADDRESS_BACK: 'Comprobante de domicilio reverso',
  DOCUMENT_CREDIT_REPORT: 'Reporte de crédito',
  DOCUMENT_SETTLEMENT_PLAN: 'Plan de liquidación',
  ADDRESS_COMPROBANT: 'Comprobante de domicilio',
  DEBT_ATTACHMENT: 'Comprobante de deuda'
}
