// Libraries
import React from 'react'

// Components
import Button from 'shared/button'
import SelectField from 'shared/selectField'
import { getOptions } from 'utils'
import { COUNTRIES } from 'shared/catalogs'

/**
 * @typedef {{
 * id: string,
 * label: string,
 * }} OptionSelect
 *
 * @typedef {{
 * onCleanFilters: function,
 * onChangeField: function,
 * onFilter: function,
 * filters: object,
 * className?: string,
 * name?: string,
 * typePlaceholder?: string,
 * optionStatus?: Array<OptionSelect>,
 * optionTypes?: Array<OptionSelect>
 * loadingIncome?: boolean,
 * }} PropsFiltersAccounting
 *
 * @param {PropsFiltersAccounting} props
 */
const FiltersAccounting = ({
  onCleanFilters,
  onChangeField,
  onFilter,
  filters,
  optionStatus,
  optionTypes,
  className,
  typePlaceholder,
  name,
  loadingIncome
}) => {
  return (
    <div className='flex-end'>
      <div
        className={`flex-end field-space field-width ${className}`}
        style={{ alignItems: 'flex-end' }}
      >
        <SelectField
          label='País'
          name='country'
          placeholder='País'
          options={getOptions(COUNTRIES)}
          value={filters.country}
          onChange={onChangeField}
        />
        {optionTypes && (
          <SelectField
            label='Selecciona el tipo'
            name={name}
            placeholder={typePlaceholder}
            options={optionTypes}
            value={filters[name]}
            onChange={onChangeField}
            disabled={!filters.country}
            loading={loadingIncome}
          />
        )}
        {optionStatus && (
          <SelectField
            label='Estado'
            name='status'
            placeholder='Estado'
            options={optionStatus}
            value={filters.status}
            onChange={onChangeField}
          />
        )}
        <Button
          buttonClass='primary'
          icon='filter'
          onClick={onFilter}
          testId={`filter-${name}`}
        >
          Filtrar
        </Button>
        <Button
          buttonClass='clear'
          icon='eraser'
          onClick={onCleanFilters}
          type='button'
          testId='clean-filter'
        >
          Limpiar
        </Button>
      </div>
    </div>
  )
}
export default FiltersAccounting
